class StudentQuiz {
  constructor(
    id,
    title,
    description,
    sessions,
    questions_number,
    duration,
    min_points,
    excellence_points,
    max_points,
    already_passed,
    already_done_in_session,
    unfinished,
    subscribed,
    is_session_open,
    already_tried
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.sessions = sessions;
    this.questions_number = questions_number;
    this.duration = duration;
    this.min_points = min_points;
    this.excellence_points = excellence_points;
    this.max_points = max_points;
    this.already_passed = already_passed;
    this.already_done_in_session = already_done_in_session;
    this.unfinished = unfinished;
    this.subscribed = subscribed;
    this.is_session_open = is_session_open;
    this.already_tried = already_tried;
  }
}

export default StudentQuiz;
