const state = {
  loadingQueue: 0,
};
const mutations = {
  /**
   * Mutation that adds a unit to the loadingQueue
   *
   * @returns {null}
   */
  startLoading: (state) => {
    state.loadingQueue++;
  },
  /**
   * Mutation that removes a unit to the loadingQueue
   *
   * @returns {null}
   */
  stopLoading: (state) => {
    if (state.loadingQueue !== 0) {
      state.loadingQueue--;
    }
  },
  /**
   * Mutation that sets loadingQueue to 0
   *
   * @returns {null}
   */
  removeLoading: (state) => {
    state.loadingQueue = 0;
  },
};

const getters = {
  /**
   * Getter that returns the loading state
   *
   * @returns {Boolean} loadingState
   */
  getLoading: (state) => state.loadingQueue > 0,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
