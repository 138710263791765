<template>
  <v-main id="container">
    <v-container>
      <v-card
        class="pa-5 mt-3"
        color="transparent"
        dark
        flat
      >
        <v-row>
          <v-col>
            <v-card-title align="left">
              <v-col
                cols="12"
                md="6"
                class="px-0"
              >
                <v-btn
                  color="red darken-2"
                  dark
                  large
                  class="btn-font"
                  @click="openModal"
                >
                  <v-icon class="mr-1">
                    mdi-delete
                  </v-icon>
                  ELIMINA CLASSI
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="px-0"
              >
                <v-text-field
                  v-model="searchClassrooms"
                  append-icon="mdi-magnify"
                  label="Cerca..."
                  single-line
                  hide-details
                />
              </v-col>
            </v-card-title>
            <v-data-table
              :headers="classroomsHeaders"
              :items="allClassrooms"
              :search="searchClassrooms"
              item-key="id"
              class="data-table"
            >
              <template #[`item.deleteClass`]="{ item }">
                <v-icon 
                  @click.stop="openDeleteClassModal(item)"
                >
                  mdi-delete-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <Modal
        v-if="showConfirmationModal"
        title="ELIMINAZIONE CLASSI"
        @closeModal="closeModal"
      >
        <template #content-center>
          <v-row>
            <v-col cols="12">
              <v-card-text class="modal-text">
                <p>
                  ATTENZIONE! Sei sicuro di voler eliminare tutte le classi?
                </p>
                <p />
                <p>
                  L'operazione è irreversibile. Per procedere digita "elimina"
                  nel box sottostante e clicca sul bottone "ELIMINA".
                </p>
              </v-card-text>
            </v-col>
            <v-col
              cols="12"
              align="center"
            >
              <v-text-field
                v-model="confirmationString"
                label="Stringa conferma eliminazione"
                hint="Digita la stringa 'elimina' e clicca sul bottone 'ELIMINA'"
                outlined
                class="modal-confirmation-string confirm-deletion-size"
                color="red accent-1"
                center
                prepend-inner-icon="mdi-skull"
              />
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-row justify="center">
              <v-btn
                large
                plain
                class="white--text mx-3"
                @click="closeModal"
              >
                <v-icon>mdi-close</v-icon>
                ANNULLA
              </v-btn>
              <v-btn
                color="red darken-2"
                class="white--text mx-3"
                large
                :disabled="!(confirmationString === 'elimina')"
                @click="confirmDelete"
              >
                <v-icon class="mr-1">
                  mdi-delete
                </v-icon>
                ELIMINA
              </v-btn>
            </v-row>
          </v-card-actions>
        </template>
      </Modal>

      <Modal
        v-if="showSingleClassDeleteModal"
        title="Conferma Eliminazione Classe"
        :show-close-button="false"
        @closeModal="showSingleClassDeleteModal = false"
      >
        <template #content-center>
          <v-row>
            <v-col>
              <v-card-text class="modal-text">
                <p>
                  ATTENZIONE! Sei sicuro di voler eliminare la
                  classe
                  <strong>{{ classroomToDelete.name }}</strong>?
                </p>
              </v-card-text>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-row justify="center">
              <v-btn
                large
                plain
                class="white--text mx-3"
                @click="showSingleClassDeleteModal = false"
              >
                <v-icon class="mr-1">
                  mdi-close
                </v-icon>
                <span>ANNULLA</span>
              </v-btn>
              <v-btn
                color="red darken-2"
                class="white--text mx-3 rounded-lg"
                large
                data-cy="confirm-delete-class"
                @click="confirmSingleClassDelete"
              >
                <v-icon left>
                  mdi-delete-outline
                </v-icon>
                ELIMINA
              </v-btn>
            </v-row>
          </v-card-actions>
        </template>
      </Modal>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "@store";
import { handleAxiosError, sendNotification } from "@/utils/notifications";
import Modal from "@cmp/utils/Modal";

export default {
  name: "ClassroomsManagement",
  components: { Modal },
  data() {
    return {
      showConfirmationModal: false,
      showSingleClassDeleteModal: false,
      classroomToDelete: false,
      searchClassrooms: "",
      confirmationString: "",
      classroomsHeaders: [
        {
          text: "Scuola",
          align: "start",
          filterable: true,
          value: "school",
        },
        { text: "Nome Classe", 
          filterable: true,
          value: "name"
        },
        {
          text: "Cognome Professore",
          filterable: true,
          value: "professor.surname",
        },
        {
          text: "Nome Professore",
          filterable: true,
          value: "professor.name",
        },
        {
          text: "Email Professore",
          filterable: true,
          value: "professor.email",
        },
        {
          text: "Elimina Classe",
          filterable: false,
          value: "deleteClass",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("admin", ["allClassrooms"]),
  },
  created() {
    this.getAllClassrooms();
  },
  methods: {
    ...mapActions("admin", ["getAllClassrooms", "deleteAllClassrooms"]),
    ...mapActions( "classrooms", ["deleteClassroom"]),
    openModal() {
      this.showConfirmationModal = true;
    },
    closeModal() {
      this.showConfirmationModal = false;
    },
    openDeleteClassModal(classroom) {
      this.showSingleClassDeleteModal = true;
      this.classroomToDelete = classroom;
    },
    async confirmDelete() {
      store.commit("loading/startLoading");
      try {
        await this.deleteAllClassrooms();
        sendNotification(`Classi eliminate con successo`, "success");
      } catch (err) {
        handleAxiosError(`Errore nell'eliminazione delle classi`, err);
      } finally {
        store.commit("loading/stopLoading");
        this.showConfirmationModal = false;
      }
    },
    async confirmSingleClassDelete() {
      await this.deleteClassroom(this.classroomToDelete.id);
      this.showSingleClassDeleteModal = false;
      this.classroomToDelete = "";
    },
  },
};
</script>

<style scoped>
#container {
  background-color: #1d1c4c !important;
  background-repeat: repeat;
}

.subtitle {
  font-size: 15px;
}

.btn-font {
  font-family: "Karasuma Gothic", sans-serif;
}

.modal-text {
  font-size: 1.4rem;
}
.confirm-deletion-size{
  max-width: 500px
}

.data-table{
  background-color: transparent;
  font-weight: 500;
}
</style>
