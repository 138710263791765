<template>
  <Modal
    v-if="dialog"
    title="Reset della password"
    :show-close-button="false"
    @closeModal="closeDialog"
  >
    <template #content-center>
      <v-card-text>
        <v-row justify="center">
          <p>
            Stai per eseguire il reset della password di <strong>{{ user.name }} {{ user.surname }}</strong>.
            L'operazione è irreversibile, vuoi proseguire?
          </p>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center mt-5">
        <v-btn
          color="#F39200"
          class="white--text mx-3 rounded-lg"
          @click="resetPassword"
        >
          Resetta
        </v-btn>
        <v-btn
          color="red darken-2"
          class="white--text mx-3 rounded-lg"
          @click="closeDialog"
        >
          Annulla
        </v-btn>
      </v-card-actions>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "../../utils/Modal.vue";

export default {
  name: "ResetUsersPasswordDialog",
  components: { Modal },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: true,
  }),
  methods: {
    ...mapActions("classrooms", ["resetMultiplePasswords"]),
    async resetPassword() {
      const tmp = [];
      tmp.push(this.user.email);
      await this.resetMultiplePasswords({ emails: tmp });
      this.closeDialog();
    },
    closeDialog() {
      this.dialog = false;
      this.$emit("close-dialog");
    },
  }
}
</script>