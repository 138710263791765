import courseService from "@/services/courses";
import episodeService from "@/services/episodes";
import { handleAxiosError, sendNotification } from "@utils/notifications";
import Course from "@/classes/course";
import Episode from "@classes/episode";
import orderBy from "lodash/orderBy";

const initialState = () => ({
  courses: [],
  sorting: 0,
  searchBar: "",
});

const state = initialState();

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  /**
   * Mutation that creates courses from BE
   *
   * @param {Array} courses
   * @returns {null}
   */
  SET_ALL_COURSES(state, { courses, isAdmin }) {
    const coursesObjects = [];
    for (const course of courses) {
      const {
        title,
        description,
        id,
        ondemand,
        subscribed,
        resource,
        authors,
        episodes,
        scope,
        thumbnail_path,
        channel_id,
        timer_priority,
      } = course;

      // Check if we can remove this if since it should always be true
      if (episodes.length >= 0 || isAdmin) {
        coursesObjects.push(
          new Course(
            title,
            description,
            id,
            ondemand,
            subscribed,
            resource,
            authors,
            episodes,
            scope,
            thumbnail_path,
            channel_id,
            timer_priority
          )
        );
      }
    }
    state.courses = coursesObjects;
  },
  REMOVE_COURSE(state, { courseId }) {
    const index = state.courses.findIndex((course) => course.id === courseId);
    state.courses.splice(index, 1);
  },

  REMOVE_QUESTION(state, { courseId, episodeId, questionId }) {
    const courseIndex = state.courses.findIndex(
      (course) => course.id === courseId
    );

    const episodeIndex = state.courses[courseIndex].episodes.findIndex(
      (episode) => episode.id === episodeId
    );

    const questionIndex = state.courses[courseIndex].episodes[
      episodeIndex
    ].questions.findIndex((question) => question.id === questionId);

    state.courses[courseIndex].episodes[episodeIndex].questions.splice(
      questionIndex,
      1
    );
  },

  REMOVE_COMMENT(state, { courseId, episodeId, questionId, commentId }) {
    const courseIndex = state.courses.findIndex(
      (course) => course.id === courseId
    );

    const episodeIndex = state.courses[courseIndex].episodes.findIndex(
      (episode) => episode.id === episodeId
    );

    const questionIndex = state.courses[courseIndex].episodes[
      episodeIndex
    ].questions.findIndex((question) => question.id === questionId);

    const commentIndex = state.courses[courseIndex].episodes[
      episodeIndex
    ].questions[questionIndex].comments.findIndex(
      (comment) => comment.id === commentId
    );

    state.courses[courseIndex].episodes[episodeIndex].questions[
      questionIndex
    ].comments.splice(commentIndex, 1);
  },
  UPDATE_COURSE(state, course) {
    const index = state.courses.findIndex((c) => c.id === course.id);
    state.courses[index] = course;
  },
  UPDATE_EPISODE(state, episode) {
    const courseIndex = state.courses.findIndex((c) => c.id === episode.course);
    const episodeIndex = state.courses[courseIndex].episodes.findIndex(
      (ep) => ep.id === episode.id
    );
    state.courses[courseIndex].episodes[episodeIndex] = episode;
  },
  SET_EPISODE_QUESTIONS(state, { data, episode_id, course_id }) {
    const courseIndex = state.courses.findIndex((c) => c.id === course_id);
    const episodeIndex = state.courses[courseIndex].episodes.findIndex(
      (ep) => ep.id === episode_id
    );
    state.courses[courseIndex].episodes[episodeIndex].questions = [];
    state.courses[courseIndex].episodes[episodeIndex].addQuestions(data);
  },
  SET_EPISODE_UNAPPROVED_QUESTIONS(state, { data, episode_id, course_id }) {
    const courseIndex = state.courses.findIndex((c) => c.id === course_id);
    const episodeIndex = state.courses[courseIndex].episodes.findIndex(
      (ep) => ep.id === episode_id
    );

    state.courses[courseIndex].episodes[episodeIndex].unapproved_questions = [];
    state.courses[courseIndex].episodes[episodeIndex].addUnapprovedQuestions(
      data
    );
  },
  DELETE_EPISODE(state, { episodeId, courseId }) {
    const courseIndex = state.courses.findIndex((c) => c.id === courseId);
    const episodeIndex = state.courses[courseIndex].episodes.findIndex(
      (ep) => ep.id === episodeId
    );
    state.courses[courseIndex].episodes.splice(episodeIndex, 1);
  },
  CREATE_COURSE(state, course) {
    state.courses.push(
      new Course(
        course.title,
        course.description,
        course.id,
        course.onDemand,
        false,
        course.resource,
        course.authors.map((a) => `${a.name}%?%${a.role}`), // because constructor wants encoded authors
        [],
        course.scope
      )
    );
  },
  CREATE_EPISODE(state, episode) {
    const courseIndex = state.courses.findIndex((c) => c.id === episode.course);
    state.courses[courseIndex].episodes.push(
      new Episode(
        episode.id,
        episode.title,
        episode.videoId,
        episode.description,
        episode.start,
        episode.end
      )
    );
  },
  SET_COURSE_RANKS(state, { courseId, ranks }) {
    const courseIndex = state.courses.findIndex((c) => c.id === courseId);
    ranks.forEach(({ id, rank }) => {
      const epIndex = state.courses[courseIndex].episodes.findIndex(
        (e) => e.id === id
      );
      state.courses[courseIndex].episodes[epIndex].rank = rank;
    });
  },
  SET_MY_RANK(state, { courseId, episodeId, myRank }) {
    const courseIndex = state.courses.findIndex((c) => c.id === courseId);
    const epIndex = state.courses[courseIndex].episodes.findIndex(
      (ep) => ep.id === episodeId
    );
    state.courses[courseIndex].episodes[epIndex].myRank = myRank;
  },
  SET_THUMBNAIL_PATH(state, { courseId, path }) {
    const courseIndex = state.courses.findIndex((c) => c.id === courseId);
    state.courses[courseIndex].thumbnailPath = path;
  },
  ADD_UNAPPROVED_QUESTION(state, { courseId, episodeId, unapproved_question }) {
    const courseIndex = state.courses.findIndex((c) => c.id === courseId);
    const epIndex = state.courses[courseIndex].episodes.findIndex(
      (ep) => ep.id === episodeId
    );
    state.courses[courseIndex].episodes[epIndex].addUnapprovedQuestion(
      unapproved_question
    );
  },
  ADD_EPISODE_COMMENT(
    state,
    { episodeId, questionId, commentId, text, time, userId, username, avatar }
  ) {
    for (const course of state.courses) {
      for (const episode of course.episodes) {
        if (episodeId === episode.id) {
          for (const question of episode.questions) {
            if (question.id === questionId) {
              question.addComment(
                commentId,
                text,
                time,
                userId,
                username,
                avatar
              );
            }
          }
        }
      }
    }
  },
  ADD_EPISODE_QUESTION(state, { questionId, episodeId, text, time, username }) {
    for (const course of state.courses) {
      for (const episode of course.episodes) {
        if (episodeId === episode.id) {
          episode.addQuestions([
            {
              id: questionId,
              text,
              time,
              user_id: "",
              username,
              comments: [],
            },
          ]);
        }
      }
    }
  },
  ADD_EPISODE_UNAPPROVED_QUESTION(
    state,
    { questionId, episodeId, text, time, username, user_email }
  ) {
    for (const course of state.courses) {
      for (const episode of course.episodes) {
        if (episodeId === episode.id) {
          episode.addUnapprovedQuestions([
            {
              id: questionId,
              text,
              time,
              user_id: "",
              username,
              comments: [],
              email: user_email,
            },
          ]);
        }
      }
    }
  },
  REMOVE_EPISODE_UNAPPROVED_QUESTION(
    state,
    { courseId, episodeId, questionId }
  ) {
    const courseIndex = state.courses.findIndex(
      (course) => course.id === courseId
    );

    const episodeIndex = state.courses[courseIndex].episodes.findIndex(
      (episode) => episode.id === episodeId
    );

    const unapproved_questionsIndex = state.courses[courseIndex].episodes[
      episodeIndex
    ].unapproved_questions.findIndex(
      (unapproved_questions) => unapproved_questions.id === questionId
    );

    state.courses[courseIndex].episodes[
      episodeIndex
    ].unapproved_questions.splice(unapproved_questionsIndex, 1);
  },
};

const actions = {
  /**
   * Actions that gets all courses from BE
   *
   * @returns {Array} courses
   * @throws error if there is a problem with the BE call
   */
  async getAllCourses({ commit, rootState }) {
    const isAdmin = rootState.auth.user.access_level === 1;
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await courseService.getCourses();
      commit("SET_ALL_COURSES", { courses: data, isAdmin });
      return data;
    } catch (err) {
      await handleAxiosError(`Errore durante il caricamento dei corsi`, err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async getClassroomsSubscribed({ commit }, courseId) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await courseService.getClassroomsSubscribed(courseId);
      return data;
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento delle classi iscritte al corso`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  /**
   * Actions that gets all episode Questions
   *
   * @returns {Array} questions
   * @throws error if there is a problem with the BE call
   */
  async getEpisodeQuestions({ commit }, { episode_id, course_id }) {
    try {
      const { data } = await courseService.getEpisodeQuestions(episode_id);
      if (data !== null) {
        commit("SET_EPISODE_QUESTIONS", {
          data,
          episode_id,
          course_id,
        });
      }
      return data;
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento delle domande`,
        err
      );
      return null;
    }
  },

  async getEpisodeUnapprovedQuestions({ commit }, { episode_id, course_id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await courseService.getUnapprovedQuestions(episode_id);
      if (data !== null) {
        commit("SET_EPISODE_UNAPPROVED_QUESTIONS", {
          data,
          episode_id,
          course_id,
        });
      }
      return data;
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento delle domande non ancora approvate`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  /**
   * Actions that adds course to a classroom
   *
   * @param {string} classroomId
   * @param {string} courseId
   * @returns {Object} of the given data
   * @throws error if there is a problem with the BE call
   */
  async addCourseToClassroom({ commit }, { classroomId, courseId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await courseService.addCourseToClassroom(classroomId, courseId);
      commit(
        "classrooms/SUBSCRIBE_CLASS_TO_COURSE",
        { classroomId, courseId },
        { root: true }
      );
      return { classroomId, courseId };
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'iscrizione della classe, provare a ricaricare la pagina`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  /**
   * Actions that unsubscribes course to a classroom
   *
   * @param {string} classroomId
   * @param {string} courseId
   * @returns {Object} of the given data
   * @throws error if there is a problem with the BE call
   */
  async unsubscribeCourse({ commit }, { classroomId, courseId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await courseService.unsubscribeCourse(classroomId, courseId);
      commit(
        "classrooms/UNSUBSCRIBE_CLASS_FROM_COURSE",
        { classroomId, courseId },
        { root: true }
      );
      return { classroomId, courseId };
    } catch (err) {
      await handleAxiosError(
        `Problema durante la rimozione dell'iscrizione della classe, provare a ricaricare la pagina`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async deleteCourse({ commit }, { courseId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await courseService.deleteCourse(courseId);
      commit("REMOVE_COURSE", { courseId });
    } catch (err) {
      await handleAxiosError(`Problema durante l'eliminazione del corso`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteQuestion({ commit }, { questionId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await courseService.deleteQuestion(questionId);
      sendNotification("Domanda anonima eliminata con successo!", "success");
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'eliminazione della domanda anonima...`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async deleteComment({ commit }, { commentId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await courseService.deleteQuestion(commentId);
      sendNotification("Commento eliminato con successo!", "success");
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'eliminazione del commento`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async createCourse({ commit }, course) {
    const {
      title,
      authors,
      onDemand,
      resource,
      scope,
      description,
      timer_priority,
    } = course;
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await courseService.createCourse(
        title,
        authors.map((a) => `${a.name}%?%${a.role}`),
        onDemand,
        resource[0] ? resource : [],
        scope,
        description,
        timer_priority
      );
      commit("CREATE_COURSE", { ...course, id: data });
      return data;
    } catch (err) {
      await handleAxiosError("Errore nella creazione del corso", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async updateCourse({ commit }, course) {
    const {
      id,
      title,
      authors,
      onDemand,
      resource,
      scope,
      description,
      timer_priority,
    } = course;
    try {
      commit("loading/startLoading", {}, { root: true });
      await courseService.updateCourse(
        id,
        title,
        authors.map((a) => `${a.name}%?%${a.role}`),
        onDemand,
        resource[0] ? resource : [],
        scope,
        description,
        timer_priority
      );
      commit("UPDATE_COURSE", course);
    } catch (err) {
      await handleAxiosError("Errore durante la modifica del corso", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async subscribeEpisode({}, { episodeId }) {
    try {
      await episodeService.subscribeEpisode(episodeId);
    } catch (err) {
      await handleAxiosError(`Errore durante la registrazione al socket`, err);
    }
  },
  async updateEpisode(
    { commit },
    { id, videoId, numepisode, title, start, end, description, course }
  ) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await episodeService.updateEpisode(
        id,
        videoId,
        title,
        start,
        end,
        description,
        course
      );
      commit("UPDATE_EPISODE", {
        id,
        videoId,
        numepisode,
        title,
        start,
        end,
        description,
        course,
      });
    } catch (err) {
      await handleAxiosError(`Errore durante la modifica dell'episodio`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async deleteEpisode({ commit }, { episodeId, courseId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await episodeService.deleteEpisode(episodeId);
      commit("DELETE_EPISODE", { episodeId, courseId });
    } catch (err) {
      await handleAxiosError(
        `Errore durante l'eliminazione dell'episodio`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async createEpisode({ commit }, episode) {
    try {
      const { videoId, title, start, end, description, course } = episode;
      commit("loading/startLoading", {}, { root: true });
      const { data } = await episodeService.createEpisode(
        videoId,
        title,
        start,
        end,
        description,
        course
      );
      commit("CREATE_EPISODE", { ...episode, id: data });
      return data;
    } catch (err) {
      await handleAxiosError(`Errore nella creazione dell'episodio`, err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async createRank({ commit, dispatch }, { courseId, episodeId, rating }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await episodeService.createRank(episodeId, rating);
      commit("SET_MY_RANK", {
        courseId,
        episodeId,
        myRank: rating,
      });
      await dispatch("getRanks", courseId);
    } catch (err) {
      await handleAxiosError(`Errore durante l'invio del voto`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getRanks({ commit }, courseId) {
    try {
      const { data } = await courseService.getRanks(courseId);
      commit("SET_COURSE_RANKS", {
        courseId,
        ranks: data.episodes,
      });
    } catch (err) {
      await handleAxiosError(`Errore nel caricamento dei voti`, err);
    }
  },
  async getMyRank({ commit }, { courseId, episodeId }) {
    try {
      const { data } = await episodeService.getMyVote(episodeId);
      commit("SET_MY_RANK", {
        courseId,
        episodeId,
        myRank: data,
      });
    } catch (err) {
      await handleAxiosError(`Errore nel caricamento del voto`, err);
    }
  },
  async getRanksExport({ commit }, { episodeId, fileName }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await episodeService.getRanksExport(episodeId);
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${fileName}.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore durante il download del ranking dell'episodio`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async setThumbnail({ commit }, { courseId, image }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await courseService.uploadThumbnail(courseId, image);
      commit("SET_THUMBNAIL_PATH", {
        courseId,
        path: data,
      });
      return data;
    } catch (err) {
      await handleAxiosError("Errore nel caricamento del thumbnail", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteThumbnail({ commit }, { courseId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await courseService.deleteThumbnail(courseId);
      commit("SET_THUMBNAIL_PATH", {
        courseId,
        path: null,
      });
    } catch (err) {
      await handleAxiosError(`Errore nell'eliminazione del thumbnail`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async requestSubscription({ commit }, courseId) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await courseService.requestSubscription(courseId);
      sendNotification(`Richiesta inviata con successo`, `success`);
    } catch (err) {
      await handleAxiosError(`Errore nell'invio richiesta`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async createComment({ commit }, { episode_id, text, main_question_id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await courseService.createComment(episode_id, text, main_question_id);
      sendNotification(`Risposta inviata con successo`, `success`);
    } catch (err) {
      await handleAxiosError(`Errore nell'invio della risposta`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async createQuestion({ commit }, { episode_id, text }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await courseService.createQuestion(episode_id, text);
      sendNotification(
        `Domanda anonima creata con successo: attendi l'approvazione degli admin!`,
        `success`
      );
    } catch (err) {
      await handleAxiosError(`Errore nell'invio della domanda anonima`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async approveQuestion(
    { commit },
    { courseId, episodeId, questionId, approved }
  ) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await courseService.approveQuestion(questionId, approved);
      await commit("REMOVE_EPISODE_UNAPPROVED_QUESTION", {
        courseId,
        episodeId,
        questionId,
      });
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'approvazione di una domanda anonima`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getNextLiveInfo() {
    try {
      const response = await courseService.getNextLiveInfo();
      return response.data;
    } catch (err) {
      return null;
    }
  },
};

const getters = {
  courses: (state) => state.courses.slice(),
  /**
   * Getter that returns courses by id
   *
   * @param  {string} id
   * @returns {Object} course
   */
  getCourseById: (state) => (id) =>
    state.courses.find((course) => course.id === id),

  getCoursesByIds: (state) => (ids) => {
    const courses = [];
    for (const id of ids) {
      const course = state.courses.find((course) => course.id === id);
      if (course) {
        const endDate = new Date(
          Math.min(...course.episodes.map((episode) => Number(episode.end))) *
            1000
        );
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(23, 59, 59, 0);
        course.isDisabled = Date.now() < endDate;
        courses.push(course);
      }
    }

    return courses;
  },

  /* Getter that returns a episode by its videoId */
  getEpisodeByVideoId: (state) => (courseId, videoId) =>
    state.courses
      .find((c) => c.id === courseId)
      .episodes.find((e) => e.videoId === videoId),

  getQuestionsFromEpisodeId: (state) => (courseId, episodeId) =>
    state.courses
      .find((c) => c.id === courseId)
      .episodes.find((e) => e.id === episodeId).questions,

  getUnapprovedQuestionsFromEpisodeId: (state) => (courseId, episodeId) =>
    state.courses
      .find((c) => c.id === courseId)
      .episodes.find((e) => e.id === episodeId).unapproved_questions,

  /**
   * Getter that returns description by video id
   *
   * @param {string} courseId
   * @param {string} videoId
   * @returns {Object} course
   */
  getEpisodeDescriptionByVideoId: (state) => (courseId, videoId) =>
    state.courses
      /** find the course by the courseId */
      .find((course) => course.id === courseId)
      /** find the episode by the episodeId */
      .episodes.find((episode) => episode.videoId === videoId).description,
  /**
   * Getter that returns episode from the video id
   *
   * @param {string} courseId
   * @param {string} videoId
   * @returns {string} episodeId
   */
  getEpisodeIdByVideoId: (state) => (courseId, videoId) =>
    state.courses
      .find((course) => course.id === courseId)
      .episodes.find((episode) => episode.videoId === videoId).id,

  getFilteredQuestions: (state, getters) => (courseId, episodeId) => {
    let filteredQuestions = getters.getQuestionsFromEpisodeId(
      courseId,
      episodeId
    );

    if (state.searchBar !== "") {
      filteredQuestions = filteredQuestions.filter((question) => {
        if (
          question.text.toLowerCase().includes(state.searchBar.toLowerCase()) ||
          question.username
            .toLowerCase()
            .includes(state.searchBar.toLowerCase()) ||
          question.comments.some((el) => {
            if (
              el.text.toLowerCase().includes(state.searchBar.toLowerCase()) ||
              el.username.toLowerCase().includes(state.searchBar.toLowerCase())
            ) {
              return true;
            }
            return false;
          })
        ) {
          return true;
        }
        return false;
      });
    }

    switch (state.sorting) {
      case 0:
      default:
        /**
         * Questions are by default ordered from most to least recent,
         * so the users can immediately read new questions
         */
        filteredQuestions = orderBy(filteredQuestions, "time", "desc");
        break;

      case 1:
        filteredQuestions = orderBy(filteredQuestions, "time", "asc");
        break;
    }

    return filteredQuestions;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
