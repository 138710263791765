class Video {
  constructor({
    video_id,
    project_name,
    team_name,
    owner,
    owner_email,
    school,
    video_uri,
    status,
  }) {
    this.videoId = video_id;
    this.projectName = project_name;
    this.teamName = team_name;
    this.owner = owner;
    this.email = owner_email;
    this.school = school;
    this.url = video_uri;
    this.status = status;
  }
}

export default Video;
