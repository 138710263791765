<template>
  <Modal
    v-if="dialog"
    title="Modifica dati utente"
    @closeModal="closeDialog"
  >
    <template #content-center>
      <v-card-text
        class="mb-3"
        justify="center"
      >
        <v-row>
          <v-img
            v-if="!avatarError"
            max-width="150px"
            @error="avatarError = true"
            :src="user.avatar"
          />
          <v-img
            v-else
            max-width="150px"
            src="https://s4s-landing.sorintdev.it/static/avatar_default.jpg"
          />
          <v-col
            cols="
                  3"
            sm="9"
            md="8"
          >
            <v-form class="ml-5">
              <v-text-field
                v-model="userName"
                label="Name"
              />
              <v-text-field
                v-model="userSurname"
                class="d-flex"
                label="Surname"
              />
              <v-text-field
                v-model="userEmail"
                label="Email"
              />
              <v-text-field
                v-model="userSecEmail"
                class="d-flex"
                label="Secondary Email"
              />
            </v-form>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-row class="mt-3 justify-center">
            <v-progress-circular
              v-if="isLoadingDelete"
              indeterminate
            />
            <v-btn
              v-else
              color="red darken-1"
              class="white--text mr-2"
              :disabled="
                user.avatar ===
                  'https://s4s-landing.sorintdev.it/static/avatar_default.jpg'
              "
              @click="deleteUserAvatar(user.id)"
            >
              Delete profile picture
            </v-btn>
            <v-btn
              color="success"
              :disabled="!user.name || !user.surname || !user.email"
              class="white--text ml-2"
              @click="saveInfo(userName, userSurname, user.id, userEmail, userSecEmail)"
            >
              Save
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </template>
  </Modal>
</template>

<script>
import Modal from "../../utils/Modal.vue"
import store from "@store";
import { mapActions } from "vuex";

export default {
  name: "EditUsersDialog",
  components: { Modal },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: true,
    isLoadingDelete: false,
    userName: "",
    userSurname: "",
    userEmail: "",
    userSecEmail: "",
    userAvatar: "",
    avatarError: false
  }),
  mounted() {
    this.avatarError = false
    this.userName = this.user.name;
    this.userSurname = this.user.surname;
    this.userEmail = this.user.email;
    this.userSecEmail = this.user.sec_email;
    this.userAvatar = this.user.avatar;
  },
  methods: {
    ...mapActions("auth", ["updateUserInformation"]),
    ...mapActions("admin", ["deleteAvatar"]),
    closeDialog() {
      this.dialog = false;
      this.$emit("close-dialog");
    },
    async deleteUserAvatar(id) {
      this.isLoadingDelete = true;
      try{
        await this.deleteAvatar({ id });
      }
      finally {
        this.user.avatar = "https://s4s-landing.sorintdev.it/static/avatar_default.jpg"
        this.isLoadingDelete = false;
      }
    },
    async saveInfo(name, surname, id, email, sec_email) {
      store.commit("loading/startLoading");
      await this.updateUserInformation({name, surname, id, email, sec_email});
      store.commit("loading/stopLoading");
    },
  }
}
</script>