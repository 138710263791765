<template>
  <v-card
    dark
    class="scroller-parent py-0 my-0"
    max-height="587"
  >
    <v-toolbar
      v-if="closable"
      color="transparent"
    >
      <v-toolbar-title class="white--text truncated-text">
        {{ name }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="closable"
        class="mr-1"
        icon
        dark
        small
        @click="$emit('closeChatCard')"
      >
        <v-icon large>
          mdi-close
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-overlay
      v-if="chatDisabled"
      absolute
      opacity="0.7"
    >
      <v-row>
        <v-col
          cols="12"
          align="center"
        >
          <v-icon
            color="white"
            x-large
            justify-self="center"
          >
            mdi-lock
          </v-icon>
        </v-col>
        <v-col
          cols="12"
          align="center"
        >
          <p class="mx-3">
            {{ disabledText }}
          </p>
        </v-col>
      </v-row>
    </v-overlay>
    <Chat
      :channel-id="course.channel_id"
      :public-channel="publicChannel"
      :disabled="chatInputDisabled"
    />
  </v-card>
</template>

<script>
import Chat from "@cmp-chat/ChatLiveCustom";
import courseHandler from "@mixins/courseHandler";

export default {
  name: "LiveChat",
  components: {
    Chat,
  },
  mixins: [courseHandler],
  props: {
    closable: {
      type: Boolean,
      required: false,
    },
    course:{
      type:Object,
      required: true,
    },
    publicChannel: {
      type: Boolean,
      required: true,
    },
    disabledText: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    now: Date.now(),
    intervalTime: null,
  }),
  computed: {
    // chatDisabled if there's no channel_id or before 2 hours from first live
    chatDisabled() {
      return !(
        this.course.channel_id &&
        this.now >
        new Date(
          this.firstLive.getTime() - 30 * 60 * 1000
        )
      );
    },
    chatInputDisabled() {
      return this.now > this.lastLiveDay;
    },
    firstLive() {
      return new Date(
        Math.min(
          ...this.course.episodes.map((episode) =>
            Number(episode.start)
          )
        ) * 1000
      );
    },
  },
  created(){
    this.intervalTime = setInterval(() => {
      this.now = Date.now();
      if (!this.chatDisabled) {
        clearInterval(this.intervalTime);
      }
    }, 1000 * 60);
  },

  beforeDestroy() {
    clearInterval(this.intervalTime);
  },
};
</script>

<style scoped>
.truncated-text {
  white-space: nowrap;
  width: 100%; /* IE6 needs any width */
  overflow: hidden; /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis; /* Opera < 11*/
  text-overflow: ellipsis; /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
}

.scroller-parent {
  position: relative;
  background-color: transparent;
  border: 2px solid #75A8E0;
}

#chat {
  height: 400px;
  width: 100%;
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  #chat {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}
</style>
