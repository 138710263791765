import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Exam/get_exam_results_student_list
 */
function resultsOfStudents(classId, quizId) {
  return api.get(
    `/exam/results/student-list?class_id=${classId}&quiz_id=${quizId}`
  );
}
function examSummary(studentId, quizId) {
  return api.get(`/exam/summary?student_id=${studentId}&quiz_id=${quizId}`, {
    responseType: "blob",
  });
}
function allExamsSummary(classId, quizId) {
  return api.get(`/exam/summary/class?class_id=${classId}&quiz_id=${quizId}`, {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Exam/get_exam_export_quiz_summary
 */
function exportQuizSummary() {
  return api.get("/exam/export/quiz-summary", {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Exam/get_exam_certificate
 */
function getCertificate(studentId, quizId) {
  return api.get(
    `/exam/certificate?student_id=${studentId}&quiz_id=${quizId}`,
    {
      responseType: "blob",
    }
  );
}
function getAllCertificates(classId, quizId) {
  return api.get(
    `/exam/certificate/class?class_id=${classId}&quiz_id=${quizId}`,
    {
      responseType: "blob",
    }
  );
}
function removeBadge(data) {
  return api.delete("/exam/badge", {
    params: {
      quiz_id: data.quiz_id,
      student_id: data.student_id,
    },
  });
}

export default {
  resultsOfStudents,
  examSummary,
  allExamsSummary,
  exportQuizSummary,
  getCertificate,
  getAllCertificates,
  removeBadge,
};
