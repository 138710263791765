<template>
  <v-col>
    <v-row
      justify="center"
      align="center"
    >
      <v-col cols="auto">
        <v-btn
          outlined
          fab
          :small="$vuetify.breakpoint.mdAndDown"
          color="white"
          :disabled="isFirstQuestion"
          @click="changeQuestion(currentIndex - 1)"
        >
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <h2 class="camingo question-n">
          DOMANDA {{ currentIndex + 1 }}
        </h2>
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="!isLastQuestion"
          class="ma-md-2"
          outlined
          fab
          color="white"
          @click="changeQuestion(currentIndex + 1)"
        >
          <v-icon dark>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          class="ma-md-2 white--text"
          fab
          :small="$vuetify.breakpoint.mdAndDown"
          color="#F39200"
          :disabled="currentQuestion.id === null"
          @click="initializeNewQuestion"
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <QuestionForm
      :current-question="currentQuestion"
      :original-question="originalQuestion"
      :quiz-info="quizInfo"
      @onChangedQuestion="changeQuestion"
      @onResetIndex="resetIndex"
      @onPreviewQuestion="previewQuestionHandler"
      @onSetInitialVariables="setInitialVariables"
      @onAddEmptyAnswer="addEmptyAnswer"
    />
    <PreviewDialog
      v-if="previewQuestion"
      :questions="[currentQuestion]"
      @onCloseModal="previewQuestion = false"
    />
  </v-col>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { mapGetters } from "vuex";
import QuizQuestion from "@classes/quizQuestion";
import PreviewDialog from "../dialogs/PreviewDialog.vue";
import QuestionForm from "../QuestionForm.vue";

export default {
  name: "QuizQuestions",
  components: { PreviewDialog, QuestionForm },
  data() {
    return {
      currentIndex: 0,
      questions: [],
      currentQuestion: {},
      originalQuestion:{},
      quizInfo:{},
      originalQuizInfo:{},
      previewQuestion: false,
    };
  },
  computed: {
    ...mapGetters("quiz", ["selectedQuiz"]),
    isFirstQuestion() {
      return this.currentIndex === 0;
    },
    isLastQuestion() {
      return (
        this.currentIndex === this.questions.length - 1
      );
    },
  },
  created() {
    this.setInitialVariables();
    this.changeQuestion(this.questions.length - 1);
  },
  methods: {
    initializeNewQuestion() {
      this.questions.push(
        new QuizQuestion(null, "", true, "single_answer", 2)
      );
      this.currentIndex++;
      this.currentQuestion =
        this.questions[this.currentIndex];
    },
    changeQuestion(index) {
      this.currentIndex = Math.min(
        index,
        this.questions.length - 1
      );
      this.currentQuestion = cloneDeep(
        this.questions[this.currentIndex]
      );
      this.originalQuestion = cloneDeep(
        this.currentQuestion
      );
    },
    addEmptyAnswer(answer){
      this.currentQuestion.answers.push(
        answer
      );
    },
    resetIndex(){
      this.changeQuestion(this.currentIndex)
    },
    previewQuestionHandler(){
      this.previewQuestion = true
    },
    setInitialVariables() {
      this.quizInfo = { ...this.selectedQuiz };
      this.originalQuizInfo = cloneDeep(this.quizInfo);
      if (this.quizInfo.questions.length > 0) {
        this.questions = this.quizInfo.questions;
      } else {
        this.questions = [
          new QuizQuestion(
            null,
            "",
            true,
            "single_answer",
            2
          ),
        ];
      }
    },
  },
};
</script>

<style scoped>
.question-n {
  font-family: "Karasuma Gothic";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
</style>

