<template>
  <v-card
    v-if="!questionRemoved"
    class="rounded-lg px-2 mt-6 mx-5 mx-md-0 mr-md-16 mb-6"
    flat
    dark
    color="#1d1c4c"
  >
    <v-card-title class="card-title">
      Contenuto segnalato: {{ reportedQuestion.text }}
    </v-card-title>
    <v-card-text class="card-text">
      <div>
        <span>Autore: </span>
        {{ reportedQuestion.user_full_name }}
      </div>
      <div>
        <span>Email autore: </span>
        {{ reportedQuestion.user_email }}
      </div>
    </v-card-text>
    <v-card-text class="card-text">
      <div>
        <span>Segnalato da: </span>
        {{ reportedQuestion.reporter_full_name }}
      </div>
      <div>
        <span>Email reporter: </span>
        {{ reportedQuestion.reporter_email }}
      </div>
    </v-card-text>
    <div class="card-footer">
      <v-btn
        dark
        class="mt-4 px-5"
        color="red"
        @click="reportQuestion(questionId)"
      >
        <v-icon class="mr-5" left>mdi-flag</v-icon>
        ELIMINA DOMANDA
      </v-btn>

      <v-btn
        color="transparent"
        dark
        class="mt-4 px-5"
        @click="deleteReport(questionId)"
      >
        <v-icon class="mr-5" left>mdi-delete</v-icon>
        ANNULLA SEGNALAZIONE
      </v-btn>
    </div>
  </v-card>
  <v-card
    v-else
    class="rounded-lg px-2 mt-6 mx-5 mx-md-0 mr-md-16 mb-6"
    flat
    dark
    color="#1d1c4c"
  >
    <v-card-title class="card-title">{{ finalText }}</v-card-title>
  </v-card>
</template>
<script lang="ts">
import { mapActions } from "vuex";

export default {
  name: "QuestionUnreport",
  data() {
    return {
      questionId: "",
      reportedQuestion: {},
      questionRemoved: false,
      finalText: "",
    };
  },
  methods: {
    ...mapActions("questions", [
      "unreportQuestionById",
      "deleteQuestionById",
      "getReportedQuestionDetails",
    ]),

    reportQuestion(questionId) {
      this.deleteQuestionById({ questionId });
      this.questionRemoved = true;
      this.finalText = "DOMANDA ELIMINATA!";
    },
    deleteReport(questionId) {
      this.unreportQuestionById({ questionId });
      this.questionRemoved = true;
      this.finalText = "SEGNALAZIONE ANNULLATA!";
    },
  },
  async created() {
    const id = this.$route.params.id;
    this.questionId = id;
    try {
      const response = await this.getReportedQuestionDetails({
        questionId: id,
      });

      this.reportedQuestion = {
        id: response.id,
        text: response.text,
        user_id: response.user_id,
        user_full_name: response.user_full_name,
        user_email: response.user_email,
        reporter_id: response.reporter_id,
        reporter_full_name: response.reporter_full_name,
        reporter_email: response.reporter_email,
      };

      console.log("reported Question: ", this.reportedQuestion);
    } catch (err) {
      console.error("Error fetching reported question: ", err);
      this.questionRemoved = true;
      this.finalText = "NESSUNA SEGNALAZIONE PRESENTE";
    }
  },
};
</script>
