<template>
  <v-app class="app-container">
    <v-container class="text-center" fill-height>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <h1 class="display-2 white--text">Whoops, 401</h1>

          <p class="white--text">
            Stai provando ad accedere a un'area non autorizzata
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn @click="goToLogin" color="white" outlined>
            Torna alla home
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { pushAuthUrl } from "@/utils/externalRouter";
export default {
  name: "ErrorUnauthorizedPage",
  methods: {
    goToLogin() {
      pushAuthUrl();
    },
  },
};
</script>
<style scoped></style>
