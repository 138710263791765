import chat from "@services/chat";

const initialState = () => ({
  userId: "",
  authToken: "",
  channels: [],
  profUnreads: 0,
  profGroupId: "",
});

const state = initialState();

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  SET_CREDENTIALS(state, { userId, authToken, prof_group_id }) {
    state.userId = userId;
    state.authToken = authToken;
    state.profGroupId = prof_group_id;
  },
  SET_UNREADS(state, channels) {
    state.channels = channels;
  },
  CLEAR_UNREADS(state, channelName) {
    const i = state.channels.findIndex((c) => c.name === channelName);
    if (i >= 0) {
      state.channels[i].unreads = 0;
    }
  },
  SET_PROF_UNREADS(state, unreads) {
    state.profUnreads = unreads;
  },
};

const actions = {
  async chatLogin({ commit }) {
    const { data } = await chat.getToken();
    commit("SET_CREDENTIALS", data);
    return data.authToken;
  },

  async getChannelUnreadMessages({ dispatch, state, commit }) {
    if (!state.userId || !state.authToken) {
      await dispatch("chatLogin");
    }

    const { data } = await chat.getChannelsJoined();
    const { channels } = data;

    // parallel query all channels unreads messages
    const promises = [];
    channels.forEach((channel, index) => {
      const promise = chat.getChannelsMessages(channel.name).then((ris) => {
        channels[index].unreads = ris.data.unreads;
      });
      promises.push(promise);
    });

    await Promise.all(promises);

    commit("SET_UNREADS", channels);
  },

  async getProfUnreads({ commit, state, dispatch }) {
    if (!state.userId || !state.authToken) {
      await dispatch("chatLogin");
    }

    const { data } = await chat.getGroupsMessages("professors");
    commit("SET_PROF_UNREADS", data.unreads);
  },
};

const getters = {
  getUnreadsByChannelName(state) {
    return (channelName) => {
      const i = state.channels.findIndex((c) => c.name === channelName);
      return i >= 0 ? state.channels[i].unreads : 0;
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
