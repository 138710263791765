<template>
  <v-col
    class="pa-0"
    cols="12"
  >
    <h4 class="section-title">
      NUMERI E CERTIFICAZIONI
    </h4>
    <p class="grey--text mb-2">
      N° domande massimo: {{ numberOfQuestions }}
    </p>
    <NumberSelectors
      v-if="numberOfQuestions > 0"
      :initial-structure="initialStructure"
      @onNumberSelected="changeCurrentNumbers"
    />
    <p class="grey--text">
      N° punti complessivi: {{ totalPoints }}
    </p>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import NumberSelectors from "./NumberSelectors.vue";
export default {
  name: "NumbersAndCertificates",
  components: { NumberSelectors },
  emits: ["onUpdateSelectedNumbers"],
  props: {
    initialStructure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedNumbers: {},
    };
  },
  computed: {
    ...mapGetters("quiz", ["selectedQuiz"]),
    currentQuestions() {
      return this.selectedQuiz.questions;
    },
    numberOfQuestions() {
      return this.currentQuestions.length;
    },
    totalPoints() {
      let totalPoints = 0;
      const keys = Object.keys(this.selectedNumbers);
      keys.forEach(
        (key) =>
          (totalPoints +=
            this.selectedNumbers[key] *
            Number.parseInt(key, 10))
      );
      return totalPoints;
    },
  },
  watch: {
    selectedNumbers() {
      this.updateSelectedNumbers();
    },
  },
  created() {
    if (!this.initialStructure.structure) {
      return;
    }
    const currentStructure = [
      ...this.initialStructure.structure,
    ];
    this.selectedNumbers = currentStructure.reduce(
      (output, value) => ({
        ...output,
        [value.points]: value.number,
      }),
      {}
    );
  },
  methods: {
    changeCurrentNumbers({ key, value }) {
      const objectToAdd = {};
      objectToAdd[key] = value;
      this.selectedNumbers = {
        ...this.selectedNumbers,
        ...objectToAdd,
      };
    },
    updateSelectedNumbers() {
      this.$emit(
        "onUpdateSelectedNumbers",
        this.selectedNumbers
      );
    },
  },
};
</script>