import { render, staticRenderFns } from "./ChatLiveCustom.vue?vue&type=template&id=57fc3267&scoped=true"
import script from "./ChatLiveCustom.vue?vue&type=script&lang=js"
export * from "./ChatLiveCustom.vue?vue&type=script&lang=js"
import style0 from "./ChatLiveCustom.vue?vue&type=style&index=0&id=57fc3267&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57fc3267",
  null
  
)

export default component.exports