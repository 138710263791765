<template>
  <v-main id="container">
    <v-container>
      <v-card
        class="rounded-lg py-5 mt-3"
        color="transparent"
        flat
      >
        <MailingList />
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import MailingList from "@cmp-admin/MailingList";

export default {
  name: "UsersManagement",
  components: {
    MailingList,
  },
};
</script>

<style scoped>
#container {
  background-color: #1d1c4c !important;
  background-repeat: repeat;
}
</style>
