var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"rounded-t-lg mt-5 admin_background elevation-12 px-8 py-8"},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Invia Comunicazione")])])],1),_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-md-4",attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"hide-selected":"","label":"DESTINATARI","items":_vm.categories,"item-text":"label","item-value":"name"},model:{value:(_vm.activeCategory),callback:function ($$v) {_vm.activeCategory=$$v},expression:"activeCategory"}})],1),_c('v-col',{staticClass:"pl-md-4",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"input-content",attrs:{"color":"purple darken-2","label":"CATEGORIA SELEZIONATA","readonly":"","required":""},model:{value:(_vm.activeCategory),callback:function ($$v) {_vm.activeCategory=$$v},expression:"activeCategory"}})],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"input-content",attrs:{"color":"purple darken-2","label":"OGGETTO","required":""},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg border mail-component-wrapper pb-3",attrs:{"color":"#01002e"}},[_c('TiptapVuetify',{staticClass:"input-content",attrs:{"extensions":_vm.extensions,"toolbar-attributes":{
            color: 'rgba(0, 0, 0, 0)',
            short: true,
            class: 'ml-5 ml-md-0',
          },"card-props":{
            flat: true,
            color: 'rgba(0, 0, 0, 0)',
            rounded: '50',
          }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"align":"start","cols":"9"}},_vm._l((_vm.attachments),function(attachment,index){return _c('v-chip',{key:index,staticClass:"mr-2 mb-2",attrs:{"draggable":"","close":"","href":`${_vm.publicUrl}/email/attachment/${attachment.filename}`,"color":"#1D1C4C"},on:{"click:close":function($event){return _vm.removeAttachment(index)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file")]),_vm._v(" "+_vm._s(attachment.label)+" ")],1)}),1),_c('v-col',{attrs:{"align":"end","cols":"12","md":"3"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":_vm.newAttachment}},[_c('v-icon',[_vm._v("mdi-paperclip")])],1),_c('v-btn',{staticClass:"rounded-lg",attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '',"medium":"","tile":"","dark":"","depressed":"","color":"primary","type":"button"},on:{"click":_vm.sendEmail}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-send")]),_vm._v(" Invia Email ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }