<template>
  <v-card
    dark
    class="mb-6"
    color="#01002e"
  >
    <div
      :style="{ 'border-left-color': color + '!important' }"
      class="border-left py-6"
    >
      <v-row align="center">
        <v-col
          cols="8"
          class="pl-10"
        >
          <v-row>
            <span
              :style="{ color: color }"
              class="text-uppercase font-weight-light"
            >{{ title }}</span>
          </v-row>
          <v-row align="center">
            <v-col>
              <v-row class="mb-0 mr-3">
                <span class="font-weight-bold h5">{{ value }}</span>
              </v-row>
            </v-col>
            <v-col
              v-if="!this.$vuetify.breakpoint.xs"
              cols="9"
              sm="6"
              lg="9"
            >
              <v-row>
                <v-progress-linear
                  :color="color"
                  height="10"
                  rounded
                  :value="percentage"
                  :aria-valuenow="{ percentage }"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="!this.$vuetify.breakpoint.xs"
          justify="center"
        >
          <v-row justify="center">
            <v-icon large>
              {{ icon }}
            </v-icon>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CardProgressBar",
  props: {
    color: {
      type: String,
      default: "#1cc88a",
    },
    icon: {
      type: String,
      default: "people",
    },
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    tot: {
      type: String,
      required: false,
      default: "No data",
    },
    value: {
      type: String,
      required: true,
      default: "No data",
    },
  },

  computed: {
    percentage() {
      if (!Number(this.value) || !Number(this.tot)) {
        return 0;
      }
      return (Number(this.value) * 100) / Number(this.tot);
    },
  },
};
</script>

<style scoped>
.border-left {
  border-left: 0.3rem solid !important;
}
</style>
