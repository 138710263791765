import store from "@/store";
function extractDomain() {
  const domain = store.getters["config/cookieDomain"];
  return domain;
}

function attachAuthCookie(token) {
  const domain = extractDomain();
  document.cookie =
    "hg-token=" + token + "; path=/; samesite=strict; domain=" + domain;
}

function attachAuthAdminCookie(token) {
  const domain = extractDomain();
  document.cookie =
    "hg-admin-token=" + token + "; path=/; samesite=strict; domain=" + domain;
}

const pushAdminUrl = (path) => {
  location.href = store.getters["config/adminUrl"].concat(path ?? "");
};
const pushEventUrl = (path) => {
  location.href = store.getters["config/eventUrl"].concat(path ?? "");
};
const pushLandingUrl = (path) => {
  location.href = store.getters["config/landingUrl"].concat(path ?? "");
};
const pushPlatformUrl = (path) => {
  location.href = store.getters["config/platformUrl"].concat(path ?? "");
};
const pushProjectsUrl = (path) => {
  location.href = store.getters["config/projectsUrl"].concat(path ?? "");
};
const pushAuthUrl = (path) => {
  location.href = store.getters["config/authUrl"].concat(path ?? "");
};
const pushJourneyUrl = (path) => {
  location.href = store.getters["config/journeyUrl"].concat(path ?? "");
};

export {
  pushAdminUrl,
  pushEventUrl,
  pushLandingUrl,
  pushPlatformUrl,
  pushProjectsUrl,
  pushAuthUrl,
  pushJourneyUrl,
  attachAuthAdminCookie,
  attachAuthCookie,
};
