<template>
  <v-expansion-panel class="resources-section">
    <v-expansion-panel-header>
      <v-row>
        <v-col cols="1">
          {{ sectionIndex + 1 }}
        </v-col>
        <v-col cols="11">
          {{ section.name }}
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list
        color="transparent"
        class="pa-0"
      >
        <v-list-item-group>
          <v-list-item
            v-for="(resource, index) in section.resources"
            :key="resource.id"
            class="px-0"
          >
            <v-list-item-content
              class="list-item"
              @click="updateQuizId(resource.id, resource.type)"
            >
              <v-col cols="12">
                <v-icon left>
                  {{ typeOfResource(resource.type) }}
                </v-icon>
                <span>{{ resource.title }}</span>
              </v-col>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-btn
                  :disabled="index === 0"
                  dark
                  icon
                  @click="changeOrder(resource, resource.order_num - 1)"
                >
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn
                  :disabled="index === section.resources.length - 1"
                  dark
                  icon
                  @click="changeOrder(resource, resource.order_num + 1)"
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name:"ResourcesSection",
  props: {
    section:{
      type: Object,
      required: true
    },
    getResources: {
      type: Function,
      required: true,
    },
    sectionIndex: {
      type: Number,
      required: true,
    },
    updateQuizId: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ...mapActions("learningResources", ["changeResourceOrder"]),
    typeOfResource(type) {
      switch (type) {
      case "video_lesson":
        return "mdi-play-box-outline";
      case "prog_exercise":
        return "mdi-file-code-outline";
      case "assessment":
        return "mdi-certificate-outline";
      default:
        return null;
      }
    },
    async changeOrder(item, newOrder) {
      await this.changeResourceOrder({resourceId: item.id, newPosition: newOrder});
      await this.getResources({pathId: this.$route.params.id});
    }
  }
}
</script>

<style scoped>
.resources-section{
  background: #1d1c4c !important;
  backdrop-filter: blur(60px);
  border-radius: 12px !important;
  font-family: "Karasuma Gothic" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #f4f5f6 !important;
  margin-top: 0px !important;
  letter-spacing: 0.75px !important;
}

.list-item{
  font-weight: 500;
  font-size: 16px;
}

.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding-left: 0 !important;
}

</style>