import api from "@services/axiosInstance";

function getTechJourney() {
  return api.get("/tech-journey");
}

function getEntity(id) {
  return api.get(`/tech-journey/${id}`);
}

function getRanking(id) {
  return api.get(`/tech-journey/resource-ranking/average/${id}`);
}

function getUnapprovedTechJourneyQuestions() {
  return api.get(`/tech-journey/questions/not-approved`);
}

function deleteUnapprovedTechJourneyQuestion(id) {
  return api.delete(`/tech-journey/questions/${id}`);
}

function approveUnapprovedTechJourneyQuestion(id) {
  return api.put(`/tech-journey/questions/approve/${id}`);
}

function getUnapprovedTechJourneyReplies() {
  return api.get(`/tech-journey/questions/reply/not-approved`);
}

function deleteUnapprovedTechJourneyReply(id) {
  return api.delete(`/tech-journey/questions/reply/${id}`);
}

function approveUnapprovedTechJourneyReply(id) {
  return api.post(`/tech-journey/questions/approvereply/${id}`);
}

function getTJLevelCompletionReport() {
  return api.get(
    "/tech-journey/report/general/download/tjlevel-completion-report",
    { responseType: "blob" }
  );
}

function getTJStudentsSubscriptions() {
  return api.get(
    "/tech-journey/report/general/download/students-subscriptions",
    { responseType: "blob" }
  );
}

export default {
  getTechJourney,
  getEntity,
  getRanking,
  getUnapprovedTechJourneyQuestions,
  deleteUnapprovedTechJourneyQuestion,
  approveUnapprovedTechJourneyQuestion,
  getUnapprovedTechJourneyReplies,
  deleteUnapprovedTechJourneyReply,
  approveUnapprovedTechJourneyReply,
  getTJLevelCompletionReport,
  getTJStudentsSubscriptions,
};
