import QuizQuestion from "./quizQuestion";

class Quiz {
  constructor(
    id,
    description,
    duration,
    title,
    published,
    someone_passed,
    min_points,
    min_points_excellence,
    sessions,
    structure,
    download_enabled,
    credly_template_id,
    credly_excellence_template_id,
    order_num
  ) {
    this.id = id;
    this.description = description;
    this.duration = duration;
    this.title = title;
    this.published = published;
    this.someone_passed = someone_passed;
    this.questions = [];
    this.min_points = min_points;
    this.min_points_excellence = min_points_excellence;
    this.structure = structure;
    this.sessions = sessions;
    this.download_enabled = download_enabled;
    this.credly_template_id = credly_template_id;
    this.credly_excellence_template_id = credly_excellence_template_id;
    this.order_num = order_num;
  }
  addQuestion(id, text, shuffle_answers, answerType, points, answers) {
    this.questions.push(
      new QuizQuestion(id, text, shuffle_answers, answerType, points, answers)
    );
  }
}

export default Quiz;
