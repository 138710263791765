class Author {
  constructor(name) {
    const pieces = name.split("%?%");
    if (pieces.length > 1) {
      [this.name, this.role] = pieces;
    } else {
      this.name = name;
      this.role = "Sorint Specialist";
    }
  }
}

export default Author;
