import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@/optimized-libraries/materialdesignicons/index.css";
// import plugin
import { TiptapVuetifyPlugin } from "tiptap-vuetify"
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css"

const vuetify = new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#410979",
        primary_accent: "#9c27b0",
        secondary_accent: "#F39200",
        secondary: "#120627",
        tertiary: "#2D0912",
        orange_sorint: "#f37021",
        project_area: "#100625",
        admin_primary: "#636490",
        admin_background: "01002e",
      },
    },
  },
});

Vue.use(Vuetify);

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi"
})

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#410979",
        primary_accent: "#9c27b0",
        secondary_accent: "#F39200",
        secondary: "#120627",
        tertiary: "#2D0912",
        orange_sorint: "#f37021",
        project_area: "#100625",
        admin_primary: "#636490",
      },
    },
  },
});
