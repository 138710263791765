import api from "@services/axiosInstance";
import livesApi from "@services/livesAxiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Reports/get_report_episode
 */
function getReportByEpisode(classroomid, episodeid) {
  return livesApi.get(
    `/report/episode?episodeid=${episodeid}&classroomid=${classroomid}`
  );
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Reports/get_report_download
 */
function getReportByCourse(classroomid, courseid) {
  return livesApi.get(
    `/report/download?courseid=${courseid}&classroomid=${classroomid}`,
    {
      responseType: "blob",
    }
  );
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Reports/get_report_download_all
 */
function getAllReportsByEpisode(courseId) {
  return livesApi.get(`/report/download/all?courseid=${courseId}`, {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Reports/post_report__episodeid_
 */
function reloadReportForEpisode(episodeId) {
  return livesApi.post(`/report/${episodeId}`);
}

/**
 * API DOC: da aggiornare con nuova api non ancora presente su swagger
 */
function downloadAllPings() {
  return livesApi.get(`/report/download-live/all-students`, {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Student/get_student_download_passed_quiz
 */
function downloadPassedQuizzes(quiz_id) {
  return api.get(`/student/download/passed-quiz?quiz_id=${quiz_id}`, {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Reports/get_report_download_live_all
 */
function downloadSubscribedStudents(courseId) {
  return livesApi.get(`/report/download-live?courseid=${courseId}`, {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Reports/get_report_download_live_all
 */
function downloadAllSubscribedStudents() {
  return livesApi.get(`/report/download-live/all`, { responseType: "blob" });
}

export default {
  getReportByEpisode,
  getReportByCourse,
  getAllReportsByEpisode,
  reloadReportForEpisode,
  downloadAllPings,
  downloadPassedQuizzes,
  downloadSubscribedStudents,
  downloadAllSubscribedStudents,
};
