<template>
  <v-col class="pa-0">
    <v-row
      :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
      no-gutters
      align="center"
    >
      <v-col cols="12">
        <v-sheet
          class="mx-auto my-auto"
          color="transparent"
        >
          <v-row
            :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
            align="center"
            no-gutters
          >
            <v-slide-group
              v-model="model"
              dark
              show-arrows
              center-active
            >
              <v-slide-item
                v-for="(episode, index) of course.episodes"
                :key="index"
                v-slot="{ active, toggle }"
              >
                <v-btn
                  class="episode-btn rounded-lg"
                  :class="{'mr-2': index !== course.episodes.length - 1}"
                  :input-value="active"
                  active-class="active-episode-btn"
                  :to="episodeLinks[index]"
                  @click="toggle"
                >
                  <span class="white--text">
                    Live {{ index + 1 }} - {{ formatDate(episode.start) }}
                  </span>
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import courseHandler from "@mixins/courseHandler";

export default {
  name: "EpisodeLinks",
  mixins: [courseHandler],
  data: () => ({
    model: null,
  }),
  computed: {
    episodeLinks() {
      const links = [];
      if (
        this.course.episodes &&
        this.course.episodes.length > 0
      ) {
        for (const episode of this.course.episodes) {
          links.push(
            `/course/${this.course.id}/video/${episode.videoId}`
          );
        }
      } else {
        links.push(`/waiting`);
      }
      return links;
    },

    liveDateFormatted() {
      return this.formatDate(this.episodeStart)
    },

    episodeStart() {
      return new Date(this.episode.start * 1000);
    },
    episodeEnd() {
      return new Date(this.episode.end * 1000);
    },
  },
  methods:
    {
      formatDate(dateToFormat) {
        return `${new Date(dateToFormat*1000).toLocaleDateString(
          "it-IT",
          {
            day: "numeric",
            year: "numeric",
            month: "long",
          }
        )}`;
      }
    }
}
</script>

<style scoped>
.episode-btn {
  background-color: transparent !important;
  border: 1px #F39200 solid !important;
  font-family: 'Karasuma Gothic' !important;
  text-transform: unset !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.active-episode-btn {
  background-color: #F39200 !important;
}
</style>