<template>
  <v-card color="transparent" class="pa-2" flat>
    <DefaultTable
      :headers="headers"
      :store-name="'techJourney'"
      :method="'getUnapprovedTechJourneyQuestions'"
      :allow-expand="true"
    >
      <template #expanded-item="{ item }">
        <td :colspan="12">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                Nome Utente: {{ item.user_name }}
              </v-col>
              <v-col cols="12" md="6">
                Cognome Utente: {{ item.user_surname }}
              </v-col>
              <v-col cols="12" md="6">
                Nome Risorsa: {{ item.resource_title }}
              </v-col>
              <v-col cols="12" md="6">
                Nome Sezione: {{ item.section_title }}
              </v-col>
            </v-row>
            <v-row class="pb-2 px-2">
              <v-btn
                dark
                small
                color="#F39200"
                class="white--text font-weight-bold btn-size rounded-lg"
                @click="approveQuestion(item.id)"
              >
                Approva
              </v-btn>
              <v-spacer />
              <v-btn
                dark
                small
                color="red"
                class="white--text font-weight-bold btn-size rounded-lg"
                @click="deleteQuestion(item.id)"
              >
                Cancella
              </v-btn>
            </v-row>
          </v-container>
        </td>
      </template>
    </DefaultTable>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import trigger from "@mixins/trigger";

import DefaultTable from "@cmp/shared/DefaultTable";
export default {
  name: "TechJourneyQuestionsManagement",
  components: { DefaultTable },
  mixins: [trigger],
  data: () => ({
    headers: [
      {
        text: "Testo domanda",
        value: "text",
      },
    ],
  }),
  methods: {
    ...mapActions("techJourney", [
      "approveTechJourneyQuestion",
      "deleteTechJourneyQuestion",
    ]),
    async approveQuestion(id) {
      await this.approveTechJourneyQuestion({ id });
      this.triggerUpdate();
    },
    async deleteQuestion(id) {
      await this.deleteTechJourneyQuestion({ id });
      this.triggerUpdate();
    },
  },
};
</script>
