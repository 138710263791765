<template>
  <v-container class="navigation-wrapper">
    <AdminNavigation
      v-if="isAdmin && showAdminDrawer"
      @onCloseDrawer="showAdminDrawer = false"
    />
    <landing-bell v-if="!isAdmin" />
    <v-toolbar :color="!isAdmin ? 'transparent' : '#01002e'" :flat="true" dark>
      <v-app-bar-nav-icon
        v-if="!isAdmin"
        class="hidden-lg-and-up"
        @click.stop="setDrawer(!drawer)"
      />
      <v-app-bar-nav-icon
        v-else
        class="hidden-lg-and-up"
        @click.stop="showAdminDrawer = true"
      />
      <v-img
        max-height="42px"
        width="379px"
        :max-width="$vuetify.breakpoint.mdAndDown ? '70%' : null"
        contain
        position="start"
        src="@/assets/landingPage/HG_LOGO.webp"
      />

      <v-spacer v-if="!$vuetify.breakpoint.smAndDown" />
      <ToolbarTimer
        v-if="isNextLive && !$vuetify.breakpoint.smAndDown"
        :is-next-live="isNextLive"
      />
      <v-spacer />

      <v-menu
        v-if="notifications.length"
        :value="notificationShown"
        transition="slide-y-transition"
        :close-on-content-click="false"
        bottom
        :offset-y="true"
        @input="hideNotifications"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            x-small
            class="text-subtitle-2 mr-2"
            color="deep-orange darken-1"
            v-on="on"
            @click.prevent="openNotifications"
          >
            <strong>{{ notifications.length }}</strong>
          </v-btn>
        </template>
        <ToolbarNotification :notifications="notifications" />
      </v-menu>

      <ToolbarMenu v-if="!showDrawer" :avatar-url="getAvatarRelodedUrl" />
    </v-toolbar>

    <v-container v-if="needsDesktopMenu && !isAdmin" class="px-0">
      <div class="home-btn mt-3">
        <img
          src="@assets/menu/desktopMenu/homePageButton.svg"
          alt="home button"
          @click="returnHome"
        />
      </div>
    </v-container>

    <DesktopMenu
      v-if="needsDesktopMenu && !isAdmin && !$vuetify.breakpoint.mobile"
    />
    <ToolbarTimer
      v-if="isNextLive && $vuetify.breakpoint.smAndDown"
      :is-next-live="isNextLive"
    />
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ToolbarNotification from "./ToolbarNotification.vue";
import store from "@store";
import AdminNavigation from "@cmp/admin/AdminNavigation";
import ToolbarMenu from "@cmp-shared/toolbar/ToolbarMenu";
import landingBell from "@cmp/utils/LandingBell";
import DesktopMenu from "./DesktopMenu.vue";
import matomoTracker from "@mixins/matomoTracker";
import ToolbarTimer from "./ToolbarTimer.vue";
import { pushAuthUrl } from "@/utils/externalRouter";

export default {
  name: "Toolbar",
  components: {
    ToolbarMenu,
    ToolbarNotification,
    AdminNavigation,
    landingBell,
    DesktopMenu,
    ToolbarTimer,
  },
  mixins: [matomoTracker],
  props: {
    reverseBg: Boolean,
  },

  data() {
    return {
      showAdminDrawer: false,
      value: "",
      error: false,
      warning: false,
      info: false,
      isNextLive: null,
    };
  },

  computed: {
    ...mapState("toolbar", [
      "notificationShown",
      "notifications",
      "notificationsLoaded",
      "drawer",
    ]),
    urlPrefix() {
      if (this.isTeacher) {
        return "teacher";
      } else if (this.isStudent) {
        return "student";
      } else if (this.isAdmin) {
        return "admin";
      } else if (this.isGuest) {
        return "guest";
      } else if (this.isSorint) {
        return "sorint";
      }
      return "";
    },
    needsDesktopMenu() {
      if (this.$route.name.includes("platform-home-page")) {
        return false;
      }
      return true;
    },
    showDrawer() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    currentUserRedirect() {
      const user = { ...this.user };
      switch (user.access_level) {
        case 2:
          return "teacher";
        case 3:
          return "student";
        case 5:
          return "sorint";
        default:
          return "guest";
      }
    },
  },
  async created() {
    this.isNextLive = await this.getNextLiveInfo();
  },

  mounted() {
    this.getNotifications();
  },

  methods: {
    ...mapMutations("toolbar", [
      "toggleNotificationShown",
      "notificationsHide",
      "setDrawer",
    ]),
    ...mapActions("auth", ["logout"]),
    ...mapActions("courses", ["getNextLiveInfo"]),

    hideNotifications() {
      this.notificationsHide();
    },
    async openNotifications() {
      await this.trackEvent("PlatformToolbar", "ClickOnNotificationsButton");
      await this.toggleNotificationShown();
    },
    getNotifications() {
      if (!this.notificationsLoaded) {
        store.dispatch("toolbar/getNotifications");
      }
    },
    async returnHome() {
      await this.logout();
      pushAuthUrl();
    },
  },
};
</script>

<style scoped>
.toolbar-btn {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* Grey/100 */

  color: #f4f5f6;
}

.input-field {
  /* padding: 5px 155px; */
  padding: 5px 16px;
  border: 1px solid #e8ebed;
  border-radius: 12px;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #c6ced2;
  width: 100%;
}

.input-field::placeholder {
  color: #c6ced2;
}

.navigation-wrapper {
  max-width: 1980px;
}

.home-btn {
  cursor: pointer;
}
</style>
