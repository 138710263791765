<template>
  <v-card
    color="transparent"
    class="pa-2"
    flat
  >
    <DefaultTable
      :headers="headers"
      :store-name="'learningResources'"
      :method="'getUnapprovedOndemandQuestions'"
      :allow-expand="true"
    >
      <template #expanded-item="{item}">
        <td :colspan="12">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                Nome Utente: {{ item.user_name }}
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                Cognome Utente: {{ item.user_surname }}
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                Nome Corso: {{ item.learning_path_title }}
              </v-col>
              <v-col
                v-if="item.main_question"
                cols="12"
                md="6"
              >
                Risposta ad una domanda
              </v-col>
            </v-row>
            <v-row class="pb-2 px-2">
              <v-btn
                dark
                small
                color="#F39200"
                class="white--text font-weight-bold btn-size rounded-lg"
                @click="approveQuestion(item.id)"
              >
                Approva
              </v-btn>
              <v-spacer />
              <v-btn
                dark
                small
                color="red"
                class="white--text font-weight-bold btn-size rounded-lg"
                @click="deleteQuestion(item.id)"
              >
                Cancella
              </v-btn>
            </v-row>
          </v-container>
        </td>
      </template>
    </DefaultTable>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import trigger from "@mixins/trigger";

import DefaultTable from "@cmp/shared/DefaultTable";
export default {
  name: "OndemandQuestionsManagement",
  components: { DefaultTable },
  mixins: [ trigger ],
  data: () => ({
    headers: [
      {
        text: "Testo domanda",
        value: "text"
      }
    ]
  }),
  methods: {
    ...mapActions("learningResources", ["approveOndemandQuestion", "deleteOndemandComment"]),
    async approveQuestion(id) {
      await this.approveOndemandQuestion({ id });
      this.triggerUpdate()
    },
    async deleteQuestion(id) {
      await this.deleteOndemandComment({ id });
      this.triggerUpdate()
    }
  }
}
</script>