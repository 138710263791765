import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/User/get_user_mailing_list_xlsx
 */
function getMailingList(category) {
  return api.get(`/user/mailing-list/xlsx?category=${category}`, {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/User/post_user_email
 */
function sendEmailToCategory(subject, category, body) {
  return api.post("/user/email", {
    category,
    subject,
    text: body,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/Email%20attachment/post_email_attachment
 */
async function addAttachment(file) {
  const response = await api.post("/email/attachment", file);
  return response.data;
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/EmailAttachment/delete_email_attachment__filename_
 */
async function deleteAttachment(filename) {
  return await api.delete(`/email/attachment/${filename}`);
}

export default { getMailingList, sendEmailToCategory, addAttachment, deleteAttachment };
