import mailingListService from "@/services/mailingList";
import { handleAxiosError } from "@utils/notifications";
const actions = {
  async getMailingList({ commit }, category) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const mailingList = await mailingListService.getMailingList(category);
      const blob = new Blob([mailingList.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${category}.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore nello scaricamento della mailing list`,
        err,
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async sendEmailToCategory({commit}, {category, subject, body}){

    try {
      commit("loading/startLoading", {}, { root: true });
      await mailingListService.sendEmailToCategory(subject, category, body)
    } catch (err) {
      await handleAxiosError(
        `Errore nell'invio della mail.`,
        err,
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async addAttachment({commit}, {file}) {
    try {
      commit("loading/startLoading", {}, { root: true });
      return await mailingListService.addAttachment(file);
    } catch (err) {
      await handleAxiosError(
        `Errore nell'aggiunta dell'allegato.`,
        err,
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteAttachment({commit}, {filename}){

    try {
      commit("loading/startLoading", {}, { root: true });
      await mailingListService.deleteAttachment(filename);
    } catch (err) {
      await handleAxiosError(
        `Errore nella rimozione dell'allegato.`,
        err,
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  }
};

export default {
  namespaced: true,
  actions,
};
