<template>
  <v-tabs grow background-color="#1d1c4c" slider-color="orange">
    <v-tab>
      DOMANDE IN PENDING
      <div v-if="unapproved_questions(courseId, episodeId).length > 0" class="ma-0">
        ({{ unapproved_questions(courseId, episodeId).length }})
      </div>
    </v-tab>
    <v-tab>
      DOMANDE APPROVATE ({{ questions(courseId, episodeId).length }})
    </v-tab>

    <!-- TAB DOMANDE PENDING -->
    <v-tab-item>
      <v-data-table :headers="pending_headers" :items="unapproved_questions(courseId, episodeId)" :search="search">
        <template #[`item.number`]="{ index }">
          {{ index + 1 }}
        </template>
        <template #[`item.author`]="{ item }">
          {{ item.username }}
        </template>
        <template #[`item.body`]="{ item }">
          {{ item.text }}
        </template>

        <template #[`item.date`]="{ item }">
          {{ convertTime(item.time) }}
        </template>
        <template #[`item.email`]="{ item }">
          <v-row>
            <v-btn plain color="blue"
              :href="`mailto:${item.email}?cc=${email_details.cc}&subject=${email_details.subject}&body=Gentile ${item.username}%2C${email_details.body}%22${item.text}%22:%0D%0A%0D%0A%0D%0A%0D%0A${email_details.firma}%0D%0A%0D%0A%0D%0A`">
              <v-icon large>
                mdi-email-edit-outline
              </v-icon>
            </v-btn>
          </v-row>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn color="red" class="white--text" @click="approve(item.id, false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn color="green" class="white--text ml-2" @click="approve(item.id, true)">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-tab-item>

    <!-- TAB DOMANDE APPROVATE -->
    <v-tab-item>
      <v-data-table :headers="approved_headers" :items="questions(courseId, episodeId)" :search="search">
        <template #[`item.number`]="{ index }">
          {{ index + 1 }}
        </template>
        <template #[`item.author`]="{ item }">
          {{ item.username }}
        </template>
        <template #[`item.body`]="{ item }">
          {{ item.text }}
        </template>
        <template #[`item.date`]="{ item }">
          {{ convertTime(item.time) }}
        </template>
        <template #[`item.comments`]="{ item }">
          {{ item.comments.length }}
        </template><template #[`item.actions`]="{ item }">
          <v-icon color="red" class="white--text" @click.stop="openDeleteModal(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-tab-item>
    <Modal v-if="dialogQuestionDelete" title="Conferma Eliminazione Domanda Anonima"
      @closeModal="dialogQuestionDelete = false">
      <template #content-center>
        <v-row>
          <v-col>
            <v-card-text class="modal-text">
              <p>
                <v-icon large left color="red">
                  mdi-alert
                </v-icon><span class="red--text">ATTENZIONE!</span> Sei sicura/o di
                voler eliminare la seguente domanda anonima?
              </p>
              <v-row>
                <v-col class="ml-10">
                  <span>
                    <span class="text-overline">Utente: </span>
                    <span class="text-body-2">
                      {{ questionToDelete.username }}</span>
                  </span>
                  <br>
                  <span>
                    <span class="text-overline">Contenuto: </span>
                    <span class="text-body-2">
                      {{ questionToDelete.text }}</span>
                  </span>
                  <br>
                  <span>
                    <span class="text-overline">Numero commenti: </span>
                    <span class="text-body-2">
                      {{ questionToDelete.comments.length }}</span>
                  </span>
                  <br>
                  <span>
                    <span class="text-overline">Data creazione: </span>
                    <span class="text-body-2">
                      {{ convertTime(questionToDelete.time) }}</span>
                  </span>
                </v-col>
              </v-row>

              <p class="font-weight-thin mt-3">
                Se la elimini perderai anche i
                {{ questionToDelete.comments.length }} commenti ad essa
                associata!
              </p>
            </v-card-text>
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-row justify="center">
            <v-btn large plain class="white--text mx-3" @click="dialogQuestionDelete = false">
              <v-icon class="mr-1">
                mdi-close
              </v-icon>
              <span>ANNULLA</span>
            </v-btn>
            <v-btn color="red darken-2" class="white--text mx-3" large data-cy="confirm-delete-class"
              @click="confirmDelete">
              <v-icon class="mr-1">
                mdi-delete
              </v-icon>
              ELIMINA
            </v-btn>
          </v-row>
        </v-card-actions>
      </template>
    </Modal>
  </v-tabs>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { sendNotification } from "@utils/notifications";
import Modal from "../utils/Modal.vue";

export default {
  name: "QuestionDataTable",
  components: { Modal },
  props: {
    episodeId: {
      required: true,
      type: String,
    },
    courseId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      email_details: {
        cc: "hackersgen@sorint.com",
        subject: "HackersGen - Risposta alla tua domanda anonima",
        body: "%0D%0ATi rispondiamo in merito alla domanda anonima ",
        firma: "Ti ringraziamo per la tua partecipazione attiva%0D%0ABuona continuazione%0D%0A%0D%0AIl Team di Hackersgen%0D%0Ahackersgen@sorint.com",
      },
      intervalQuestions: null,
      dialogQuestionDelete: false,
      questionToDelete: "",
      search: "",
      pending_headers: [
        {
          text: "#",
          value: "number",
        },
        {
          text: "Nome Cognome",
          value: "author",
        },
        {
          text: "Domanda",
          value: "body",
        },
        {
          text: "Data",
          value: "date",
        },
        {
          text: "Messaggio",
          value: "email",
        },
        {
          text: "Rifiuta o Accetta",
          value: "actions",
        },
      ],
      approved_headers: [
        {
          text: "#",
          value: "number",
        },
        {
          text: "Nome Cognome",
          value: "author",
        },
        {
          text: "Domanda",
          value: "body",
        },
        {
          text: "# Commenti",
          value: "comments",
        },
        {
          text: "Data",
          value: "date",
        },
        {
          text: "Azioni",
          value: "actions",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("courses", { questions: "getQuestionsFromEpisodeId" }),
    ...mapGetters("courses", {
      unapproved_questions: "getUnapprovedQuestionsFromEpisodeId",
    }),
  },
  async beforeMount() {
    this.updateQuestions();
  },

  beforeDestroy() {
    clearInterval(this.intervalQuestions);
  },
  methods: {
    ...mapActions("courses", [
      "requestSubscription",
      "getEpisodeQuestions",
      "getEpisodeUnapprovedQuestions",
      "approveQuestion",
      "subscribeEpisode",
      "deleteQuestion",
    ]),
    async approve(questionId, approved) {
      try {
        await this.approveQuestion({
          courseId: this.courseId,
          episodeId: this.episodeId,
          questionId,
          approved,
        });

        if (approved) {
          this.updateQuestions();
          sendNotification(`Domanda approvata :)`, "success");
        } else {
          sendNotification(`Hai rifiutato una domanda anonima :(`, "error");
        }
      } catch (err) {
        sendNotification(`Errore durante l'approvazione della domanda.`, "error");
      }
    },
    async updateQuestions() {
      await this.getEpisodeQuestions({
        episode_id: this.episodeId,
        course_id: this.courseId,
      });
      await this.getEpisodeUnapprovedQuestions({
        episode_id: this.episodeId,
        course_id: this.courseId,
      });

      this.intervalQuestions = setInterval(async () => {
        await this.getEpisodeQuestions({
          episode_id: this.episodeId,
          course_id: this.courseId,
        });
        await this.getEpisodeUnapprovedQuestions({
          episode_id: this.episodeId,
          course_id: this.courseId,
        });
      }, 60000);
    },
    openDeleteModal(question) {
      this.dialogQuestionDelete = true;
      this.questionToDelete = question;
    },
    confirmDelete() {
      this.deleteQuestion({ questionId: this.questionToDelete.id });
      this.dialogQuestionDelete = false;
    },
    convertTime(time) {
      /**
       * converts from unix format to the one required by the mockup
       */
      return new Date(time * 1000).toLocaleString("it-IT").slice(0, -3);

    },
  },
}
</script>
