import { handleAxiosError, sendNotification } from "@utils/notifications";
import examService from "@services/exam";

const initialState = () => ({
  id: null,
  title: null,
  duration: null,
  time_left: null,
  max_points: null,
  questions: [],
  result: null,
});

const state = initialState();

const getters = {
  id(state) {
    return state.id;
  },

  title(state) {
    return state.title;
  },

  duration(state) {
    return state.duration;
  },

  time_left(state) {
    return state.time_left;
  },

  max_points(state) {
    return state.max_points;
  },

  questions(state) {
    return state.questions;
  },

  result(state) {
    return state.result;
  },
};

const mutations = {
  SET_RESULT(state, data) {
    state.result = data;
  },

  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
  async resultsOfStudents({ commit }, { classId, quizId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.resultsOfStudents(classId, quizId);
      return data;
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento dei risultati`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async examSummary({ commit }, { studentId, quizId, filename }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.examSummary(studentId, quizId);
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${filename}.zip`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento dei risultati`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async allExamsSummary({ commit }, { classId, quizId, filename }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.allExamsSummary(classId, quizId);
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${filename}.zip`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento dei risultati`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async exportQuizSummary({ commit }, { filename }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.exportQuizSummary();
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${filename}.zip`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore durante il download dell'export`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getCertificate({ commit }, { studentId, quizId, filename }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.getCertificate(studentId, quizId);
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${filename}.pdf`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore durante lo scaricamento del certificato`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getAllCertificates({ commit }, { classId, quizId, filename }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await examService.getAllCertificates(classId, quizId);
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${filename}.zip`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore durante lo scaricamento dei certificati`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async removeBadge(_context, { quizId, studentId }) {
    try {
      await examService.removeBadge({ quiz_id: quizId, student_id: studentId });
      sendNotification("Badge rimosso con successo", "success");
    } catch (err) {
      await handleAxiosError("Errore nella cancellazione del badge", err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
