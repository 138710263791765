<template>
  <v-col
    cols="12"
    class="pa-0 px-3"
  >
    <h2 class="sections-title mb-2">
      Sezioni
    </h2>
    <v-expansion-panels
      v-if="sections"
      dark
      flat
      multiple
    >
      <ResourcesSection
        v-for="(section, index) in sections"
        :key="index"
        :section="section"
        :section-index="index"
        :get-resources="getResources"
        :update-quiz-id="updateQuizId"
        class="mb-5"
      />
    </v-expansion-panels>
    <span
      v-else
      class="span-content"
    >Per procedere, creare almeno una sezione.</span>
    <v-row class="justify-end mr-1 mt-1">
      <v-menu
        offset-y
        transition="slide-y-transition"
      >
        <template #activator="{ on, attrs, value }">
          <v-btn
            :disabled="isPublished"
            class="mx-2 resources-btn"
            rounded
            text
            v-bind="attrs"
            v-on="on"
          >
            Aggiungi
            <v-icon
              class="resources-btn_icon white--text"
              :class="{ rotate: value }"
            >
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-list class="resources-list">
          <v-list-item
            v-for="(type, index) in types"
            :key="index"
            class="resources-list_item"
            target="_blank"
            @click="newResource(type.value)"
          >
            <v-list-item-title class="resources-list_title">
              {{ type.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
  </v-col>
</template>

<script>
import ResourcesSection from "./ui/ResourcesSection.vue";
import { mapActions } from "vuex";
export default {
  name: "SectionsManagement",
  components: { ResourcesSection },
  props: {
    updateQuizId: {
      type: Function,
      required: true,
    },
    updateRequest: {
      type: Boolean,
      required: true,
    },
    requestToFalse: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    sections: [],
    isPublished: false,
    types: [
      {
        name: "Quiz",
        value: "assessment",
      },
      {
        name: "Video",
        value: "video_lesson",
      },
      {
        name: "Esercizio",
        value: "prog_exercise",
      },
    ],
  }),
  watch: {
    async updateRequest(value) {
      if(value) {
        await this.getResources();
      }
    }
  },
  async mounted() {
    await this.getResources();
  },
  methods: {
    ...mapActions("learningPaths", ["getOndemandById"]),
    async getResources() {
      const data = await this.getOndemandById({ id: this.$route.params.id });
      this.sections = data.sections;
      this.isPublished = data.published;
      this.requestToFalse();
    },
    newResource(type) {
      this.updateQuizId("", type);
    }
  }
}
</script>

<style scoped>
.span-content {
  font-family: "Karasuma Gothic" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #f4f5f6 !important;
}
.resources-list {
  background-color: #F39200 !important;
  border-radius: 8px;
}
.resources-list_title {
  color: #f4f5f6;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}
.resources-btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6 !important;
  background-color: #F39200;
  text-transform: none;
}

.resources-btn_icon {
  transition: transform 0.3s ease-in-out !important;
}

.resources-btn_icon.rotate {
  transform: rotate(180deg);
}

.sections-title{
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 1px;
  color: #FFFFFF;
}
</style>