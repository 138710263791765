<template>
  <v-col class="pa-0 mb-5 camingo">
    <div
      class="py-5"
      v-dompurify-html:safeHtml="
        `<strong>Domanda ${number + 1})</strong> <div class='mt-3'>${
          question.text
        }</div>`
      "
    />
    <v-row>
      <v-col cols="auto">
        <h4 class="mb-2">Risposte</h4>
        <v-radio-group
          v-if="question.answerType === 'single_answer'"
          v-model="correctAnswers[0]"
          class="pt-0 my-1"
          readonly
        >
          <v-radio
            v-for="(answer, index) in question.answers"
            :key="index"
            :label="answer.text"
            color="indigo"
            :value="answer.id"
          />
        </v-radio-group>
        <v-checkbox
          v-for="(answer, index) in question.answers"
          v-else
          :key="index"
          v-model="correctAnswers"
          readonly
          :label="answer.text"
          color="indigo"
          :value="answer.id"
          hide-details
          class="my-1"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "QuestionPreview",
  props: {
    question: {
      type: Object,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      checkedAnswer: 0,
    };
  },
  computed: {
    correctAnswers() {
      return this.question.answers
        .filter((answer) => answer.correct === true)
        .map((correctAnswer) => correctAnswer.id);
    },
  },
};
</script>
