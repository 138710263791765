import reportService from "@services/reports";
import { handleAxiosError } from "@utils/notifications";
import { sendNotification } from "@/utils/notifications";

const actions = {
  async getReportByCourse({ commit }, { classroomId, courseId, fileName }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report = await reportService.getReportByCourse(
        classroomId,
        courseId
      );
      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${fileName}.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento del report`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getAllReportsByEpisode({ commit }, { courseId, fileName }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const zip = await reportService.getAllReportsByEpisode(courseId);
      const blob = new Blob([zip.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${fileName}.zip`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento del report`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async downloadPassedQuizzes({ commit }, { quiz_id, fileName }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report = await reportService.downloadPassedQuizzes(quiz_id);
      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${fileName}.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore nel download dei risultati degli studenti`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getReportAllPings(_context, { fileName }) {
    try {
      const { data } = await reportService.downloadAllPings();
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${fileName}.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento del report`, err);
    }
  },
  async reloadReportById({ commit }, { episodeId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await reportService.reloadReportForEpisode(episodeId);
    } catch (err) {
      await handleAxiosError(`Errore nel reload del report`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
      sendNotification("Report in fase di ricalcolo");
    }
  },
  async downloadSubscribedStudents({ commit }, { fileName, courseId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report = await reportService.downloadSubscribedStudents(courseId);
      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `subscribed_classes_${fileName}.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore nello scaricamento del report studenti iscritti`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async downloadAllSubscribedStudents({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report = await reportService.downloadAllSubscribedStudents();
      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `all_subscribed_classes.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        `Errore nello scaricamento del report di tutti gli studenti iscritti`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  }
};

export default {
  namespaced: true,
  actions,
};
