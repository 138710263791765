import livesApi from "@services/livesAxiosInstance";
import rocketApi from "@services/rocketChatAxios";

/* Backend API */
function getToken() {
  return livesApi.post("chat/login");
}

/* Rocketchat API */
function getChannelsMessages(roomName) {
  return rocketApi.get("api/v1/channels.counters", {
    params: { roomName },
  });
}

function getGroupsMessages(roomName) {
  return rocketApi.get("api/v1/groups.counters", { params: { roomName } });
}

function getChannelsJoined() {
  return rocketApi.get("api/v1/channels.list.joined", {
    params: { fields: { _id: 1, name: 1 } },
  });
}

function setMessagesRead(roomId) {
  return rocketApi.post("api/v1/subscriptions.read", { rid: roomId });
}

function loadRoomMessages(roomId, publicChannel) {
  const aux = publicChannel ? "channels" : "groups";

  return rocketApi.get(`api/v1/${aux}.history`, {
    params: {
      roomId,
      count: 10000,
    },
  });
}

export default {
  getToken,
  getGroupsMessages,
  getChannelsMessages,
  getChannelsJoined,
  setMessagesRead,
  loadRoomMessages,
};
