<template>
  <div
    class="desktop-menu-tile mb-3"
    :class="{'desktop-menu-tile_disabled': !isOpen}"
    @click="changeRoute"
  >
    <h3
      class="desktop-menu-tile--text"
      :style="{color: isActive ? '#F39200' : '#F4F5F6'}"
    >
      {{ text }} 
    </h3>
    <img
      :src="require(`@assets/menu/desktopMenu/DesktopMenuTile-${isActive ? 'active' : 'inactive'}.svg`)"
    >
  </div>
</template>

<script>
export default {
  name:"DesktopMenuTile",
  props: {
    text: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
  },
  computed:{
    isActive(){
      return this.$route.name === this.routeName
    }
  },
  methods:{
    changeRoute(){
      if (this.isOpen) {
        this.$router.push({name: this.routeName })
      }
    }
  }
}
</script>

<style scoped>
.desktop-menu-tile{
  width: 232px;
  height: 41px;
  position: relative;
  cursor: pointer;
  display: block;
}
.desktop-menu-tile_disabled {
  cursor: default;
  opacity: 0.5;
}

.desktop-menu-tile--text{
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
  font-family: 'outerorbit', 'Orbitron';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 20px;
  text-align: center;
}
</style>