<template>
  <div>
    <h1
      v-if="ondemand"
      class="course-title my-2"
    >
      {{ course.title }}
    </h1>
    <p class="course-paragraph">
      {{ course.description }}
    </p>
    <p
      v-if="!ondemand"
      class="course-paragraph"
    >
      {{ episodeDescription }}
    </p>
    <v-row class="mb-2 mt-md-4">
      <v-col
        v-for="(authors, index) in course.authors"
        :key="index"
        cols="12"
        md="auto"
        class="mr-md-6"
      >
        <h4 class="trainer-name">
          {{ course.authors[index].name }}
        </h4>
        <span class="trainer-role">{{ course.authors[index].role }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CourseInfoSection",
  props: {
    ondemand: {
      type: Boolean,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
    episodeDescription: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    videoUri: "",
  }),
};
</script>

<style scoped>

.course-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

.course-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #98a6ae;
}

.trainer-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #f4f5f6;
}

.trainer-role {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #98a6ae;
}

.button-text {
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
}
</style>