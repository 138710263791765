<template>
  <v-col
    class="pa-0"
    cols="12"
  >
    <v-card
      class="pa-5 mt-3"
      color="#1d1c4c"
      dark
    >
      <v-card-title class="pa-0">
        <v-col
          cols="12"
        >
          <v-row>
            <h2 class="font-weight-light">
              REPORT ONDEMAND
            </h2>
          </v-row>
        </v-col>

        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            sm="8"
            md="6"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cerca un corso"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-row class="mt-5">
        <v-col>
          <v-data-table
            :headers="coursesHeaders"
            :items="filteredCourses"
            class="data-table"
          >
            <template #[`item.openClasses`]="{ item }">
              <v-btn 
                color="#F39200"
                width="40px"
                height="40px"
                min-width="0"
                class="rounded-lg open-button"
                @click="selectCourse(item.id)"
              >
                <v-icon>mdi-login</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ReportOnDemand",
  data() {
    return {
      search: "",
      coursesHeaders: [
        {
          text: "Corsi",
          value: "title",
          sortable: true,
        },
        {
          text: "Apri elenco classi",
          value: "openClasses",
          sortable: false,
        },
      ],
      ondemandCourses: [],
    };
  },
  computed: {
    filteredCourses() {
      return this.ondemandCourses.filter((course) => {
        if (!this.search || course.title.includes(this.search)) {
          return true;
        }
        return false;
      });
    },
  },
  async mounted() {
    this.ondemandCourses = await this.getOndemandCourses();
  },
  methods: {
    ...mapActions("learningPaths", ["getOndemandCourses"]),
    selectCourse(courseId) {
      this.$router.push({
        name: `admin-report-ondemand-details`,
        params: { id: courseId },
      });
    },
  },
};
</script>

<style>
.data-table{
  background-color: transparent !important;
}

.open-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f4f5f6;
  text-transform: none;
}
</style>
