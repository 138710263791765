import QuizAnswer from "./quizAnswer";

class QuizQuestion {
  constructor(id, text, shuffle_answers, answerType, points, answers = []) {
    this.id = id;
    this.text = text;
    this.shuffleAnswers = shuffle_answers;
    this.answerType = answerType;
    this.points = points
    this.answers = answers;
  }
  addAnswer(id, text, correct) {
    this.answers.push(new QuizAnswer(id, text, correct));
  }
}

export default QuizQuestion;
