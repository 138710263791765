<template>
  <v-stepper-header>
    <template
      v-for="(step, index) in steps" 
    >
      <v-stepper-step
        :key="index + '-step'"
        class="steps-text ma-0 pa-0"
        color="#7FBAF4"
        :editable="isEditing"
        :step="index + 1"
      >
        {{ step.title }}
      </v-stepper-step>
      <v-divider
        class="mx-3"
        v-if="index + 1 < steps.length"
        :key="index + '-divider'"
      />
    </template>
  </v-stepper-header>
</template>

<script>
export default {
  name: "QuizHeader",
  props: {
    isEditing: {
      type: Boolean,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      steps: [
        { title: "Informazioni base" },
        { title: "Domande e Risposte" },
        { title: "Riepilogo" },
        { title: "Sessioni e pubblicazione" },
      ],
    };
  },
};
</script>

<style scoped>
.steps-text {
  font-family: "Karasuma Gothic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
}

::v-deep .v-stepper__step__step {
  width: 32px;
  height: 32px;
}
</style>
