<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="courses"
      :search="search"
      class="data-table"
    >
      <template #[`item.number`]="{ index }">
        {{ index + 1 }}
      </template>
      <template #[`item.button`]="{ item }">
        <v-btn
          class="white--text rounded-lg"
          :disabled="item.episodes.length === 0"
          :color="item.onDemand ? 'purple' : 'green'"
          @click="courseLinks(item)"
        >
          <strong>Apri</strong>
        </v-btn>
      </template>

      <template #[`item.type`]="{ item }">
        <span v-if="item.onDemand"> On-demand </span>
        <span v-else>Live</span>
      </template>

      <template #[`item.speaker`]="{ item }">
        {{ item.authors.map((author) => author.name).join(", ") }}
      </template>

      <template #[`item.dates`]="{ item }">
        {{
          item.episodes
            .map((ep) => dateFromTs(ep.start))
            .sort()
            .join(" - ")
        }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          :to="{name: 'admin-course-edit', params: {id: item.id}}"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon
            @click="
              downloadClassroomsSubscribed({
                courseId: item.id,
                courseTitle: item.title,
              })
            "
          >
            mdi-download
          </v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon
            @click="openConfirmDeleteModal(item.id)"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <Modal
      v-if="showConfirmDelete"
      title="ELIMINAZIONE CORSO"
      @closeModal="closeConfirmDeleteModal"
    >
      <template #content>
        <v-card-text class="modal-text">
          ATTENZIONE! Sei sicuro di voler eliminare il corso? L'operazione è
          irreversibile
        </v-card-text>

        <v-card-actions class="mt-3">
          <v-row justify="center">
            <v-btn
              large
              plain
              class="white--text mx-3"
              @click="closeConfirmDeleteModal"
            >
              <v-icon class="mr-1">
                mdi-close
              </v-icon>
              ANNULLA
            </v-btn>
            <v-btn
              color="red darken-2"
              class="white--text mx-3"
              large
              @click="confirmDelete"
            >
              <v-icon class="mr-1">
                mdi-delete
              </v-icon>
              ELIMINA
            </v-btn>
          </v-row>
        </v-card-actions>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Modal from "@cmp/utils/Modal";
export default {
  name: "LiveCoursesTable",
  components: { Modal },
  props: {
    search: {
      type: String,
      default: "",
    }
  },
  data: () => ({
    headers: [
      {
        text: "#",
        value: "number",
      },
      {
        text: "Nome corso",
        value: "title",
      },
      { text: "Pagina corso", value: "button" },
      { text: "Tipo", value: "type" },
      {
        text: "Speaker",
        value: "speaker",
      },
      {
        text: "Date live",
        value: "dates",
      },
      {
        text: "Azioni",
        value: "actions",
        sortable: false,
      },
    ],
    elements: [],
    showConfirmDelete: false,
    confirmingDeleteID: null,
  }),
  computed: {
    ...mapState("courses", ["courses"]),
  },
  methods: {
    courseSelector(title) {
      switch (title) {
      case "On Demand":
        return this.onDemandCourses;

      case "Live":
        return this.liveCourses;
      
      default:
        return this.courses;
      }
    },
    courseLinks(_item) {
      this.$router.push(
        `/course/${_item.id}/video/${_item.episodes[0].videoId}`
      );
    },
    ...mapActions("courses", ["deleteCourse"]),
    ...mapActions("classrooms", ["downloadClassroomsSubscribed"]),
    dateFromTs(timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toISOString().substring(0, 10);
    },
    closeConfirmDeleteModal() {
      this.showConfirmDelete = false;
      this.confirmingDeleteID = null;
    },
    openConfirmDeleteModal(courseId) {
      this.showConfirmDelete = true;
      this.confirmingDeleteID = courseId;
    },
    confirmDelete() {
      this.deleteCourse({ courseId: this.confirmingDeleteID });
      this.confirmingDeleteID = null;
      this.showConfirmDelete = false;
    },
  },
}
</script>

<style scoped>
.data-table{
  background-color: transparent;
  font-weight: 500;
}
</style>