import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/delete_learning_paths_resources__id_
 */
function createOndemandQuiz(
  description,
  duration,
  learningPathId,
  sectionName,
  title
) {
  return api.post(`/learning-paths/quizzes`, {
    description,
    duration,
    learning_path_id: learningPathId,
    section_name: sectionName,
    title,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20quiz/put_learning_paths_quizzes
 */
function updateOndemandQuiz(
  description,
  duration,
  learningPathId,
  sectionName,
  title
) {
  return api.put(`/learning-paths/quizzes`, {
    description,
    duration,
    id: learningPathId,
    section_name: sectionName,
    title,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20quiz/post_learning_paths_quizzes_questions
 */
function createOndemandQuizQuestion(
  answers,
  learningPathQuizId,
  shuffleAnswers,
  text
) {
  return api.post(`/learning-paths/quizzes/questions`, {
    answers,
    learning_path_quiz_id: learningPathQuizId,
    shuffle_answers: shuffleAnswers,
    text,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20quiz/put_learning_paths_quizzes_questions
 */
function updateOndemandQuizQuestion(answers, id, shuffleAnswers, text) {
  return api.put(`/learning-paths/quizzes/questions`, {
    answers,
    id,
    shuffle_answers: shuffleAnswers,
    text,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20quiz/delete_learning_paths_quizzes_questions__id_
 */
function deleteOndemandQuizQuestion(id) {
  return api.delete(`/learning-paths/quizzes/questions/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20quiz/get_learning_paths_quizzes__id_
 */
function getOndemandQuizzes() {
  return api.get(`learning-paths/quizzes`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20quiz/get_learning_paths_quizzes__id_
 */
function getOndemandQuizzesById(id) {
  return api.get(`learning-paths/quizzes/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/post_learning_paths_video_lessons
 */
function createOndemandVideo(
  duration,
  learningPathId,
  sectionName,
  title,
  videoUri
) {
  return api.post(`/learning-paths/video-lessons`, {
    duration,
    learning_path_id: learningPathId,
    section_name: sectionName,
    title,
    video_uri: videoUri,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/get_learning_paths_video_lessons__id_
 */
function getOndemandVideo(videoPathId) {
  return api.get(`learning-paths/video-lessons/${videoPathId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/put_learning_paths_video_lessons
 */
function updateOndemandVideo(duration, id, sectionName, title, videoUri) {
  return api.put(`learning-paths/video-lessons`, {
    duration,
    id,
    section_name: sectionName,
    title,
    video_uri: videoUri,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/post_learning_paths_prog_exercises
 */
function createOndemandExercise(
  hint,
  learningPathId,
  sectionName,
  text,
  title
) {
  return api.post(`learning-paths/prog-exercises`, {
    hint,
    learning_path_id: learningPathId,
    section_name: sectionName,
    text,
    title,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/get_learning_paths_prog_exercises__id_
 */
function getOndemandExerciseDetails(id) {
  return api.get(`learning-paths/prog-exercises/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/get_learning_paths_prog_exercises__id__hint
 */
function getOndemandExerciseHint(id) {
  return api.get(`learning-paths/prog-exercises/${id}/hint`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/put_learning_paths_prog_exercises
 */
function updateOndemandExercise(hint, id, sectionName, text, title) {
  return api.put(`learning-paths/prog-exercises`, {
    hint,
    id,
    section_name: sectionName,
    text,
    title,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/put_learning_path_resources_order
 */
function changeResourceOrder(resourceId, newPosition) {
  return api.put(`/learning-paths/resources/order`, {
    new_position: newPosition,
    resource_id: resourceId,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20resource/delete_learning_paths_resources__id_
 */
function deleteOndemandResource(id) {
  return api.delete(`/learning-paths/resources/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20questions/get_learning_paths_questions
 */
function getOndemandComments(id) {
  return api.get(`learning-paths/questions`, {
    params: {
      id,
    },
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20questions/get_learning_paths_questions
 */
function postNewQuestion(learningPathId, text) {
  return api.post(`learning-paths/questions`, {
    learning_path_id: learningPathId,
    text,
  });
}
function postNewComment(learningPathId, text, mainQuestion) {
  return api.post(`learning-paths/questions`, {
    learning_path_id: learningPathId,
    text,
    main_question: mainQuestion,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20questions/get_learning_paths_questions_not_approved
 */
function getUnapprovedOndemandQuestions() {
  return api.get(`learning-paths/questions/not-approved`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20questions/put_learning_paths_questions_approve
 */
function approveOndemandQuestion(id) {
  return api.put(`learning-paths/questions/approve?id=${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path%20questions/delete_learning_paths_questions
 */
function deleteOndemandComment(id) {
  return api.delete(`learning-paths/questions`, {
    params: {
      id,
    },
  });
}

export default {
  createOndemandQuiz,
  updateOndemandQuiz,
  createOndemandQuizQuestion,
  updateOndemandQuizQuestion,
  deleteOndemandQuizQuestion,
  getOndemandQuizzes,
  getOndemandQuizzesById,
  createOndemandVideo,
  updateOndemandVideo,
  getOndemandVideo,
  createOndemandExercise,
  getOndemandExerciseDetails,
  getOndemandExerciseHint,
  updateOndemandExercise,
  deleteOndemandResource,
  changeResourceOrder,
  getOndemandComments,
  postNewQuestion,
  postNewComment,
  getUnapprovedOndemandQuestions,
  approveOndemandQuestion,
  deleteOndemandComment,
};
