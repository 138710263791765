<template>
  <v-main id="container">
    <v-container>
      <h2 class="wrapper-title pl-5">Utenti, classi, scuole</h2>
      <v-card
        id="link-mgmt-container"
        class="rounded-lg pa-5 mt-3"
        color="transparent"
        flat
      >
        <v-expansion-panels class="mt-5" dark flat>
          <v-expansion-panel
            v-for="(item, index) in items"
            :key="index"
            class="more-offers-panel mb-5"
          >
            <v-expansion-panel-header>
              <v-col cols="12">
                <v-row>
                  <div class="mr-3 gap-2">
                    <v-icon v-for="icon in item.icons"> mdi-{{ icon }} </v-icon>
                  </div>
                  <h2 class="title-text">
                    {{ item.name }}
                  </h2>
                </v-row>
                <v-row>
                  <h3 class="subtitle-text">
                    {{ item.description }}
                  </h3>
                </v-row>
              </v-col>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <UsersManagement v-if="item.name === 'Gestione utenti'" />
              <TeachersApproval v-if="item.name === 'Approvazione docenti'" />
              <ClassroomsManagement v-if="item.name === 'Gestione classi'" />
              <SchoolsManagement v-if="item.name === 'Gestione scuole'" />
              <QuestionsManagement
                v-if="item.name === 'Gestione domande live'"
              />
              <OndemandQuestionsManagement
                v-if="item.name === 'Gestione domande ondemand'"
              />
              <TechJourneyQuestionsManagement
                v-if="item.name === 'Gestione domande Tech Journey'"
              ></TechJourneyQuestionsManagement>
              <TechJourneyReplyManagement
                v-if="item.name === 'Gestione risposte / commenti Tech Journey'"
              ></TechJourneyReplyManagement>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import UsersManagement from "../../components/admin/UsersManagement.vue";
import TeachersApproval from "../../components/admin/TeachersApproval.vue";
import ClassroomsManagement from "../../components/admin/ClassroomsManagement.vue";
import QuestionsManagement from "../../components/admin/QuestionsManagement.vue";
import OndemandQuestionsManagement from "../../components/admin/OndemandQuestionsManagement.vue";
import TechJourneyQuestionsManagement from "../../components/admin/TechJourneyQuestionsManagement.vue";
import TechJourneyReplyManagement from "../../components/admin/TechJourneyReplyManagement.vue";
import SchoolsManagement from "../../components/admin/SchoolsManagement.vue";
import store from "@store";

export default {
  name: "Users",
  components: {
    UsersManagement,
    TeachersApproval,
    ClassroomsManagement,
    QuestionsManagement,
    OndemandQuestionsManagement,
    TechJourneyQuestionsManagement,
    TechJourneyReplyManagement,
    SchoolsManagement,
  },
  data: () => ({
    items: [
      {
        name: "Gestione utenti",
        description: `Area dedicata alla gestione dei dati utente e invio delle bulk mail.`,
        icons: ["account"],
      },
      {
        name: "Approvazione docenti",
        description: ` Area dedicata all’approvazione o al rifiuto delle richieste d’iscrizione dei professori.`,
        icons: ["human-male-board"],
      },
      {
        name: "Gestione classi",
        description: `Area dedicata alla consultazione e eliminazione delle classi.`,
        icons: ["account-group"],
      },
      {
        name: "Gestione scuole",
        description: `Area dedicata alla creazione e modifica delle scuole.`,
        icons: ["school"],
      },
      {
        name: "Gestione domande live",
        description: `Area dedicata all’approvazione o al rifiuto della pubblicazione delle domande anonime relative ai corsi live.`,
        icons: ["video-wireless", "chat-question-outline"],
      },
      {
        name: "Gestione domande ondemand",
        description: `Area dedicata all’approvazione o al rifiuto della pubblicazione delle domande anonime relative ai corsi ondemand.`,
        icons: ["video", "chat-question-outline"],
      },
      {
        name: "Gestione domande Tech Journey",
        description: `Area dedicata all’approvazione o al rifiuto della pubblicazione delle domande anonime relative ai video nella Tech Journey.`,
        icons: ["rocket", "chat-question-outline"],
      },
      {
        name: "Gestione risposte / commenti Tech Journey",
        description: `Area dedicata all’approvazione o al rifiuto della pubblicazione delle risposte alle domande anonime relative ai video nella Tech Journey.`,
        icons: ["rocket", "chat-alert-outline"],
      },
    ],
  }),
  beforeRouteEnter: async (to, from, next) => {
    try {
      store.commit("loading/startLoading");
      await store.dispatch("courses/getAllCourses");
      next();
    } catch (err) {
      next(err);
    }
    store.commit("loading/stopLoading");
  },
};
</script>

<style scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.btn-size {
  min-width: 80%;
}

.title-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.subtitle-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.more-offers-panel {
  background: #1d1c4c !important;
  backdrop-filter: blur(60px);
  border-radius: 12px !important;
  font-family: "Karasuma Gothic" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #f4f5f6 !important;
  margin-top: 0px !important;
  padding: 5px !important;
  letter-spacing: 0.75px !important;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
