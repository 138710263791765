<template>
  <v-card
    class="flex-1"
    flat
    color="transparent"
  >
    <v-row
      v-if="customTopActionSlots.length > 0"
      no-gutters
      class="px-7 pt-5 mb-5"
    >
      <v-col
        cols="12"
        class="pa-0"
      >
        <v-col
          v-if="customTopActionSlots.length > 0"
          class="pa-0"
        >
          <v-row
            v-for="slot in customTopActionSlots"
            :key="slot"
            class="mb-3"
            no-gutters
          >
            <slot :name="slot" />
          </v-row>
        </v-col>
      </v-col>
    </v-row>
    <v-col
      class="pa-0"
      cols="12"
    >
      <v-data-table
        class="data-table"
        disable-sort
        dark
        disable-filtering
        :items-per-page="itemsPerPage"
        :show-expand="allowExpand"
        :item-key="itemKey"
        :single-expand="allowExpand"
        :expanded.sync="expanded"
        :headers="tableHeaders"
        :items="items"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 30, 40, 50],
        }"
        :loading="isLoading"
      >
        <template
          v-for="pageHeader in tableHeaders"
          #[`header.${pageHeader.value}`]="{ header }"
        >
          <v-responsive
            :key="pageHeader.value"
            min-width="150px"
          >
            <v-row
              :key="pageHeader.value"
              no-gutters
              justify="start"
              align="center"
            >
              <span class="header-font">{{ header.text }}</span>
            </v-row>
          </v-responsive>
        </template>

        <template
          v-for="slot in customSlots"
          #[`item.${slot}`]="{ item }"
        >
          <slot
            :name="slot"
            :item="item"
          />
        </template>

        <template
          v-if="allowExpand"
          #expanded-item="{ item }"
        >
          <slot
            :name="'expanded-item'"
            :item="item"
          />
        </template>
      </v-data-table>
    </v-col>
  </v-card>
</template>

<script>
import trigger from "@mixins/trigger";
export default {
  name: "DefaultTable",
  mixins: [trigger],
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    storeName: {
      type: String,
      default: ""
    },
    method: {
      type: String,
      default: ""
    },
    customTopActionSlots: {
      type: Array,
      default: () => [],
    },
    customSlots: {
      type: Array,
      default: () => [],
    },
    itemType: {
      type: Object,
      default: null,
    },
    needsInitialFetch: {
      type: Boolean(),
      default: true,
    },
    localValues:{
      type: Array,
      default: () => []
    },
    allowExpand: {
      type: Boolean(),
      default: false,
    },
    itemKey: {
      type: String,
      default: "id"
    }
  },
  data() {
    return {
      items: [],
      itemsPerPage: 5,
      tableHeaders: [],
      isLoading: false,
      expanded: [],
    };
  },
  watch: {
    needsUpdate(newValue) {
      if (newValue) {this.refreshItems();}
    },
  },
  async mounted() {
    this.isLoading = true;
    this.tableHeaders = this.headers
      ? this.headers.map((value) => ({
        ...value,
        align: "start",
      }))
      : [];

    if (this.needsInitialFetch) {await this.getItems();}
    this.isLoading = false;
  },
  methods: {
    async refreshItems() {
      this.$logger("refreshing items");
      this.isLoading = true;
      await this.getItems();
      this.isLoading = false;
      this.resetTrigger();
    },
    async getItems() {
      if(this.localValues.length){
        this.items = this.localValues;
        return
      }
      const response = await this.$store.dispatch(
        `${this.storeName}/${this.method}`,
        JSON.parse(
          JSON.stringify({
            ...this.itemType,
          })
        )
      );
      if (response === null) {
        return;
      }
      this.items = response;
    },
  },
};
</script>

<style scoped>
.data-table {
  background-color: transparent;
  font-weight: 500;
}

.header-font {
  font-size: 16px;
  font-weight: 500;
  line-height: 27.5px;
}
</style>
