<template>
  <span>
    <v-row
      v-if="!starsOnly"
      justify="center"
      align="center"
    >

      <span class="text--lighten-2 text-caption"> Vota il corso: </span>
    </v-row>
    <v-row
      class="d-flex justify-center"
      :class="starsOnly ? 'justify-md-end' : ''"
      align="center"
    >
      <v-rating
        large
        hover
        data-cy="rate-course"
        :value="episode.myRank"
        color="white"
        @input="vote"
      />
    </v-row>
    <v-row
      v-if="!starsOnly"
      justify="center"
      align="center"
    >
      <span class="text--lighten-2 text-caption">
        {{ episode.rank }} su 5
      </span>
    </v-row>
  </span>
</template>

<script>
import { sendNotification } from "@utils/notifications";
import { mapActions } from "vuex";

export default {
  name: "Rating",
  props: {
    episode: {
      required: true,
      type: Object,
    },
    courseId: {
      required: true,
      type: String,
    },
    starsOnly: {
      required: false,
      type: Boolean
    }
  },
  methods: {
    ...mapActions("courses", ["createRank", "getRanks"]),
    async vote(rank) {
      await this.createRank({
        courseId: this.courseId,
        episodeId: this.episode.id,
        rating: rank,
      });
      sendNotification("Grazie per il tuo voto!", "success");
    },
  },
};
</script>
