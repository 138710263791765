<template>
  <v-main id="container">
    <v-container>
      <v-btn
        color="admin_primary"
        class="white--text my-5"
        large
        to="/report/1"
      >
        <v-icon left> mdi-keyboard-backspace </v-icon>INDIETRO
      </v-btn>
      <v-card class="pa-5 mt-3" color="#1d1c4c" dark>
        <v-row>
          <v-col>
            <v-card-title class="pa-0">
              <h2 class="font-weight-light">REPORT TEST</h2>
            </v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!selectedClassId">
            <v-row no-gutters>
              <v-col class="pa-0" cols="12" sm="8" md="6">
                <v-text-field
                  v-model="searchClass"
                  class="pa-0"
                  append-icon="mdi-magnify"
                  label="Cerca una classe, un professore o un istituto"
                  single-line
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col>
                <v-data-table
                  :key="'classes'"
                  :headers="headers"
                  :items="filteredClassrooms"
                  class="data-table"
                >
                  <template #[`item.number_tries`]="{ item }">
                    <v-icon
                      v-if="item.number_tries > 0"
                      class="justify-center ml-5"
                    >
                      mdi-star
                    </v-icon>
                  </template>

                  <template #[`item.openStudents`]="{ item }">
                    <v-btn
                      color="#F39200"
                      width="40px"
                      height="40px"
                      min-width="0"
                      class="rounded-lg open-button"
                      @click="selectClass(item.id, item.name)"
                    >
                      <v-icon>mdi-login</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else>
            <v-row no-gutters>
              <v-btn
                text
                class="white--text"
                elevation="0"
                @click="unselectClass"
              >
                <v-icon left> mdi-arrow-left </v-icon>
                Torna all classi
              </v-btn>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="8" md="6">
                <v-text-field
                  v-model="searchStudent"
                  append-icon="mdi-magnify"
                  label="Cerca studente"
                  single-line
                  hide-details
                />
              </v-col>

              <v-col
                cols="12"
                sm="12"
                xl="6"
                class="d-flex flex-wrap justify-end mt-5"
              >
                <v-progress-circular
                  v-if="isLoadingDownloadAllExams"
                  indeterminate
                />
                <v-btn
                  v-else
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                  color="orange white--text"
                  :disabled="!atLeastOneStudentFinishedAQuiz"
                  @click="downloadAllExams"
                >
                  <v-icon left> mdi-download </v-icon>
                  Scarica tutti gli esami
                </v-btn>
                <v-progress-circular
                  v-if="isLoadingDownloadAllCertificates"
                  indeterminate
                />
                <v-btn
                  v-else
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                  :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'ml-5'"
                  color="primary white--text"
                  :disabled="!atLeastOneStudentPassedAQuiz"
                  @click="downloadAllCertificates"
                >
                  <v-icon left> mdi-download </v-icon>
                  Scarica tutti i certificati
                </v-btn>
                <v-progress-circular v-if="isLoadingDownload" indeterminate />
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col>
                <v-data-table
                  :key="'students'"
                  :headers="studentHeaders"
                  :items="filteredStudents"
                  class="data-table"
                >
                  <template #[`item.esito`]="{ item }">
                    <template v-if="!item.quiz_taken">
                      <span class="grey--text">NON EFFETTUATO</span>
                    </template>
                    <template v-if="item.quiz_taken && !item.quiz_passed">
                      <span class="red--text">NON PASSATO</span>
                    </template>
                    <template v-if="item.quiz_taken && item.quiz_passed">
                      <span class="green--text">PASSATO</span>
                    </template>
                  </template>
                  <template #[`item.avg_seconds`]="{ item }">
                    {{ Math.floor(item.avg_seconds / 60) }}
                  </template>
                  <template #[`item.download`]="{ item }">
                    <v-progress-circular
                      v-if="isLoadingDownload === item.student_id"
                      indeterminate
                    />
                    <v-btn
                      v-else
                      color="orange white--text"
                      :disabled="!item.quiz_taken"
                      small
                      @click="downloadQuiz(item)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <template #[`item.download_certificate`]="{ item }">
                    <v-progress-circular
                      v-if="isLoadingDownloadCertificate === item.student_id"
                      indeterminate
                    />
                    <v-btn
                      v-else
                      color="primary white--text"
                      :disabled="!item.quiz_passed"
                      small
                      @click="downloadCertificate(item)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <template #[`item.has_badge`]="{ item }">
                    <Modal
                      v-if="dialogBadgeRemove === item.student_id"
                      title="Rimozione badge studente"
                      width="600px"
                      :glassify="false"
                      @closeModal="dialogBadgeRemove = ''"
                    >
                      <template #content-center>
                        <v-card-text class="mb-3" justify="center">
                          Confermando l'azione rimuoverai il badge allo
                          studente. Vuoi procedere?
                        </v-card-text>
                        <v-card-actions>
                          <v-row justify="center">
                            <v-progress-circular
                              v-if="isLoadingRemoveBadge === true"
                              indeterminate
                            />
                            <v-btn
                              color="success"
                              class="btn-font"
                              @click="() => removeCredlyBadge(item)"
                            >
                              <v-icon class="mr-1"> mdi-check </v-icon>
                              CONFERMA
                            </v-btn>
                          </v-row>
                        </v-card-actions>
                      </template>
                    </Modal>
                    <v-btn
                      v-if="item.quiz_passed"
                      small
                      :disabled="item.has_badge === false"
                      @click="dialogBadgeRemove = item.student_id"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "../../components/utils/Modal.vue";

export default {
  name: "ReportTestDetails",
  components: {
    Modal,
  },
  props: {
    id: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialogBadgeRemove: "",
      searchClass: "",
      searchStudent: "",
      classrooms: [],
      quizName: "",
      selectedClassId: "",
      headers: [
        {
          text: "Classe",
          value: "name",
        },
        {
          text: "Istituto",
          value: "school_name",
        },
        {
          text: "Email Docente",
          value: "prof_email",
        },
        {
          text: "Almeno un tentativo",
          value: "number_tries",
        },
        {
          text: "Apri elenco studenti",
          value: "openStudents",
        },
      ],
      studentHeaders: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "student_name",
        },
        {
          text: "Cognome",
          align: "start",
          sortable: true,
          value: "student_surname",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "student_email",
        },
        {
          text: "Esito",
          align: "start",
          value: "esito",
        },
        {
          text: "Tempo esecuzione medio (minuti)",
          align: "start",
          sortable: true,
          value: "avg_seconds",
        },
        {
          text: "Tentativi",
          align: "start",
          sortable: true,
          value: "num_tries",
        },
        {
          text: "Punteggio",
          align: "start",
          sortable: true,
          value: "last_result",
        },
        {
          text: "Punteggio massimo",
          align: "start",
          sortable: false,
          value: "max_points",
        },
        {
          text: "Download test studente",
          align: "start",
          value: "download",
          sortable: false,
        },
        {
          text: "Download certifica studente",
          align: "start",
          value: "download_certificate",
          sortable: false,
        },
        {
          text: "Revoca badge",
          align: "start",
          value: "has_badge",
          sortable: false,
        },
      ],
      isLoadingDownload: null,
      isLoadingDownloadCertificate: null,
      isLoadingDownloadAllCertificates: false,
      isLoadingDownloadAllExams: false,
      isLoadingRemoveBadge: false,
      students: [],
    };
  },
  computed: {
    filteredClassrooms() {
      return this.classrooms.filter((classroom) => {
        if (
          !this.searchClass ||
          classroom.name.includes(this.searchClass) ||
          classroom.school_name.includes(this.searchClass) ||
          classroom.prof_email.includes(this.searchClass)
        ) {
          return true;
        }
        return false;
      });
    },
    filteredStudents() {
      return this.students.filter((student) => {
        if (
          !this.searchStudent ||
          student.student_name.includes(this.searchStudent) ||
          student.student_surname.includes(this.searchStudent) ||
          student.student_email.includes(this.searchStudent)
        ) {
          return true;
        }
        return false;
      });
    },
    atLeastOneStudentFinishedAQuiz() {
      if (this.students instanceof Array && this.students.length > 0) {
        return this.students.some((student) => student.quiz_taken);
      }
      return false;
    },
    atLeastOneStudentPassedAQuiz() {
      if (this.students instanceof Array && this.students.length > 0) {
        return this.students.some((student) => student.quiz_passed);
      }
      return false;
    },
  },
  async created() {
    const classrooms = await this.getClassroomsByQuiz(this.id);
    if (classrooms) {
      this.classrooms = classrooms;
    }
  },
  methods: {
    ...mapActions("quiz", ["getClassroomsByQuiz"]),
    ...mapActions("exam", [
      "resultsOfStudents",
      "examSummary",
      "allExamsSummary",
      "getCertificate",
      "getAllCertificates",
      "removeBadge",
    ]),
    async selectClass(classId, className) {
      this.selectedClassId = classId;
      this.selectedClassName = className;
      const students = await this.resultsOfStudents({
        classId: this.selectedClassId,
        quizId: this.id,
      });
      if (students) {
        this.students = students.student_results.map((student) => ({
          ...student,
          max_points: students.max_points,
        }));
        this.quizName = students.quiz_title;
      }
    },
    async downloadQuiz(student) {
      try {
        this.isLoadingDownload = student.student_id;
        await this.examSummary({
          studentId: student.student_id,
          quizId: this.id,
          filename: `${this.quizName}_${student.student_name}_${student.student_surname}`,
        });
      } catch (e) {
        this.$logger(e);
      } finally {
        this.isLoadingDownload = null;
      }
    },
    async downloadCertificate(student) {
      try {
        this.isLoadingDownloadCertificate = student.student_id;
        await this.getCertificate({
          studentId: student.student_id,
          quizId: this.id,
          filename: `${this.quizName}_${student.student_name}_${student.student_surname}`,
        });
      } catch (e) {
        this.$logger(e);
      } finally {
        this.isLoadingDownloadCertificate = null;
      }
    },
    async downloadAllCertificates() {
      try {
        this.isLoadingDownloadAllCertificates = true;
        await this.getAllCertificates({
          classId: this.selectedClassId,
          quizId: this.id,
          filename: `Elenco_Certificati_${this.quizName}_${this.selectedClassName}`,
        });
      } catch (e) {
        this.$logger(e);
      } finally {
        this.isLoadingDownloadAllCertificates = false;
      }
    },
    async downloadAllExams() {
      try {
        this.isLoadingDownloadAllExams = true;
        await this.allExamsSummary({
          classId: this.selectedClassId,
          quizId: this.id,
          filename: `Elenco_Esami_${this.quizName}_${this.selectedClassName}`,
        });
      } catch (e) {
        this.$logger(e);
      } finally {
        this.isLoadingDownloadAllExams = false;
      }
    },
    async removeCredlyBadge(student) {
      try {
        this.isLoadingRemoveBadge = true;
        await this.removeBadge({
          quizId: this.id,
          studentId: student.student_id,
        });
      } catch (e) {
        this.$logger(e);
      } finally {
        this.isLoadingRemoveBadge = false;
      }
    },
    unselectClass() {
      this.selectedClassId = "";
      this.selectedClassName = "";
    },
  },
};
</script>

<style scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.data-table {
  background-color: transparent;
}

.open-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f4f5f6;
  text-transform: none;
}
</style>
