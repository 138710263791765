<template>
  <v-col
    cols="12"
    class="pa-0"
  >
    <v-sheet
      color="rgba(0,0,0,0)"
      height="60"
      class="px-md-8 mb-3 mb-md-5"
    >
      <v-toolbar
        color="rgba(0,0,0,0.05)"
        class="calendar-header"
        flat
      >
        <v-btn
          text
          :small="$vuetify.breakpoint.mdAndDown"
          outlined
          color="white"
          class="mr-2 mx-md-5"
          @click="prev"
        >
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          outlined
          :small="$vuetify.breakpoint.mdAndDown"
          color="white"
          @click="setToday"
        >
          Oggi
        </v-btn>
        <v-toolbar-title
          v-if="currentTitle && !$vuetify.breakpoint.mdAndDown"
          class="ml-5 bold"
        >
          {{ currentTitle }}
        </v-toolbar-title>
        <v-btn
          text
          outlined
          :small="$vuetify.breakpoint.mdAndDown"
          color="white"
          class="ml-2 ml-md-5"
          @click="next"
        >
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <v-btn
                text
                outlined
                :small="$vuetify.breakpoint.mdAndDown"
                color="white"
                class="ml-2 ml-md-0 mx-md-5"
                :disabled="
                  !selectedQuiz.credly_template_id ||
                    !selectedQuiz.credly_excellence_template_id
                "
                @click="createNewSession"
              >
                <v-icon small>
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
          </template>
          Inserisci e salva i template Credly prima di creare delle sessioni
        </v-tooltip>
      </v-toolbar>
    </v-sheet>
    <v-row
      v-if="currentTitle && $vuetify.breakpoint.mdAndDown"
      justify="center"
      class="mb-2"
    >
      <v-col cols="auto">
        <v-toolbar-title
          class="bold calendar-header py-1 px-2"
        >
          {{ currentTitle }}
        </v-toolbar-title>
      </v-col>
    </v-row>
    <v-sheet
      color="rgba(0,0,0,0.15)"
      height="1000"
    >
      <v-calendar
        ref="calendar"
        v-model="focus"
        :events="events"
        :event-color="getEventColor"
        dark
        type="month"
        event-overlap-mode="stack"
        :event-overlap-threshold="30"
        :show-week="true"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        locale="it-IT"
        :start="startingDay"
        @click:date="dateClick"
        @click:event="eventClick"
      >
        <template #event="{ event }">
          <v-row
            no-gutters
            justify="center"
            class="overflow-x-hidden fill-height"
          >
            <v-col
              cols="10"
              class="pa-0 fill-height"
            >
              <v-row
                class="fill-width"
                no-gutters
              >
                <span class="event-title fill-width">
                  {{ `${event.name} - Sessione ` }} {{ event.counter }}
                </span>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-calendar>
    </v-sheet>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "Calendar",
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  emits: ["onDateClick"],
  data() {
    return {
      focus: "",
      currentTitle: "",
    };
  },
  computed: {
    ...mapGetters("quiz", ["selectedQuiz"]),
    startingDay() {
      return dayjs().add(1, "day").toDate();
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
    this.updateTitle();
  },
  methods: {
    setToday() {
      this.focus = "";
      this.updateTitle();
    },
    prev() {
      this.$refs.calendar.prev();
      this.updateTitle();
    },
    next() {
      this.$refs.calendar.next();
      this.updateTitle();
    },
    updateTitle() {
      setTimeout(() => {
        this.currentTitle =
          this.$refs.calendar.title.charAt(0).toUpperCase() +
          this.$refs.calendar.title.slice(1);
      }, 0);
    },
    createNewSession() {
      const todayDateString = dayjs().add(1, "day").format("YYYY-MM-DD");
      this.$emit("onDateClick", {
        startingDate: todayDateString,
        endingDate: todayDateString,
      });
    },
    getEventColor(slot) {
      if (slot.mysession) {
        return "#66cc66";
      }
      return "#410979";
    },
    dateClick(event) {
      const dayjsDate = dayjs(event.date);
      if (
        dayjsDate.isBefore(this.startingDay, "day") &&
        !dayjsDate.isSame(this.startingDay, "day")
      ) {
        return;
      }
      this.$emit("onDateClick", {
        startingDate: event.date,
        endingDate: event.date,
      });
    },
    eventClick(event) {
      this.$emit("onEventClick", event);
    },
  },
};
</script>

<style scoped>
.calendar-header {
  border: 1px solid white !important;
  border-radius: 12px;
}

:deep(.v-calendar), :deep(.v-future), :deep(.v-past) {
  background-color: rgba(0,0,0,0) !important;
}

:deep(.v-calendar-weekly__head-weeknumber), :deep(.v-calendar-weekly__weeknumber), :deep(.v-calendar-weekly__head-weekday) {
  background-color: rgba(0,0,0,0.2) !important;
}

:deep(.v-present > .v-calendar-weekly__day-label > .v-btn) {
  background-color: #F39200;
  height: 22px;
  width: 42px;
  border-radius: 5px;
  margin-top: 9px;
}

@media (max-width: 767.98px) {
  :deep(.v-present > .v-calendar-weekly__day-label > .v-btn) {
    width: 28px;
  }
}
</style>