<template>
  <v-main id="container">
    <v-container>
      <v-card class="white pa-5 mt-3">
        <v-row align="baseline">
          <v-card-title>
            <h2 class="font-weight-light">
              CHAT
            </h2>
          </v-card-title>
        </v-row>
        <v-row>
          <Chat
            channel=""
            :embedded="false"
            class="chat-container"
          />
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import Chat from "@cmp-chat/ChatIframe";
export default {
  name: "AdminChat",
  components: { Chat },
};
</script>

<style scoped>
.chat-container {
  height: 80vh;
}

#container {
  background-color: #01002e;
  background-repeat: repeat;
}
</style>
