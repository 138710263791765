<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="elements"
      :search="search"
      :loading="loading"
      loading-text="Caricamento lista corsi... Attendere"
      class="data-table"
    >
      <template #[`item.authors`]="{ item }">
        {{ item.authors.map((author) => author.split("%?%").join(" - ")).join(", ") }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          @click="unarchived(item)"
        >
          <v-icon>
            mdi-archive-arrow-up
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { handleAxiosError } from "@utils/notifications";
import { mapActions } from "vuex";
import learningPathsService from "@services/learningPaths";
export default {
  name: "OndemandCoursesArchived",
  props: {
    search: {
      type: String,
      default: "",
    }
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: "Nome corso",
        value: "title",
      },
      {
        text: "Autori",
        value: "authors",
      },
      {
        text: "Punti",
        value: "points",
      },
      {
        text: "Ripristina",
        value: "actions",
        sortable: false,
      },
    ],
    elements: [],
  }),
  async mounted() {
    await this.getCourses();
  },
  methods: {
    ...mapActions("learningPaths", ["archiveOndemandCourse"]),
    async getCourses() {
      this.loading = true;
      try {
        const { data } = await learningPathsService.getOndemandCourses();
        this.elements = data.filter((c) => c.archived);
      } catch (err) {
        handleAxiosError(`Errore recupero corsi ondemand`, err);
      }
      finally {
        this.loading = false;
      }
    },
    async unarchived(item) {
      await this.archiveOndemandCourse(item);
      await this.getCourses();
    },
  }
}
</script>

<style scoped>
.data-table{
  background-color: transparent;
  font-weight: 500;
}
</style>