import learningPathsService from "@services/learningPaths";
import { handleAxiosError, sendNotification } from "@utils/notifications";
import OndemandCourse from "@/classes/ondemandCourse";

const initialState = () => ({
  ondemandCourses: [],
});

const state = initialState();

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  /**
   * Mutation that creates courses from BE
   *
   * @param {Array} ondemandCourses
   * @returns {null}
   */
  SET_ONDEMAND_COURSES(state, { ondemandCourses }) {
    state.ondemandCourses = [];
    for (const ondemandCourse of ondemandCourses) {
      const {
        id,
        title,
        description,
        authors,
        thumbnail_url,
        published,
        archived,
        points,
        internship_minutes,
        external_resource_link,
        order_num,
        sections,
      } = ondemandCourse;
      state.ondemandCourses.push(
        new OndemandCourse(
          id,
          title,
          description,
          authors,
          thumbnail_url,
          published,
          archived,
          points,
          internship_minutes,
          external_resource_link,
          order_num,
          sections
        )
      );
    }
  },
  UPDATE_ONDEMAND_COURSE(state, ondemandCourse) {
    const index = state.ondemandCourses.findIndex(
      (c) => c.id === ondemandCourse.id
    );
    state.ondemandCourses[index] = ondemandCourse;
  },
};

const actions = {
  async getOndemandCourses({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningPathsService.getOndemandCourses();
      return data;
    } catch (err) {
      await handleAxiosError(
        `Errore durante il caricamento dei corsi ondemand`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async updateLearningPathVideo(_context, id) {
    try {
      const response = await learningPathsService.updateLearningPathVideo(id);
      return response;
    } catch (e) {
      return null;
    }
  },
  async getOndemandById(_context, { id }) {
    try {
      const { data } = await learningPathsService.getOndemandById(id);
      const {
        title,
        description,
        authors,
        thumbnail_url,
        published,
        archived,
        points,
        internship_minutes,
        external_resource_link,
        order_num,
        sections,
      } = data;
      const names = authors.map((author) => author.split("%?%")[0]);
      const roles = authors.map((author) => author.split("%?%")[1]);
      const speakers = [{ name: "", role: "" }];
      [speakers[0].name] = names;
      [speakers[0].role] = roles;
      for (let i = 1; i < authors.length; i++) {
        speakers.push({ name: names[i], role: roles[i] });
      }
      const course = {
        id,
        title,
        description,
        authors: speakers,
        thumbnailUrl: thumbnail_url,
        published,
        archived,
        points,
        internshipMinutes: internship_minutes / 60,
        externalResourceLink: external_resource_link,
        orderNum: order_num,
        sections,
      };
      return course;
    } catch (err) {
      await handleAxiosError(`Errore nel caricamento del corso ondemand`, err);
      return null;
    }
  },
  async deleteOndemandCourse({ commit }, { id }) {
    try {
      commit("loading/startloading", {}, { root: true });
      await learningPathsService.deleteOndemandCourse(id);
      sendNotification("Corso ondemand eliminato con successo!", "success");
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'eliminazione del corso ondemand`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async setOndemandThumbnail({ commit }, { id, image }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningPathsService.uploadOndemandThumbnail(
        id,
        image
      );
      return data;
    } catch (err) {
      await handleAxiosError("Errore nel caricamento del thumbnail", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteOndemandThumbnail({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningPathsService.deleteOndemandThumbnail(id);
      sendNotification("Thumbnail ondemand eliminata con successo!", "success");
    } catch (err) {
      await handleAxiosError(`Errore nell'eliminazione del thumbnail`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async createOndemandCourse({ commit }, ondemandCourse) {
    const {
      authors,
      description,
      internshipMinutes,
      externalResourceLink,
      points,
      title,
    } = ondemandCourse;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningPathsService.createOndemandCourse(
        authors.map((a) => `${a.name}%?%${a.role}`),
        description,
        internshipMinutes * 60,
        externalResourceLink,
        points,
        title
      );
    } catch (err) {
      await handleAxiosError(
        "Errore durante la creazione del corso ondemand",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async updateOndemandCourse({ commit }, ondemandCourse) {
    const {
      archived,
      authors,
      description,
      id,
      internshipMinutes,
      externalResourceLink,
      points,
      published,
      title,
    } = ondemandCourse;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningPathsService.updateOndemandCourse(
        archived,
        authors.map((a) => `${a.name}%?%${a.role}`),
        description,
        id,
        internshipMinutes * 60,
        externalResourceLink,
        points,
        published,
        title
      );
      commit("UPDATE_ONDEMAND_COURSE", ondemandCourse);
      sendNotification("Informazioni aggiornate con successo!", "success");
    } catch (err) {
      await handleAxiosError(
        "Errore durante la modifica del corso ondemand",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async archiveOndemandCourse({ commit }, ondemandCourse) {
    const archived = false;
    const {
      authors,
      description,
      id,
      internship_minutes,
      external_resource_link,
      points,
      published,
      title,
    } = ondemandCourse;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningPathsService.updateOndemandCourse(
        archived,
        authors,
        description,
        id,
        internship_minutes,
        external_resource_link,
        points,
        published,
        title
      );
      commit("UPDATE_ONDEMAND_COURSE", ondemandCourse);
      sendNotification("Informazioni aggiornate con successo!", "success");
    } catch (err) {
      await handleAxiosError(
        "Errore durante la modifica del corso ondemand",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async updateOndemandOrder({ commit }, { id, newPosition }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningPathsService.updateOndemandOrder(id, newPosition);
      sendNotification("Informazioni aggiornate con successo!", "success");
    } catch (err) {
      await handleAxiosError(
        "Errore durante la modifica dell'ordine del corso ondemand",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async getOndemandReportByClassId(
    { commit },
    { classId, learningPathId }
  ) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await learningPathsService.getOndemandReportByClassId({
          classId,
          learningPathId,
        });
      return data;
    } catch (err) {
      await handleAxiosError(`Errore nel recupero del report`, err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
