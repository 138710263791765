<template>
  <v-main id="container">
    <v-container>
      <v-btn
        color="admin_primary"
        class="white--text my-5"
        large
        to="/report/2"
      >
        <v-icon left>
          mdi-keyboard-backspace
        </v-icon>INDIETRO
      </v-btn>
      <v-card
        class="pa-5 mt-3"
        color="#1d1c4c"
        dark
      >
        <v-row>
          <v-col>
            <v-card-title class="pa-0">
              <h2 class="font-weight-light">
                REPORT ONDEMAND
              </h2>
            </v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!selectedClassId">
            <v-row
              no-gutters
            >
              <v-col
                class="pa-0"
                cols="12"
                sm="8"
                md="6"
              >
                <v-text-field
                  v-model="searchClass"
                  class="pa-0"
                  append-icon="mdi-magnify"
                  label="Cerca una classe, un professore o un istituto"
                  single-line
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col>
                <v-data-table
                  :key="'classes'"
                  :headers="headers"
                  :items="allClassrooms"
                  :loading="isLoading"
                  :search="searchClass"
                  class="data-table"
                >
                  <template #[`item.openStudents`]="{ item }">
                    <v-btn 
                      color="#F39200"
                      width="40px"
                      height="40px"
                      min-width="0"
                      class="rounded-lg open-button"
                      @click="selectClass(item.id)"
                    >
                      <v-icon>mdi-login</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else>
            <v-row
              no-gutters
              justify="space-between"
            >
              <v-btn
                text
                class="white--text"
                elevation="0"
                @click="unselectClass"
              >
                <v-icon left>
                  mdi-arrow-left
                </v-icon>
                Torna all classi
              </v-btn>
            </v-row>
            <!-- DETTAGLIO STUDENTI -->
            <DefaultTable
              v-if="!isLoading"
              :headers="studentHeaders"
              :store-name="'learningPaths'"
              :method="'getOndemandReportByClassId'"
              :allow-expand="true"
              :item-type="{ classId: selectedClassId, learningPathId: courseId }"
              class="mt-5"
            >
              <template #expanded-item="{item}">
                <td :colspan="12">
                  <v-tabs
                    v-model="tab"
                    class="mt-5 mb-3"
                    background-color="transparent"
                    color="#F39200"
                    hide-slider
                    active-class="tab-selected"
                    align-with-title
                  >
                    <v-tab
                      v-for="(tab, index) in tabs"
                      :key="index"
                      :href="`#tab-${index}`"
                      class="ma-0 tabs-title"
                    >
                      {{ tab }}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items
                    v-model="tab"
                    class="transparent"
                  >
                    <v-tab-item
                      v-for="(tab, index) in tabs"
                      :key="index"
                      :value="`tab-${index}`"
                    >
                      <v-row no-gutters>
                        <v-col
                          v-for="resource in realResources(item.resources, tab)"
                          :key="resource.id"
                          cols="12"
                          md="4"
                          class="resource-label mb-2"
                        >
                          <v-icon
                            v-if="tab === resource.section_name"
                            left
                            dark
                          >
                            {{ iconSelector(resource.type) }}
                          </v-icon>
                          <span v-if="tab === resource.section_name">
                            {{ resource.title }}:
                          </span>
                          <span
                            v-if="tab === resource.section_name"
                            class="resource-status"
                            :class="resource.completed ? 'green--text' : 'red--text'"
                          >{{ resource.completed ? 'completato' : 'non completato' }}</span>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </td>
              </template>
            </DefaultTable>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";
import DefaultTable from "../../components/shared/DefaultTable.vue";

export default {
  name: "ReportOnDemandDetails",
  components: { DefaultTable },
  data() {
    return {
      isLoading: true,
      searchClass: "",
      selectedClass: [],
      selectedClassId: "",
      allClassrooms: [],
      tabs: [],
      tab: null,
      headers: [
        {
          text: "Classe",
          value: "name",
        },
        {
          text: "Istituto",
          value: "school",
        },
        {
          text: "Apri elenco studenti",
          value: "openStudents",
        }
      ],
      studentHeaders: [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "Cognome",
          value: "surname",
        },
      ],
    };
  },
  computed: {
    courseId() {
      return this.$route.params.id;
    }
  },
  watch: {
    async selectedClassId() {
      await this.getData();
    }
  },
  async created() {
    this.allClassrooms = await this.getClassroomsByLpId({ learningPathId: this.courseId });
    this.isLoading = false;
  },
  methods: {
    ...mapActions("classrooms", ["getClassroomsByLpId"]),
    ...mapActions("learningPaths", ["getOndemandById"]),
    selectClass(classId) {
      this.selectedClassId = classId;
    },
    unselectClass() {
      this.selectedClassId = "";
      this.selectedClass = [];
    },
    async getData() {
      this.isLoading = true;
      try {
        const data = await this.getOndemandById({
          id: this.courseId,
        });
        this.tabs = data.sections.map((s) => s.name).slice(); 
      } finally {
        this.isLoading = false;
      }
    },
    iconSelector(item) {
      switch(item) {
      case "assessment":
        return "mdi-certificate-outline";
      case "video_lesson":
        return "mdi-movie-open-outline";
      case "prog_exercise":
        return "mdi-file-code-outline";
      default: return ""
      }
    },
    realResources(item, tab) {
      const currentSection = item.filter((i) => i.section_name === tab);
      return currentSection;
    }
  },
};
</script>

<style scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.data-table{
  background-color: transparent;
}

.tabs-title{
  font-size: 16px;
  text-transform: none;
}

.tab-selected{
  font-weight: 700;
}

.resource-label{
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
}

.resource-status{
  font-weight: 500;
}

.open-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f4f5f6;
  text-transform: none;
}
</style>
