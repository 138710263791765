<template>
  <v-main>
    <h2 class="wrapper-title px-5 px-md-0">Modera Contenuto</h2>
    <QuestionUnreport v-if="type === 'question'" />
    <ReplyUnreport v-else />
  </v-main>
</template>

<script lang="ts">
import QuestionUnreport from "../../components/admin/contentManagement/QuestionUnreport.vue";
import ReplyUnreport from "../../components/admin/contentManagement/ReplyUnreport.vue";

export default {
  name: "ContentManagement",
  components: { QuestionUnreport, ReplyUnreport },
  data() {
    return {
      paramsId: "",
      type: "",
    };
  },
  methods: {
    getPathType(path) {
      const pathParts = path.split("/");
      switch (pathParts[1]) {
        case "question": {
          this.type = "question";
          break;
        }
        case "reply": {
          this.type = "reply";
          break;
        }
        default: {
          throw new Error("Unknown type");
        }
      }
    },
  },
  async created() {
    this.getPathType(this.$route.fullPath);
  },
};
</script>

<style>
.container {
  max-width: 1185px;
}
.header-btn,
.card-title {
  font-family: "Karasuma Gothic";
  color: #ffffff;
}

.header-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  border-radius: 12px;
}

.card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 27.5px;
}

.card-text {
  font-size: 20px;
  line-height: 27.5px;
}

.card-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
}
</style>
