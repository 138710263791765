import api from "@services/axiosInstance";
import publicApi from "@services/publicAxiosInstance";
import livesApi from "@services/livesAxiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Courses/get_course
 */
function getCourses() {
  return livesApi.get("/course");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Question/get_episode_question
 */
function getEpisodeQuestions(episodeId) {
  return livesApi.get(`/episode/question?episode_id=${episodeId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Courses/get_course__course_id__subscribers_number
 */
function getSubscribedNumber(courseId) {
  return livesApi.get(`/course/${courseId}/subscribers-number`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/main/swagger/index.html#/Classroom/get_classroom_subscribed
 */
function getClassroomsSubscribed(courseId) {
  return api.get(`/classroom/subscribed?courseid=${courseId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Question/post_episode_question
 */
function createComment(episodeId, text, mainQuestionId) {
  return livesApi.post("/episode/question", {
    episode_id: episodeId,
    main_question_id: mainQuestionId,
    text,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Question/post_episode_question
 */
function createQuestion(episodeId, text) {
  return livesApi.post("/episode/question", {
    episode_id: episodeId,
    text,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Courses/delete_course
 */
function deleteCourse(courseID) {
  return livesApi.delete("/course", { params: { courseid: courseID } });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Question/delete_episode_question__question_id_
 */
function deleteQuestion(questionId) {
  return livesApi.delete(`/episode/question/${questionId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Courses/post_course
 */
function createCourse(
  title,
  authors,
  ondemand,
  resources,
  scope,
  description,
  timer_priority
) {
  return livesApi.post("/course", {
    title,
    authors,
    ondemand,
    resources,
    scope,
    description,
    timer_priority,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Courses/put_course_edit
 */
function updateCourse(
  id,
  title,
  authors,
  ondemand,
  resources,
  scope,
  description,
  timer_priority
) {
  return livesApi.put("/course/edit", {
    id,
    title,
    authors,
    ondemand,
    resources,
    scope,
    description,
    timer_priority,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Ranking/get_rank
 */
function getRanks(courseId) {
  return livesApi.get("/rank", { params: { courseid: courseId } });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Courses/post_course_thumbnail
 */
function uploadThumbnail(courseId, image) {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("course_id", courseId);
  return livesApi.post("/course/thumbnail", formData);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Courses/delete_course_thumbnail__course_id_
 */
function deleteThumbnail(courseId) {
  return livesApi.delete(`/course/thumbnail/${courseId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Courses/post_course_subscription
 */
function requestSubscription(courseId) {
  return livesApi.post(`/course/subscription?course_id=${courseId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Question/get_episode_question_unapproved
 */
function getUnapprovedQuestions(episodeId) {
  return livesApi.get(`/episode/question/unapproved?episode_id=${episodeId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/lives/swagger/index.html#/Question/post_episode_question_approve
 */
function approveQuestion(questionId, approved) {
  return livesApi.post(
    `/episode/question/approve?question_id=${questionId}&approved=${approved}`
  );
}

/**
 * API DOC: https://s4s-api.sorintdev.it/docs/public/rest/index.html#/Courses/get_next_live
 */
function getNextLiveInfo() {
  return publicApi.get(`/next-live`);
}

export default {
  getCourses,
  getClassroomsSubscribed,
  getEpisodeQuestions,
  deleteCourse,
  createCourse,
  updateCourse,
  getRanks,
  uploadThumbnail,
  deleteThumbnail,
  requestSubscription,
  createComment,
  createQuestion,
  deleteQuestion,
  getSubscribedNumber,
  getUnapprovedQuestions,
  approveQuestion,
  getNextLiveInfo,
};
