<template>
  <div>
    <v-col>
      <v-form ref="ondemandForm" v-model="isFormValid">
        <v-text-field
          v-model="onDemandQuizInfo.title"
          counter="255"
          :rules="[rules.required, rules.maxLengthTitle]"
          label="Titolo del quiz"
          required
          class="mb-5"
        />
        <v-textarea
          v-model="onDemandQuizInfo.description"
          :rules="[rules.required]"
          outlined
          label="Descrizione del quiz"
        />
        <v-combobox v-if="!changeSection" v-model="onDemandQuizInfo.sectionName" :items="sections" :rules="[rules.required]" label="Seleziona sezione" />
        <v-autocomplete v-if="changeSection" v-model="onDemandQuizInfo.sectionName" :items="sections" :rules="[rules.required]" label="Cambia sezione" />
        <v-text-field v-model.number="onDemandQuizInfo.duration" :rules="[rules.required]" label="Durata in minuti" />
      </v-form>
    </v-col>
    <v-card-actions>
      <v-row
        v-if="isEditable"
        no-gutters
        justify="start"
      >
        <v-switch
          v-model="changeSection"
          label="Cambia posizione"
        />
      </v-row>
      <v-row
        no-gutters
        justify="end"
      >
        <v-btn
          :disabled="!isFormValid"
          color="#F39200"
          class="white--text mr-2 rounded-lg"
          @click="saveQuiz(onDemandQuizInfo)"
        >
          Salva e Procedi
        </v-btn>
        <v-btn
          v-if="isEditable"
          color="red"
          class="white--text mr-2 rounded-lg"
          @click="deleteQuiz"
        >
          Elimina quiz
        </v-btn>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import sectionManager from "@mixins/sectionManager.js";
export default {
  name: "OnDemandQuizBasicInfo",
  mixins: [sectionManager],
  props: {
    updateStepper: {
      type: Function,
      required: true,
    },
    resourceId: {
      type: String,
      required: true,
    },
    requestToTrue: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      isFormValid: false,
      onDemandQuizInfo: {
        title: "",
        description: "",
        duration: 0,
        learningPathId: "",
        sectionName: "",
      },
      sections: [],
      rules: {
        required: (v) => Boolean(v) || "Elemento obbligatorio",
        maxLengthTitle: (v) => v?.length < 255 || "Lunghezza massima superata",
      },
    };
  },
  watch: {
    async resourceId() {
      if(this.resourceId !== "") {
        this.learningPathQuiz = await this.getOndemandQuizzesById({ id: this.resourceId });
        this.inputValues();
      } else {
        this.onDemandQuizInfo = {};
        this.onDemandQuizInfo.learningPathId = this.$route.params.id;
      }
    }
  },
  computed: {
    isEditable() {
      return this.resourceId !== "";
    }
  },
  methods: {
    ...mapActions("learningPaths", ["getOndemandById"]),
    ...mapActions("learningResources", ["createOndemandQuiz", "updateOndemandQuiz", "deleteOndemandResource", "getOndemandQuizzesById", "changeResourceOrder"]),
    async saveQuiz(item){
      if(this.changeSection) {
        const requiredPosition = await this.getRequiredOrder(this.onDemandQuizInfo.sectionName);
        await this.changeResourceOrder({ resourceId: this.onDemandQuizInfo.learningPathId, newPosition: requiredPosition });
      }
      if(!this.isEditable){
        await this.createOndemandQuiz({ onDemandQuizInfo: item });
        this.$router.go();
      } else{
        this.onDemandQuizInfo.learningPathId = this.learningPathQuiz.id;
        await this.updateOndemandQuiz( { onDemandQuizInfo: item });
        this.updateStepper();
        this.requestToTrue();
      }
    },
    async deleteQuiz() {
      const resourceId = this.learningPathQuiz.id;
      await this.deleteOndemandResource({ id: resourceId });
      this.requestToTrue();
    },
    inputValues() {
      this.onDemandQuizInfo.title = this.learningPathQuiz.title;
      this.onDemandQuizInfo.description = this.learningPathQuiz.description;
      this.onDemandQuizInfo.sectionName = this.learningPathQuiz.section_name;
      this.onDemandQuizInfo.duration = this.learningPathQuiz.duration;
    },
    async getData() {
      this.learningPathQuiz = await this.getOndemandQuizzesById({ id: this.resourceId });
      this.onDemandQuizInfo.learningPathId = this.resourceId;
      this.inputValues();
    }
  },
  async created() {
    if(this.resourceId) {
      await this.getData();
    } else {
      this.onDemandQuizInfo.learningPathId = this.$route.params.id;
    };
    const data = await this.getOndemandById({
      id: this.$route.params.id,
    });
    const sections = data.sections?.map(value=> value.name)
    this.sections =[ ...new Set(sections)]
  },
};
</script>

