import Question from "@classes/question";

class Episode {
  constructor(id, title, link, numepisode, description, start, end) {
    this.id = id;
    this.title = title;
    this.videoId = link;
    this.description = description;
    this.numepisode = numepisode;
    this.start = start;
    this.end = end;
    this.rank = 0;
    this.myRank = 0;
    this.questions = [];
    this.unapproved_questions = [];
  }
  addQuestions(questions) {
    for (const question of questions) {
      this.questions.push(
        new Question(
          question.id,
          question.text,
          question.time,
          question.user_id,
          question.username,
          question.comments,
          question.email
        )
      );
    }
  }

  addUnapprovedQuestions(unapproved_questions) {
    for (const question of unapproved_questions) {
      this.unapproved_questions.push(
        new Question(
          question.id,
          question.text,
          question.time,
          question.user_id,
          question.username,
          [],
          question.email
        )
      );
    }
  }
}

export default Episode;
