<template>
  <Modal
    :title="'Anteprima domanda'"
    :background="false"
    :glassify="true"
    @closeModal="() => $emit('onCloseModal')"
  >
    <template
      #content
    >
      <QuestionPreview
        v-for="(question, index) in questions"
        :key="question.id"
        :number="index"
        :question="question"
      />
    </template>
  </Modal>
</template>

<script>
import Modal from "../../../utils/Modal.vue";
import QuestionPreview from "../QuestionPreview.vue";
export default {
  name: "PreviewDialog",
  components: { Modal, QuestionPreview },
  props: {
    questions: {
      type: Array,
      default: () => []
    }
  }
}
</script>