import Vue from "vue";
import Vuex from "vuex";
import auth from "@store/modules/auth";
import bellMessages from "@store/modules/bellMessages";
import classrooms from "@store/modules/classrooms";
import courses from "@store/modules/courses";
import learningPaths from "./modules/learningPaths";
import learningResources from "./modules/learningResources";
import eventActivities from "./modules/eventActivities";
import loading from "@store/modules/loading";
import toolbar from "@store/modules/toolbar";
import admin from "@store/modules/admin";
import chat from "@store/modules/chat";
import config from "@store/modules/config";
import reports from "@store/modules/reports";
import mailingList from "@store/modules/mailingList";
import quiz from "@store/modules/quiz";
import exam from "@store/modules/exam";
import trigger from "@store/modules/trigger";
import techJourney from "./modules/techJourney";
import questions from "./modules/questions";

Vue.use(Vuex);

const actions = {
  resetState({ commit }) {
    commit("admin/resetState", {}, { root: true });
    commit("auth/resetState", {}, { root: true });
    localStorage.removeItem("userAdmin");
    commit("chat/resetState", {}, { root: true });
    commit("classrooms/resetState", {}, { root: true });
    commit("courses/resetState", {}, { root: true });
    commit("learningPaths/resetState", {}, { root: true });
    commit("toolbar/resetState", {}, { root: true });
  },
};

export default new Vuex.Store({
  modules: {
    auth,
    bellMessages,
    classrooms,
    courses,
    learningPaths,
    learningResources,
    eventActivities,
    loading,
    admin,
    toolbar,
    chat,
    config,
    reports,
    mailingList,
    quiz,
    exam,
    trigger,
    techJourney,
    questions,
  },
  actions,
});
