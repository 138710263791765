import dayjs from "dayjs";

class Session {
  constructor({ id, begin_ts, end_ts, quiz }) {
    this.id = id;
    this.begin_ts = begin_ts;
    this.end_ts = end_ts;
    this.name = quiz;
    this.start = dayjs.unix(begin_ts).format("YYYY-MM-DD");
    this.end = dayjs.unix(end_ts).subtract(1, "day").format("YYYY-MM-DD");
  }
}

export default Session;
