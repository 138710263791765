<template>
  <v-main id="container">
    <v-container dark>
      <v-row class="mb-2">
        <v-btn
          text
          color="transparent"
          @click="backButton"
        >
          <v-icon
            left
            color="#F4F5F6"
          >
            mdi-chevron-left
          </v-icon>
          <span class="back-to-courses-list"> TORNA INDIETRO</span>
        </v-btn>
      </v-row>
      <v-row
        no-gutters
        justify="space-between"
      >
        <v-col
          cols="12"
          md="8"
          class="pa-0"
        >
          <VideoResourceManagement
            v-if="resourceType === 'video_lesson'"
            :resource-id="resourceId"
            :request-to-true="requestToTrue"
            :clean-resources="cleanResources"
          />
          <ProgramExcercisesResourceManagement
            v-else-if="resourceType === 'prog_exercise'"
            :resource-id="resourceId"
            :request-to-true="requestToTrue"
            :clean-resources="cleanResources"
          />
          <OndemandQuizManagement
            v-else-if="resourceType === 'assessment'"
            :resource-id="resourceId"
            :request-to-true="requestToTrue"
          />
          <p
            v-else
            class="white--text"
          >
            Per procedere, seleziona una risorsa già esistente oppure aggiungine una nuova
          </p>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="pa-0"
        >
          <SectionsManagement
            :update-quiz-id="updateQuizId"
            :request-to-false="requestToFalse"
            :update-request="updateRequest"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import VideoResourceManagement from "../../components/admin/courses/management/VideoResourceManagement.vue";
import ProgramExcercisesResourceManagement from "../../components/admin/courses/management/ProgramExcercisesResourceManagement.vue";
import SectionsManagement from "../../components/admin/courses/management/SectionsManagement.vue";
import OndemandQuizManagement from "../../components/admin/resources/onDemandQuiz/OndemandQuizManagement.vue"
export default {
  name: "OnDemandResourceManagement",
  components: { VideoResourceManagement, ProgramExcercisesResourceManagement, SectionsManagement, OndemandQuizManagement },
  data: () => ({
    sections: [],
    quizzes: [],
    resourceId: "",
    updateRequest: false,
    resourceType: "",
  }),
  methods: {
    updateQuizId(_id, type) {
      this.resourceId = _id;
      this.resourceType = type;
    },
    requestToFalse() {
      this.updateRequest = false;
    },
    requestToTrue() {
      this.updateRequest = true;
    },
    backButton() {
      this.$router.go(-1);
    },
    cleanResources() {
      this.resourceType = "";
    }
  }
};
</script>

<style scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}
.back-to-courses-list {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}
</style>
