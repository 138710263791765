<template>
  <v-card
    v-if="!replyRemoved"
    class="rounded-lg px-2 mt-6 mx-5 mx-md-0 mr-md-16 mb-6"
    flat
    dark
    color="#1d1c4c"
  >
    <v-card-title class="card-title">
      Contenuto segnalato: {{ reportedReply.text }}
    </v-card-title>
    <v-card-text class="card-text">
      <div>
        <span>Autore: </span>
        {{ reportedReply.user_full_name }}
      </div>
      <div>
        <span>Email autore: </span>
        {{ reportedReply.user_email }}
      </div>
    </v-card-text>
    <v-card-text class="card-text">
      <div>
        <span>Segnalato da: </span>
        {{ reportedReply.reporter_full_name }}
      </div>
      <div>
        <span>Email reporter: </span>
        {{ reportedReply.reporter_email }}
      </div>
    </v-card-text>
    <div class="card-footer">
      <v-btn dark class="mt-4 px-5" color="red" @click="deleteReply(replyId)">
        <v-icon class="mr-5" left>mdi-flag</v-icon>
        ELIMINA DOMANDA
      </v-btn>

      <v-btn
        color="transparent"
        dark
        class="mt-4 px-5"
        @click="unreport(replyId)"
      >
        <v-icon class="mr-5" left>mdi-delete</v-icon>
        ANNULLA SEGNALAZIONE
      </v-btn>
    </div>
  </v-card>
  <v-card
    v-else
    class="rounded-lg px-2 mt-6 mx-5 mx-md-0 mr-md-16 mb-6"
    flat
    dark
    color="#1d1c4c"
  >
    <v-card-title class="card-title">{{ finalText }}</v-card-title>
  </v-card>
</template>
<script lang="ts">
import { mapActions } from "vuex";

export default {
  name: "QuestionUnreport",
  data() {
    return {
      replyId: "",
      reportedReply: {},
      replyRemoved: false,
      finalText: "",
    };
  },
  methods: {
    ...mapActions("questions", [
      "unreportReplyById",
      "deleteReplyById",
      "getReportedReplyDetails",
    ]),

    deleteReply(replyId) {
      this.deleteReplyById({ replyId });
      this.replyRemoved = true;
      this.finalText = "RISPOSTA ELIMINATA!";
    },
    unreport(replyId) {
      this.unreportReplyById({ replyId });
      this.replyRemoved = true;
      this.finalText = "SEGNALAZIONE ANNULLATA!";
    },
  },
  async created() {
    const id = this.$route.params.id;
    this.replyId = id;
    console.log(id);
    try {
      const response = await this.getReportedReplyDetails({
        replyId: id,
      });

      this.reportedReply = {
        id: response.id,
        text: response.text,
        user_id: response.user_id,
        user_full_name: response.user_full_name,
        user_email: response.user_email,
        reporter_id: response.reporter_id,
        reporter_full_name: response.reporter_full_name,
        reporter_email: response.reporter_email,
      };

      console.log("reported Question: ", this.reportedReply);
    } catch (err) {
      console.error("Error fetching reported question: ", err);
      this.replyRemoved = true;
      this.finalText = "NESSUNA SEGNALAZIONE PRESENTE";
    }
  },
};
</script>
