<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <h3 class="question-title d-flex justify-start">
          Domande anonime
        </h3>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <span class="trainer-role d-flex justify-md-end mt-n6 mt-md-0 mb-3 mb-md-0"> {{ topics.length }} domande anonime </span>
      </v-col>
    </v-row>
    <v-card
      class="rounded-lg comment-wrapper pt-3 px-4"
    >
      <v-card-actions>
        <v-row>
          <v-col
            cols="12"
            class="px-0 pb-0"
          >
            <textarea
              v-model="newQuestion.text"
              rows="2"
              maxlength="200"
              class="input-field"
              placeholder="Scrivi qui la tua domanda"
            />
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-end pr-0"
          >
            <v-btn
              :disabled="newQuestion.text.length === 0"
              small
              dark
              strong
              outlined
              color="#F39200 !important"
              class="white--text rounded-lg py-4"
              @click="postQuestion"
            >
              <img
                src="@assets/landingPage/send.svg"
                alt="send"
                class="mr-1 icon-button"
                :class="{'disable-icon': newQuestion.text.length === 0}"
              >
              <span class="button-text">Invia</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-row class="mt-4">
      <v-col
        v-for="topic in topics"
        :key="topic.id"
        cols="12"
      >
        <v-card
          class="rounded-lg comment-wrapper pt-3 px-4 mb-1"
        >
          <v-card-text class="pb-0">
            <v-row class="mb-2">
              <v-col
                cols="2"
                md="1"
              >
                <v-img
                  min-width="40"
                  class="rounded-circle"
                  src="@assets/users-icon/volpe.svg"
                />
              </v-col>
              <v-col cols="8">
                <h4 class="trainer-name">
                  {{ ondemand ? currentUser(topic) : topic.username }}
                </h4>
                <span class="trainer-role">{{ convertTime(topic.time) }}</span>
              </v-col>
              <v-col cols="12">
                <p class="course-paragraph">
                  {{ topic.text }}
                </p>
              </v-col>
              <v-col
                v-if="topic.comments.length > 0"
                align="center"
                cols="12"
              >
                <v-btn
                  v-if="showComments !== topic.id"
                  text
                  class="no-background-button comments-button"
                  @click="showCommentsById(topic.id)"
                >
                  <span
                    class="comment-section-actions"
                  >Visualizza {{ topic.comments.length }} commenti</span>
                </v-btn>
                <v-btn
                  v-else-if="showComments === topic.id"
                  text
                  class="no-background-button comments-button"
                  @click="showComments = ''"
                >
                  <span
                    class="comment-section-actions"
                  >Nascondi commenti</span>
                </v-btn>
              </v-col>
              <v-container class="d-flex justify-center">
                <v-col
                  v-if="topic.comments.length > 0 && showComments === topic.id"
                  cols="12"
                  md="8"
                >
                  <v-row
                    v-for="comment in topic.comments"
                    :key="comment.id"
                  >
                    <v-col cols="10">
                      <v-row>
                        <v-col
                          cols="2"
                          md="1"
                          class="mr-3"
                        >
                          <v-img
                            v-if="!isGuest"
                            min-width="40"
                            class="rounded-circle"
                            :src="ondemand
                              ? 'https://s4s-landing.sorintdev.it/' + comment.user_avatar_path
                              : comment.avatar"
                          />
                          <v-img
                            v-else
                            min-width="40"
                            class="rounded-circle"
                            src="@assets/users-icon/volpe.svg"
                          />
                        </v-col>
                        <v-col
                          cols="7"
                          md="8"
                        >
                          <h4 class="trainer-name">
                            {{
                              isGuest
                                ? 'Utente Hackersgen'
                                : ondemand
                                  ? comment.user_full_name
                                  : comment.username
                            }}
                          </h4>
                          <span class="trainer-role">{{ convertTime(comment.time) }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-if="comment.user_id === decodedToken.id"
                      cols="2"
                      class="d-flex justify-end"
                    >
                      <v-btn
                        icon
                        @click="deleteQuestion(comment.id)"
                      >
                        <v-icon color="#98a6ae">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <p class="course-paragraph">
                        {{ comment.text }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-row>
          </v-card-text>
          <v-card-actions class="pt-0 my-5">
            <v-row>
              <v-col
                cols="12"
                class="pa-0"
              >
                <input
                  v-model="topic.active"
                  class="input-field"
                  placeholder="Commenta qui"
                >
              </v-col>
              <v-col
                cols="12"
                class="pr-0 d-flex justify-end"
              >
                <v-btn
                  :disabled="!topic.active"
                  small
                  dark
                  strong
                  outlined
                  color="#F39200 !important"
                  class="white--text rounded-lg py-4"
                  @click="
                    postComment(topic.id, topic.active);
                    topic.active = ''
                  "
                >
                  <img
                    src="@assets/landingPage/send.svg"
                    alt="send"
                    class="mr-1 icon-button"
                    :class="{'disable-icon': !topic.active}"
                  >
                  <span class="button-text">Invia</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <CourseCommentModal
      v-if="showDialog"
      :comment-id="commentId"
      :get-data="getData"
      :ondemand="ondemand"
      @close-dialog="showDialog = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CourseCommentModal from "./CourseCommentModal.vue"
import userHandler from "@mixins/userHandler";
export default {
  name: "CourseCommentsSection",
  components: { CourseCommentModal },
  mixins: [userHandler],
  props: {
    ondemand: {
      required: true,
      type: Boolean,
    },
    episodeId: {
      required: false,
      type: String,
      default: null
    }
  },
  data: () => ({
    showDialog: false,
    commentId: "",
    showComments: "",
    topics: [],
    newQuestion: {
      learningPathId: "",
      text: "",
    },
    newComment: {
      mainQuestion: "",
      learningPathId: "",
      text: "",
    }
  }),
  computed: {
    ...mapGetters("courses", {
      getLiveComments: "getFilteredQuestions",
    }),
  },
  watch: {
    async episodeId() {
      await this.getData();
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    ...mapActions("learningResources", [
      "getOndemandComments",
      "postNewQuestion",
      "postNewComment"
    ]),
    ...mapActions("courses", {
      createLiveQuestion: "createQuestion",
      createLiveComment: "createComment"
    }),
    convertTime(time) {
      return new Date(time * 1000)
        .toLocaleString("it-IT")
        .slice(0, -3);
    },
    async getData() {
      const data = this.ondemand
        ? await this.getOndemandComments({ id: this.$route.params.id })
        : await this.getLiveComments( this.$route.params.id, this.episodeId)

      if(data) {
        this.topics = data;
      } else {
        this.topics = [];
      }
    },
    async postQuestion() {
      if (this.ondemand) {
        this.newQuestion.learningPathId = this.$route.params.id;
        await this.postNewQuestion({ newQuestion: this.newQuestion});
      } else {
        this.createLiveQuestion({
          episode_id: this.episodeId,
          text: this.newQuestion.text
        });
      }
      this.newQuestion.text = "";
    },
    async postComment(id, text) {
      if (this.ondemand) {
        this.newComment.learningPathId = this.$route.params.id;
        this.newComment.mainQuestion = id;
        this.newComment.text = text;
        await this.postNewComment({ newComment: this.newComment});
      } else {
        await this.createLiveComment({
          episode_id: this.episodeId,
          text,
          main_question_id: id,
        });
      }
      await this.getData();
    },
    deleteQuestion(id) {
      this.showDialog = true;
      this.commentId = id;
    },
    showCommentsById(id) {
      this.showComments = id;
    },
    currentUser(item) {
      switch(item.user_acc_level) {
      case 1:
        return "Admin";
      case 2:
        return "Professore";
      case 3:
        return "Studente";
      case 4:
        return "Ospite";
      default:
        return "Sorint"

      }
    }
  }
}
</script>


<style scoped>
textarea {
  resize: none;
}

.course-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

.course-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #98a6ae;
}

.trainer-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #f4f5f6;
}

.trainer-role {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #98a6ae;
}

.question-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.comment-wrapper {
  background-color: transparent !important;
  border: 1px #5a6972 solid;
}

.input-field {
  padding: 12px 16px;
  border: 1px solid #e8ebed;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #5a6972;
  width: 100%;
}

.comment-section-actions{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #F4F5F6;
}

.input-field::placeholder {
  color: #5a6972;
}

.button-text {
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
}

.icon-button {
  width: 18px;
  filter:invert(30%) sepia(50%) saturate(5258%) hue-rotate(7deg) brightness(102%) contrast(103%);
}

.disable-icon {
  filter:saturate(0);
  opacity: 0.4;
}

.comments-button{
  text-transform: none;
}
</style>