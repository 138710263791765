<template>
    <v-main id="container" class="d-flex flex-row align-center justify-center">
        <v-container fluid>
            <v-card class="mt-10 align-center download-card">
                <v-card-title class="justify-center title">
                    Download di <b class="px-1"> {{ fileName }} </b> in corso...
                </v-card-title>
            </v-card>
        </v-container>
    </v-main>
</template>

<script>
import { mapActions } from "vuex";


export default {
    name: "Download",

    computed: {
        fileName() {
            return this.$route.params.filename;
        }
    },
    methods: {
        ...mapActions("admin", ["downloadCv"]),

        async startDownload() {
            try {
                this.downloadCv(this.fileName);
            } catch (error) {
            }
        }
    },
    mounted() {
        this.startDownload();
    },
};
</script>

<style  scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.download-card {
  background: linear-gradient(109.46deg,
      rgba(1, 0, 46, 0.3) 1.57%,
      rgba(1, 0, 46, 0.3) 100%),
    linear-gradient(109.46deg,
      rgba(201, 201, 201, 0.3) 1.57%,
      rgba(196, 196, 196, 0.05) 100%) !important;
  backdrop-filter: blur(30px);
  border-radius: 12px;
  font-size: 20px;
  line-height: 18.5px;
  color: #f4f5f6;
  width: 50vw;
  margin: 0 auto;
}

.title {
    font-family: Karasuma Gothic;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0.75px;
    text-align: left;
    text-transform: none;
}
</style>