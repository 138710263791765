<template>
<div>
  <h2 class="resource-title mb-2">Video</h2>
  <v-card class="pa-2 rounded-lg" color="#1d1c4c" dark>
    <v-form v-model="isFormValid">
      <v-text-field
        v-model="ondemandVideo.title"
        :rules="[rules.required, rules.maxLengthTitle]"
        label="Titolo del video"
        required
      />
      <v-text-field
        v-model.number="ondemandVideo.duration"
        :rules="[rules.required]"
        label="Durata video in secondi"
        required
      />
      <v-text-field
        v-model="ondemandVideo.videoUri"
        :rules="[rules.required]"
        outlined
        label="Link del video"
      />
      <v-combobox
        v-if="!changeSection"
        v-model="ondemandVideo.sectionName"
        :items="sections"
        :rules="[rules.required]"
        label="Seleziona sezione"
      />
      <v-autocomplete
        v-if="changeSection"
        v-model="ondemandVideo.sectionName"
        :items="sections"
        :rules="[rules.required]"
        label="Cambia sezione"
      />
    </v-form>
    <v-card-actions>
      <v-row v-if="isEditable" no-gutters justify="start"><v-switch label="Cambia posizione" v-model="changeSection" /></v-row>
      <v-row no-gutters justify="end">
        <v-btn color="#F39200" class="white--text mr-2 rounded-lg" :disabled="!isFormValid" @click="saveVideo">Salva</v-btn>
        <v-btn v-if="isEditable" color="red" class="white--text mr-2 rounded-lg" @click="deleteOndemandVideo">Elimina video</v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import sectionManager from "@mixins/sectionManager.js";
export default {
  name: "VideoResourceManagement",
  mixins: [sectionManager],
  props: {
    resourceId: {
      type: String,
      required: true,
    },
    requestToTrue: {
      type: Function,
      required: true,
    },
    cleanResources: {
      type: Function,
      required: true,
    }
  },
  data: () => ({
    isFormValid: false,
    ondemandVideo: {
      duration: 0,
      learningPathId: "",
      sectionName: "",
      title: "",
      videoUri: "",
    },
    sections: [],
    rules: {
      required: (v) => Boolean(v) || "Elemento obbligatorio",
      maxLengthTitle: (v) => v?.length < 255 || "Lunghezza massima superata",
    },
  }),
  watch: {
    async resourceId(value) {
      if(value !== ""){
        await this.getData();
      }
      else {
        this.ondemandVideo = {};
      }
    }
  },
  computed: {
    isEditable() {
      return this.resourceId !== "";
    },
  },
  async created() {
    const data = await this.getOndemandById({
      id: this.$route.params.id,
    });
    const sections = data.sections?.map((value) => value.name);
    this.sections = [...new Set(sections)];
    if (!this.isEditable) {
      this.ondemandVideo.learningPathId = this.$route.params.id;
    } else {
      await this.getData();
    }
  },
  methods: {
    ...mapActions("learningPaths", ["getOndemandById"]),
    ...mapActions("learningResources", ["createOndemandVideo", "getOndemandVideo", "deleteOndemandResource", "updateOndemandVideo", "changeResourceOrder"]),
    async saveVideo() {
      if(this.changeSection) {
        const requiredPosition = await this.getRequiredOrder(this.ondemandVideo.sectionName);
        await this.changeResourceOrder({ resourceId: this.ondemandVideo.learningPathId, newPosition: requiredPosition });
      }
      if(!this.isEditable) {
        await this.createOndemandVideo({ ondemandVideo: this.ondemandVideo });
      } else{
        await this.updateOndemandVideo({ ondemandVideo: this.ondemandVideo });
      }
      this.requestToTrue();
    },
    async getData() {
      const dataOndemandVideo = await this.getOndemandVideo({ id: this.resourceId });
      this.ondemandVideo.title = dataOndemandVideo.title;
      this.ondemandVideo.sectionName = dataOndemandVideo.section_name;
      this.ondemandVideo.videoUri = dataOndemandVideo.video_uri;
      this.ondemandVideo.duration = dataOndemandVideo.duration;
      this.ondemandVideo.learningPathId = dataOndemandVideo.id;
    },
    async deleteOndemandVideo() {
      await this.deleteOndemandResource({ id: this.ondemandVideo.learningPathId });
      this.requestToTrue();
      this.cleanResources();
    }
  },
};
</script>

<style scoped>
.resource-title{
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 1px;
  color: #FFFFFF;
}
</style>
