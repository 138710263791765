<template>
  <v-card
    class="rounded-lg pa-6 mt-6 mx-5 mx-md-0 mr-md-16 list-wrapper"
    elevation="0"
    dark
  >
    <v-row dense justify-md="space-between">
      <v-col cols="12" md="auto">
        <v-card-title class="text-subtitle-1">
          Crea, modifica o elimina i test.
        </v-card-title>
      </v-col>
      <v-col cols="12" md="auto" align="center" align-md="end">
        <v-btn
          color="#F39200"
          class="white--text header-btn mx-auto mx-md-0"
          :to="{ name: 'admin-quiz-management-new' }"
        >
          <v-icon size="24" left> mdi-plus </v-icon>
          Crea TEST
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dark
          :headers="headers"
          :items="allQuizzes"
          class="quiz-table"
        >
          <template #[`item.order_num`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.order_num"
              large
              @save="saveOrderPosition(props.item.id, props.item.order_num)"
              @cancel="cancel"
            >
              <span title="Clicca per modificare l'ordine">
                {{ props.item.order_num }}
              </span>
              <template #input>
                <v-text-field
                  v-model="props.item.order_num"
                  label="Posizione"
                  single-line
                  counter
                />
              </template>
            </v-edit-dialog>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-tooltip open-delay="500" bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  :to="{
                    name: 'admin-quiz-management-edit',
                    params: { id: item.id, step: 1 },
                  }"
                  v-on="on"
                  class="ml-n2"
                >
                  <v-icon color="white"> mdi-file-edit-outline </v-icon>
                </v-btn>
              </template>
              Modifica Test "{{ item.title }}"
            </v-tooltip>

            <v-tooltip open-delay="500" bottom>
              <template #activator="{ on }">
                <div class="d-inline" v-on="on">
                  <v-btn
                    icon
                    :disabled="item.published"
                    @click="() => deleteQuizId(item.id)"
                  >
                    <v-icon
                      color="white darken-1"
                      class="white--text"
                      v-on="on"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              {{
                item.published
                  ? `Impossibile eliminare quiz pubblicati`
                  : `Elimina quiz "${item.title}"`
              }}
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { sendNotification } from "@utils/notifications";

export default {
  name: "NormalQuizSection",
  data() {
    return {
      headers: [
        {
          text: "Titolo",
          value: "title",
          width: "75%",
        },
        {
          text: "Ordine",
          value: "order_num",
        },
        {
          text: "Azioni",
          value: "actions",
          sortable: false,
        },
      ],
      allQuizzes: [],
    };
  },
  async mounted() {
    this.allQuizzes = await this.getAllQuizzes();
  },
  computed: {
    maxOrder() {
      return this.allQuizzes.length;
    },
  },
  methods: {
    ...mapActions("quiz", ["getAllQuizzes", "updateQuizdOrder", "deleteQuiz"]),

    async deleteQuizId(quizId) {
      try {
        await this.deleteQuiz(quizId);
      } catch (err) {
        console.log(err);
      } finally {
        location.reload();
      }
    },

    async saveOrderPosition(id, orderNumber) {
      const number = Number(orderNumber);
      if (number > this.maxOrder) {
        sendNotification(
          `Inserire un valore numero inferiore a ${this.maxOrder}`,
          "error"
        );
        await this.getAllQuizzes();
        return;
      }
      await this.updateQuizdOrder({ id, orderNumber: number });
      await this.getAllQuizzes();
    },

    cancel() {
      console.log("closed");
    },
  },
};
</script>

<style scoped>
.quiz-table {
  background-color: transparent;
}

.list-wrapper {
  background-color: #1d1c4c;
}

.header-btn {
  font-family: "Karasuma Gothic";
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  border-radius: 12px;
}
</style>
