<template>
  <v-app>
    <v-main class="container-admin-dashboard">
      <Toolbar />
      <div :class="{ 'grid-container': $vuetify.breakpoint.lgAndUp}">
        <div v-if="$vuetify.breakpoint.lgAndUp">
          <AdminNavigation />
        </div>
        <div>
          <router-view />
        </div>
      </div>
    </v-main>
  </v-app>   
</template>

<script>
import Toolbar from "@cmp-shared/toolbar/Toolbar";
import AdminNavigation from "@cmp/admin/AdminNavigation";

export default {
  name: "DashboardAdmin",
  components: {
    Toolbar,
    AdminNavigation,
  },
};
</script>

<style>
.container-admin-dashboard {
  background-color: #01002e;
}

.theme--light.v-tabs-items {
  background-color: transparent;
}

.grid-container{
  display: grid;
  grid-template-columns: 250px auto;
}

.wrapper-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 48.83px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
}

@media (max-width: 767.98px) {
  .wrapper-title {
    font-size: 40px;
  }
}
</style>
