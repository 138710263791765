<template>
  <v-row
    no-gutters
    class="my-5"
  >
    <v-col
      cols="12"
      md="6"
      class="pa-0"
    >
      <h4 class="section-title">
        DURATA TEST
      </h4>
      <v-slider
        v-model="currentDuration"
        color="#7FBAF4"
        track-color="#F4F5F6"
        loader-height="100"
        step="5"
        thumb-label
        :hide-details="true"
        max="300"
        min="5"
        ticks
      />
      <span class="grey--text">
        Durata del test: {{ formattedDuration }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DurationPicker",
  props: {
    initialDuration: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentDuration: 0,
    };
  },
  emits: ["onUpdateDuration"],
  computed: {
    formattedDuration() {
      if (this.currentDuration < 60) {
        return this.currentDuration;
      }
      const hours = ~~(this.currentDuration / 60);
      const minutes = this.currentDuration % 60;
      if (minutes > 0) {
        return `${hours} ${
          hours > 1 ? "ore" : "ora"
        } e ${minutes} minuti`;
      }
      return`${hours} ${
        hours > 1 ? "ore" : "ora"
      }`;
    },
  },
  watch: {
    currentDuration() {
      this.updateDuration();
    },
  },
  created() {
    if (!this.initialDuration) {
      this.currentDuration = 60;
      return;
    }

    this.currentDuration = this.initialDuration;
  },
  methods: {
    updateDuration() {
      this.$emit("onUpdateDuration", this.currentDuration);
    },
  },
};
</script>
