import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Professor/get_professor
 */
function getAllTeachers(format) {
  return api.get("professor", {
    params: {
      format,
    },
  });
}
function downloadTeachersInfo(format, only_active) {
  return api.get("professor", {
    params: {
      format,
      only_active,
    },
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/CV/get_cv_attachment__filename_
 */
function downloadCv(fileName) {
  return api.get("cv/attachment/" + fileName, {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Professor/get_professor
 */
function getAllUnverifiedTeachers() {
  return api.get(`professor/not-verified`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Professor/put_professor_verify__professorid_
 */
function verifyTeacher(id) {
  return api.put(`professor/verify/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Professor/delete_professor_verify__professorid_
 */
function removeTeacher(id) {
  return api.delete(`professor/verify/${id}`);
}
/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/User/delete_user_avatar__id_
 */
function deleteAvatar(id) {
  return api.delete(`user/avatar/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/User/delete_user__id_
 */
function deleteSingleUser(id) {
  return api.delete(`/user/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Student/get_student
 */
function getAllStudents(format) {
  if (format === "xlsx") {
    return api.get(`/student`, {
      params: {
        format,
      },
      responseType: "blob",
    });
  }
  return api.get("student", {
    params: {
      format,
    },
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/User/get_user_mailing_list
 */
function getMailingList() {
  return api.get(`/user/mailing-list`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/User/get_user_statistics
 */
function getUserStatistics() {
  return api.get("/user/statistics");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/get_classroom
 */
function getAllClassrooms() {
  return api.get(`/classroom`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Visitor/get_visitor
 */
function getAllGuests(format) {
  if (format === "xlsx") {
    return api.get(`/visitor`, {
      params: {
        format,
      },
      responseType: "blob",
    });
  }
  return api.get("visitor", {
    params: {
      format,
    },
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/User/get_user_sorint
 */
function getAllSorintians() {
  return api.get("/user/sorint");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Student/get_student_download_pending
 */
function downloadPendingStudents() {
  return api.get(`/student/download/pending`, { responseType: "blob" });
}

/**
 * https://s4s-api.sorintdev.it/docs/main/index.html#/Debug/GetLogFileByDate
 */
function downloadDebugLogs(date, pack) {
  return api.get(`/debug/logs?date=${date}&package=${pack}`, {
    responseType: "blob",
  });
}

/**
 * https://s4s-api.sorintdev.it/main/docs/index.html#/Schools/get_schools
 */
function getAllSchools() {
  return api.get(`/schools`);
}

/**
 * https://s4s-api.sorintdev.it/main/docs/index.html#/Schools/post_schools
 */
function addSchool(school) {
  return api.post("/schools", school);
}

/**
 * https://s4s-api.sorintdev.it/main/docs/index.html#/Schools/put_schools__school_code_
 */
function updateSchool(school_code, school) {
  return api.put(`/schools/${school_code}`, school);
}

export default {
  verifyTeacher,
  getAllTeachers,
  downloadTeachersInfo,
  downloadCv,
  removeTeacher,
  deleteAvatar,
  getAllStudents,
  getMailingList,
  getUserStatistics,
  getAllClassrooms,
  downloadPendingStudents,
  getAllGuests,
  getAllSorintians,
  getAllUnverifiedTeachers,
  downloadDebugLogs,
  deleteSingleUser,
  getAllSchools,
  addSchool,
  updateSchool,
};
