class Classroom {
  constructor(id, name, courses, professor, school) {
    this.id = id;
    this.name = name;
    this.courses = courses ? courses : [];
    this.students = [];
    this.professor = professor ? professor : "";
    this.school = school ? school : "";
  }
}

export default Classroom;
