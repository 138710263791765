<template>
  <v-card class="rounded-t-0" color="#01002e">
    <v-card-title>
      <v-select
        :value="selected"
        class="mr-2"
        :items="items"
        label="Utente"
        dense
        outlined
        hide-details
        @change="update"
      />
      <v-btn icon color="green" @click="update">
        <v-icon>mdi-cached</v-icon>
      </v-btn>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="px-6 mb-2"
      />
    </v-card-title>
    <v-data-table
      item-key="id"
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="users"
      :search="search"
      :show-expand="selected === 'Profs'"
      class="data-table"
      @current-items="getFiltered"
    >
      <template #[`item.name`]="{ item }">
        {{ item.name }}
        <ResetUsersPasswordDialog
          v-if="dialogPasswordUser === item.id"
          :user="item"
          @close-dialog="dialogPasswordUser = ''"
        />
      </template>
      <!-- eslint-disable -->
      <template #item.data-table-expand="{ item, expand, isExpanded }">
        <td
          v-if="item.classrooms && item.classrooms.length > 0"
          class="text-start"
        >
          <v-btn
            icon
            class="v-data-table__expand-icon"
            :class="{ 'v-data-table__expand-icon--active': isExpanded }"
            @click="expand(!isExpanded)"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </td>
      </template>
      <!-- eslint-enable -->

      <template #expanded-item="{ headers, item }">
        <td />
        <td :colspan="headers.length">
          <v-row no-gutters align="center">
            <v-col cols="4" md="11" class="pa-0 py-4">
              <v-row no-gutters class="mb-3">
                <v-col class="mr-3"> Class name: </v-col>
                <v-col
                  v-for="(classroom, classroomIndex) of item.classrooms"
                  :key="classroomIndex"
                  class="mr-1 font-weight-bold"
                >
                  {{ classroom.name }}
                </v-col>
              </v-row>
              <v-row>
                <v-col><span>Pending students:</span></v-col>
                <v-col
                  v-for="(classroom, classroomIndex) of item.classrooms"
                  :key="classroomIndex"
                >
                  {{ classroom.pendings }}
                </v-col>
              </v-row>
              <v-row>
                <v-col><span>Confirmed students:</span></v-col>
                <v-col
                  v-for="(classroom, classroomIndex) of item.classrooms"
                  :key="classroomIndex"
                >
                  {{ classroom.enrolled }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </td>
      </template>
      <template #[`item.newsletter`]="{ item }">
        <span v-if="item.newsletter"> Accettato </span>
        <span v-else> Non accettato </span>
      </template>
      <template #[`item.can_contact`]="{ item }">
        <span v-if="item.can_contact"> Accettato </span>
        <span v-else> Non accettato </span>
      </template>
      <template #[`item.impersonation`]="{ item }">
        <v-col class="d-flex justify-center pb-0">
          <v-icon medium class="ml-2" @click="() => impersonate(item)">
            mdi-account
          </v-icon>
        </v-col>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-col class="d-flex justify-center pb-0">
          <v-icon
            v-if="selected === 'Profs' || selected === 'Students'"
            small
            @click="dialogEditUser = item.id"
          >
            mdi-pencil
          </v-icon>
          <v-icon small class="ml-2" @click="sendMailTo(item)">
            mdi-send
          </v-icon>
        </v-col>
        <v-col class="d-flex justify-center pb-0">
          <v-icon
            v-if="selected !== 'Sorintians'"
            @click="() => (dialogPasswordUser = item.id)"
          >
            mdi-lock-reset
          </v-icon>
          <v-icon
            v-if="selected !== 'Sorintians'"
            @click="dialogDeleteUser = item.id"
          >
            mdi-delete
          </v-icon>
        </v-col>
        <EditUsersDialog
          v-if="dialogEditUser === item.id"
          :user="item"
          @close-dialog="dialogEditUser = ''"
        />

        <DeleteUsersDialog
          v-if="dialogDeleteUser === item.id"
          :user="item"
          @close-dialog="dialogDeleteUser = ''"
        />
      </template>
      <template #[`item.status`]="{ item }">
        <span
          v-if="
            item.school &&
            item.class_name &&
            (!item.invite_school || !item.invite_class)
          "
          >Con classe e senza invito</span
        >
        <span
          v-else-if="
            item.school &&
            item.class_name &&
            (item.invite_school || item.invite_class)
          "
          >Con classe e con invito</span
        >
        <span
          v-else-if="
            !item.school &&
            !item.class_name &&
            !item.invite_school &&
            !item.invite_class
          "
          >Senza classe e senza invito</span
        >
        <span v-else>Senza classe e con invito</span>
      </template>
      <template #[`footer.prepend`]>
        <v-btn
          v-if="selected !== 'Sorintians'"
          dark
          small
          color="primary"
          class="rounded-lg"
          @click="() => (dialodPasswordUsersType = true)"
        >
          Reset all {{ selected }}
          {{ $vuetify.breakpoint.xsOnly ? "" : "passwords" }}
        </v-btn>
      </template>
    </v-data-table>
    <ResetUsersTypePasswordDialog
      v-if="dialodPasswordUsersType"
      :type-of-users="selected"
      :list-of-users="users"
      @close-dialog="dialodPasswordUsersType = false"
    />
  </v-card>
</template>

<script>
import { handleAxiosError } from "@utils/notifications";
import services from "@services/admin";
import { attachAuthCookie } from "@utils/externalRouter";
import authServices from "@services/auth";
import { initData } from "./costants/DataTable";
import ResetUsersPasswordDialog from "./resources/ResetUsersPasswordDialog.vue";
import ResetUsersTypePasswordDialog from "./resources/ResetUsersTypePasswordDialog.vue";
import EditUsersDialog from "./resources/EditUsersDialog.vue";
import DeleteUsersDialog from "./resources/DeleteUsersDialog.vue";
import { mapGetters } from "vuex";

export default {
  name: "DataTable",
  components: {
    ResetUsersPasswordDialog,
    ResetUsersTypePasswordDialog,
    EditUsersDialog,
    DeleteUsersDialog,
  },
  props: {
    updateUser: {
      type: Function,
      default: () => console.log(""),
    },
  },
  data() {
    return initData();
  },
  computed: {
    ...mapGetters("config", ["platformUrl", "cookieDomain"]),
    checkSearch() {
      return this.search === "";
    },
  },
  async created() {
    await this.getStudents();
  },
  methods: {
    getFiltered(e) {
      this.filteredItems = e;
    },
    async update(value) {
      if (value.length < 20) {
        this.selected = value;
      }
      if (this.selected === "Profs") {
        await this.getProfs();
      } else if (this.selected === "Students") {
        await this.getStudents();
      } else if (this.selected === "Guests") {
        await this.getGuests();
      } else if (this.selected === "Sorintians") {
        await this.getSorintians();
      }
      this.updateUser(this.selected);
    },
    sendMailTo(item) {
      window.location = `mailto:${item.email}`;
    },
    async getStudents() {
      this.loading = true;
      try {
        const { data } = await services.getAllStudents("json");
        this.users = data;
        this.headers = this.hstudents;
      } catch (err) {
        handleAxiosError(`Errore recupero info`, err);
      }

      this.loading = false;
    },
    async getProfs() {
      this.loading = true;
      try {
        const { data } = await services.getAllTeachers("json");
        this.users = data;
        this.headers = this.hprofs;
      } catch (err) {
        handleAxiosError(`Errore recupero info`, err);
      }
      this.loading = false;
    },
    async getGuests() {
      this.loading = true;
      try {
        const { data } = await services.getAllGuests("json");
        this.users = data;
        this.headers = this.hguests;
      } catch (err) {
        handleAxiosError(`Errore recupero info`, err);
      }
      this.loading = false;
    },
    async getSorintians() {
      this.loading = true;
      try {
        const { data } = await services.getAllSorintians();
        this.users = data;
        this.headers = this.hsorintians;
      } catch (err) {
        handleAxiosError(`Errore recupero info`, err);
      }
      this.loading = false;
    },
    async impersonate(user) {
      try {
        const { data } = await authServices.impersonate(user.id);
        attachAuthCookie(data.access_token);
        window.open(`${this.platformUrl}/auth/sso`, "__blank");
      } catch (error) {
        handleAxiosError("Errore nell'impersonare la persona", error);
      }
    },
  },
};
</script>

<style scoped>
.data-table {
  background-color: transparent;
  font-weight: 500;
}
</style>
