<template>
  <div class="text-center">
    <Modal
      v-if="dialog"
      title="Elimina commento"
      :show-close-button="false"
      @closeModal="closeDialog"
    >
      <template #content-center>
        <v-card-text>Sei sicurə di voler cancellare definitivamento il tuo commento?</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-2"
            class="white--text mx-3 rounded-lg"
            large
            @click="deleteComment"
          >
            Elimina
          </v-btn>
          <v-btn
            color="#F39200"
            class="white--text mx-3 rounded-lg"
            large
            @click="closeDialog"
          >
            Chiudi
          </v-btn>
        </v-card-actions>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "../utils/Modal.vue";
export default {
  name: "CourseCommentModal",
  components: { Modal },
  props: {
    commentId: {
      type: String,
      required: true,
    },
    getData: {
      type: Function,
      required: true,
    },
    ondemand: {
      type: Boolean,
      required: true,
    }
  },
  data: () => ({
    dialog: true,
  }),
  methods: {
    ...mapActions("learningResources", ["deleteOndemandComment"]),
    ...mapActions("courses", { deleteLiveComment: "deleteComment" },
    ),
    closeDialog() {
      this.dialog = false;
      this.$emit("close-dialog");
    },
    async deleteComment() {
      if (this.ondemand) {
        await this.deleteOndemandComment({ id: this.commentId });
      } else {
        this.deleteLiveComment({ commentId: this.commentId });
      }
      this.$emit("close-dialog");
      await this.getData();
    }
  }
}
</script>