<template>
  <v-col class="pa-0" cols="12">
    <v-card class="pa-5 mt-3" color="#1d1c4c" dark>
      <v-card-title class="pa-0">
        <v-col cols="12">
          <v-row>
            <h2 class="font-weight-light">REPORT TECH JOURNEY</h2>
          </v-row>
        </v-col>
      </v-card-title>

      <v-row class="mt-5">
        <v-col cols="12" class="d-flex">
          <p class="mr-10 my-auto">
            Scarica il report degli studenti che hanno superato almeno un
            livello della Tech Journey
          </p>
          <v-btn
            color="#F39200"
            width="120px"
            height="40px"
            min-width="0"
            class="rounded-lg open-button"
            @click="downloadTJLevelCompletionReport"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12" class="d-flex">
          <p class="mr-10 my-auto">
            Scarica il report degli studenti iscritti alla tech journey
          </p>
          <v-btn
            color="#F39200"
            width="120px"
            height="40px"
            min-width="0"
            class="rounded-lg open-button"
            @click="downloadTJStudentsSubscriptions"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ReportTechJourney",
  methods: {
    ...mapActions("techJourney", [
      "getTJLevelCompletionReport",
      "getTJStudentsSubscriptions",
    ]),
    async downloadTJLevelCompletionReport() {
      await this.getTJLevelCompletionReport({
        fileName: `report_tjlevel_completion_${new Date()
          .toISOString()
          .substring(0, 10)}`,
      });
    },
    async downloadTJStudentsSubscriptions() {
      await this.getTJStudentsSubscriptions({
        fileName: `tj_subscriptions_${new Date()
          .toISOString()
          .substring(0, 10)}`,
      });
    },
  },
};
</script>

<style>
.data-table {
  background-color: transparent !important;
}

.open-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f4f5f6;
  text-transform: none;
}
</style>
