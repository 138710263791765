<template>
  <iframe
    id="video"
    class="rounded-lg"
    title="Live Episode"
    :src="`https://www.youtube.com/embed/${episode.videoId}`"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  />
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "VideoPlayer",
  props: {
    episode: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style>
#video {
  height: 50vh;
  width: 100%;
  border: none;
}

@media only screen and (max-width: 600px) {
  #video {
    height: 30vh;
    width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  #video {
    height: 40vh;
    width: 100%;
  }
}
</style>
