<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="elements"
      :search="search"
      :loading="loading"
      loading-text="Caricamento lista corsi... Attendere"
      class="data-table"
    >
      <template #[`item.authors`]="{ item }">
        {{ item.authors.map((author) => author.split("%?%").join(" - ")).join(", ") }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          :to="{name: 'admin-ondemandcourse-edit', params: {id: item.id}}"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template #[`item.order_num`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.order_num"
          large
          @save="savePosition(props.item.id, props.item.order_num)"
          @cancel="cancel"
        >
        <span
          title="Clicca per modificare l'ordine">
          {{ props.item.order_num }}
        </span>
          <template #input>
            <v-text-field
              v-model="props.item.order_num"
              label="Posizione"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { handleAxiosError, sendNotification } from "@utils/notifications";
import { mapActions } from "vuex";
import learningPathsService from "@services/learningPaths";
export default {
  name: "OndemandCourseTable",
  props: {
    search: {
      type: String,
      default: "",
    }
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: "Nome corso",
        value: "title",
      },
      {
        text: "Autori",
        value: "authors",
      },
      {
        text: "Punti",
        value: "points",
      },
      {
        text: "Ordine",
        value: "order_num",
      },
      {
        text: "Modifica",
        value: "actions",
        sortable: false,
      },
    ],
    elements: [],
  }),
  computed: {
    maxOrder() {
      return this.elements.length;
    }
  },
  async mounted() {
    await this.getCourses();
  },
  methods: {
    ...mapActions("learningPaths", ["updateOndemandOrder"]),
    async getCourses() {
      this.loading = true;
      try {
        const { data } = await learningPathsService.getOndemandCourses();
        this.elements = data.filter((c) => !c.archived);
      } catch (err) {
        handleAxiosError(`Errore recupero corsi ondemand`, err);
      }
      finally {
        this.loading = false;
      }
    },
    async savePosition(id, newPosition) {
      const number = Number(newPosition);
      if(number > this.maxOrder) {
        sendNotification(`Inserire un valore numero inferiore a ${this.maxOrder}`, "error");
        await this.getCourses();
        return;
      }
      await this.updateOndemandOrder({ id, newPosition: number });
      await this.getCourses();
    },
    cancel() {
      console.log("closed");
    },
  }
}
</script>

<style scoped>
.data-table{
  background-color: transparent;
  font-weight: 500;
}
</style>