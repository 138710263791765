import api from "@services/axiosInstance";

function getQuestionById(id) {
  return api.get(`/question/${id}`);
}

function deleteQuestion(questionId) {
  return api.delete(`/tech-journey/questions/warning/${questionId}`);
}

function deleteReply(replyId) {
  return api.delete(`/tech-journey/questions/reply/warning/${replyId}`);
}

function unreportQuestion(questionId) {
  return api.post(`/tech-journey/questions/unreport/${questionId}`);
}

function unreportReply(replyId) {
  return api.post(`/tech-journey/questions/reply/unreport/${replyId}`);
}

function getReportedQuestionDetail(questionId) {
  return api.get(`/tech-journey/questions/report/${questionId}`);
}
function getReportedReplyDetail(replyId) {
  return api.get(`/tech-journey/questions/reply/report/${replyId}`);
}

export default {
  getQuestionById,
  deleteQuestion,
  deleteReply,
  unreportQuestion,
  unreportReply,
  getReportedQuestionDetail,
  getReportedReplyDetail,
};
