<template>
  <v-container>
    <v-card
      class="px-2 rounded-lg"
      color="transparent"
      dark
      flat
    >
      <v-row>
        <v-col>
          <v-card color="#01002e">
            <v-card-title>
              <h4>ELENCO LIVE</h4>
            </v-card-title>

            <v-list color="#01002e">
              <v-list-item
                v-for="(item, i) in getLiveCourses"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="list-item-text"
                    v-text="item.title"
                  />
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    small
                    color="#F39200"
                    class="white--text rounded-lg font-weight-bold"
                    :to="`questions-management/details/${getLiveCourses[i].id}`"
                  >
                    Apri
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "QuestionsManagement",
  computed: {
    ...mapState("courses", ["courses"]),
    getLiveCourses() {
      return this.courses.filter((course) => {
        if (!course.onDemand) {
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style scoped>
#container {
  background-color: #1d1c4c !important;
  background-repeat: repeat;
}

.list-item-text {
  font-weight: 500;
}
</style>