class GuestQuiz {
  constructor(
    id,
    title,
    description,
    sessions,
    questions_number,
    duration,
    min_points,
    excellence_points
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.sessions = sessions;
    this.questions_number = questions_number;
    this.duration = duration;
    this.min_points = min_points;
    this.excellence_points = excellence_points
  }
}

export default GuestQuiz;
