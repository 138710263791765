<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-row 
      class="mt-6 mt-md-2 mx-n6 mx-md-0"
      no-gutters
    >
      <v-col
        cols="12"
        md="8"
        class="pa-0"
      >
        <TiptapVuetify
          v-model="currentQuestion.text"
          placeholder="Inserisci il testo della domanda..."
          :extensions="extensions"
          :toolbar-attributes="{ color: 'rgba(0, 0, 0, 0)', short: true, class: 'mt-3 ml-5 ml-md-0' }"
          :card-props="{ flat: true, color: 'rgba(0, 0, 0, 0)', rounded: '50'}"
          class="mt-3 mb-1"
          :class="{ 'question-editor': validateTipTap, 'tiptap-error': !validateTipTap }"
        />
        <span
          v-if="!validateTipTap"
          class="red--text text-caption"
        >Inserisci il testo della domanda</span>
        <v-row
          v-show="!checkCorrectAnswers || !checkNumberAnswers"
          dense
          class="pt-4 pb-0"
        >
          <v-col
            v-if="!checkNumberAnswers"
            align="center"
          >
            <h4 class="red--text text--accent-3">
              <v-icon color="red accent-3">
                mdi-alert
              </v-icon>
              Crea almeno due risposte!
            </h4>
          </v-col>
          <v-col
            v-else
            cols="12"
            align="center"
          >
            <h4 class="red--text text--accent-3">
              <v-icon color="red accent-3">
                mdi-alert
              </v-icon>
              Controlla il numero di risposte corrette!
            </h4>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="px-9 mt-3"
      >
        <v-select
          v-model="currentQuestion.points"
          :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          label="N. punti"
          :rules="[rules.required]"
        />
        <v-switch
          v-model="currentQuestion.shuffleAnswers"
          inset
          label="Ordine risposte random"
        />
        <v-radio-group v-model="currentQuestion.answerType">
          <template #label>
            <div>Risposte corrette:</div>
          </template>
          <v-radio
            label="Scelta singola"
            value="single_answer"
          />
          <v-radio
            label="Scelta multipla"
            value="multiple_answers"
          />
        </v-radio-group>
        <v-row
          v-if="!hidePreview"
          class="mt-5"
        >
          <v-btn
            color="#00A1FF"
            class="white--text rounded-btn"
            @click="() => $emit('onPreviewQuestion')"
          >
            <v-icon left>
              mdi-eye-outline
            </v-icon>
            PREVIEW DOMANDA
          </v-btn>
        </v-row>
        <v-row class="mt-5">
          <v-btn
            :disabled="
              !valid ||
                !checkCorrectAnswers ||
                !validateTipTap ||
                !checkNumberAnswers ||
                !questionModified
            "
            color="green"
            class="white--text rounded-btn"
            @click="saveQuestion"
          >
            <v-icon left>
              mdi-content-save-outline
            </v-icon>
            SALVA DOMANDA
          </v-btn>
        </v-row>
        <v-row class="mt-5">
          <v-btn
            color="red"
            class="white--text rounded-btn"
            :disabled="!currentQuestion.id"
            @click="deleteQuestion"
          >
            <v-icon left>
              mdi-trash-can-outline
            </v-icon>
            ELIMINA DOMANDA
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="start"
      align="center"
      no-gutters
      class="mt-12"
    >
      <v-card
        v-for="(
          answer, index
        ) in currentQuestion.answers"
        :key="index"
        shaped
        flat
        color="#1D1C4C"
        width="283px"
        class="answer-card mr-4 my-2"
      >
        <v-card-title>
          Risposta {{ index + 1 }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="answer.text"
            label="Testo"
            :rules="[rules.required]"
          />
          <v-row>
            <v-col cols="8">
              <v-switch
                v-model="answer.correct"
                inset
                label="corretta"
                class="pt-0"
              />
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-btn
                  class="ma-2"
                  outlined
                  fab
                  small
                  :color="hover ? 'red' : 'grey'"
                  @click="deleteAnswer(index)"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        shaped
        flat
        color="#1D1C4C"
        width="283px"
        class="answer-card my-2"
      >
        <v-card-title> Nuova risposta </v-card-title>
        <v-card-text>
          <v-text-field label="Testo" />
          <v-row>
            <v-col cols="8">
              <v-switch
                inset
                label="corretta"
                class="pt-0"
              />
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-btn
                  class="ma-2"
                  outlined
                  fab
                  small
                  :color="hover ? 'red' : 'grey'"
                  @click="deleteAnswer(index)"
                >
                  <v-icon> mdi-trash-can-outline </v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
        <v-overlay
          absolute
          color="#1D1C4C"
          opacity="0.5"
        >
          <v-btn
            class="mx-2"
            fab
            dark
            color="#F39200"
            @click="addEmptyAnswer"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-overlay>
      </v-card>
    </v-row>
  </v-form>
</template>

<script>
import isEqual from "lodash/isEqual";
import { mapActions, mapGetters } from "vuex";
import QuizAnswer from "@classes/quizAnswer";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  CodeBlock,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  name: "QuestionForm",
  components: {
    TiptapVuetify,
  },
  props: {
    currentQuestion: {
      type: Object,
      required: true,
    },
    originalQuestion: {
      type: Object,
      required: true,
    },
    quizInfo: {
      type: Object,
      required: true,
    },
    hidePreview: {
      type: Boolean,
      required: false,
    },
  },
  emits: [
    "onSetInitialVariables",
    "onChangedQuestion",
    "onResetIndex",
    "onAddEmptyAnswer",
  ],
  data() {
    return {
      openMenu: false,
      questionSaved: false,
      answerInfo: {},
      questions: [],
      valid: false,
      rules: {
        required: (v) =>
          Boolean(v) || "Elemento obbligatorio",
        select: (v) =>
          (Boolean(v) && v.length > 0) ||
          "Elemento obbligatorio",
      },
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        CodeBlock,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    };
  },
  computed: {
    ...mapGetters("quiz", ["selectedQuiz"]),

    correctAnswersNum() {
      return this.currentQuestion.answers.filter(
        (answer) => answer.correct
      ).length;
    },
    checkCorrectAnswers() {
      if (
        this.currentQuestion.answerType === "single_answer"
      ) {
        return this.correctAnswersNum === 1;
      }
      return this.correctAnswersNum > 1;
    },
    checkNumberAnswers() {
      return this.currentQuestion.answers.length >= 2;
    },
    validateTipTap() {
      return this.currentQuestion.text.length > 7;
    },
    questionModified() {
      return !isEqual(
        this.currentQuestion,
        this.originalQuestion
      );
    },
  },
  mounted() {
    this.validateForm();
  },
  methods: {
    ...mapActions("quiz", [
      "createQuizQuestion",
      "updateQuizQuestion",
      "deleteQuizQuestion",
    ]),

    async saveQuestion() {
      if (this.currentQuestion.id) {
        await this.updateQuizQuestion({
          quizId: this.quizInfo.id,
          question: this.currentQuestion,
        });
      } else {
        await this.createQuizQuestion({
          quizId: this.quizInfo.id,
          question: this.currentQuestion,
        });
      }
      this.$emit("onSetInitialVariables");
      this.resetIndex();
    },
    async addEmptyAnswer() {
      await this.$emit(
        "onAddEmptyAnswer",
        new QuizAnswer(null, "", false)
      );
      this.validateForm();
    },
    validateForm() {
      this.$refs.form.validate();
    },
    resetIndex() {
      this.$emit("onResetIndex");
    },
    changeQuestion(index) {
      this.$emit("onChangedQuestion", index);
    },
    deleteAnswer(index) {
      this.currentQuestion.answers.splice(index, 1);
    },
    async deleteQuestion() {
      await this.deleteQuizQuestion({
        questionId: this.currentQuestion.id,
      });
      this.$emit("onSetInitialVariables");
      this.resetIndex();
    },
  },
};
</script>

<style>

.tiptap-vuetify-editor__content .ProseMirror {
  height: 300px;
  overflow-y: auto;
  border: 1px solid white;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.tiptap-error {
  border: 1px solid red;
  border-radius: 10px;
}

.question-editor {
  border: 1px solid white;
  border-radius: 10px;
}

.answer-card {
  border: 1px solid white !important;
}

.tiptap-vuetify-editor__toolbar > .v-toolbar > * {
  width: 100%;
  justify-content: center;
}
</style>
