import { mapActions } from "vuex";
export default {
  data: () => ({
    changeSection: false,
  }),
  methods: {
    ...mapActions("learningPaths", ["getOndemandById"]),
    async getRequiredOrder(sectionName) {
      const response = await this.getOndemandById({ id: this.$route.params.id });
      const lastNum = response.sections[response.sections.length - 1].resources[0].order_num;
      const firstId = response.sections.find((s) => s.name === sectionName);
      const requiredNum = firstId.resources[0].order_num + 1;
      if(lastNum < requiredNum || firstId.resources.length === 1) {
        return requiredNum - 1;
      }
      return requiredNum + 1;
    }
  }
}