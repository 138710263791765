import { render, staticRenderFns } from "./QuizBasicInfo.vue?vue&type=template&id=5896ff16&scoped=true"
import script from "./QuizBasicInfo.vue?vue&type=script&lang=js"
export * from "./QuizBasicInfo.vue?vue&type=script&lang=js"
import style0 from "./QuizBasicInfo.vue?vue&type=style&index=0&id=5896ff16&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5896ff16",
  null
  
)

export default component.exports