class TeacherQuiz {
  constructor(
    id,
    title,
    description,
    subscribed_classrooms,
    questions_number,
    duration,
    min_points,
    max_points,
    excellence_points,
    sessions
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.subscribed_classrooms = subscribed_classrooms;
    this.questions_number = questions_number;
    this.duration = duration;
    this.min_points = min_points;
    this.max_points = max_points;
    this.excellence_points = excellence_points;
    this.sessions = sessions;
  }
}

export default TeacherQuiz;
