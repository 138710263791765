<template>
  <div v-if="!isLoading">
    <v-row no-gutters>
      <v-col cols="4">
        <v-btn
          :disabled="currentPosition === 1"
          icon
          class="mt-2"
          @click="previousQuestion"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="4">
        <h3 class="dialog-title mt-2">
          Domanda {{ currentPosition }}
        </h3>
      </v-col>
      <v-col
        cols="4"
        class="d-flex justify-end mt-2"
      >
        <v-btn
          v-if="currentPosition < learningPathQuiz.questions.length"
          icon
          @click="nextQuestion"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          @click="addQuestion"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-form v-model="valid">
      <v-row no-gutters>
        <v-col
          cols="8"
          class="pa-0"
        >
          <v-card
            light
            class="ml-2 mt-3 mb-1"
          >
            <TiptapVuetify
              v-model="currentQuestion.text"
              :extensions="extensions"
              :class="{ 'tiptap-error': !validateTipTap }"
            />
          </v-card>
          <span
            v-if="!validateTipTap"
            class="red--text font-weight-bold ml-2"
          >Inserisci il testo della domanda</span>
        </v-col>
        <v-col
          cols="4"
          class="px-10"
        >
          <v-switch
            v-model="currentQuestion.shuffleAnswers"
            inset
            label="Ordine risposte random"
          />
          <v-row class="mt-5">
            <v-btn
              :disabled="!checkCorrectAnswers || !checkNumberAnswers || !valid"
              color="#F39200"
              class="white--text rounded-lg"
              @click="saveAnswer"
            >
              <v-icon left>
                mdi-content-save
              </v-icon>
              SALVA DOMANDA
            </v-btn>
          </v-row>
          <v-row class="mt-5">
            <v-btn
              color="red"
              class="white--text rounded-lg"
              :disabled="isNew"
              @click="deleteQuestion"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              ELIMINA DOMANDA
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="!checkCorrectAnswers || !checkNumberAnswers"
      >
        <v-col
          v-if="!checkNumberAnswers"
          align="center"
        >
          <h4 class="red--text text--accent-3">
            <v-icon color="red accent-3">
              mdi-alert
            </v-icon>
            Crea almeno due risposte!
          </h4>
        </v-col>
        <v-col
          v-else
          cols="12"
          align="center"
        >
          <h4 class="red--text text--accent-3">
            <v-icon color="red accent-3">
              mdi-alert
            </v-icon>
            Controlla il numero di risposte corrette!
          </h4>
        </v-col>
      </v-row>
      <v-row
        justify="start"
        align="center"
        no-gutters
      >
        <v-col
          v-for="(
            answer, index
          ) in currentQuestion.answers"
          :key="index"
          cols="12"
          md="6"
          lg="4"
          class="pa-2"
        >
          <v-card
            elevation="2"
            outlined
            shaped
            light
          >
            <v-card-title>
              Risposta {{ index + 1 }}
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="answer.text"
                label="Testo"
                :rules="[rules.required]"
              />
              <v-row>
                <v-col cols="8">
                  <v-switch
                    v-model="currentQuestion.answers[index].correct"
                    inset
                    label="corretta"
                    class="pt-0"
                  />
                </v-col>
                <v-col cols="4">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      class="ma-2"
                      outlined
                      fab
                      small
                      :color="hover ? 'red' : 'grey'"
                      @click="deleteAnswer(index)"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="pa-2"
        >
          <v-card
            elevation="0"
            outlined
            shaped
            light
          >
            <v-card-title> Nuova risposta </v-card-title>
            <v-card-text>
              <v-text-field label="Testo" />
              <v-row>
                <v-col cols="8">
                  <v-switch
                    inset
                    label="corretta"
                    class="pt-0"
                  />
                </v-col>
                <v-col cols="4">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      class="ma-2"
                      outlined
                      fab
                      small
                      :color="hover ? 'red' : 'grey'"
                      @click="deleteAnswer(index)"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card-text>
            <v-overlay
              absolute
              opacity="0.25"
            >
              <v-btn
                class="mx-2"
                fab
                dark
                color="indigo"
                @click="addEmptyAnswer"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  CodeBlock,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  name: "OnDemandQuizQuestions",
  components: { TiptapVuetify },
  props: {
    resourceId: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    learningPathQuiz: "",
    isLoading: false,
    valid: false,
    lastId:"",
    currentPosition: 1,
    currentQuestion: {
      text: "",
      answers: [{correct: false, text: ""}],
      learningPathQuizId: "",
      id:"",
      shuffleAnswers: false,
    },
    rules: {
      required: (v) =>
        Boolean(v) || "Elemento obbligatorio",
    },
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      CodeBlock,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  computed: {
    isNew() {
      return this.currentPosition > this.learningPathQuiz.questions.length;
    },
    validateTipTap() {
      return this.currentQuestion.text.length > 7;
    },
    checkNumberAnswers() {
      return this.currentQuestion.answers.length >= 2;
    },
    checkCorrectAnswers() {
      for(const answer of this.currentQuestion.answers) {
        if (answer.correct) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    async resourceId() {
      this.currentPosition = 1;
      await this.getData();
    }
  },
  async mounted() {
    if(this.resourceId){
      await this.getData();
    }
  },
  methods: {
    ...mapActions("learningResources", ["getOndemandQuizzes", "createOndemandQuizQuestion", "deleteOndemandQuizQuestion", "updateOndemandQuizQuestion", "getOndemandQuizzesById"]),
    addEmptyAnswer() {
      const newAnswer = { correct: false, text: ""};
      this.currentQuestion.answers.push(newAnswer);
    },
    deleteAnswer(index) {
      this.currentQuestion.answers.splice(index, 1);
    },
    async saveAnswer() {
      if(!this.isNew) {
        this.currentQuestion.id = this.learningPathQuiz.questions[this.currentPosition - 1].id;
        await this.updateOndemandQuizQuestion({ onDemandQuizQuestion: this.currentQuestion });
      } else {
        await this.createOndemandQuizQuestion({ onDemandQuizQuestion: this.currentQuestion });
      }
      await this.getData();
    },
    nextQuestion() {
      this.currentPosition += 1;
      this.inputValues();
    },
    previousQuestion() {
      this.currentPosition -= 1;
      this.inputValues();
    },
    inputValues() {
      this.currentQuestion.text = this.learningPathQuiz.questions[this.currentPosition - 1].text;
      this.currentQuestion.shuffleAnswers = this.learningPathQuiz.questions[this.currentPosition - 1].shuffle_answers;
      this.currentQuestion.answers = this.learningPathQuiz.questions[this.currentPosition - 1].answers;
    },
    addQuestion() {
      this.currentPosition += 1;
      this.currentQuestion.text = "";
      this.currentQuestion.shuffleAnswers = false;
      this.currentQuestion.learningPathQuizId = this.learningPathQuiz.id;
      this.currentQuestion.answers = [{correct: false, text: ""}];
    },
    async deleteQuestion() {
      const questionId = this.learningPathQuiz.questions[this.currentPosition - 1].id;
      await this.deleteOndemandQuizQuestion({ id: questionId });
      this.currentPosition -= 1;
      await this.getData();
    },
    async getData() {
      this.isLoading = true;
      this.learningPathQuiz = await this.getOndemandQuizzesById({ id: this.resourceId });
      this.currentQuestion.learningPathQuizId = this.learningPathQuiz.id;
      if(this.learningPathQuiz.questions.length > 0) {
        this.inputValues();
      }
      this.isLoading = false;
    }
  }
}
</script>

<style>
.tiptap-vuetify-editor__content .ProseMirror {
  height: 200px;
}
.tiptap-error {
  border: 1px solid red;
}

.dialog-title{
  text-align: center;
}
</style>