<template>
  <div>
    <h2 class="resource-title mb-2">
      Quiz Ondemand
    </h2>
    <v-card
      class="pa-2 rounded-lg"
      color="#1d1c4c"
      dark
    >
      <v-stepper
        v-model="currentStep"
        elevation="0"
        non-linear
        class="stepper-wrapper"
      >
        <OnDemandQuizStepperHeader :is-editing="isEditable" />
        <OnDemandQuizBasicInfo
          v-if="currentStep === 1"
          :update-stepper="updateStepper"
          :resource-id="resourceId"
          :request-to-true="requestToTrue"
        />
        <OnDemandQuizQuestions
          v-if="currentStep === 2"
          :resource-id="resourceId"
        />
      </v-stepper>
    </v-card>
  </div>
</template>

<script>
import OnDemandQuizStepperHeader from "./OnDemandQuizStepperHeader.vue";
import OnDemandQuizBasicInfo from "./OnDemandQuizBasicInfo.vue";
import OnDemandQuizQuestions from "./OnDemandQuizQuestions.vue";
export default {
  name: "OndemandQuizManagement",
  components: { OnDemandQuizStepperHeader, OnDemandQuizBasicInfo, OnDemandQuizQuestions },
  props: {
    resourceId: {
      type: String,
      required: true,
    },
    requestToTrue: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      currentStep: 1,
      pathId: "",
    };
  },
  computed: {
    isEditable() {
      return this.resourceId !== "";
    }
  },
  watch: {
    resourceId() {
      if(this.resourceId === ""){
        this.currentStep = 1;
      }
    }
  },
  methods: {
    updateStepper() {
      this.currentStep = 2;
    },
  },
};
</script>

<style scoped>
.resource-title{
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 1px;
  color: #FFFFFF;
}

.stepper-wrapper{
  background: transparent !important;
}
</style>
