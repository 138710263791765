import courseHandler from "./courseHandler";
import userHandler from "./userHandler";

export default {
  mixins: [courseHandler, userHandler],
  computed: {
    featureLockedText() {
      // Studente non iscritto ad una classe
      if (this.isStudent && !this.hasSchool) {
        return "Per visualizzare la funzionalità devi essere associato ad una classe ed iscritto al corso dal tuo professore";
      }
      // Studente iscritto ad una classe ma non al corso
      if (
        this.isStudent &&
        this.hasSchool &&
        !this.course.subscribed
      ) {
        return "Per visualizzare le funzionalità di interazione devi chiedere al tuo professore di iscriverti al corso";
      }
      // Studente iscritto ad una classe ed al corso
      if (
        this.isStudent &&
        this.hasSchool &&
        this.course.subscribed
      ) {
        return "Potrai accedere alla chat dal giorno di apertura del corso";
      }
      // Ospite
      if (this.isGuest) {
        return "Per visualizzare la funzionalità devi possedere un profilo di studente";
      }
      // Fallback
      return "Sezione disponibile dal giorno della live e per gli utenti iscritti al corso.";
    },
    canViewResources() {
      // should be a prof or a student with class
      return (
        !this.isGuest &&
        (!this.isStudent || this.course.subscribed)
      );
    },
  },
};
