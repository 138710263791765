<template>
  <v-main id="container">
    <v-container>
      <h2 class="title-text pl-5">Comunicazioni</h2>
      <v-card
        id="message-mgmt-container"
        class="rounded-lg pa-5 white mt-3"
        color="#01002e !important"
        flat
      >
        <v-form ref="form" v-model="isValid">
          <v-expansion-panels class="mt-5" dark flat>
            <v-expansion-panel
              v-for="(box, boxIndex) in boxes"
              :key="boxIndex"
              class="more-offers-panel mb-5"
            >
              <v-expansion-panel-header>
                <v-row>
                  <v-col cols="12">
                    <h2 class="title-accordion-text">
                      {{ box.name }}
                    </h2>
                  </v-col>
                  <v-col cols="12">
                    <h3 class="subtitle-accordion-text">
                      {{ box.description }}
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    v-for="(message, messageIndex) in messages[box.type]"
                    :key="message.id"
                    align="center"
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <h3 class="label text-left mb-2">
                      {{ messagePosition(messageIndex) }} messaggio
                    </h3>
                    <textarea
                      v-model="message.text"
                      class="input-field white--text"
                      rows="4"
                      :maxlength="box.textMaxLength"
                      :rules="[rules.required]"
                    />
                    <v-row>
                      <v-col cols="12" class="d-flex justify-end">
                        <span class="counter mr-3"
                          >{{ message.text.length }}/{{
                            box.textMaxLength
                          }}</span
                        >
                      </v-col>
                    </v-row>
                    <h3
                      v-if="box.type === 'info'"
                      class="label mt-4 text-left mb-2"
                    >
                      Link {{ messageIndex + 1 }}
                    </h3>
                    <input
                      v-if="box.type === 'info'"
                      v-model="message.link"
                      class="input-field white--text"
                      placeholder="Inserisci il link"
                      :rules="[rules.url]"
                    />
                    <v-row class="mt-4">
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            class="ml-1"
                            dark
                            :disabled="messageIndex === 0"
                            v-bind="attrs"
                            icon
                            :color="box.color"
                            v-on="on"
                            @click="switchPosition(box.type, messageIndex - 1)"
                          >
                            <v-icon
                              v-if="$vuetify.breakpoint.mdAndUp"
                              color="#5A6972"
                            >
                              mdi-arrow-left
                            </v-icon>
                            <v-icon v-else color="#5A6972">
                              mdi-arrow-up
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Sposta a sinistra</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            dark
                            :disabled="
                              messageIndex === messages[box.type].length - 1
                            "
                            v-bind="attrs"
                            icon
                            :color="box.color"
                            v-on="on"
                            @click="switchPosition(box.type, messageIndex)"
                          >
                            <v-icon
                              v-if="$vuetify.breakpoint.mdAndUp"
                              color="#5A6972"
                            >
                              mdi-arrow-right
                            </v-icon>
                            <v-icon v-else color="#5A6972">
                              mdi-arrow-down
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Sposta a destra</span>
                      </v-tooltip>
                      <v-spacer />
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            icon
                            :color="box.color"
                            v-on="on"
                            @click="deleteMessage(box.type, messageIndex)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Cancella il messaggio</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="12" lg="10" class="d-flex justify-start">
                    <v-btn
                      dark
                      strong
                      :disabled="messages[box.type].length > 2"
                      :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                      :color="box.color"
                      class="white--text font-weight-bold btn-size rounded-lg"
                      @click="addMessage(box.type)"
                    >
                      AGGIUNGI MESSAGGIO
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="2"
                    :class="
                      $vuetify.breakpoint.smAndDown
                        ? 'd-flex justify-start'
                        : 'd-flex justify-end'
                    "
                  >
                    <v-btn
                      dark
                      strong
                      color="#F39200"
                      :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                      :disabled="!messagesModified || !isValid"
                      class="white--text font-weight-bold btn-size rounded-lg"
                      @click="saveMessages"
                    >
                      <v-icon left> mdi-arrow-top-right </v-icon>
                      pubblica
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import settingsServices from "@services/settings";
import store from "@store";
import { sendNotification, handleAxiosError } from "@utils/notifications";
import isEqual from "lodash/isEqual";

export default {
  name: "MessageManagement",
  data: () => ({
    boxes: [
      {
        name: "Messaggi standard",
        description:
          "Questi sono i messaggi che visualizzerai nella parte sopra il menu della Landing e all’interno della piattaforma.",
        type: "info",
        enabled: true,
        color: "#2196f3",
        textMaxLength: "75",
      },
      {
        name: "Messaggi di attenzione",
        description:
          "Questi sono i messaggi di attenzione che visualizzerai solo in piattaforma.",
        type: "warning",
        enabled: true,
        color: "orange",
        textMaxLength: "100",
      },
      {
        name: "Messaggi di errore",
        description:
          "Questi sono i messaggi di errore che visualizzerai solo in piattaforma.",
        type: "error",
        enabled: true,
        color: "red",
        textMaxLength: "100",
      },
    ],
    retrievedMessages: { error: [], info: [], warning: [] },
    messages: { error: [], info: [], warning: [] },
    rules: {
      required: (v) => Boolean(v) || "Valore richiesto",
      url: (v) =>
        !v ||
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
          v
        ) ||
        `URL non valido`,
    },
    isValid: false,
  }),
  computed: {
    messagesModified() {
      return !isEqual(this.retrievedMessages, this.formattedMessages);
    },
    formattedMessages() {
      return {
        error: this.messages.error.map((message) => message.text),
        info: this.messages.info.map((message) => ({
          text: message.text,
          link: message.link,
        })),
        warning: this.messages.warning.map((message) => message.text),
      };
    },
  },
  created() {
    this.retrieveMessages();
  },
  methods: {
    messagePosition(id) {
      switch (id) {
        case 0:
          return "Primo";
        case 1:
          return "Secondo";
        case 2:
          return "Terzo";
        default:
          return "";
      }
    },
    addMessage(boxType) {
      if (boxType === "info") {
        this.messages[boxType].push({
          link: null,
          text: "",
          id: Math.random(),
        });
      } else {
        this.messages[boxType].push({ text: "", id: Math.random() });
      }
    },
    async saveMessages() {
      store.commit("loading/startLoading");
      try {
        await settingsServices.setMessages(this.formattedMessages);
        this.retrieveMessages();
        sendNotification(`Messaggi salvati con successo`, "success");
      } catch (err) {
        handleAxiosError(`Errore durante il salvataggio dei messaggi`, err);
      }
      store.commit("loading/stopLoading");
    },
    deleteMessage(boxType, messageIndex) {
      this.messages[boxType].splice(messageIndex, 1);
    },
    retrieveMessages() {
      store.commit("loading/startLoading");
      settingsServices
        .getMessages()
        .then((response) => {
          this.retrievedMessages = response.data;
          this.messages.info = this.retrievedMessages.info.map((message) => ({
            ...message,
            id: Math.random(),
          }));
          this.messages.warning = this.retrievedMessages.warning.map(
            (message) => ({ id: Math.random(), text: message })
          );
          this.messages.error = this.retrievedMessages.error.map((message) => ({
            id: Math.random(),
            text: message,
          }));
          store.commit("loading/stopLoading");
        })
        .catch((err) => {
          handleAxiosError(`Errore durante il caricamento dei messaggi`, err);
          store.commit("loading/stopLoading");
        });
    },
    switchPosition(boxType, index) {
      const items = [
        this.messages[boxType][index],
        this.messages[boxType][index + 1],
      ];
      this.messages[boxType].splice(index, 2, items[1], items[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.btn-size {
  min-width: 80%;
}

.counter {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.75px;
  color: #5a6972;
}

textarea {
  resize: none;
}

.title-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 47px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
}

.box-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff;
}

.label {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #f4f5f6;
}

.more-offers-panel {
  background: #1d1c4c !important;
  backdrop-filter: blur(60px);
  border-radius: 12px !important;
  font-family: "Karasuma Gothic" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #f4f5f6 !important;
  margin-top: 0px !important;
  padding: 5px !important;
  letter-spacing: 0.75px !important;
}

.title-accordion-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.subtitle-accordion-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.input-field {
  padding: 12px 16px;
  border: 1px solid #e8ebed;
  border-radius: 12px;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #5a6972;
  width: 100%;
}

.input-field:focus {
  border: 1px solid #f39200;
}

.input-field::placeholder {
  color: #5a6972;
}
</style>
