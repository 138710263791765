<template>
  <v-col class="pa-0">
    <v-row
      v-for="(_occurrencies, key) of questionsSelectorMap"
      :key="key"
      class="mb-2"
      align="center"
      no-gutters
    >
      <span class="grey--text">
        N° domande con {{ key }} punti (max
        {{ _occurrencies }}):
      </span>
      <input
        type="number"
        class="input-number pl-3 ml-2 ml-md-0 mx-md-5"
        :value="currentSelectorMap[key] || 0"
        @input="
          ($event) => setNumber($event, key, _occurrencies)
        "
      >
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import { sendNotification } from "../../../utils/notifications";
export default {
  name: "NumberSelectors",
  props: {
    initialStructure: {
      type: Object,
      required: true,
    },
  },
  emits: ["onNumberSelected"],
  computed: {
    ...mapGetters("quiz", ["selectedQuiz"]),
    currentQuestions() {
      return this.selectedQuiz.questions;
    },
    questionsSelectorMap() {
      const possiblePoints = [
        ...new Set(
          this.currentQuestions.map(
            (question) => question.points
          )
        ),
      ];
      const pointsMap = {};
      for (let i = 0; i < possiblePoints.length; i++) {
        const point = possiblePoints[i];
        const filteredMap = this.currentQuestions.filter(
          (question) => question.points === point
        );
        pointsMap[point] = filteredMap.length;
      }
      return pointsMap;
    },
    currentSelectorMap() {
      if (!this.initialStructure.structure) {
        return {};
      }
      const currentStructure = [
        ...this.initialStructure.structure,
      ];
      return currentStructure.reduce(
        (output, value) => ({
          ...output,
          [value.points]: value.number,
        }),
        {}
      );
    },
  },
  methods: {
    setNumber(event, key, maxOccurrencies) {
      const { srcElement } = event;
      const { value } = srcElement;
      const integerValue = Number.parseInt(value, 10);
      if (isNaN(integerValue)) {
        sendNotification("Valore non valido", "error");
        return;
      }
      if (integerValue > maxOccurrencies) {
        sendNotification(
          `Valore troppo grande, il massimo è ${maxOccurrencies}`,
          "error"
        );
        return;
      }
      this.$emit("onNumberSelected", {
        key,
        value: integerValue,
      });
    },
  },
};
</script>