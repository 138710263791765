<template>
  <v-main id="container">
    <v-container>
      <v-card class="pa-5 mt-3" color="transparent" dark flat>
        <v-row>
          <v-col>
            <v-card-title align="left">
              <v-col align="left" cols="12" md="6" class="px-0">
                <span class="subtitle">RICHIESTE DI ISCRIZIONE</span>
                <v-avatar
                  v-if="unverifiedTeachers.length"
                  class="red ml-5"
                  size="30"
                >
                  <span class="white--text text-subtitle-1">{{
                    unverifiedTeachers.length
                  }}</span>
                </v-avatar>
              </v-col>
              <v-col cols="12" md="6" class="px-0">
                <v-text-field
                  v-model="searchUnverified"
                  append-icon="mdi-magnify"
                  data-cy="search-teacher"
                  label="Cerca..."
                  single-line
                  hide-details
                />
              </v-col>
            </v-card-title>
            <v-data-table
              :headers="unverifiedHeaders"
              :items="unverifiedTeachers"
              :search="searchUnverified"
              item-key="id"
              class="data-table"
            >
              <template #[`item.school_verified`]="{ item }">
                <v-icon v-if="item.school_verified">mdi-check-bold</v-icon>
                <v-icon v-else>mdi-close-thick</v-icon>
              </template>

              <template #[`item.edit_school`]="{ item }">
                <v-btn icon @click="openEditSchoolModal(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <template #[`item.action`]="{ item }">
                <v-btn
                  icon
                  color="green"
                  :data-cy="`approve-teacher`"
                  @click="openApprovalModal(item)"
                >
                  <v-icon>mdi-check-bold</v-icon>
                </v-btn>
                <v-btn icon color="red" @click="dialogNotApproval = item.id">
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>

                <NotApprovalDialog
                  v-if="dialogNotApproval === item.id"
                  :user="item"
                  @close-dialog="dialogNotApproval = ''"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>

      <Modal
        v-if="showSchoolModal"
        :title="'Modifica scuola'"
        @closeModal="closeModal"
      >
        <template #content-center>
          <v-row>
            <v-col cols="12">
              <v-card-text class="modal-text">
                <p>
                  Inserisci <strong>tutti</strong> i dati prima di proseguire:
                  <u>la scuola non sarà eliminabile.</u>
                </p>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="schoolData.name"
                label="Nome"
                hint="Il nome della scuola"
                outlined
                rounded
                tabindex="1"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" align="center">
              <v-autocomplete
                v-model="schoolData.region"
                label="Regione"
                hint="La regione dove si trova la scuola"
                :items="regions"
                outlined
                rounded
                tabindex="2"
              />
            </v-col>
            <v-col cols="4" align="center">
              <v-autocomplete
                :disabled="!schoolData.region"
                v-model="schoolData.province"
                label="Provincia"
                hint="La provincia dove si trova la scuola"
                :items="filteredProvinces"
                item-text="label"
                item-value="value"
                outlined
                rounded
                tabindex="3"
              />
            </v-col>
            <v-col cols="4" align="center">
              <v-autocomplete
                :disabled="!schoolData.region || !schoolData.province"
                v-model="schoolData.city"
                label="Città"
                hint="La città dove si trova la scuola"
                :items="filteredCities"
                outlined
                rounded
                tabindex="4"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" align="center">
              <v-text-field
                v-model="schoolData.address"
                label="Indirizzo"
                hint="L'indirizzo della scuola (via e numero civico)"
                outlined
                rounded
                tabindex="5"
              />
            </v-col>
            <v-col cols="4" align="center">
              <v-text-field
                v-model="schoolData.zip_code"
                label="CAP"
                hint="Il Codice di Avviamento Postale della scuola"
                outlined
                rounded
                tabindex="6"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">
              <v-autocomplete
                v-model="schoolData.category"
                label="Categoria"
                hint="La categoria della scuola (ateneo, scuola, altro)"
                :items="schoolCategories"
                outlined
                rounded
                tabindex="7"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">
              <v-autocomplete
                v-model="schoolData.type"
                label="Tipologia"
                hint="La tipologia della scuola"
                :items="schoolTypes"
                outlined
                rounded
                tabindex="8"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="schoolData.code"
                :disabled="true"
                label="Codice"
                hint="Il codice meccanografico della scuola"
                outlined
                rounded
                tabindex="9"
              />
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-row justify="end">
              <v-btn large plain class="white--text" @click="closeModal">
                <v-icon>mdi-close</v-icon>
                ANNULLA
              </v-btn>
              <v-btn
                color="green darken-2"
                class="white--text ml-3"
                large
                rounded
                :disabled="!isDataComplete"
                @click="updateSchoolData()"
              >
                <v-icon class="mr-1"> mdi-check </v-icon>
                <span>MODIFICA</span>
              </v-btn>
            </v-row>
          </v-card-actions>
        </template>
      </Modal>

      <Modal
        v-if="showConfirmModal"
        :title="'Attenzione!'"
        @closeModal="showConfirmModal = false"
      >
        <template #content-center>
          <v-row>
            <v-col cols="12">
              <p class="mt-3">
                {{ approvalText }}
              </p>
            </v-col>
          </v-row>

          <v-card-actions class="mt-3">
            <v-row justify="end">
              <v-btn
                large
                rounded
                color="#F39200"
                class="white--text ml-3"
                @click="openEditSchoolModal(teacherData)"
              >
                Visualizza dati scuola
              </v-btn>
              <v-btn
                color="green darken-2"
                class="white--text ml-3"
                large
                rounded
                @click="approveTeacher()"
              >
                <span>Approva</span>
              </v-btn>
            </v-row>
          </v-card-actions>
        </template>
      </Modal>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NotApprovalDialog from "./resources/NotApprovalDialog.vue";
import { handleAxiosError, sendNotification } from "@utils/notifications";
import store from "@store";
import Modal from "@cmp/utils/Modal";
import { regions } from "@data/regions.js";
import { provinces } from "@data/provinces.js";
import { cities } from "@data/cities.js";

export default {
  name: "TeachersApproval",
  components: {
    NotApprovalDialog,
    Modal,
  },
  data() {
    return {
      searchUnverified: "",
      dialogNotApproval: "",
      showSchoolModal: false,
      showConfirmModal: false,
      teacherData: {},
      approvalText: "",
      unverifiedHeaders: [
        {
          text: "Email",
          align: "start",
          filterable: true,
          value: "email",
        },
        { text: "Nome", filterable: true, value: "name" },
        { text: "Cognome", filterable: true, value: "surname" },
        { text: "Scuola", filterable: false, value: "school_name" },
        { text: "Scuola Validata", filterable: true, value: "school_verified" },
        { text: "Modifica Scuola", filterable: false, value: "edit_school" },
        { text: "Approvazione", value: "action" },
      ],
      schoolData: {
        address: "",
        city: "",
        code: "",
        name: "",
        province: "",
        region: "",
        type: "",
        zip_code: "",
        category: "",
      },
    };
  },
  computed: {
    ...mapGetters("admin", ["unverifiedTeachers", "allSchools"]),

    schoolTypes() {
      return [
        "Altro",
        "Università",
        ...new Set(
          this.allSchools.map((school) => school.type.trim().toUpperCase())
        ),
      ];
    },
    schoolCategories() {
      return ["ateneo", "scuola", "altro"];
    },
    regions() {
      return regions.map((region) => region.toUpperCase());
    },
    filteredProvinces() {
      return provinces
        .filter(
          (province) =>
            province.region.toUpperCase() ==
            this.schoolData.region.toUpperCase()
        )
        .map((province) => province.name.toUpperCase());
    },
    filteredCities() {
      return cities
        .filter(
          (city) =>
            city.province.toUpperCase() ==
            this.schoolData.province.toUpperCase()
        )
        .map((city) => city.name.toUpperCase());
    },
    isDataComplete() {
      return (
        this.schoolData.address &&
        this.schoolData.city &&
        this.schoolData.code &&
        this.schoolData.name &&
        this.schoolData.province &&
        this.schoolData.region &&
        this.schoolData.category &&
        this.schoolData.type &&
        this.schoolData.zip_code
      );
    },
  },
  async created() {
    store.commit("loading/startLoading");
    try {
      await this.getAllUnverifiedTeachers();
    } catch (error) {
      handleAxiosError("Errore nel caricamento dei dati", error);
    } finally {
      store.commit("loading/stopLoading");
    }
  },
  methods: {
    ...mapActions("admin", [
      "getAllTeachers",
      "verifyTeacher",
      "getAllUnverifiedTeachers",
      "getAllSchools",
      "updateSchool",
    ]),

    async openEditSchoolModal(data) {
      store.commit("loading/startLoading");
      this.showConfirmModal = false;
      this.teacherData = data;
      await this.filteredSchoolByCode();
      this.showSchoolModal = true;
      store.commit("loading/stopLoading");
    },
    closeModal() {
      this.showSchoolModal = false;
    },
    async filteredSchoolByCode() {
      try {
        await this.getAllSchools();
        const filteredSchools = this.allSchools.filter((school) =>
          school.code
            .toLowerCase()
            .includes(this.teacherData.school_code.toLowerCase())
        );

        if (filteredSchools.length > 0) {
          this.schoolData = filteredSchools[0];
        }
      } catch (error) {
        handleAxiosError("Errore nel recupero della scuola", error);
      }
    },
    async updateSchoolData() {
      try {
        store.commit("loading/startLoading");

        await this.updateSchool({
          address: this.schoolData.address.trim().toUpperCase(),
          city: this.schoolData.city,
          code: this.schoolData.code,
          name: this.schoolData.name.trim(),
          province: this.schoolData.province,
          region: this.schoolData.region,
          category: this.schoolData.category,
          type: this.schoolData.type,
          zip_code: this.schoolData.zip_code.trim(),
        });

        await this.getAllUnverifiedTeachers();
        this.closeModal();
        sendNotification(`Scuola modificata con successo!`, "success");
      } catch (error) {
        handleAxiosError("Errore nella modifica della scuola", error);
      } finally {
        store.commit("loading/stopLoading");
      }
    },
    openApprovalModal(data) {
      this.teacherData = data;
      this.showConfirmModal = true;
      if (this.teacherData.school_verified) {
        this.approvalText =
          "Stai approvando un professore. Approvandolo, sarà associato alla scuola già validata!";
      } else {
        this.approvalText =
          "Stai approvando un professore con una nuova scuola. Approvandolo, validerai anche la scuola!";
      }
    },
    async approveTeacher() {
      try {
        await this.verifyTeacher(this.teacherData.id);
        this.showConfirmModal = false;
      } catch (error) {
        handleAxiosError("Errore nell'approvazione del professore", error);
      } finally {
        await this.getAllUnverifiedTeachers();
      }
    },
  },
};
</script>

<style scoped>
#container {
  background-color: #1d1c4c !important;
  background-repeat: repeat;
}

a {
  text-decoration: none;
}

.subtitle {
  font-size: 15px;
}

.data-table {
  background-color: transparent;
  font-weight: 500;
}

.modal-text {
  font-size: 1.4rem;
}
</style>
