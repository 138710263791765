import Comment from "@classes/comment";

class Question {

  constructor(id, text, time, user_id, username, comments, email) {
    this.id = id;
    this.text = text;
    this.time = time;
    this.user_id = user_id;
    this.username = username;
    this.comments = [];
    this.email = email;
    this.show_comments = false;
    this.show_reply = false;
    this.inputValid = false;

    for (const comment of comments) {
      this.comments.push(
        new Comment(
          comment.id,
          comment.text,
          comment.time,
          comment.user_id,
          comment.username,
          comment.avatar
        )
      );
    }
  }

  addComment(id, text, time, user_id, username, avatar) {
    this.comments.push(new Comment(id, text, time, user_id, username, avatar));
  }
}

export default Question;
