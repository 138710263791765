<template>
  <v-container
    id="container"
    class="px-5"
  >
    <v-row justify="start">
      <v-btn
        text
        color="transparent"
        :to="{name: 'admin-courses'}"
      >
        <v-icon
          left
          color="#f4f5f6"
        >
          mdi-chevron-left
        </v-icon>
        <span class="back-button">Torna indietro</span>
      </v-btn>
    </v-row>
    <v-card
      class="py-5 mt-3"
      color="transparent"
      flat
      dark
    >
      <v-row>
        <v-card-title>
          <h2 class="font-weight-light">
            {{ id ? "MODIFICA CORSO" : "AGGIUNGI CORSO" }}
          </h2>
        </v-card-title>
      </v-row>
      <v-form v-model="formValid">
        <v-row>
          <v-col
            cols="12"
            md="10"
          >
            <v-text-field
              v-model="course.title"
              :rules="[rules.required, rules.maxLength]"
              label="Nome del corso"
            />
          </v-col>
          <v-col 
            cols="12"
            md="2"
            fluid
          >
            <v-switch
              v-model="course.timer_priority"
              :label="`Timer priority: ${course.timer_priority.toString()}`"
            />
          </v-col>
        </v-row>
        <v-row
          v-for="(author, index) in course.authors"
          :key="`author${index}`"
          align="baseline"
        >
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="course.authors[index].name"
              :rules="[rules.required, rules.maxLength]"
              :label="`Nome speaker ${index + 1}`"
            />
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="course.authors[index].role"
              :rules="[rules.required, rules.maxLength]"
              :label="`Ruolo speaker ${index + 1}`"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  color="red"
                  class="white--text mx-2 rounded-lg"
                  v-bind="attrs"
                  :disabled="course.authors.length === 1"
                  v-on="on"
                >
                  <v-icon
                    @click="removeSpeaker(index)"
                  >
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Elimina speaker {{ index + 1 }}</span>
            </v-tooltip>

            <v-tooltip
              v-if="index === 0"
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="white--text mx-2 rounded-lg"
                  fab
                  small
                  color="admin_primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon @click="addSpeaker">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>Aggiungi speaker</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="course.resource[0]"
              label="Link risorse"
            />
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="course.scope"
              :rules="[rules.required, rules.maxLength]"
              label="Scope"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-radio-group
              v-model="type"
              label="Tipologia di corso"
            >
              <v-radio
                value="ondemand"
                label="On demand"
              />
              <v-radio
                value="live"
                label="Live"
              />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="course.description"
              :rules="[rules.required, rules.maxLength]"
              label="Descrizione del corso"
              counter="255"
              outlined
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-btn
            color="admin_primary"
            class="white--text rounded-lg"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            :disabled="!formValid"
            @click="saveCourse"
          >
            SALVA INFO GENERALI CORSO
          </v-btn>
        </v-col>
      </v-row>

      <h2 class="font-weight-light my-4">
        THUMBNAIL
      </h2>
      <v-row
        v-if="!course.id"
        justify="center"
        class="mt-10"
      >
        <v-col cols="11">
          <span>Per poter modificare il thumbnail, devi prima salvare le info
            generali del corso</span>
        </v-col>
      </v-row>
      <v-row
        v-else
        align="center"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            v-if="!course.thumbnailPath"
            tile
            outlined
            class="d-flex justify-center align-center pa-12"
          >
            <span>Nessun thumbnail è stato caricato</span>
          </v-card>
          <v-img
            v-else
            :src="course.thumbnailPath"
            class="rounded-lg"
            max-height="300"
            max-width="500"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-file-input
            v-model="thumbnail"
            label="Thumbnail"
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="admin_primary"
            class="white--text rounded-lg"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            :disabled="!thumbnail"
            @click="submitThumbnail"
          >
            CARICA
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="red"
            class="white--text rounded-lg"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            :disabled="!course.thumbnailPath"
            @click="submitDeleteThumbnail"
          >
            ELIMINA
          </v-btn>
        </v-col>
      </v-row>
      <h2 class="font-weight-light my-4">
        GESTIONE EPISODI
      </h2>
      <v-row
        justify="center"
        class="mt-10"
      >
        <v-col cols="12">
          <span
            v-if="!course.id"
          >Per poter modificare o inserire degli episodi, devi prima salvare
            le info generali del corso</span>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="episode in course.episodes"
              :key="`episode${episode.numepisode}`"
              class="panel-content mb-2"
            >
              <v-expansion-panel-header>
                <h3>
                  {{ episode.title || "** New episode **" }}
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <EditEpisodePanel
                  :episode="episode"
                  :course-id="id"
                  @removeEpisode="removeEpisode(episode.num)"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row class="my-8">
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-btn
            v-if="course.id"
            color="admin_primary"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            class="white--text rounded-lg"
            @click="addEpisode"
          >
            Aggiungi episodio
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import store from "@store";
import EditEpisodePanel from "@cmp-admin/EditEpisodePanel";
import { handleAxiosError } from "@utils/notifications";

export default {
  name: "EditLiveCourse",
  components: { EditEpisodePanel },
  props: {
    id: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      course: {
        title: "",
        authors: [{ name: "", role: "" }],
        resource: [""],
        scope: "",
        description: "",
        onDemand: false,
        episodes: [],
        id: "",
        thumbnailPath: "",
        timer_priority: false,
      },
      rules: {
        required: (v) => Boolean(v) || "Elemento obbligatorio",
        maxLength: (v) => v.length <= 255 || "Lunghezza massima superata",
      },
      formValid: false,
      courseTypes: ["live", "ondemand"],
      type: "live",
      thumbnail: null,
    };
  },
  computed: {
    ...mapState("courses", ["courses"]),
  },
  mounted() {
    if (this.id) {
      const index = this.courses.findIndex((c) => c.id === this.id);
      this.course = Object.assign({}, this.courses[index]);
      this.type = this.course.onDemand ? "ondemand" : "live";
    }
  },
  methods: {
    ...mapActions("courses", [
      "createCourse",
      "updateCourse",
      "setThumbnail",
      "deleteThumbnail",
    ]),
    async submitThumbnail() {
      const path = await this.setThumbnail({
        courseId: this.id,
        image: this.thumbnail,
      });
      this.thumbnail = null;
      this.course.thumbnailPath = `${path}?dummy=${new Date().getMilliseconds()}`;
    },
    async submitDeleteThumbnail() {
      await this.deleteThumbnail({ courseId: this.id });
      this.course.thumbnailPath = "";
    },
    addSpeaker() {
      this.course.authors.push({ name: "", role: "" });
    },
    removeSpeaker(i) {
      this.course.authors.splice(i, 1);
    },
    async saveCourse() {
      this.course.onDemand = this.type === "ondemand";
      if (this.course.id) {
        await this.updateCourse({ ...this.course });
      } else {
        try {
          const id = await this.createCourse({ ...this.course });
          await this.$router.push(`/course/edit/${id}`);
          this.course.id = id;
        } catch (err) {
          handleAxiosError("Errore durante la creazione del corso", err);
        }
      }
    },
    removeEpisode(num) {
      const index = this.course.episodes.findIndex(
        (ep) => ep.numepisode === num
      );
      this.course.episodes.splice(index, 1);
    },
    addEpisode() {
      this.course.episodes.push({
        numepisode: this.course.episodes.length + 1,
        title: "",
        description: "",
        videoId: "",
      });
    },
  },
  beforeRouteEnter: async (to, from, next) => {
    if (store.state.courses.courses.length === 0) {
      try {
        store.commit("loading/startLoading");
        await store.dispatch("courses/getAllCourses");
        next();
      } catch (err) {
        next(err);
      } finally {
        store.commit("loading/stopLoading");
      }
    }
    next();
  },
};
</script>

<style scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.panel-content {
  background: #1d1c4c !important;
  backdrop-filter: blur(60px);
  border-radius: 12px !important;
  color: #f4f5f6 !important;
  margin-top: 0px !important;
  padding: 5px !important;
  letter-spacing: 0.75px !important;
}

.back-button {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}
</style>
