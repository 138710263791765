<template>
  <v-stepper-header>
    <template
      v-for="(step, index) in steps"
    >
      <v-stepper-step
        :key="index + '-step'"
        :editable="isEditing"
        :step="index + 1"
      >
        {{ step.title }}
      </v-stepper-step>
      <v-divider
        v-if="index + 1 < steps.length"
        :key="index + '-divider'"
      />
    </template>
  </v-stepper-header>
</template>

  <script>
export default {
  name: "OnDemandQuizStepperHeader",
  props: {
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      steps: [
        { title: "Informazioni base" },
        { title: "Domande e Risposte" },
      ],
    };
  },
};
  </script>
