<template>
  <v-container
    id="container"
    class="px-5"
  >
    <v-row justify="start">
      <v-btn
        text
        color="transparent"
        :to="{name: 'admin-courses'}"
      >
        <v-icon
          left
          color="#f4f5f6"
        >
          mdi-chevron-left
        </v-icon>
        <span class="back-button">Torna indietro</span>
      </v-btn>
    </v-row>
    <v-card
      dark
      flat
      class="py-5 mt-3"
      color="transparent"
    >
      <v-row>
        <v-card-title>
          <h2 class="font-weight-light">
            {{ id ? "MODIFICA CORSO" : "AGGIUNGI CORSO ONDEMAND" }}
          </h2>
        </v-card-title>
        <v-spacer />
        <v-btn
          color="#F39200"
          class="white--text rounded-lg"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          :class="$vuetify.breakpoint.smAndDown ? 'mb-2' : 'ma-5'"
          large
          :to="`/admin-ondemand-resource-management-sections/${course.id}`"
        >
          STRUTTURA CORSO
        </v-btn>
      </v-row>
      <v-form v-model="formValid">
        <v-row align="baseline">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="course.title"
              :rules="[rules.required, rules.titleMaxLength]"
              label="Nome del corso"
              counter="51"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model.number="course.points"
              :rules="[rules.required]"
              label="Punti"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model.number="course.internshipMinutes"
              :rules="[rules.required, rules.hoursValue]"
              :items="populatePctoHours"
              label="Ore PCTO"
            />
          </v-col>
        </v-row>
        <v-row
          v-for="(author, index) in course.authors"
          :key="`author${index}`"
          align="baseline"
        >
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="course.authors[index].name"
              :rules="[rules.required, rules.maxLength]"
              :label="`Nome speaker ${index + 1}`"
            />
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="course.authors[index].role"
              :rules="[rules.required, rules.maxLength]"
              :label="`Ruolo speaker ${index + 1}`"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  color="red"
                  class="white--text mx-2 rounded-lg"
                  v-bind="attrs"
                  :disabled="course.authors.length === 1"
                  v-on="on"
                >
                  <v-icon @click="removeSpeaker(index)">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Elimina speaker {{ index + 1 }}</span>
            </v-tooltip>

            <v-tooltip
              v-if="index === 0"
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="white--text mx-2 rounded-lg"
                  fab
                  small
                  color="admin_primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon @click="addSpeaker">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>Aggiungi speaker</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12">
            <v-textarea
              v-model="course.description"
              :rules="[rules.required, rules.maxLength]"
              label="Descrizione del corso"
              counter="255"
              outlined
            />
          </v-col>
        </v-row>
        <v-row v-if="isChangeable">
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="course.published"
              :disabled="dataPublished"
              :items="[true, false]"
              label="Pubblico"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="course.archived"
              :disabled="!dataPublished"
              :items="[true, false]"
              label="Archiviato"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="course.externalResourceLink"
              :rules="[rules.maxLinkLength]"
              label="Link alle risorse esterne"
              counter="125"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row justify="center">
        <v-btn
          color="admin_primary"
          class="white--text rounded-lg"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          :class="$vuetify.breakpoint.smAndDown ? 'my-2' : ''"
          :disabled="!formValid"
          @click="
            isChangeable ? updateOndemandCourse(course) : saveCourse(course)
          "
        >
          SALVA INFO GENERALI CORSO
        </v-btn>
      </v-row>

      <h2 class="font-weight-light my-4">
        THUMBNAIL
      </h2>
      <v-row
        v-if="!isChangeable"
        justify="center"
        class="mt-10"
      >
        <v-col cols="11">
          <span>Per poter modificare il thumbnail, devi prima salvare le info
            generali del corso</span>
        </v-col>
      </v-row>
      <v-row
        v-else
        align="center"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            v-if="!course.thumbnailUrl"
            tile
            outlined
            class="d-flex justify-center align-center pa-12"
          >
            <span>Nessun thumbnail è stato caricato</span>
          </v-card>
          <v-img
            v-else
            :src="course.thumbnailUrl"
            max-height="300"
            max-width="500"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-file-input
            v-model="thumbnail"
            label="Thumbnail"
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="admin_primary"
            class="white--text rounded-lg"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            :disabled="!thumbnail"
            @click="submitThumbnail"
          >
            CARICA
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="red"
            class="white--text rounded-lg"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            :disabled="!course.thumbnailUrl"
            @click="submitDeleteThumbnail"
          >
            ELIMINA
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { handleAxiosError } from "@utils/notifications";
import learningPathsService from "@services/learningPaths";

export default {
  name: "EditOndemandCourse",
  props: {
    id: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      course: {
        id: "",
        title: "",
        description: "",
        authors: [{ name: "", role: "" }],
        thumbnailUrl: "",
        points: 0,
        internshipMinutes: 0,
        externalResourceLink: "",
        orderNum: 0,
        archived: false,
        published: false,
      },
      rules: {
        required: (v) => Boolean(v) || "Elemento obbligatorio",
        titleMaxLength: (v) => v!== null && v.length <= 51 || "Lunghezza massima superata",
        maxLength: (v) => v!== null && v.length <= 255 || "Lunghezza massima superata",
        maxLinkLength: (v) => this.extractMaxLinkLength(v),
        hoursValue: (v) => Number.isInteger(v * 2) || "Sono accettati solo numeri interi o .5"
      },
      formValid: false,
      thumbnail: null,
      dataPublished: "",
      lastId: "",
    };
  },
  computed: {
    isChangeable() {
      return this.$route.params.id;
    },
    populatePctoHours() {
      return [...Array(40).fill(null).map((_, index) => index + 0.5), ...Array(40).fill(null).map((_, index) => index)].sort((a, b) => {
        if (a === "K" || b === "N") {
          return -1;
        }
        if (a === "N" || b ==="K") {
          return 1;
        }
        return Number(a) - Number(b);
      })
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.course = await this.getOndemandById({ id: this.$route.params.id });
      this.dataPublished = this.course.published;
    }
  },
  methods: {
    ...mapActions("learningPaths", [
      "setOndemandThumbnail",
      "deleteOndemandThumbnail",
      "updateOndemandCourse",
      "createOndemandCourse",
      "getOndemandById",
    ]),
    async submitThumbnail() {
      const path = await this.setOndemandThumbnail({
        id: this.id,
        image: this.thumbnail,
      });
      this.thumbnail = null;
      this.course.thumbnailUrl = `${path}?dummy=${new Date().getMilliseconds()}`;
    },
    async submitDeleteThumbnail() {
      await this.deleteOndemandThumbnail({ id: this.id });
      this.course.thumbnailUrl = "";
    },
    addSpeaker() {
      this.course.authors.push({ name: "", role: "" });
    },
    removeSpeaker(i) {
      this.course.authors.splice(i, 1);
    },
    async saveCourse(_course) {
      try {
        await this.createOndemandCourse(_course);
        await this.getLastCourse();
        await this.$router.push(`/ondemandcourse/edit/${this.lastId}`);
      } catch (err) {
        handleAxiosError("Errore durante la creazione del corso", err);
      }
    },
    async getLastCourse() {
      try {
        const { data } = await learningPathsService.getOndemandCourses();
        this.lastId = data[data.length - 1].id;
      } catch (err) {
        handleAxiosError(`Errore recupero corsi ondemand`, err);
      }
    },
    extractMaxLinkLength(v) {
      if(v === null)
      { return null }
      return v.length <= 125 || "Lunghezza massima superata"},
  },
};
</script>

<style scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.back-button{
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f4f5f6;
}
</style>
