import questionService from "@services/questions";
import { handleAxiosError, sendNotification } from "@utils/notifications";

const actions = {
  async deleteQuestionById({ commit }, { questionId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await questionService.deleteQuestion(questionId);
      await sendNotification("Domanda eliminata con successo", "success")
    } catch (err) {
      await handleAxiosError(
        `Errore durante la l'eliminazione della domanda`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async unreportQuestionById({ commit }, { questionId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await questionService.unreportQuestion(questionId);
      await sendNotification("Segnalazione eliminata con successo", "success")
    } catch (err) {
      await handleAxiosError(
        `Errore durante la cancellazione della segnalazione`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteReplyById({ commit }, { replyId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await questionService.deleteReply(replyId);
      await sendNotification("Risposta eliminata con successo", "success")
    } catch (err) {
      await handleAxiosError(
        `Errore durante la l'eliminazione della risposta`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async unreportReplyById({ commit }, { replyId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await questionService.unreportReply(replyId);
      await sendNotification("Segnalazione eliminata con successo", "success")
    } catch (err) {
      await handleAxiosError(
        `Errore durante la cancellazione della segnalazione`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getReportedQuestionDetails({ commit }, { questionId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const response = await questionService.getReportedQuestionDetail(
        questionId
      );
      if (!response) throw new Error(response);
      return response.data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel richiedere i dettagli del report",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getReportedReplyDetails({ commit }, { replyId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const response = await questionService.getReportedReplyDetail(replyId);
      if (!response) throw new Error(response);
      return response.data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel richiedere i dettagli del report",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
};

export default {
  namespaced: true,
  actions,
};
