<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="5">
        <iframe
          v-if="episode.videoId"
          id="video"
          title="Episode"
          class="rounded-lg video-frame"
          :src="`https://www.youtube.com/embed/${episode.videoId}`"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </v-col>
      <v-col cols="12" md="7">
        <v-form v-model="formValid">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="episode.title"
                label="Titolo"
                :rules="[rules.required, rules.maxLength]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="episode.videoId"
                label="Codice del video (solo la parte finale, ad esempio 4QZDa7DvP4U)"
                :rules="[rules.required, rules.maxLength]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-menu
                ref="date-menu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="pickedDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="pickedDate"
                    label="Data (YYYY-MM-DD)"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    :rules="[rules.required]"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="pickedDate"
                  no-title
                  scrollable
                  :min="today"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="dateMenu = false">
                    Annulla
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs['date-menu'].save(pickedDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu
                ref="start-menu"
                v-model="startMenu"
                :close-on-content-click="false"
                :return-value.sync="pickedStartTime"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="pickedStartTime"
                    label="Orario di inizio"
                    prepend-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    :rules="[rules.required]"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="pickedStartTime"
                  :max="pickedEndTime"
                  format="24hr"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="startMenu = false">
                    Annulla
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs['start-menu'].save(pickedStartTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu
                ref="end-menu"
                v-model="endMenu"
                :close-on-content-click="false"
                :return-value.sync="pickedEndTime"
                transition="scale-transition"
                offset-y
                format="24hr"
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="pickedEndTime"
                    label="Orario di fine"
                    prepend-icon="mdi-clock"
                    readonly
                    :rules="[rules.required]"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="pickedEndTime"
                  :min="pickedStartTime"
                  format="24hr"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="endMenu = false">
                    Annulla
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs['end-menu'].save(pickedEndTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="episode.description"
                counter="255"
                outlined
                :rules="[rules.required]"
                label="Descrizione"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-btn
        color="admin_primary"
        class="white--text rounded-lg"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
        :disabled="!formValid"
        @click="save"
      >
        Salva episodio
      </v-btn>

      <v-btn
        :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'ml-6'"
        color="red"
        outlined
        class="rounded-lg"
        @click="deleteEp"
      >
        Elimina episodio
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { handleAxiosError } from "@utils/notifications";
import dayjs from "dayjs";

export default {
  name: "EditEpisodePanel",
  props: {
    episode: {
      type: Object,
      required: true,
    },
    courseId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dateMenu: false,
      startMenu: false,
      endMenu: false,
      pickedDate: "",
      pickedStartTime: "",
      pickedEndTime: "",
      formValid: false,
      rules: {
        required: (v) => Boolean(v) || "Elemento obbligatorio",
        maxLength: (v) => v.length < 255 || "Lunghezza massima superata",
      },
    };
  },
  computed: {
    today() {
      return new dayjs().format("YYYY-MM-DD");
    },
  },
  mounted() {
    const utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);
    if (this.episode.start) {
      const dayjsDate = new dayjs.unix(this.episode.start).utc().local();
      this.pickedDate = dayjsDate.format("YYYY-MM-DD");
      this.pickedStartTime = dayjsDate.format("HH:mm");
    }
    if (this.episode.end) {
      const dayjsDate = new dayjs.unix(this.episode.end).utc().local();
      this.pickedEndTime = dayjsDate.format("HH:mm");
    }
  },
  methods: {
    ...mapActions("courses", [
      "updateEpisode",
      "deleteEpisode",
      "createEpisode",
    ]),
    async save() {
      const saved = { ...this.episode };
      const startDateDayjs = new dayjs(
        `${this.pickedDate} ${this.pickedStartTime}`,
        "YYYY-MM-DD HH:mm"
      );
      const endDateDayjs = new dayjs(
        `${this.pickedDate} ${this.pickedEndTime}`,
        "YYYY-MM-DD HH:mm"
      );
      saved.start = startDateDayjs.unix(); // because it should be in seconds instead of milliseconds
      saved.end = endDateDayjs.unix();
      saved.course = this.courseId;

      if (saved.id) {
        await this.updateEpisode(saved);
      } else {
        try {
          this.episode.id = await this.createEpisode(saved);
        } catch (err) {
          handleAxiosError(`Errore durante la creazione dell'episodio`, err);
        }
      }
      location.reload();
    },
    async deleteEp() {
      if (this.episode.id) {
        await this.deleteEpisode({
          episodeId: this.episode.id,
          courseId: this.courseId,
        });
      }
      this.$emit("removeEpisode", this.episode.numepisode);
    },
  },
};
</script>

<style scoped>
.video-frame {
  height: 40vh;
  width: 100%;
  border: none;
}
</style>
