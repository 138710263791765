<template>
  <div
    style="
      background-color: #01002e;
      width: 100vw;
      height: 100vh;
      text-align: center;
    "
  >
    <v-icon
      class="animate-spin"
      size="4em"
      style="color: white; margin-top: 40vh"
      >mdi-loading</v-icon
    >
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { pushAuthUrl } from "@/utils/externalRouter";

export default {
  async mounted() {
    const token = this.$cookies.get("hg-admin-token");
    if (token == null) {
      pushAuthUrl();
      return;
    }
    try {
      const claims = JSON.parse(Buffer.from(token.split(".")[1], "base64"));
      if (claims.access_level !== 1) {
        pushAuthUrl();
        return;
      }
      await this.setToken({ token, claims });
      this.$router.push({ name: "admin-link-management" });
    } catch (error) {
      pushAuthUrl();
    }
  },
  methods: {
    ...mapActions("auth", ["setToken"]),
  },
};
</script>

<style scoped>
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
