import { mapGetters } from "vuex";
import accessLevelToRouteType from "../data/accessLevelToRouteType.json";
export default {
  data() {
    return {
      reloadCounter: 0,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "user",
      "isTeacher",
      "isStudent",
      "isImpersonated",
      "isAdmin",
      "isGuest",
      "isSorint",
      "isAuthenticated",
      "hasSchool",
      "decodedToken",
    ]),
    userType() {
      const accessLevel = this.user?.access_level;
      if (!accessLevel) return "NOT_LOGGED_IN";
      const routeType = accessLevelToRouteType[String(accessLevel)];
      if (routeType) return routeType;
      return "NOT_LOGGED_IN";
    },
    getAvatarUrl() {
      if (this.user !== null && this.user.avatar !== "") {
        return `${this.user.avatar}`;
      }
      return require("@assets/Avatar.svg");
    },

    getAvatarRelodedUrl() {
      if (this.user !== null && this.user.avatar !== "") {
        const counter = localStorage.getItem("reloadCounter");
        if (counter) {
          this.reloadCounter = counter;
        }
        // reload_counter is a useless query param which is used to force reload (since the url changes, the browser
        // reloads the image) when the user changes profile pic
        return `${this.user.avatar}?reload_counter=${this.reloadCounter}`;
      }
      return require("@/assets/demo/Sassolino Elenco.svg");
    },
    userAgent() {
      return navigator.userAgent;
    },
    userWindowLocation() {
      return location.href;
    },
    userFullName() {
      return `${this.decodedToken.name} ${this.decodedToken.surname}`;
    },
    userEmail() {
      return this.decodedToken.email;
    },
    userSchool() {
      return this.decodedToken.school;
    },
    userTokenExpiration() {
      return this.decodedToken.exp;
    },
  },
  methods: {
    reloadAvatar() {
      this.reloadCounter++;
      localStorage.setItem("reloadCounter", this.reloadCounter);
    },
  },
};
