<template>
  <div>
    <v-row>
      <v-col>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-textarea
            v-model="quizInfo.title"
            counter="255"
            :rules="[rules.required, rules.maxLengthTitle]"
            label="Titolo del test"
            placeholder="Inserisci il titolo del test"
            required
            outlined
            no-resize
            height="28"
            class="mb-5"
          />
          <v-textarea
            v-model="quizInfo.description"
            :rules="[
              rules.required,
              rules.maxLengthDescription,
            ]"
            outlined
            no-resize
            counter="500"
            label="Descrizione del test"
            placeholder="Descrizione esaustiva del test"
          />
        </v-form>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="mt-2 mt-md-0"
      justify="center"
      justify-md="end"
    >
      <v-btn
        v-if="true"
        color="#F39200"
        class="white--text rounded-btn"
        :disabled="!valid || !quizModified"
        @click="saveQuiz"
      >
        <v-icon left>
          mdi-content-save-outline
        </v-icon>
        Salva e Procedi
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "QuizBasicInfo",
  data() {
    return {
      openMenu: false,
      valid: false,
      quizInfo: {},
      rules: {
        required: (v) =>
          Boolean(v) || "Elemento obbligatorio",
        maxLengthTitle: (v) =>
          v.length < 255 || "Lunghezza massima superata",
        maxLengthDescription: (v) =>
          v.length < 500 || "Lunghezza massima superata",
      },
    };
  },
  computed: {
    ...mapGetters("quiz", ["selectedQuiz"]),

    quizModified() {
      return !isEqual(this.quizInfo, this.originalQuizInfo);
    },
  },
  created() {
    this.quizInfo = { ...this.selectedQuiz };
    this.originalQuizInfo = cloneDeep(this.quizInfo);
  },
  methods: {
    ...mapActions("quiz", ["createQuiz", "updateQuiz"]),

    async saveQuiz() {
      let { id } = this.quizInfo;
      this.$logger(id, this.quizInfo);
      try {
        if (id) {
          this.$logger("updating");
          await this.updateQuiz(this.quizInfo);
        } else {
          this.$logger("creating");
          id = await this.createQuiz(this.quizInfo);
        }
        // refactor che funzioni anche senza il successivo await
        await this.$router.push({
          name: "admin-quiz-management-edit",
          params: { id, step: "2" },
        });
        this.$emit("onNextStep", this.quizInfo.title);
      } catch (err) {
        this.$logger(err);
      }
    },
  },
};
</script>

<style scoped>
.rounded-btn {
  font-family: "Karasuma Gothic";
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  border-radius:12px;
}
</style>
