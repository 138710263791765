import techJourney from "../../services/techJourney";

import { handleAxiosError, sendNotification } from "@utils/notifications";
const actions = {
  async getUnapprovedTechJourneyQuestions({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await techJourney.getUnapprovedTechJourneyQuestions();
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero delle domande della tech Journey",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async deleteTechJourneyQuestion({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await techJourney.deleteUnapprovedTechJourneyQuestion(id);
      sendNotification("Domanda cancellata con successo!", "success");
    } catch (err) {
      await handleAxiosError("Errore nella cancellazione della domanda", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async approveTechJourneyQuestion({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await techJourney.approveUnapprovedTechJourneyQuestion(id);
      sendNotification("Domanda approvata con successo!", "success");
    } catch (err) {
      await handleAxiosError("Errore nell'approvazione della domanda", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async getUnapprovedTechJourneyReplies({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await techJourney.getUnapprovedTechJourneyReplies();
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero delle risposte alle domande anonime della tech Journey",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async deleteTechJourneyReply({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await techJourney.deleteUnapprovedTechJourneyReply(id);
      sendNotification("Risposta cancellata con successo!", "success");
    } catch (err) {
      await handleAxiosError("Errore nella cancellazione della risposta", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async approveTechJourneyReply({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await techJourney.approveUnapprovedTechJourneyReply(id);
      sendNotification("Risposta approvata con successo!", "success");
    } catch (err) {
      await handleAxiosError("Errore nell'approvazione della domanda", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async getTJLevelCompletionReport({ commit }, { fileName }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report = await techJourney.getTJLevelCompletionReport();
      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${fileName}.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento del report`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async getTJStudentsSubscriptions({ commit }, { fileName }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const subscriptions = await techJourney.getTJStudentsSubscriptions();
      const blob = new Blob([subscriptions.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `${fileName}.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento delle iscrizioni`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
};

export default {
  namespaced: true,
  actions,
};
