<template>
  <v-main id="container">
    <v-container>
      <h2 class="wrapper-title pl-5">
        Landing Page
      </h2>
      <v-card
        id="link-mgmt-container"
        class="rounded-lg pa-5 mt-3"
        color="transparent"
        flat
      >
        <v-expansion-panels
          class="mt-5"
          dark
          flat
        >
          <v-expansion-panel
            v-for="(item, index) in items"
            :key="index"
            class="more-offers-panel mb-5"
          >
            <v-expansion-panel-header>
              <v-row>
                <v-col cols="12">
                  <h2 class="title-text">
                    {{ item.name }}
                  </h2>
                </v-col>
                <v-col cols="12">
                  <h3 class="subtitle-text">
                    {{ item.description }}
                  </h3>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <MoreOffers v-if="item.name === 'Altre offerte'" />
              <NewsManagement v-if="item.name === 'Contest, Campus & News'" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import MoreOffers from "../../components/admin/MoreOffers.vue";
import NewsManagement from "../../components/admin/NewsManagement.vue";

export default {
  name: "LinkManagement",
  components: { MoreOffers, NewsManagement },
  data: () => ({
    items: [
      {
        name: "Altre offerte",
        description: `I link che inserirai appariranno nel dropwdown del menu sotto la voce
                    "Altre offerte".`,
      },
      {
        name: "Contest, Campus & News",
        description: `I link che inserirai saranno pubblicati legati al pulsante
                    “School Contest & Campus” all’interno della sezione degli studenti.`,
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.btn-size {
  min-width: 80%;
}

.title-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.subtitle-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.more-offers-panel {
  background: #1d1c4c !important;
  backdrop-filter: blur(60px);
  border-radius: 12px !important;
  font-family: "Karasuma Gothic" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #f4f5f6 !important;
  margin-top: 0px !important;
  padding: 5px !important;
  letter-spacing: 0.75px !important;
}
</style>
