import userHandler from "./userHandler";

export default {
  mixins: [userHandler],
  methods: {
    async trackEvent(category, action, value = 0) {
      const Piwik = (window.Piwik = window.Piwik || []);
      
      if (typeof Piwik.getAsyncTracker === "function") {
        const tracker = Piwik.getAsyncTracker();
        await tracker.trackEvent(category, action, this.userType, value);
      }
    },
  },
};
