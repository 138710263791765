import api from "@services/axiosInstance";
import livesApi from "@services/livesAxiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/get_learning_paths
 */
function getOndemandCourses() {
  return api.get("/learning-paths");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/get_learning_paths__id_
 */
function getOndemandById(id) {
  return api.get(`/learning-paths/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/get_learning_paths_classroom__id_
 */
function updateLearningPathVideo(resourceId) {
  return api.get(`/learning-paths/video-lessons/${resourceId}/status`);
}
/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/post_learning_paths_thumbnail
 */
function uploadOndemandThumbnail(id, image) {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("path_id", id);
  return api.post("/learning-paths/thumbnail", formData);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/delete_learning_paths_thumbnail
 */
function deleteOndemandThumbnail(id) {
  return api.delete(`/learning-paths/thumbnail`, {
    params: { path_id: id },
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/put_learning_paths
 */
function updateOndemandCourse(
  archived,
  authors,
  description,
  id,
  internshipMinutes,
  externalResourceLink,
  points,
  published,
  title
) {
  const objectToSend = {
    archived,
    authors,
    description,
    id,
    internship_minutes: internshipMinutes,
    external_resource_link: externalResourceLink,
    points,
    published,
    title,
  };
  if (!externalResourceLink) {
    delete objectToSend.external_resource_link;
  }
  return api.put("/learning-paths", objectToSend);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/put_learning_paths_order
 */
function updateOndemandOrder(id, newPosition) {
  return api.put("/learning-paths/order", {
    learning_path_id: id,
    new_position: newPosition,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/post_learning_paths
 */
function createOndemandCourse(
  authors,
  description,
  internshipMinutes,
  externalResourceLink,
  points,
  title
) {
  return api.post("/learning-paths", {
    authors,
    description,
    internship_minutes: internshipMinutes,
    external_resource_link: externalResourceLink || null,
    points,
    title,
  });
}

function getOndemandReportByClassId({ classId, learningPathId }) {
  return livesApi.get(
    `/report/resources-report?class_id=${classId}&learning_path_id=${learningPathId}`
  );
}
export default {
  getOndemandCourses,
  getOndemandById,
  uploadOndemandThumbnail,
  deleteOndemandThumbnail,
  updateOndemandCourse,
  updateOndemandOrder,
  createOndemandCourse,
  updateLearningPathVideo,
  getOndemandReportByClassId,
};
