import livesApi from "@services/livesAxiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Episodes/post_episode
 */
function createEpisode(link, title, start, end, description, course) {
  return livesApi.post("/episode", {
    link,
    title,
    start,
    end,
    description,
    course,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Episodes/put_episode_edit
 */
function updateEpisode(id, link, title, start, end, description, course) {
  return livesApi.put("/episode/edit", {
    id,
    link,
    title,
    start,
    end,
    description,
    course,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Episodes/delete_episode
 */
function deleteEpisode(episodeId) {
  return livesApi.delete("/episode", { params: { episodeid: episodeId } });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Ranking/post_rank
 */
function createRank(episodeId, vote) {
  return livesApi.post("/rank", {
    Episodeid: episodeId,
    Vote: vote,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Ranking/get_rank_export
 */
function getRanksExport(episodeId) {
  return livesApi.get(`/rank/export?episodeid=${episodeId}`, {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Ranking/get_rank_my
 */
function getMyVote(episodeId) {
  return livesApi.get("/rank/my", {
    params: {
      episodeid: episodeId,
    },
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Question/post_episode_question_subscribe
 */
function subscribeEpisode(episodeId) {
  return livesApi.post(`/episode/question/subscribe?episode_id=${episodeId}`);
}

export default {
  updateEpisode,
  deleteEpisode,
  createEpisode,
  createRank,
  getMyVote,
  getRanksExport,
  subscribeEpisode,
};
