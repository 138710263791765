<template>
  <div>
    <v-data-table
      :headers="moduleHeaders"
      :items="modules"
      :search="search"
      :loading="loadingModule"
      sort-by="order-num"
      loading-text="Caricamento lista moduli... Attendere"
      class="data-table"
    >
      <template #[`item.body`]="moduleProps">
        <v-expansion-panels flat>
          <v-expansion-panel
            class="expandible"
            @change="expandedModule(moduleProps.item.title)"
          >
            <v-expansion-panel-header>
              <v-chip small color="#f39200" class="order-chip mr-2">{{
                moduleProps.item.order_num
              }}</v-chip>
              {{ moduleProps.item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="entityHeaders"
                :items="moduleProps.item.entities"
                :loading="loadingModule"
                sort-by="order-num"
                loading-text="Caricamento lista entità... Attendere"
                class="data-table"
              >
                <template #[`item.body`]="entityProps">
                  <v-expansion-panels flat>
                    <v-expansion-panel
                      class="expandible"
                      @change="expandedEntity(entityProps.item.id)"
                    >
                      <v-expansion-panel-header>
                        {{ entityProps.item.title }}
                        <v-chip
                          small
                          :color="getModuleColor(entityProps.item.type)"
                          class="type-chip ml-2"
                          >{{ entityProps.item.type }}
                        </v-chip>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="resourceHeaders"
                          :items="activeResources"
                          :loading="loadingResources"
                          sort-by="orderNum"
                          loading-text="Caricamento lista risorse... Attendere"
                          class="data-table"
                        >
                          <template #[`item.type`]="{ item }">
                            <v-icon>{{ getResourceIcon(item.type) }}</v-icon>
                          </template>
                          <template #[`item.rank`]="{ item }">
                            <div v-if="item.rating.count">
                              <b>{{ item.rating?.rank.toFixed(2) }}</b> su
                              <b>{{ item.rating?.count }}</b> voti
                            </div>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { handleAxiosError } from "@utils/notifications";
import techJourneyService from "@services/techJourney";
export default {
  name: "OndemandCourseTable",
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loadingModule: false,
    loadingResources: false,
    moduleHeaders: [
      {
        text: "Moduli",
        value: "body",
        sortable: false,
      },
    ],
    entityHeaders: [
      {
        text: "Entità",
        value: "body",
        sortable: false,
      },
    ],
    resourceHeaders: [
      {
        text: "Sezione",
        value: "section",
      },
      {
        text: "Tipo",
        value: "type",
      },
      {
        text: "Titolo",
        value: "title",
      },
      {
        text: "Valutazione",
        value: "rank",
      },
    ],
    modules: [],
    activeModule: "",
    activeEntity: null,
    activeResources: [],
  }),
  computed: {
    maxOrder() {
      return this.modules.length;
    },
  },
  async mounted() {
    await this.getTechJourney();
  },
  methods: {
    async getTechJourney() {
      this.loadingModule = true;
      try {
        const { data } = await techJourneyService.getTechJourney();
        this.modules = data;
      } catch (err) {
        handleAxiosError(`Errore recupero moduli`, err);
      } finally {
        this.loadingModule = false;
      }
    },
    async getEntity(id) {
      this.loadingModule = true;
      try {
        const { data } = await techJourneyService.getEntity(id);
        this.activeEntity = data;
      } catch (err) {
        handleAxiosError(`Errore recupero risorse`, err);
      } finally {
        this.loadingModule = false;
      }
    },
    async getRating(id) {
      let rating = {
        count: 0,
        rank: 0,
      };

      try {
        const { data } = await techJourneyService.getRanking(id);
        rating = data;
      } catch (err) {
        handleAxiosError(`Errore recupero valutazione video`, err);
      } finally {
        this.loadingModule = false;
      }

      return rating;
    },
    expandedModule(title) {
      if (this.activeModule !== title) {
        this.activeModule = title;
      } else {
        this.activeModule = "";
      }
    },
    async expandedEntity(id) {
      this.loadingResources = true;
      if (this.activeEntity?.id !== id) {
        this.activeEntity = { id };
        await this.getEntity(id);
        this.activeResources = await this.groupResources(
          this.activeEntity?.sections
        );
      } else {
        this.activeModule = null;
      }
      this.loadingResources = false;
    },
    async groupResources(sections) {
      let groupedResources = [];

      if (sections) {
        sections.forEach((section) => {
          groupedResources = groupedResources.concat(
            section.resources.map((resource) => {
              let newResource = resource;
              newResource.section = section.title;
              newResource.orderNum = section.orderNum;
              newResource.rating = {
                count: 0,
                rank: 0,
              };

              return newResource;
            })
          );
        });
      }

      await Promise.all(
        groupedResources.map(async (resource) => {
          let newResource = resource;

          if (resource.type === "video_lesson") {
            newResource.rating = await this.getRating(resource.id);
          }

          return newResource;
        })
      );

      return groupedResources;
    },
    getModuleColor(type) {
      if (type === "module") {
        return "orange";
      } else if (type === "pit_stop") {
        return "blue";
      } else if (type === "focus") {
        return "green";
      } else if (type === "final_test") {
        return "red";
      }
    },
    getResourceIcon(type) {
      if (type === "video_lesson") {
        return "mdi-video";
      } else if (type === "quiz") {
        return "mdi-help-box";
      } else if (type === "exercise") {
        return "mdi-pencil";
      }
    },
  },
};
</script>

<style scoped>
.data-table {
  background-color: transparent;
  font-weight: 500;
}

.expandible {
  background-color: transparent !important;
  font-weight: 500;
}

.order-chip,
.type-chip {
  max-width: fit-content;
}

.type-chip {
  text-transform: uppercase;
}
</style>
