<template>
  <Modal
    v-if="dialog"
    title="Reset delle password"
    :show-close-button="false"
    @closeModal="closeDialog"
  >
    <template #content-center>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <p>
              Stai per resettare la password di tutti {{ usersSelector }}.
              L'operazione è irreversibile, sicurə di voler procedere?<br>
              Per farlo inserisci la parola "<strong>RESET</strong>" nel box sotto.
            </p>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="keyword"
              label="Inserisci la keyword"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="#FC4955"
          :disabled="keyword !== 'RESET'"
          class="rounded-lg"
          @click="resetPasswords"
        >
          Resetta le password
        </v-btn>
        <v-spacer />
        <v-btn
          color="#f3900e"
          class="rounded-lg"
          @click="closeDialog"
        >
          Chiudi
        </v-btn>
      </v-card-actions>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "../../utils/Modal.vue";

export default {
  name: "ResetUsersTypePasswordDialog",
  components: { Modal },
  props: {
    typeOfUsers: {
      type: String,
      required: true,
    },
    listOfUsers: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    dialog: true,
    keyword: "",
  }),
  computed: {
    usersSelector() {
      switch(this.typeOfUsers) {
      case "Students":
        return "gli studenti";
      case "Profs":
        return "i professori";
      case "Guests":
        return "gli appassionati";
      default:
        return "";
      }
    }
  },
  methods: {
    ...mapActions("classrooms", ["resetMultiplePasswords"]),
    closeDialog() {
      this.dialog = false;
      this.$emit("close-dialog");
    },
    async resetPasswords(){
      const tmp = this.listOfUsers.map((u) => u.email);
      await this.resetMultiplePasswords({ emails: tmp });
      this.closeDialog();
    }
  }
}
</script>