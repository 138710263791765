import services from "@services/classroom";
import { handleAxiosError, sendNotification } from "@utils/notifications";
import Student from "@classes/student";
import Classroom from "@classes/classroom";

const initialState = () => ({
  classrooms: [],
  classLoaded: false,
  pendingClassroom: {},
});

const state = initialState();

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  /**
   * Mutation that creates classrooms from BE
   *
   * @returns {null}
   */
  SET_CLASSROOMS(state, classrooms) {
    /** reset classrooms from previous calls */
    state.classrooms = [];
    for (const classroom of classrooms) {
      /** for each classroom creates an Object and pushes it to the classrooms array */
      state.classrooms.push(
        new Classroom(classroom.id, classroom.name, classroom.courses)
      );
    }
    state.classLoaded = true;
  },
  /**
   * Mutation that creates students from BE
   *
   * @returns {null}
   */
  SET_STUDENTS(state, { data, classroomId }) {
    /** Find the classroom's array index */
    const classIndex = state.classrooms.findIndex(
      (classroom) => classroom.id === classroomId
    );
    /** if I've found the classroom */
    if (classIndex >= 0) {
      /** reset students and pendings from the classrooms found */
      state.classrooms[classIndex].students = [];
      /** if BE returns valid values */
      if (data !== null) {
        for (const student of data) {
          /** for each student creates a Student Object and pushes it to the classrooms Array  */
          state.classrooms[classIndex].students.push(
            new Student(
              student.email,
              student.name,
              student.surname,
              student.id,
              student.sec_email,
              student.can_contact,
            )
          );
        }
      }
    }
  },
  SUBSCRIBE_CLASS_TO_COURSE(state, { courseId, classroomId }) {
    const i = state.classrooms.findIndex((c) => c.id === classroomId);
    state.classrooms[i].courses.push(courseId);
  },
  UNSUBSCRIBE_CLASS_FROM_COURSE(state, { courseId, classroomId }) {
    const i = state.classrooms.findIndex((c) => c.id === classroomId);
    const j = state.classrooms[i].courses.findIndex((c) => c === courseId);
    state.classrooms[i].courses.splice(j, 1);
  },
  /**
   * Mutation that sets the pending classroom from BE
   *
   * @returns {null}
   */
  SET_PENDING_CLASSROOM(state, classroom) {
    /** reset pendingClassroom from previous calls */
    state.pendingClassroom = new Classroom(
      classroom.id,
      classroom.name,
      [],
      classroom.professor,
      classroom.school
    );
  },
};
const actions = {
  async deleteClassroom({ dispatch, commit }, idToDelete) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await services.deleteClassroom(idToDelete);
      sendNotification(`Classe eliminata con successo`, `success`);
      return idToDelete;
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'eliminazione della classe, provare a ricaricare la pagina`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async downloadClassroomsSubscribed({commit}, {courseId, courseTitle}) {
		try {
			commit("loading/startLoading", {}, { root: true })
			const report = await services.downloadClassroomsSubscribed(courseId)
			const blob = new Blob([report.data])
			const reader = new FileReader()
			reader.readAsDataURL(blob)
			reader.onload = (e) => {
				const a = document.createElement("a")
				a.download = `${courseTitle}_subscribedClassrooms.xlsx`
				a.href = e.target.result
				a.click()
			}
		} catch (err) {
			await handleAxiosError(`Errore nello scaricamento del report`, err)
		} finally {
			commit("loading/stopLoading", {}, { root: true })
		}
	},
  async getClassroomsByLpId(_context, { learningPathId }) {
    try {
      const { data } = await services.getClassroomsByLpId(learningPathId);
      return data;
    } catch(err) {
      await handleAxiosError("Errore nel recupero delle classi associate al corso ondemand.", err);
      return null;
    }
  },
  async resetMultiplePasswords({ commit }, {emails}) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await services.resetMultiplePasswords(emails);
      sendNotification(emails.length > 1 ? `Password resettate con successo!` : `Password resettata con successo!`, `success`);
    } catch (err) {
      await handleAxiosError(`Problema durante il reset delle password.`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
}

const getters = {
  /**
   * Getter that returns classrooms
   *
   * @returns {Array} classrooms
   */
  getClassrooms(state) {
    return state.classrooms;
  },
  /**
   * Getter that returns registered students
   *
   * @param  {String} classroomId
   * @returns {Array} students
   */
  getStudents: (state) => (classroomId) =>
    state.classrooms.find((classroom) => classroom.id === classroomId).students,
  /**
   * Getter that returns classrooms already registered to a course
   *
   * @param  {String} courseId
   * @returns {Array} classrooms
   */
  getClassroomsAlreadyRegistered: (state) => (courseId) =>
    state.classrooms.filter((classroom) => {
      for (const course of classroom.courses) {
        if (course === courseId) {
          return true;
        }
      }
      return false;
    }),
  /**
   * Getter that returns classrooms that aren't registered to a course
   *
   * @param {string} courseId
   * @returns {Array} unregistered classrooms
   */
  getClassroomsNotAlreadyRegistered: (state) => (courseId) =>
    state.classrooms.filter((classroom) => {
      for (const course of classroom.courses) {
        /** if course === courseId => the classroom is already registered, skip */
        if (course === courseId) {
          return false;
        }
      }
      return true;
    }),
  /**
   * Getter that returns pending students of a given classroomId
   *
   * @param {string} classroomId
   * @returns {Array} pending students
   */
  getPendingStudents: (state) => (classroomId) =>
    state.classrooms.find((classroom) => classroom.id === classroomId).pendings,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
