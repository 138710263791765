<template>
  <v-card class="mb-6">
    <div
      :style="{ 'border-left-color': color + '!important' }"
      class="border-left py-6"
    >
      <v-row align="center">
        <v-col
          cols="8"
          sm="6"
          lg="8"
          class="pl-10"
        >
          <v-row>
            <span
              :style="{ color: color }"
              class="text-uppercase font-weight-bold"
            >{{ title }}</span>
          </v-row>
          <v-row class="mb-0 mr-3">
            <span class="font-weight-bold h5 mb-0">{{ value }}</span>
          </v-row>
        </v-col>
        <v-col
          v-if="!this.$vuetify.breakpoint.xs"
          justify="center"
        >
          <v-row justify="center">
            <v-icon large>
              {{ icon }}
            </v-icon>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "Card",
  props: {
    color: {
      type: String,
      default: "#1cc88a",
    },
    icon: {
      type: String,
      default: "people",
    },
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    value: {
      type: String,
      required: true,
      default: "No data",
    },
  },
};
</script>

<style scoped>
.border-left {
  border-left: 0.3rem solid !important;
}
</style>
