<template>
  <v-container>
    <v-tabs
      v-model="tab"
      class="mt-10 mb-3"
      background-color="#1d1c4c"
      align-with-title
      grow
      slider-color="orange"
    >
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
        :href="`#tab-${index}`"
        class="ma-0 white--text"
      >
        <span v-if="$vuetify.breakpoint.xs">
          {{ item.shortname }}
        </span>
        <span v-else>
          {{ item.name }}
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="transparent">
      <v-tab-item
        v-for="(item, index) in tabs"
        :key="index"
        :value="`tab-${index}`"
      >
        <component :is="item.component" />
      </v-tab-item>
    </v-tabs-items>
    <LogsDownloadCard />
  </v-container>
</template>

<script>
import ReportPing from "@cmp-admin/ReportPing.vue";
import ReportTests from "@cmp-admin/ReportTests.vue";
import ReportOnDemand from "@cmp-admin/ReportOnDemand.vue";
import ReportTechJourney from "@cmp-admin/ReportTechJourney.vue";
import LogsDownloadCard from "@cmp-admin/LogsDownloadCard.vue";

export default {
  name: "Report",
  components: {
    ReportPing,
    ReportTests,
    ReportOnDemand,
    ReportTechJourney,
    LogsDownloadCard,
  },
  data() {
    return {
      tabs: [
        { name: "REPORT LIVE", shortname: "LIVE", component: "ReportPing" },
        {
          name: "REPORT DEI TESTS",
          shortname: "TESTS",
          component: "ReportTests",
        },
        {
          name: "REPORT ONDEMAND",
          shortname: "ONDEMAND",
          component: "ReportOnDemand",
        },
        {
          name: "REPORT TECH JOURNEY",
          shortname: "TECH JOURNEY",
          component: "ReportTechJourney",
        },
      ],
      tab: "tab-0",
    };
  },
  created() {
    const { tab } = this.$route.params;
    if (tab) {
      this.updateTab(tab);
    }
  },
  methods: {
    updateTab(tab) {
      this.tab = `tab-${tab}`;
    },
  },
};
</script>

<style scoped>
.fit-content {
  width: fit-content;
}
</style>
