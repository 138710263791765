<template>
  <v-responsive
    height="56px"
    width="100%"
    class="message-input"
  >
    <v-row
      class="chat-input mt-0 pa-0 mx-4"
      justify="space-between"
    >
      <v-col
        cols="9"
        class="pa-0"
      >
        <v-textarea
          v-model="text"
          outlined
          dark
          class="pa-0 rounded-lg chat-text-area"
          no-resize
          rows="1"
          row-height="20"
          placeholder="Scrivi un messaggio"
          @keyup.enter.prevent="sendMsg"
        />
      </v-col>

      <v-col
        cols="2"
        class="pa-0"
      >
        <v-btn
          height="54px"
          width="100%"
          color="#F39200"
          class="chat-send-btn rounded-lg"
          :disabled="text.length === 0"
          icon
          @click="sendMsg"
        >
          <v-icon
            :size="this.$vuetify.breakpoint.xs ? 27 : 20"
            color="#F39200"
          >
            mdi-send-outline
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-responsive>
</template>

<script>
export default {
  name: "MessageInput",
  props: {
    disabled: {
      type: Boolean,
    },
    channelId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      text: "",
    };
  },

  methods: {
    sendMsg() {
      if (this.text.length > 1) {
        const data = {
          msg: "method",
          method: "sendMessage",
          id: "unique-id",
          params: [
            {
              _id: `id${window.performance.now()}`,
              rid: this.channelId,
              msg: this.text,
            },
          ],
        };
        this.$emit("messageSended", data);
      }
      this.text = "";
    },
  },
};
</script>

<style>
.chat-input{
  position:relative;
  height: 56px;
}

.msg {
  background-color: #cccccc;
  color: black;
  border-radius: 10px;
  padding: 5px;
  max-width: 70%;
}

.chat-send-btn {
  background-color: transparent !important;
  border: 1px #F39200 solid !important;
  text-transform: unset !important;
  font-family: 'Karasuma Gothic' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.message-input{
  height: 56px;
  position: relative;
}
</style>
