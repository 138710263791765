import axios from "axios";

import store from "@/store";

const rocketAxios = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

rocketAxios.interceptors.request.use((config) => {
  if (store.state.chat.authToken && store.state.chat.userId) {
    config.headers["X-User-Id"] = store.state.chat.userId;
    config.headers["X-Auth-Token"] = store.state.chat.authToken;
  }
  return config;
});

export const setupRocketchatAxios = () => {
  rocketAxios.defaults.baseURL = store.getters["config/rocketChatUrl"];
};

export default rocketAxios;
