const baseData = [
  { text: "Name", value: "name" },
  { text: "Surname", value: "surname" },
  { text: "Primary mail", value: "email" },
  { text: "Secondary mail", value: "sec_email" },
];

const actions = { text: "Actions", value: "actions", sortable: false };
const impersonation = {
  text: "Impersonifica",
  value: "impersonation",
  sortable: false,
};

export const initData = () => ({
  dialogEditUser: "",
  loading: true,
  items: ["Students", "Profs", "Guests", "Sorintians"],
  selected: "Students",
  search: "",
  headers: [
    { text: "Name", value: "name" },
    { text: "Surname", value: "surname" },
    { text: "Email", value: "email" },
    actions,
    impersonation,
  ],
  hprofs: [
    ...baseData,
    { text: "School", value: "school" },
    { text: "Classes", value: "classrooms.length" },
    actions,
    impersonation,
  ],
  hstudents: [
    ...baseData,
    { text: "Marketing communication", value: "newsletter" },
    { text: "Job offers", value: "can_contact" },
    { text: "Class", value: "class_name" },
    { text: "School", value: "school" },
    { text: "School Year", value: "school_year" },
    { text: "Class invitation", value: "invite_class" },
    { text: "School invitation", value: "invite_school" },
    { text: "Status", value: "status" },
    actions,
    impersonation,
  ],
  hguests: [
    ...baseData,
    { text: "Company", value: "company" },
    { text: "Invitation from", value: "school_name" },
    { text: "Class", value: "classroom_name" },
    actions,
    impersonation,
  ],
  hsorintians: [
    { text: "Name", value: "name" },
    { text: "Surname", value: "surname" },
    { text: "Primary mail", value: "email" },
    { text: "Username", value: "username" },
    actions,
    impersonation,
  ],
  users: [],
  filteredItems: [],
  dialogPasswordUser: "",
  dialogDeleteUser: "",
  dialodPasswordUsersType: false,
  tmpName: "",
  tmpSurname: ",",
});
