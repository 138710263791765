<template>
  <Modal
    v-if="dialog"
    title="Eliminazione professore"
    :show-close-button="false"
    @closeModal="closeDialog"
  >
    <template #content-center>
      <v-card-text>
        <v-row justify="center">
          <p class="text-h6">
            Stai per eliminare la richiesta di approvazione del professore
            <strong>{{ user.name }} {{ user.surname }}</strong
            >. L'operazione è irreversibile, vuoi proseguire?
          </p>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-3">
        <v-row justify="end">
          <v-btn
            large
            rounded
            color="#F39200"
            class="white--text ml-3"
            @click="closeDialog"
          >
            Annulla
          </v-btn>
          <v-btn
            large
            rounded
            color="red darken-2"
            class="white--text ml-3"
            @click="removeApprovalTeacher"
          >
            Elimina
          </v-btn>
        </v-row>
      </v-card-actions>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "../../utils/Modal.vue";
import { handleAxiosError } from "@utils/notifications";

export default {
  name: "NotApprovalDialog",
  components: { Modal },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: true,
  }),
  methods: {
    ...mapActions("admin", ["removeTeacher", "getAllUnverifiedTeachers"]),
    async removeApprovalTeacher() {
      try {
        await this.removeTeacher(this.user.id);
        this.closeDialog();
      } catch (error) {
        handleAxiosError("Errore nell'eliminazione del professore", error);
      } finally {
        await this.getAllUnverifiedTeachers();
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$emit("close-dialog");
    },
  },
};
</script>
