<template>
  <v-main id="container">
    <v-container>
      <v-btn
        color="admin_primary"
        class="white--text my-5"
        large
        to="/report"
      >
        <v-icon left>
          mdi-keyboard-backspace
        </v-icon>INDIETRO
      </v-btn>
      <v-card
        class="pa-5 mt-3"
        color="#1d1c4c"
        dark
      >
        <v-row>
          <v-col>
            <v-card-title class="pa-0">
              <h2 class="font-weight-light">
                REPORT LIVE: {{ course ? course.title : "Caricamento..." }}
              </h2>
            </v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row
              no-gutters
            >
              <v-col
                class="pa-0"
                cols="12"
                sm="8"
                md="6"
              >
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Cerca una classe, un professore o un istituto..."
                  single-line
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="filteredClassrooms()"
                  class="data-table"
                >
                  <template #[`item.classroom`]="{ item }">
                    {{ item.name }}
                  </template>

                  <template #[`item.teacher`]="{ item }">
                    {{
                      item.professor.name.concat(" ").concat(item.professor.surname)
                    }}
                  </template>
                  <template #[`item.email`]="{ item }">
                    {{ item.professor.email }}
                  </template>

                  <template #[`item.action`]="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-icon
                          class="white--text"
                          @click="downloadExcel(item.id, item.name)"
                          v-on="on"
                        >
                          mdi-microsoft-excel
                        </v-icon>
                      </template>
                      Scarica l'excel con i report delle live
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ReportPingDetails",
  props: {
    id: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      search: "",
      classrooms: [],
      headers: [
        {
          text: "Classe",
          value: "classroom",
        },
        {
          text: "Istituto",
          value: "school",
        },
        {
          text: "Email Docente",
          value: "email",
        },
        {
          text: "Report",
          value: "action",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("courses", ["courses"]),
    course() {
      return this.courses.find((x) => x.id === this.id);
    },
  },
  async created() {
    this.classrooms = await this.getClassroomsSubscribed(this.id);
    await this.getAllCourses();
  },
  methods: {
    ...mapActions("courses", ["getClassroomsSubscribed", "getAllCourses"]),
    ...mapActions("reports", ["getReportByCourse"]),

    filteredClassrooms() {
      return this.classrooms.filter(
        (classroom) =>
          classroom.name.toLowerCase().match(this.search.toLowerCase()) ||
          classroom.professor.name
            .toLowerCase()
            .match(this.search.toLowerCase()) ||
          classroom.professor.surname
            .toLowerCase()
            .match(this.search.toLowerCase()) ||
          classroom.professor.email
            .toLowerCase()
            .match(this.search.toLowerCase()) ||
          classroom.school.toLowerCase().match(this.search.toLowerCase())
      );
    },

    downloadExcel(classroomId, className) {
      this.getReportByCourse({
        classroomId,
        courseId: this.id,
        // report_CLASSNAME_COURSETITLE_YYYY-MM-DD
        fileName: `report_${className}_${this.course.title}_${new Date()
          .toISOString()
          .substring(0, 10)}`,
      });
    },
  },
};
</script>

<style scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
  min-height: 90vh;
}

.data-table{
  background-color: transparent;
}
</style>
