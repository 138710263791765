import services from "@/services/admin";
import classServices from "@/services/classroom";
import { handleAxiosError, sendNotification } from "@/utils/notifications";
import Teacher from "@/classes/teacher";
import Classroom from "@/classes/classroom";
import School from "@/classes/school";
import Video from "@/classes/adminVideo";

const initialState = () => ({
  teachers: [],
  classrooms: [],
  schools: [],
  guests: [],
  videoList: {
    approved: [],
    rejected: [],
    pending: [],
  },
});

const state = initialState();

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  /**
   * Dato l'array contenente i docenti, crea un oggetto della classe Teacher per ogni docente
   *
   *
   * @param {Object} state Vuex state
   * @param {Array} teachers Array che arriva direttamente dalla chiamata Axios
   * @return {Void} Non viene ritornato nulla
   */
  SET_UNVERIFIED_TEACHERS: (state, teachers) => {
    const newTeachers = [];
    for (const teacher of teachers) {
      const {
        id,
        name,
        surname,
        email,
        school,
        subject,
        headmaster,
        verified,
      } = teacher;
      newTeachers.push(
        new Teacher(
          id,
          name,
          surname,
          email,
          school,
          subject,
          headmaster,
          verified
        )
      );
    }
    state.teachers = teachers;
  },
  SET_ALL_GUESTS: (state, guests) => {
    state.guests = [];
    for (const guest of guests) {
      const { avatar, company, email, name, sec_email, surname } = guest;
      state.guests.push(
        new guest(avatar, company, email, name, sec_email, surname)
      );
    }
  },
  /**
   * Dato l'array contenente le classi, crea un oggetto della classe Classroom per ogni classe
   *
   *
   * @param {Object} state Vuex state
   * @param {Array} teachers Array che arriva direttamente dalla chiamata Axios
   * @return {Void} Non viene ritornato nulla
   */
  SET_ALL_CLASSROOMS: (state, classrooms) => {
    state.classrooms = [];
    for (const classroom of classrooms) {
      const { id, name, courses, professor, school } = classroom;
      state.classrooms.push(
        new Classroom(id, name, courses, professor, school)
      );
    }
  },
  /**
   * Aggiorna localmente dall'array nel Vuex il docente eliminato, senza bisogno di fare un update
   *
   *
   * @param {Object} state Vuex state
   * @param {String} id Id del docente da Rimuovere
   * @return {Void} Non viene ritornato nulla
   */
  UPDATE_TEACHERS_LIST: (state, id) => {
    const teacher_verified_index = state.teachers.findIndex(
      (teacher) => teacher.ID === id
    );
    state.teachers.splice(teacher_verified_index, 1);
  },
  SET_VIDEO_LIST(state, videoList) {
    state.videoList.approved = videoList.approved
      ? videoList.approved.map(
          (video) => new Video({ ...video, status: "approved" })
        )
      : [];
    state.videoList.rejected = videoList.rejected
      ? videoList.rejected.map(
          (video) => new Video({ ...video, status: "rejected" })
        )
      : [];
    state.videoList.pending = videoList.pending
      ? videoList.pending.map(
          (video) => new Video({ ...video, status: "pending" })
        )
      : [];
  },
  APPROVE_VIDEO(state, item) {
    state.videoList.pending = state.videoList.pending.filter(
      (video) => video.videoId !== item.videoId
    );
    item.status = "approved";
    state.videoList.approved.unshift(item);
  },
  REJECT_VIDEO(state, item) {
    state.videoList.pending = state.videoList.pending.filter(
      (video) => video.videoId !== item.videoId
    );
    item.status = "rejected";
    state.videoList.rejected.unshift(item);
  },
  SET_ALL_SCHOOLS: (state, schools) => {
    state.schools = [];
    for (const school of schools) {
      const {
        address,
        city,
        code,
        name,
        province,
        region,
        category,
        type,
        zip_code,
        verified,
      } = school;
      state.schools.push(
        new School(
          address,
          city,
          code,
          name,
          province,
          region,
          category,
          type,
          zip_code,
          verified
        )
      );
    }
  },
  ADD_SCHOOL: (state, school) => {
    state.schools = state.schools.concat(school);
  },
  UPDATE_SCHOOL(state, school) {
    const index = state.schools.findIndex(
      (existingSchool) => existingSchool.code === school.code
    );
    state.schools[index] = school;
  },
};

const actions = {
  /**
   * Action che effettua fisicamente la chiamata al service, ritorna tutti i docenti
   *
   *
   * @param {Object} commit Vuex { commit } = context
   * @return {Void} Non viene ritornato nulla
   */
  async getAllUnverifiedTeachers({ commit }) {
    try {
      const { data } = await services.getAllUnverifiedTeachers();
      commit("SET_UNVERIFIED_TEACHERS", data);
    } catch (err) {
      await handleAxiosError(`Errore durante il caricamento dei docenti`, err);
    }
  },

  async getAllGuests({ commit }) {
    try {
      const { data } = await services.getAllGuests();
      commit("SET_ALL_GUESTS", data);
    } catch (err) {
      await handleAxiosError("Errore durante il caricamento degli ospiti", err);
    }
  },
  /**
   * Action che effettua fisicamente la chiamata al service, verifica un docente che ha fatto richiesta al portale
   *
   *
   * @param {Object} commit Vuex { commit } = context
   * @param {String} id id del docente da verificare
   * @return {Void} Non viene ritornato nulla
   */
  async verifyTeacher({ commit }, id = "") {
    try {
      await services.verifyTeacher(id);
      commit("UPDATE_TEACHERS_LIST", id);
    } catch (err) {
      await handleAxiosError(`Errore durante l'approvazione del docente`, err);
    }
  },
  /**
   * Action che effettua fisicamente la chiamata al service, rimuove un docente che ha fatto richiesta al portale
   *
   *
   * @param {Object} commit Vuex { commit } = context
   * @param {String} id id del docente da rimuovere
   * @return {Void} Non viene ritornato nulla
   */
  async removeTeacher({ commit }, id = "") {
    try {
      await services.removeTeacher(id);
      commit("UPDATE_TEACHERS_LIST", id);
    } catch (err) {
      await handleAxiosError(`Errore durante la rimozione docente`, err);
    }
  },



  /**
   * Action che effettua fisicamente la chiamata al service, ritorna tutte le classi
   *
   *
   * @param {Object} commit Vuex { commit } = context
   * @return {Void} Non viene ritornato nulla
   */
  async getAllClassrooms({ commit }) {
    try {
      const { data } = await services.getAllClassrooms();
      if (data !== null) {
        commit("SET_ALL_CLASSROOMS", data);
      }
    } catch (err) {
      await handleAxiosError(`Errore durante il caricamento delle classi`, err);
    }
  },
  /**
   * Action che effettua fisicamente la chiamata al service, elimina tutte le classi
   *
   *
   * @param {Object} commit Vuex { commit } = context
   * @return {Void} Non viene ritornato nulla
   */
  async deleteAllClassrooms({ commit, dispatch }) {
    try {
      await classServices.deleteAllClassrooms();
      commit("SET_ALL_CLASSROOMS", "");
      dispatch("getAllClassrooms");
    } catch (err) {
      await handleAxiosError(`Errore durante l'eliminazione delle classi`, err);
    }
  },
  async downloadPendingStudents({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report = await services.downloadPendingStudents();
      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `pending_students.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento del report`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async downloadStudentsInfo({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report = await services.getAllStudents("xlsx");
      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `Students_information.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento degli studenti`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async downloadTeachersInfo({ commit }, onlyActive) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report = await services.downloadTeachersInfo("xlsx", onlyActive);
      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = onlyActive
          ? `Active_Teachers_information.xlsx`
          : `Teachers_information.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento dei professori`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async downloadCv({ commit }, fileName) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const cv = await services.downloadCv(fileName);
      const blob = new Blob([cv.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = fileName;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento del cv`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },

  async downloadGuestsInfo({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report = await services.getAllGuests("xlsx");
      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = `Guests_information.xlsx`;
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento degli ospiti`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteAvatar(_context, { id }) {
    try {
      await services.deleteAvatar(id);
      sendNotification("Foto rimossa con successo", "success");
    } catch (err) {
      await handleAxiosError("Errore nella cancellazione dell'immagine", err);
    }
  },
  async deleteSingleUser({ dispatch, commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await services.deleteSingleUser(id);
      sendNotification(`Utente eliminato con successo`, `success`);
      return { id };
    } catch (err) {
      await handleAxiosError(
        `Problema durante l'eliminazione dell'utente, provare a ricaricare la pagina`,
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async downloadDebugLogs({ commit }, { date, pack }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const report = await services.downloadDebugLogs(date, pack);
      let fileName = "";
      const contentDisposition = report.headers["content-disposition"];

      if (
        contentDisposition &&
        contentDisposition.indexOf("filename=") !== -1
      ) {
        const contentDispositionParts = contentDisposition.split("; ");
        const filenamePart = contentDispositionParts.find((part) =>
          part.startsWith("filename=")
        );
        fileName = filenamePart.split("=")[1];
      }

      const blob = new Blob([report.data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = fileName;
        a.href = e.target.result;
        a.click();
      };
      sendNotification(
        `Scaricato ${pack.toUpperCase()} log del ${date}`,
        `success`
      );
    } catch (err) {
      await handleAxiosError(`Errore nello scaricamento dei log`, err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getAllSchools({ commit }) {
    try {
      const { data } = await services.getAllSchools();
      if (data !== null) {
        commit("SET_ALL_SCHOOLS", data);
      }
    } catch (err) {
      await handleAxiosError(`Errore durante il caricamento delle scuole`, err);
    }
  },
  async addSchool({ dispatch, commit }, school) {
    try {
      await services.addSchool(school);
      if (school) {
        commit("ADD_SCHOOL", school);
      }
    } catch (err) {
      await handleAxiosError(`Errore durante l'aggiunta della scuola`, err);
    }
  },
  async updateSchool({ dispatch, commit }, school) {
    try {
      await services.updateSchool(school.code, school);
      if (school.code) {
        commit("UPDATE_SCHOOL", school);
      }
    } catch (err) {
      await handleAxiosError(`Errore durante la modifica della scuola`, err);
    }
  },
};

const getters = {
  /**
   * Getter che torna i docenti non verificati
   *
   *
   * @param {Object} state Vuex state
   * @return {Array} Array docenti filtrato
   */
  unverifiedTeachers: (state) => state.teachers,
  notPendingVideoList: (state) =>
    state.videoList.approved.concat(state.videoList.rejected),
  pendingVideoList: (state) => state.videoList.pending,
  /**
   * Getter che torna tutte le classi
   *
   *
   * @param {Object} state Vuex state
   * @return {Array} Array docenti filtrato
   */
  allClassrooms: (state) => state.classrooms,
  allSchools: (state) => state.schools,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
