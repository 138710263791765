<template>
  <div v-if="remainingTime > 0">
    <template v-if="course">
      <div class="toolbar-timer">
        <h4
          v-if="!$vuetify.breakpoint.mobile"
          class="toolbar-timer-text"
        >
          IL CORSO
        </h4>
        <h3 class="toolbar-timer-course-title">
          {{ course.title }}
        </h3>
        <h4 class="toolbar-timer-text">
          COMINCERà TRA
        </h4>
        <h3 class="toolbar-timer-hours">
          {{ hours }}<span class="toolbar-timer-hours-text">H</span> {{ minutes }}<span class="toolbar-timer-hours-text">MIN</span>
        </h3>
      </div>
    </template>
    <v-progress-circular
      v-else
      indeterminate
    />
  </div>
</template>

<script>
import dayjs from "dayjs";


export default {
  name:"ToolbarTimer",
  props: {
    isNextLive: {
      type: Object,
      required: true,
    }
  },
  data(){
    return {
      course: null,
      currentTime: Date.now() ,
      intervalTime: null,
    }
  },
  computed:{
    remainingTime(){
      if(!this.course) {return 1;}
      return dayjs.unix(this.course.start).diff(dayjs(this.currentTime), "milliseconds")
    },
    hours(){
      return Math.floor(this.remainingTime / 3600000)
    },
    minutes(){
      return Math.floor((this.remainingTime / (1000 * 60)) % 60)
    },
  },
  created(){
    if(this.isNextLive !== null){
      this.course = this.isNextLive
      this.intervalTime = setInterval(() => {
        this.currentTime = Date.now();
      }, 1000 * 60);
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalTime);
  },
}
</script>

<style>
.toolbar-timer{
display: flex;
flex-direction: row;
align-items: center;
padding: 4px 16px;
gap: 14px;
text-transform: uppercase;
border: 2px solid #7FBAF4;
border-radius: 12px;
}
.toolbar-timer-text{
font-family: 'Karasuma Gothic', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
/* identical to box height */

text-align: center;

/* Grey/400 */

color: #98A6AE;
}
.toolbar-timer-course-title{
font-family: 'Karasuma Gothic', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
text-align: center;
max-width: 255px;
text-overflow: ellipsis;

/* Grey/100 */

color: #F4F5F6;
}
.toolbar-timer-hours{

font-family: 'outerorbit', 'Orbitron', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 24px;
text-align: center;
color: #7FBAF4;

}
.toolbar-timer-hours-text{

font-family: 'Karasuma Gothic', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #7FBAF4;

}

@media only screen and (max-width: 600px) {
  .toolbar-timer {
flex-direction: column;
justify-content: center;
align-items: center;
padding: 4px 16px;
gap:1px;
border: 2px solid #7FBAF4;
border-radius: 12px;
  }
}
</style>