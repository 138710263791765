<template>
  <v-col cols="12">
    <v-data-table
      class="quiz-table"
      :headers="headers"
      :items="currentQuestions"
      :items-per-page="5"
      :footer-props="{'items-per-page-text': 'Righe per pagina'}"
    >
      <template
        #[`item.title`]="{ item }"
      >
        {{ item.title }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              icon
              @click="() => modifyQuestion(item)"
              v-on="on"
            >
              <v-icon color="white">
                mdi-file-edit-outline
              </v-icon>
            </v-btn>
          </template>
          Modifica domanda
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on }">
            <div
              class="d-inline"
              v-on="on"
            >
              <v-btn
                icon
                @click="() => deleteQuestion(item.id)"
              >
                <v-icon
                  color="white"
                  v-on="on"
                >
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
          </template>
          Elimina domanda
        </v-tooltip>
      </template>
    </v-data-table>
    <EditQuestionDialog
      v-if="selectedQuestion"
      :selected-question="selectedQuestion"
      :original-selected-question="originalSelectedQuestion"
      
      @onCloseEditQuestion="closeEditQuestionDialog"
    />
  </v-col>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { mapGetters, mapActions } from "vuex";
import EditQuestionDialog from "./dialogs/EditQuestionDialog.vue";
export default {
  name: "QuestionTable",
  components: { EditQuestionDialog },
  data() {
    return {
      selectedQuestion: null,
      originalSelectedQuestion: null,
      headers: [
        {
          text: "Titolo",
          value: "formattedText",
          width: "80%"
        },
        {
          text: "Punteggio",
          value: "points",
        },
        {
          text: "Azioni",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("quiz", ["selectedQuiz"]),
    currentQuestions() {
      return this.selectedQuiz.questions.map((q) => {
        q.formattedText = q.text.slice(3, q.text.length - 4)
        return q
      });
    },
  },
  methods: {
    ...mapActions("quiz", ["deleteQuizQuestion"]),
    async deleteQuestion(questionId) {
      this.$logger(questionId);
      await this.deleteQuizQuestion({ questionId });
    },
    modifyQuestion(question) {
      this.selectedQuestion = cloneDeep(question);
      this.originalSelectedQuestion = cloneDeep(question);
    },
    closeEditQuestionDialog(){
      this.selectedQuestion = null;
      this.originalSelectedQuestion = null;
    }
  },
};
</script>

<style scoped>
.quiz-table {
  background-color: rgba(0,0,0,0);
  max-height: 450px;
  overflow: auto;
}
</style>
