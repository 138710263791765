/**
 *
 * Utils
 */
import ErrorUnauthorized from "@views/utils/ErrorUnauthorized";
import ErrorPage from "@views/utils/ErrorPage";
import Token from "@cmp/auth/Token";
import ContentManagement from "../views/admin/ContentManagement.vue";
const VideoPage = () =>
  import(/*webpackChunkName: "student"*/ "@views-common/VideoPage");
/**
 * Admin views
 */
const AdminDashboard = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/Dashboard");
const MessageManagement = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/MessageManagement");
const LinkManagement = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/LinkManagement");
const AdminCourses = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/Courses");
const EditCourse = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/EditCourse");
const EditOndemandCourse = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/EditOndemandCourse");
const AdminChat = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/AdminChat");
const Users = () => import(/*webpackChunkName: "admin"*/ "@views-admin/Users");
const Event = () => import(/*webpackChunkName: "admin"*/ "@views-admin/Event");
const QuizManagement = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/QuizManagement");
const OnDemandResourceManagement = () =>
  import(
    /*webpackChunkName: "admin"*/ "@views-admin/OnDemandResourceManagement"
  );
const QuizForm = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/QuizForm");
const QuestionManagementDetails = () =>
  import(
    /*webpackChunkName: "admin"*/ "@views-admin/QuestionManagementDetails"
  );
const Report = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/Report");
const ReportPingDetails = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/ReportPingDetails");
const ReportTestDetails = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/ReportTestDetails");
const ReportOnDemandDetails = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/ReportOnDemandDetails");
const Download = () =>
  import(/*webpackChunkName: "admin"*/ "@views-admin/Download");
const KeycloakRedirect = () =>
  import(/*webpackChunkName: "keycloak"*/ "@views-common/KeycloakRedirect");
const routes = [
  {
    path: "/",
    name: "admin-dashboard",
    children: [
      {
        path: "report/:tab?",
        name: "admin-report",
        component: Report,
      },
      {
        path: "report-ping/details/:id?",
        name: "admin-report-ping-details",
        component: ReportPingDetails,
        props: true,
      },
      {
        path: "report-test/details/:id?",
        name: "admin-report-test-details",
        component: ReportTestDetails,
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: "report-ondemand/details/:id?",
        name: "admin-report-ondemand-details",
        component: ReportOnDemandDetails,
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: "questions-management/details/:id?",
        name: "admin-questions-management-details",
        component: QuestionManagementDetails,
        props: true,
      },
      {
        path: "message-management",
        name: "admin-message-management",
        component: MessageManagement,
      },
      {
        path: "link-management",
        name: "admin-link-management",
        component: LinkManagement,
      },
      {
        path: "courses",
        name: "admin-courses",
        component: AdminCourses,
      },
      {
        path: "ondemand-courses",
        name: "admin-ondemand-courses",
        component: AdminCourses,
      },
      {
        path: "course/edit/:id?",
        name: "admin-course-edit",
        component: EditCourse,
        props: true,
      },
      {
        path: "ondemandcourse/edit/:id?",
        name: "admin-ondemandcourse-edit",
        component: EditOndemandCourse,
        props: true,
      },
      {
        path: "chat",
        name: "admin-chat",
        component: AdminChat,
      },
      {
        path: "users",
        name: "admin-users",
        component: Users,
      },
      {
        path: "event",
        name: "admin-event",
        component: Event,
      },
      {
        path: "quiz-management",
        name: "admin-quiz-management",
        component: QuizManagement,
      },
      {
        path: "admin-ondemand-resource-management-sections/:id",
        name: "admin-ondemand-resource-management-sections",
        component: OnDemandResourceManagement,
      },
      {
        path: "quiz-management/new",
        name: "admin-quiz-management-new",
        component: QuizForm,
      },
      {
        path: "quiz-management/edit/:id?/step/:step",
        name: "admin-quiz-management-edit",
        component: QuizForm,
      },
      {
        path: "question/unreport/:id",
        name: "admin-question-unreport",
        component: ContentManagement,
      },
      {
        path: "reply/unreport/:id",
        name: "admin-reply-unreport",
        component: ContentManagement,
      },
    ],
    meta: {
      isAdmin: true,
    },
    redirect: { name: "admin-link-management" },
    component: AdminDashboard,
  },
  {
    path: "/download/:filename",
    name: "admin-download",
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
    component: Download,
  },
  {
    path: "/course/:id/video/:videoId",
    name: "live-video-page",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: VideoPage,
  },
  {
    path: "/error",
    name: "error",
    component: ErrorPage,
    meta: {
      accessible: true,
    },
  },
  {
    path: "/auth/sso",
    name: "token",
    component: Token,
    meta: {
      accessible: true,
    },
  },
  {
    path: "/401",
    name: "401",
    component: ErrorUnauthorized,
    meta: {
      accessible: true,
    },
  },
  {
    path: "*",
    name: "NotFound",
    meta: {
      accessible: true,
    },
    component: ErrorPage,
  },
];

export default routes;
