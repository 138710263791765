import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/delete_classroom_all
 */
function deleteAllClassrooms() {
  return api.delete(`/classroom/all`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/delete_classroom
 */
function deleteClassroom(id) {
  return api.delete(`/classroom?classroomid=${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Classroom/get_classroom_download_subscribed
 */
function downloadClassroomsSubscribed(courseId) {
  return api.get(`/classroom/download/subscribed?courseid=${courseId}`, {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Learning%20path/get_learning_paths__id__classroom
 */
function getClassroomsByLpId(learningPathId) {
  return api.get(`/learning-paths/${learningPathId}/classrooms`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Reset%20Password/post_user_reset_multiple_passwords
 */
function resetMultiplePasswords(emails) {
  return api.post("/user/reset-multiple-passwords", { emails });
}

export default {
  deleteClassroom,
  deleteAllClassrooms,
  downloadClassroomsSubscribed,
  getClassroomsByLpId,
  resetMultiplePasswords,
};
