<template>
  <v-col class="pa-0" cols="12">
    <v-card class="pa-5 mt-3" color="#1d1c4c" dark>
      <v-card-title class="pa-0">
        <v-col cols="12">
          <v-row>
            <h2 class="font-weight-light">
              REPORT LIVE
            </h2>
          </v-row>
          <v-row>
            <span class="text-subtitle-1">Sono disponibili solo i report dei corsi di cui è terminata
              almeno una live</span>
          </v-row>
        </v-col>

        <v-row no-gutters>
          <v-col cols="12" sm="8" md="6">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Cerca un corso o uno speaker..." single-line
              hide-details />
          </v-col>

          <v-col cols="12" sm="12" xl="6" class="d-flex flex-wrap justify-end mt-5">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn color="secondary" :width="$vuetify.breakpoint.smAndDown ? '100%' : ''" dark v-on="on"
                  @click="showConfirmModal = true">
                  <v-icon left>
                    mdi-download
                  </v-icon>
                  Download All Ping
                </v-btn>
              </template>
              Scarica il report dei ping associati a tutti i corsi live
            </v-tooltip>


            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn color="admin_primary" :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                  :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'ml-5'" dark v-on="on"
                  @click="downloadAllSubscribedStudents">
                  <v-icon left>
                    mdi-download
                  </v-icon>
                  Download All Subscribers
                </v-btn>
              </template>
              Scarica il report delle classi iscritte a tutti i corsi live
            </v-tooltip>
          </v-col>
        </v-row>

        <Modal v-if="showConfirmModal" title="Download report di tutti i ping" width="600px" :show-close-button="false"
          :glassify="true" @closeModal="showConfirmModal = false">
          <template #content-center>
            <v-card-text class="mb-3" justify="center">
              Confermando l'azione scaricherai un file molto pesante del
              report di <strong>TUTTI</strong> i ping. Si consiglia di eseguire
              questa azione solo a fine sessione. Procedere?
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-progress-circular v-if="isLoadingDownload" indeterminate class="mr-4" />
              <v-btn v-else color="success" class="rounded-lg" @click="downloadAllPings">
                Conferma
              </v-btn>
              <v-btn color="#f3900e" class="rounded-lg" @click="showConfirmModal = false">
                Chiudi
              </v-btn>
            </v-card-actions>
          </template>
        </Modal>
      </v-card-title>

      <v-row class="mt-5">
        <v-col>
          <v-data-table :headers="headersLive" :items="filteredLive" class="data-table">
            <!-- MEMO: non riordina questo campo... da fare controllo -->
            <template #[`item.author`]="{ item }">
              {{ item.authors.map((author) => author.name).join(", ") }}
            </template>

            <template #[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn class="mr-2" color="secondary" width="40px" height="40px" min-width="0"
                    @click="downloadZip(item.id, item.title)" v-on="on">
                    <v-icon>
                      mdi-archive-arrow-down
                    </v-icon>
                  </v-btn>
                </template>
                Scarica il report dei ping associati alla live
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn color="admin_primary" width="40px" height="40px" min-width="0"
                    @click="downloadSubscribedStudents({ courseId: item.id, fileName: item.title })" v-on="on">
                    <v-icon>
                      mdi-archive-arrow-down
                    </v-icon>
                  </v-btn>
                </template>
                Scarica il report delle classi iscritte alla live
              </v-tooltip>
            </template>

            <template #[`item.openClasses`]="{ item }">
              <v-btn color="#F39200" width="40px" height="40px" min-width="0" class="rounded-lg open-button"
                @click="selectLive(item.id)">
                <v-icon>mdi-login</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-5 mt-3" color="#1d1c4c" dark>
      <v-card-title class="pa-0">
        <v-col cols="12">
          <v-row>
            <h2 class="font-weight-light">
              RELOAD DEI REPORT
            </h2>
          </v-row>
          <v-row>
            <span class="text-subtitle-1">
              E' possibile ricalcolare i report degli episodi passati (utile se
              è cambiato l'orario di inizio e fine)
            </span>
          </v-row>
        </v-col>

        <v-row no-gutters>
          <v-col cols="12" sm="8" md="6">
            <v-text-field v-model="searchReport" append-icon="mdi-magnify" label="Cerca un episodio..." single-line
              hide-details />
          </v-col>
        </v-row>
      </v-card-title>

      <v-row class="mt-5">
        <v-col>
          <v-data-table :headers="headersReloadPings" :items="filteredEpisodesLive" class="data-table">
            <template #[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon class="white--text" @click="reloadReport(item)" v-on="on">
                    mdi-reload
                  </v-icon>
                </template>
                Reload del report dell'episodio
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon class="white--text ml-1" @click="downloadEpisodesRanks(item)" v-on="on">
                    mdi-download
                  </v-icon>
                </template>
                Download del report ranking dell'episodio
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@store";
import Modal from "@cmp-shared/utils/Modal.vue";


export default {
  name: "ReportPing",
  components: {
    Modal,
  },
  data() {
    return {
      showConfirmModal: false,
      isLoadingDownload: false,
      search: "",
      searchReport: "",
      headersLive: [
        {
          text: "Corso",
          value: "title",
          sortable: true,
        },
        {
          text: "Speaker",
          value: "author",
          sortable: true,
        },
        {
          text: "Download",
          value: "action",
        },
        {
          text: "Apri elenco classi",
          value: "openClasses",
          sortable: false,
        },
      ],
      headersReloadPings: [
        {
          text: "Episodio",
          value: "episode.title",
          sortable: true,
        },
        {
          text: "Corso",
          value: "title",
          sortable: true,
        },
        {
          text: "Azioni",
          value: "action",
        },
      ],
    };
  },
  computed: {
    ...mapState("courses", ["courses"]),
    liveCourses() {
      return this.courses.filter((course) => {
        if (!course.onDemand) {
          return true;
        }
        return false;
      });
    },
    today() {
      return new Date();
    },

    /**
     * It removes live courses that are note completed yet.
     * We can search for course title, author name or episode title
     * */
    filteredLive() {
      const validCourses = this.liveCourses.filter((course) =>
        course.episodes.at(-1)
      );
      return validCourses
        .filter((x) => new Date(x.episodes.at(0).end * 1000) < this.today)
        .filter(
          (live) =>
            live.title.toLowerCase().match(this.search.toLowerCase()) ||
            live.authors.some(
              (author) =>
                author.name.toLowerCase().match(this.search.toLowerCase()) ||
                live.episodes.some((episode) =>
                  episode.title.toLowerCase().match(this.search.toLowerCase())
                )
            )
        );
    },

    filteredEpisodesLive() {
      const validCourses = this.liveCourses.filter((course) =>
        course.episodes.at(-1)
      );
      const remappedEpisodes = validCourses
        .map((course) =>
          course.episodes
            .filter((episode) => new Date(episode.end * 1000) < this.today)
            .map((episode) => ({ title: course.title, episode }))
        )
        .flat();
      return remappedEpisodes.filter(
        (episodeCourse) =>
          episodeCourse.title
            .toLowerCase()
            .match(this.searchReport.toLowerCase()) ||
          episodeCourse.episode.title
            .toLowerCase()
            .match(this.searchReport.toLowerCase())
      );
    },
  },
  async created() {
    store.commit("loading/startLoading");
    await store.dispatch("courses/getAllCourses");
    store.commit("loading/stopLoading");
  },
  methods: {
    ...mapActions("reports", [
      "getAllReportsByEpisode",
      "reloadReportById",
      "getReportAllPings",
      "downloadSubscribedStudents",
      "downloadAllSubscribedStudents"
    ]),
    ...mapActions("courses", ["getRanksExport"]),
    async downloadZip(courseId, course_title) {
      await this.getAllReportsByEpisode({
        courseId,
        // report_COURSETITLE_YYYY-MM-DD
        fileName: `report_${course_title}_${new Date()
          .toISOString()
          .substring(0, 10)}`,
      });
    },
    async downloadAllPings() {
      try {
        this.isLoadingDownload = true;
        await this.getReportAllPings({
          // report_COURSETITLE_YYYY-MM-DD
          fileName: `report_ALLPINGS_${new Date()
            .toISOString()
            .substring(0, 10)}`,
        });
      } catch (e) {
        this.$logger(e);
      } finally {
        this.isLoadingDownload = null;
      }
    },
    async reloadReport(item) {
      const episodeId = item.episode.id;
      await this.reloadReportById({ episodeId });
    },
    async downloadEpisodesRanks(item) {
      await this.getRanksExport({
        episodeId: item.episode.id,
        fileName: `report_ranking_${item.episode.title}`
      })
    },
    selectLive(liveId) {
      this.$router.push({
        name: `admin-report-ping-details`,
        params: { id: liveId },
      });
    },
  },
};
</script>

<style scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
  min-height: 90vh;
}

.fit-content {
  width: fit-content;
}

.data-table {
  background-color: transparent;
}

.open-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f4f5f6;
  text-transform: none;
}
</style>
