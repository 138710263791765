<template>
  <v-col class="pa-0">
    <Calendar
      :events="parsedAllSessions"
      @onDateClick="handleCalendarClick"
      @onEventClick="handleEventClick"
    />
    <AddChangeSessionDialog
      v-if="selectedDates"
      :selected-dates="selectedDates"
      @onRemoveSelectedDates="removeSelectedDates"
      @onUpdateStartingDate="updateStartingDate"
      @onUpdateEndingDate="updateEndingDate"
      @onAddSession="addSession"
      @onRemoveSession="removeSession"
      @onModifySession="modifySession"
    />
    <PublishQuizDialog
      v-if="wannaPublishQuiz"
      @onConfirmPublish="publishQuiz"
      @onClosePublishDialog="closePublishDialog"
    />
    <v-row
      no-gutters
      justify="center"
      justify-md="start"
      class="mt-5 mx-3"
    >
      <v-col cols="12" md="8">
        <h4>
          Vuoi rendere scaricabile questo test ai professori?
        </h4>
        <h4>
          Per i professori ora il report
          <span
            :class="{
              'red--text': !selectedQuiz.download_enabled,
              'green--text': selectedQuiz.download_enabled,
            }"
          >{{
            selectedQuiz.download_enabled
              ? "è scaricabile"
              : "NON è scaricabile"
          }}</span>
        </h4>
        <v-btn
          large
          :light="!selectedQuiz.published"
          :dark="selectedQuiz.published"
          :disabled="!selectedQuiz.published"
          color="#00A1FF"
          class="mt-5 rounded-btn"
          @click="enableQuizDownload"
        >
          <v-icon left>
            {{ selectedQuiz.download_enabled ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }} 
          </v-icon>
          {{
            selectedQuiz.download_enabled
              ? "NASCONDI REPORT"
              : "RENDI VISIBILE REPORT"
          }}
        </v-btn>
      </v-col>
      <v-col>
        <v-card
          class="mt-4 mt-md 0 pa-3 pa-md-6 credly-card"
          :min-width="$vuetify.breakpoint.mdAndDown ? 250 : 300"
          color="#1D1C4C"
          flat
        >
          <v-card-title class="mdi-button">
            CREDLY
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="credly_template_id"
              :rules="credlyTemplateRules"
              label="template id"
              required
            />
            <v-text-field
              v-model="credly_excellence_template_id"
              :rules="credlyTemplateRules"
              label="excellence template id"
              required
            />
          </v-card-text>

          <v-card-actions>
            <v-btn
              :disabled="!isCredlySaveEnabled"
              color="success"
              class="rounded-btn mr-4"
              @click="saveCredlyTemplates"
            >
              <v-icon left>
                mdi-content-save-outline
              </v-icon>
              Salva
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      no-gutters
      justify="center"
      justify-md="end"
      class="mt-8"
    >
      <v-btn
        dark
        color="#F39200"
        class="rounded-btn mr-md-5"
        @click="saveQuiz"
      >
        <v-icon left>
          mdi-content-save-outline
        </v-icon>
        SALVA TEST
      </v-btn>
      <v-btn
        :dark="!selectedQuiz.published"
        :disabled="selectedQuiz.published"
        color="blue darken-3"
        class="rounded-btn mt-4 mt-md-0"
        @click="openPublishQuizDialog"
      >
        <v-icon left>
          mdi-publish
        </v-icon>
        PUBBLICA TEST
      </v-btn>
    </v-row>
  </v-col>
</template>

<script>
import Calendar from "../Calendar.vue";
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import { sendNotification } from "../../../../utils/notifications";
import AddChangeSessionDialog from "../dialogs/AddChangeSessionDialog.vue";
import PublishQuizDialog from "../dialogs/PublishQuizDialog.vue";
export default {
  name: "QuizSessions",
  components: {
    Calendar,
    AddChangeSessionDialog,
    PublishQuizDialog,
  },
  data() {
    return {
      selectedDates: null,
      wannaPublishQuiz: false,
      credly_template_id: null,
      credly_excellence_template_id: null,
      credlyTemplateRules: [
        (v) => Boolean(v) && v.trim().length > 0 || "Credly template id is required!",
      ]
    };
  },
  computed: {
    ...mapGetters("quiz", ["sessions", "selectedQuiz"]),
    mysessions() {
      return this.selectedQuiz.sessions;
    },
    parsedAllSessions() {
      let allSessions = [];
      if (this.mysessions) {
        let counter = 0;
        const mysessions = this.mysessions.map(
          (session) => {
            counter++;
            return {
              ...session,
              mysession: true,
              name: this.selectedQuiz.title,
              counter,
            };
          }
        );
        const mySessionsIds = mysessions.map(
          (session) => session.id
        );
        let otherSessions = [];
        otherSessions = this.sessions.filter(
          (session) => !mySessionsIds.includes(session.id)
        );
        allSessions = [...otherSessions, ...mysessions];
      } else {
        allSessions = this.sessions;
      }
      return allSessions.map((session) => ({
        begin_ts: session.begin_ts,
        end_ts: session.end_ts,
        id: session.id,
        start: dayjs.unix(session.begin_ts).toDate(),
        end: dayjs.unix(session.end_ts).subtract(1, "day").toDate(),
        ...session,
      }));
    },
    isCredlySaveEnabled() {
      return this.credly_template_id && this.credly_excellence_template_id && this.credly_template_id.trim().length > 0 && this.credly_excellence_template_id.trim().length > 0;
    }
  },
  created() {
    this.credly_template_id = this.selectedQuiz.credly_template_id;
    this.credly_excellence_template_id = this.selectedQuiz.credly_excellence_template_id;
  },
  methods: {
    ...mapActions("quiz", [
      "createSession",
      "updateSession",
      "deleteSession",
      "publishSelectedQuiz",
      "setQuizDownloadable",
      "updateQuiz"
    ]),
    handleCalendarClick(event) {
      this.selectedDates = event;
    },
    handleEventClick({ event }) {
      if (!event.mysession) {
        return;
      }
      const { start, end, id } = event;
      const dateToFormatStart = dayjs(start);
      const dateToFormatEnd = dayjs(end);
      this.selectedDates = {
        startingDate:
          dateToFormatStart.format("YYYY-MM-DD"),
        endingDate: dateToFormatEnd.format("YYYY-MM-DD"),
        id,
      };
    },
    removeSelectedDates() {
      this.selectedDates = null;
    },
    updateStartingDate(newStartingDate) {
      this.selectedDates.startingDate = newStartingDate;
    },
    updateEndingDate(newEndingDate) {
      this.selectedDates.endingDate = newEndingDate;
    },
    async addSession() {
      const parsedDate = {
        start: dayjs(
          this.selectedDates.startingDate
        ).format("YYYY-MM-DD"),
        end: dayjs(this.selectedDates.endingDate).format(
          "YYYY-MM-DD"
        ),
      };
      await this.createSession(parsedDate);
      this.removeSelectedDates();
    },
    async modifySession() {
      const indexToUpdate =
        this.parsedAllSessions.findIndex(
          (session) => session.id === this.selectedDates.id
        );
      if (indexToUpdate !== -1) {
        const parsedDate = {
          start: dayjs(
            this.selectedDates.startingDate
          ).format("YYYY-MM-DD"),
          end: dayjs(this.selectedDates.endingDate).format(
            "YYYY-MM-DD"
          ),
          id: this.selectedDates.id,
        };
        await this.updateSession(parsedDate);
      } else {
        sendNotification(
          "Sessione non più esistente",
          "error"
        );
      }
      this.removeSelectedDates();
    },
    async removeSession() {
      const indexToUpdate =
        this.parsedAllSessions.findIndex(
          (session) => session.id === this.selectedDates.id
        );
      if (indexToUpdate !== -1) {
        await this.deleteSession(this.selectedDates.id);
      } else {
        sendNotification(
          "Sessione non più esistente",
          "error"
        );
      }
      this.removeSelectedDates();
    },
    saveQuiz() {
      this.$router.push("/quiz-management");
      sendNotification(
        "Quiz salvato con successo!",
        "success"
      );
    },
    async publishQuiz() {
      this.$logger("publishing..");
      await this.publishSelectedQuiz();
      this.closePublishDialog();
    },
    async enableQuizDownload() {
      await this.setQuizDownloadable(
        !this.selectedQuiz.download_enabled
      );
    },
    openPublishQuizDialog() {
      this.wannaPublishQuiz = true;
    },
    closePublishDialog() {
      this.wannaPublishQuiz = false;
    },
    async saveCredlyTemplates() {
      try {
        await this.updateQuiz({ ...this.selectedQuiz, credly_template_id: this.credly_template_id, credly_excellence_template_id: this.credly_excellence_template_id })
      } catch(e) {
        this.$logger(e);
      }
    }
  },
};
</script>

<style scoped>
.credly-card {
  border: 1px solid white !important;
  border-radius: 10px;
}
</style>
