import api from "@services/axiosInstance";
import publicApi from "@services/publicAxiosInstance";

function getBellMessages(){
  return publicApi.get(`/info-msg`);
}

function setBellMessages(){
    return api.post(`/settings/landing-links`);
  }

export default {
  getBellMessages,
  setBellMessages
}