import Author from "@/classes/author";
import Episode from "@/classes/episode";

class Course {
  constructor(
    title,
    description,
    id,
    ondemand,
    subscribed,
    resource,
    authors,
    episodes,
    scope,
    thumbnail_path,
    channel_id,
    timer_priority
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.onDemand = ondemand;
    this.image =
      "https://www.dpc-computer.com/wp-content/uploads/2019/03/Esempio-Server-1024x675.jpg";
    this.subscribed = subscribed;
    this.thumbnailPath = thumbnail_path;
    this.resource = resource;
    this.authors = [];
    this.episodes = [];
    this.scope = scope;
    this.channel_id = channel_id;
    this.timer_priority = timer_priority

    for (const author of authors) {
      this.authors.push(new Author(author));
    }

    for (const episode of episodes) {
      this.episodes.push(
        new Episode(
          episode.id,
          episode.Title, // Da chiedere title in camelCase
          episode.link,
          episode.numepisode,
          episode.description,
          episode.start,
          episode.end
        )
      );
    }

    this.episodes.sort((ep1, ep2) => ep1.numepisode - ep2.numepisode);
  }
}

export default Course;
