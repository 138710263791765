<template>
  <v-menu
    ref="date-menu"
    v-model="dateMenuOpen"
    :close-on-content-click="false"
    :return-value.sync="pickedDate"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="pickedDate"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        :rules="[rules.required]"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="pickedDate"
      no-title
      scrollable
      :min="today"
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="dateMenuOpen = false"
      >
        Annulla
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs['date-menu'].save(pickedDate)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    initialDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dateMenuOpen: false,
      pickedDate: null,
      rules: {
        required: (v) =>
          Boolean(v) || "Elemento obbligatorio",
      },
    };
  },

  computed: {
    today() {
      return new Date().toISOString().substring(0, 10);
    },
  },
  watch: {
    pickedDate() {
      this.updateDate();
    },
  },
  created() {
    this.pickedDate = this.initialDate;
  },
  methods: {
    updateDate() {
      this.$emit("onPickedDate", this.pickedDate);
    },
  },
};
</script>