var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[(_vm.isAdmin)?_c('AdminInfoSection',{attrs:{"course":_vm.course}}):_vm._e(),_c('h2',{staticClass:"courses-title"},[_vm._v(" Live ")]),_c('v-card',{staticClass:"mt-10 justify-center course-card"},[_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.typeOfUser(_vm.user.access_level) !== 'admin'),expression:"typeOfUser(user.access_level) !== 'admin'"}],staticClass:"ml-5",attrs:{"text":"","color":"transparent","to":`/${_vm.typeOfUser(_vm.user.access_level)}/courses`}},[_c('v-icon',{attrs:{"color":"#ffffff","left":"","size":"22"}},[_vm._v(" mdi-arrow-left ")]),_c('span',{staticClass:"back-to-courses"},[_vm._v(" visualizza lista corsi ")])],1)],1),(
        !(
          _vm.$vuetify.breakpoint.sm ||
          _vm.$vuetify.breakpoint.xs
        )
      )?_c('v-col',{staticClass:"px-0 pb-0"},[_c('p',{staticClass:"font-weight-bold mt-3"},[_vm._v(" Chat Live ")])]):_vm._e()],1),_c('v-row',{staticClass:"mx-5 mt-1",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('VideoPlayer',{attrs:{"episode":_vm.episode}}),_c('v-row',{staticClass:"py-0 my-0 mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"course-title d-flex justify-center justify-md-start"},[_vm._v(" "+_vm._s(_vm.course.title)+" - "+_vm._s(_vm.episode.title)+" ")])]),_c('v-col',{staticClass:"d-flex justify-center justify-md-start",attrs:{"cols":"12"}},[_c('Rating',{attrs:{"stars-only":true,"episode":_vm.episode,"course-id":_vm.course.id}})],1)],1),(
          (
            _vm.$vuetify.breakpoint.sm ||
            _vm.$vuetify.breakpoint.xs
          )
        )?_c('v-row',{staticClass:"mx-n8 mb-4",attrs:{"cols":"12","md":"4"}},[_c('v-col',[_c('LiveChat',{staticClass:"rounded-lg",attrs:{"id":"chat","disabled-text":_vm.featureLockedText,"course":_vm.course,"public-channel":true,"name":"Chat Live"}})],1)],1):_vm._e(),_c('v-row',{staticClass:"py-0 my-0 mx-n14 mx-md-n3"},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('EpisodeLinks',{attrs:{"course":_vm.course,"episode":_vm.episode}})],1)],1)],1),(
        !(
          _vm.$vuetify.breakpoint.sm ||
          _vm.$vuetify.breakpoint.xs
        )
      )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('LiveChat',{staticClass:"rounded-lg",attrs:{"id":"chat","disabled-text":_vm.featureLockedText,"course":_vm.course,"public-channel":true,"name":"Chat Live"}})],1):_vm._e()],1),_c('v-row',{staticClass:"py-0 my-0 mx-5"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('router-view',{key:_vm.$route.name,staticClass:"py-2"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"order-2 order-md-1",attrs:{"cols":"12","md":"3","xl":"2"}},[_c('span',{staticClass:"stepper-span",class:{
              'stepper-span-clicked': _vm.selectedTab === 0,
            },on:{"click":function($event){return _vm.tabSelector(0)}}},[_vm._v("Informazioni del corso")]),(_vm.selectedTab === 0)?_c('hr',{staticClass:"tab-divider-info"}):_vm._e()]),_c('v-col',{staticClass:"order-3 order-md-2 pl-md-3 mt-2 mt-md-0",attrs:{"cols":"12","md":"5","xl":"2"}},[_c('span',{staticClass:"stepper-span",class:{
              'stepper-span-clicked': _vm.selectedTab === 1,
            },on:{"click":function($event){return _vm.tabSelector(1)}}},[_vm._v("Domande anonime")]),(_vm.selectedTab === 1)?_c('hr',{staticClass:"tab-divider-questions"}):_vm._e()]),(_vm.course.resource && _vm.course.resource.length > 0)?_c('v-col',{staticClass:"order-1 order-md-3 d-flex justify-md-end mt-2 mt-md-0",attrs:{"cols":"12","md":"4","xl":"8","justify":"end","align-self":"end"}},[_c('v-btn',{staticClass:"resources-button rounded-lg",class:_vm.$vuetify.breakpoint.xsOnly ? 'mb-4' : '',attrs:{"width":_vm.$vuetify.breakpoint.xsOnly ? '100%' : '',"outlined":"","color":"#F39200","href":_vm.course.resource[0],"target":"_blank"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-layers-triple-outline ")]),_vm._v(" Materiali ")],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',[(_vm.selectedTab === 0)?_c('CourseInfoSection',{attrs:{"ondemand":false,"episode-description":_vm.episode.description,"course":_vm.course}}):_vm._e(),(_vm.selectedTab === 1)?_c('CourseCommentsSection',{staticClass:"py-2",attrs:{"ondemand":false,"episode-id":_vm.episode.id}}):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }