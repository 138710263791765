import api from "@services/eventAxiosInstance";

function getActivities() {
  return api.get(`/activities`);
}

function downloadActivities() {
  return api.get(`/activities/xlsx`, {
    responseType: "blob",
  });
}

function downloadEventSubscriptions() {
  return api.get(`/subscriptions/xlsx`, {
    responseType: "blob",
  });
}

function downloadNewsletters() {
  return api.get(`/newsletters/emails/xlsx`, {
    responseType: "blob",
  });
}

function createActivities(activitiesList) {
  return api.post(`/activities`, activitiesList);
}

function updateActivities(activitiesList) {
  return api.put(`/activities`, activitiesList);
}

function deleteActivities(activityId) {
  return api.delete(`/activities/${activityId}`);
}

function approveStudentParticipation(activityId, studentId) {
  return api.put(
    `/activities/subscriptions/approve/${activityId}/${studentId}`
  );
}

function rejectStudentParticipation(activityId, studentId) {
  return api.put(`/activities/subscriptions/reject/${activityId}/${studentId}`);
}

export default {
  getActivities,
  downloadEventSubscriptions,
  downloadActivities,
  downloadNewsletters,
  createActivities,
  updateActivities,
  deleteActivities,
  approveStudentParticipation,
  rejectStudentParticipation,
};
