<template>
  <v-card
    class="rounded-lg pa-5 white mt-3"
    color="transparent !important"
    flat
  >
    <v-form
      ref="form"
      v-model="isValid"
    >
      <v-col
        v-for="(box, boxIndex) in boxes"
        :key="boxIndex"
        class="pa-0"
        cols="12"
      >
        <v-row class="mt-3">
          <v-col
            v-for="(link, linkIndex) in links[box.type]"
            :key="link.id"
            cols="12"
            lg="3"
            :class="`${box.color}`"
          >
            <v-row class="mb-2">
              <h3 class="label ml-3">
                Label {{ linkIndex + 1 }}
              </h3>
              <v-spacer />
              <span class="label mr-3">{{ link.text.length }}/20</span>
            </v-row>
            <input
              v-model="link.text"
              class="input-field mb-6"
              maxlength="20"
              placeholder="Scrivi il titolo del link"
            >
            <v-row class="mb-2">
              <h3 class="label ml-3">
                Link {{ linkIndex + 1 }}
              </h3>
            </v-row>
            <input
              v-model="link.url"
              class="input-field"
              placeholder="Inserisci il link"
              :rules="[rules.url]"
            >
           
            <v-row class="mt-3">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="linkIndex === 0"
                    v-bind="attrs"
                    icon
                    dark
                    :color="box.color"
                    v-on="on"
                    @click="switchPosition(box.type, linkIndex - 1)"
                  >
                    <v-icon
                      v-if="$vuetify.breakpoint.mdAndUp"
                      color="#5A6972"
                    >
                      mdi-arrow-left
                    </v-icon>
                    <v-icon
                      v-else
                      color="#5A6972"
                    >
                      mdi-arrow-up
                    </v-icon>
                  </v-btn>
                </template>
                <span>Sposta a sinistra</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="linkIndex === links[box.type].length - 1"
                    v-bind="attrs"
                    dark
                    icon
                    :color="box.color"
                    v-on="on"
                    @click="switchPosition(box.type, linkIndex)"
                  >
                    <v-icon
                      v-if="$vuetify.breakpoint.mdAndUp"
                      color="#5A6972"
                    >
                      mdi-arrow-right
                    </v-icon>
                    <v-icon
                      v-else
                      color="#5A6972"
                    >
                      mdi-arrow-down
                    </v-icon>
                  </v-btn>
                </template>
                <span>Sposta a destra</span>
              </v-tooltip>
              <v-spacer />
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    icon
                    color="#F39200"
                    v-on="on"
                    @click="deleteLink(box.type, linkIndex)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Cancella il link</span>
              </v-tooltip>
            </v-row>
            <v-divider
              v-if="linkIndex < 4"
              dark
              class="mt-10"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="d-flex justify-start"
          >
            <v-btn
              dark
              strong
              :disabled="links[box.type].length > 7"
              color="#F39200"
              class="white--text font-weight-bold btn-size rounded-lg"
              :width="$vuetify.breakpoint.xsOnly ? '100%' : ''"
              @click="addLink(box.type)"
            >
              aggiungi link
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="d-flex justify-end"
          >
            <v-btn
              dark
              strong
              color="#F39200"
              :disabled="!linksModified || !isValid"
              :class="{ 'disable-icon': !linksModified || !isValid }"
              class="white--text font-weight-bold btn-size rounded-lg"
              :width="$vuetify.breakpoint.xsOnly ? '100%' : ''"
              @click="saveLinks"
            >
              <v-icon left>
                mdi-arrow-top-right
              </v-icon>
              pubblica
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-form>
    <v-row>
      <v-col
        cols="12"
        lg="10"
        class="d-flex justify-start"
      >
        <v-footer class="footer-text">
          I pulsanti verranno visualizzati in ordine in base al numero dopo la parola “Link” e “Label”.
        </v-footer>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import settingsServices from "@services/settings";
import store from "@store";
import { sendNotification, handleAxiosError } from "@utils/notifications";
import isEqual from "lodash/isEqual";

export default {
  name: "MoreOffers",
  data: () => ({
    boxes: [
      {
        name: "LINKS",
        type: "links",
        enabled: true,
        color: "transparent",
      },
    ],
    retrievedLinks: { links: [] },
    links: { links: [] },
    rules: {
      required: (v) => Boolean(v) || "Valore richiesto",
      url: (v) =>
        !v ||
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
          v
        ) ||
        `URL non valido`,
    },
    isValid: false,
  }),
  computed: {
    linksModified() {
      return !isEqual(this.retrievedLinks, this.formattedLinks);
    },
    formattedLinks() {
      return this.links.links.map((link) => ({
        text: link.text,
        url: link.url,
      }));
    },
  },
  created() {
    this.retrieveLinks();
  },
  methods: {
    addLink(boxType) {
      this.links[boxType].push({ url: null, text: "", id: Math.random() });
    },
    async saveLinks() {
      store.commit("loading/startLoading");
      try {
        await settingsServices.setLandingLinks({ links: this.formattedLinks });
        this.retrieveLinks();
        sendNotification(`Link salvati con successo`, "success");
      } catch (err) {
        handleAxiosError(`Errore durante il salvataggio dei link`, err);
      }
      store.commit("loading/stopLoading");
    },
    deleteLink(boxType, linkIndex) {
      this.links[boxType].splice(linkIndex, 1);
    },
    retrieveLinks() {
      store.commit("loading/startLoading");
      settingsServices
        .getLandingLinks()
        .then((response) => {
          this.retrievedLinks = response.data;
          this.links.links = this.retrievedLinks.map((link) => ({
            ...link,
            id: Math.random(),
          }));
          store.commit("loading/stopLoading");
        })
        .catch((err) => {
          handleAxiosError(`Errore durante il caricamento dei link`, err);
          store.commit("loading/stopLoading");
        });
    },
    switchPosition(boxType, index) {
      const items = [
        this.links[boxType][index],
        this.links[boxType][index + 1],
      ];
      this.links[boxType].splice(index, 2, items[1], items[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.btn-size {
  min-width: 80%;
}

@media only screen and (min-width: 900px) and (max-width: 1260px) {
  .container {
    max-width: 99vw;
  }
}

.footer-text {
  background-color: transparent !important;
  font-family: 'Karasuma Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #98A6AE;
  padding-left: 0;
}

.label {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #C6CED2;
}

.input-field{
  padding: 12px 16px;
  border: 1px solid #E8EBED;
  border-radius: 12px;
  font-family: 'Karasuma Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #5a6972;
  width: 100%;
}

.input-field:focus{
  border: 1px solid #f39200;
}

.input-field::placeholder{
  color: #5a6972;
}
</style>
