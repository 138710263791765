<template>
  <v-main id="container">
    <v-container>
      <h2 class="wrapper-title px-5 px-md-0">Gestione Test</h2>
      <NormalQuizSection />
    </v-container>
  </v-main>
</template>

<script>
import NormalQuizSection from "../../components/admin/quiz/ui/NormalQuizSection.vue";
export default {
  name: "QuizManagement",
  components: { NormalQuizSection },
};
</script>

<style scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
  min-height: 90vh;
}

.title-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.subtitle-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.more-offers-panel {
  background: #1d1c4c !important;
  backdrop-filter: blur(60px);
  border-radius: 12px !important;
  font-family: "Karasuma Gothic" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #f4f5f6 !important;
  margin-top: 0px !important;
  padding: 5px !important;
  letter-spacing: 0.75px !important;
}
</style>
