import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/get_quiz
 */
function getQuizzes() {
  return api.get("/quiz");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/get_quiz__id_
 */
function getQuiz(quizId) {
  return api.get(`/quiz/${quizId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/post_quiz
 */
function createQuiz({ description, title }) {
  return api.post("/quiz", {
    description,
    title,
  });
}
/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/post_quiz
 */
function updateQuiz({
  description,
  published,
  title,
  id,
  download_enabled,
  credly_template_id,
  credly_excellence_template_id,
}) {
  return api.put("/quiz", {
    description,
    title,
    quiz_id: id,
    published,
    download_enabled: Boolean(download_enabled),
    credly_template_id,
    credly_excellence_template_id,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/Quiz/put_quiz_order_number
 */
function updateQuizdOrder(id, orderNumber) {
  return api.put("/quiz/order-number", {
    quiz_id: id,
    order_num: orderNumber,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/delete_quiz__id_
 */
function deleteQuiz(quizId) {
  return api.delete(`/quiz/${quizId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/post_quiz_question
 */
function createQuizQuestion(
  quizId,
  text,
  points,
  shuffleAnswers,
  type,
  answers
) {
  return api.post("/quiz/question", {
    quiz_id: quizId,
    text,
    points,
    shuffle_answers: shuffleAnswers,
    type,
    answers,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/put_quiz_question
 */
function updateQuizQuestion(
  quizId,
  questionId,
  text,
  points,
  shuffleAnswers,
  type,
  answers
) {
  return api.put("/quiz/question", {
    quiz_id: quizId,
    id: questionId,
    text,
    points,
    shuffle_answers: shuffleAnswers,
    type,
    answers,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/delete_quiz_question__id_
 */
function deleteQuizQuestion(questionId) {
  return api.delete(`/quiz/question/${questionId}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/Quiz/post_quiz_session
 */
function createSession(begin_date, end_date, quiz_id) {
  return api.post("/quiz/session", {
    begin_date,
    end_date,
    quiz_id,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/Quiz/post_quiz_session
 */
function updateSession(begin_date, end_date, session_id) {
  return api.put("/quiz/session", {
    begin_date,
    end_date,
    id: session_id,
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/Quiz/delete_quiz_session__id_
 */
function deleteSession(id) {
  return api.delete(`/quiz/session/${id}`);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/Quiz/get_quiz_session
 */
function getAllSessions() {
  return api.get("/quiz/session");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/Quiz/post_quiz_structure
 */
function createUpdateStructure(structure) {
  return api.post("/quiz/structure", structure);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/index.html#/Quiz/get_quiz_subscriptions_report__id_
 */
function getQuizSubscriptions(quizId) {
  return api.get(`/quiz/subscriptions-report/${quizId}`, {
    responseType: "blob",
  });
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/swagger/index.html#/Quiz/get_quiz_subscriptions_report
 */
function getAllQuizzesSubscriptions() {
  return api.get("/quiz/subscriptions-report", {
    responseType: "blob",
  });
}
/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Quiz/get_quiz_classrooms
 */
function getClassroomsByQuiz(quizId) {
  return api.get(`/quiz/classrooms?quiz_id=${quizId}`);
}

export default {
  getQuizzes,
  getQuiz,
  createQuiz,
  updateQuiz,
  deleteQuiz,
  createQuizQuestion,
  updateQuizQuestion,
  deleteQuizQuestion,
  createSession,
  createUpdateStructure,
  updateSession,
  deleteSession,
  getAllSessions,
  getQuizSubscriptions,
  getClassroomsByQuiz,
  getAllQuizzesSubscriptions,
  updateQuizdOrder,
};
