<template>
  <div class="pt-5">
    <v-navigation-drawer
      color="#01002e"
      permanent
      :width="$vuetify.breakpoint.mdAndDown ? '75%' : '100%'"
      :absolute="$vuetify.breakpoint.mdAndDown"
      :temporary="$vuetify.breakpoint.mdAndDown"
    >
      <v-list>
        <v-list-item class="px-2">
          <span class="navigation-title ml-2">gestione</span>
          <v-spacer />
          <v-btn
            v-if="$vuetify.breakpoint.mdAndDown"
            icon
            color="#f4f5f6"
            @click="closeDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item>

        <v-list nav dense class="px-0">
          <v-list-item v-for="(item, index) in items" :key="index" class="pl-0">
            <v-btn
              class="list-admin-navigation no-background-button"
              active-class="clicked"
              text
              :to="{ name: `${item.component}` }"
              @click="activeNewIcon(item)"
            >
              <v-img
                class="icon-button mr-1"
                :class="{ 'disable-icon': isDisabled(item) }"
                :src="require(`@/assets/admin/menu/${item.image}`)"
              />
              <span> {{ item.name }} </span>
            </v-btn>
          </v-list-item>

          <v-divider
            v-if="$vuetify.breakpoint.mdAndDown"
            class="my-5 divider-color"
          />

          <v-list-item v-if="$vuetify.breakpoint.mdAndDown" class="pl-0">
            <v-btn
              class="list-admin-navigation no-background-button"
              active-class="clicked"
              text
              @click="quit"
            >
              <v-icon color="white" class="px-1"> mdi-logout </v-icon>
              <span class="px-1"> LOGOUT </span>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { pushAuthUrl } from "@/utils/externalRouter";

export default {
  name: "AdminNavigation",
  emits: ["onCloseDrawer"],
  data: () => ({
    drawer: "",
    selectedButton: "LANDING PAGE",
    items: [
      {
        name: "LANDING PAGE",
        image: "layout.svg",
        component: "admin-link-management",
      },
      {
        name: "CORSI",
        image: "monitor.svg",
        component: "admin-courses",
      },
      {
        name: "COMUNICAZIONI",
        image: "volume.svg",
        component: "admin-message-management",
      },
      {
        name: "UTENTI, CLASSI, SCUOLE",
        image: "user.svg",
        component: "admin-users",
      },
      {
        name: "HACKERSGEN EVENT",
        image: "user.svg",
        component: "admin-event",
      },
      {
        name: "CHAT",
        image: "volume.svg",
        secondImage: "active_volume.svg",
        component: "admin-chat",
      },
      {
        name: "GESTIONE TEST",
        image: "layers.svg",
        component: "admin-quiz-management",
      },
      {
        name: "REPORT",
        image: "trending_up.svg",
        component: "admin-report",
      },
    ],
  }),
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    selectedButton() {
      this.closeDrawer();
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    activeNewIcon(item) {
      this.selectedButton = item.name;
    },
    isDisabled(item) {
      return this.selectedButton !== item.name;
    },
    closeDrawer() {
      this.$emit("onCloseDrawer");
    },
    async quit() {
      try {
        await this.logout();
        pushAuthUrl();
      } catch (err) {
        console.info("Router error");
      }
    },
  },
};
</script>

<style scoped>
.navigation-title {
  color: #5a6972;
  font-family: Karasuma Gothic;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: left;
  text-transform: uppercase;
}

.list-admin-navigation {
  color: #5a6972;
  font-family: Karasuma Gothic;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: left;
  text-transform: none;
}

.clicked {
  background-color: transparent important;
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #f4f5f6;
  text-transform: none;
}

.disable-icon {
  filter: saturate(0);
  opacity: 0.8;
}

.icon-button {
  max-width: 30px;
}

.divider-color {
  background-color: #01004d;
}
</style>
