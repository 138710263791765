class School {
  constructor(
    address,
    city,
    code,
    name,
    province,
    region,
    category,
    type,
    zip_code,
    verified
  ) {
    this.address = address;
    this.city = city;
    this.code = code;
    this.name = name;
    this.province = province;
    this.region = region;
    this.category = category;
    this.type = type;
    this.zip_code = zip_code;
    this.verified = verified;
  }
}

export default School;
