import learningResourcesService from "@services/learningResources";
import {
  handleAxiosError,
  sendNotification,
} from "@utils/notifications";

const actions = {
  async createOndemandQuiz(
    { commit },
    { onDemandQuizInfo }
  ) {
    const {
      description,
      duration,
      learningPathId,
      sectionName,
      title,
    } = onDemandQuizInfo;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.createOndemandQuiz(
        description,
        duration,
        learningPathId,
        sectionName,
        title
      );
      sendNotification(
        "Quiz creato con successo!",
        "success"
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nella creazione del corso",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async updateOndemandQuiz(
    { commit },
    { onDemandQuizInfo }
  ) {
    const {
      description,
      duration,
      learningPathId,
      sectionName,
      title,
    } = onDemandQuizInfo;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.updateOndemandQuiz(
        description,
        duration,
        learningPathId,
        sectionName,
        title
      );
      sendNotification(
        "Quiz modificato con successo!",
        "success"
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nella modifica del corso",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async createOndemandQuizQuestion(
    { commit },
    { onDemandQuizQuestion }
  ) {
    const {
      answers,
      learningPathQuizId,
      shuffleAnswers,
      text,
    } = onDemandQuizQuestion;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.createOndemandQuizQuestion(
        answers,
        learningPathQuizId,
        shuffleAnswers,
        text
      );
      sendNotification(
        "Domanda aggiunta con successo!",
        "success"
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nell'aggiunta della domanda",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async updateOndemandQuizQuestion(
    { commit },
    { onDemandQuizQuestion }
  ) {
    const { answers, id, shuffleAnswers, text } =
      onDemandQuizQuestion;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.updateOndemandQuizQuestion(
        answers,
        id,
        shuffleAnswers,
        text
      );
      sendNotification(
        "Domanda aggiornata con successo!",
        "success"
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nell'aggiornamento della domanda",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteOndemandQuizQuestion({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.deleteOndemandQuizQuestion(
        id
      );
      sendNotification(
        "Domanda cancellata con successo!",
        "success"
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nella cancellazione della domanda",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandQuizzesById({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } =
        await learningResourcesService.getOndemandQuizzesById(
          id
        );
      if (!Object.hasOwn(data, "questions")) {
        data.questions = [];
      }
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero dei quiz ondemand",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async changeResourceOrder(
    { commit },
    { resourceId, newPosition }
  ) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.changeResourceOrder(
        resourceId,
        newPosition
      );
      sendNotification(
        "Ordine aggiornato con successo!",
        "success"
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nel cambiamento dell'ordine",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandQuizzes({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } =
        await learningResourcesService.getOndemandQuizzes();
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero dei quiz ondemand",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteOndemandResource({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.deleteOndemandResource(
        id
      );
      sendNotification(
        "Risorsa cancellata con successo!",
        "success"
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nella cancellazione della risorsa",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async createOndemandVideo({ commit }, { ondemandVideo }) {
    const {
      duration,
      learningPathId,
      sectionName,
      title,
      videoUri,
    } = ondemandVideo;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.createOndemandVideo(
        duration,
        learningPathId,
        sectionName,
        title,
        videoUri
      );
      sendNotification(
        "Video aggiunto con successo!",
        "success"
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nell'aggiunta del video",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandVideo({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } =
        await learningResourcesService.getOndemandVideo(id);
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero del video ondemand",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async updateOndemandVideo({ commit }, { ondemandVideo }) {
    const {
      duration,
      learningPathId,
      sectionName,
      title,
      videoUri,
    } = ondemandVideo;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.updateOndemandVideo(
        duration,
        learningPathId,
        sectionName,
        title,
        videoUri
      );
      sendNotification(
        "Video modificato con successo!",
        "success"
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nella modifica del video",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async createOndemandExercise(
    { commit },
    { ondemandExercise }
  ) {
    const {
      hint,
      learningPathId,
      sectionName,
      title,
      text,
    } = ondemandExercise;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.createOndemandExercise(
        hint,
        learningPathId,
        sectionName,
        text,
        title
      );
      sendNotification(
        "Esercizio aggiunto con successo!",
        "success"
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nell'aggiunta dell'esercizio",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandExerciseDetails({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } =
        await learningResourcesService.getOndemandExerciseDetails(
          id
        );
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero dell'esercizio ondemand",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandExerciseHint({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } =
        await learningResourcesService.getOndemandExerciseHint(
          id
        );
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero del suggerimento dell'esercizio",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async updateOndemandExercise(
    { commit },
    { ondemandExercise }
  ) {
    const {
      hint,
      learningPathId,
      sectionName,
      title,
      text,
    } = ondemandExercise;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.updateOndemandExercise(
        hint,
        learningPathId,
        sectionName,
        text,
        title
      );
      sendNotification(
        "Esercizio modificato con successo!",
        "success"
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nella modifica dell'esercizio",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getOndemandComments({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } =
        await learningResourcesService.getOndemandComments(
          id
        );
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero dei commenti",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async postNewQuestion({ commit }, { newQuestion }) {
    const { learningPathId, text } = newQuestion;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.postNewQuestion(
        learningPathId,
        text
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nella pubblicazione della domanda",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async postNewComment({ commit }, { newComment }) {
    const { learningPathId, text, mainQuestion } =
      newComment;
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.postNewComment(
        learningPathId,
        text,
        mainQuestion
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nella pubblicazione del commento",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async getUnapprovedOndemandQuestions({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } =
        await learningResourcesService.getUnapprovedOndemandQuestions();
      return data;
    } catch (err) {
      await handleAxiosError(
        "Errore nel recupero delle domande anonime dei corsi ondemand",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async approveOndemandQuestion({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.approveOndemandQuestion(
        id
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nell'approvazione della domanda anonima",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteOndemandComment({ commit }, { id }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await learningResourcesService.deleteOndemandComment(
        id
      );
    } catch (err) {
      await handleAxiosError(
        "Errore nella cancellazione della domanda",
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
};

export default {
  namespaced: true,
  actions,
};
