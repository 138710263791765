<template>
  <v-main id="container">
    <v-container>
      <v-card class="pa-5 mt-3" color="transparent" dark flat>
        <v-row>
          <v-col>
            <v-card-title align="left">
              <v-col cols="12" md="6" class="px-0">
                <v-btn
                  color="info darken-2"
                  dark
                  large
                  class="btn-font"
                  @click="openModal()"
                  :disabled="loadingSchools"
                >
                  <v-icon class="mr-1"> mdi-plus </v-icon>
                  Crea Scuola
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" class="px-0">
                <v-text-field
                  v-model="searchSchools"
                  append-icon="mdi-magnify"
                  label="Cerca..."
                  single-line
                  hide-details
                />
              </v-col>
            </v-card-title>
            <v-data-table
              :headers="schoolsHeaders"
              :items="allSchools"
              :search="searchSchools"
              item-key="code"
              sort-by="name"
              class="data-table"
              :loading="loadingSchools"
              loading-text="Caricamento scuole..."
            >
              <template #[`item.city`]="{ item }">
                {{ item.city }} ({{ getProvinceAbbr(item.province) }})
              </template>

              <template #[`item.verified`]="{ item }">
                <v-icon v-if="item.verified">mdi-check-bold</v-icon>
                <v-icon v-else>mdi-close-thick</v-icon>
              </template>

              <template #[`item.updateSchool`]="{ item }">
                <v-icon @click.stop="openModal(item)"> mdi-pencil </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <Modal
        v-if="showNewSchoolModal"
        :title="isExistingSchool ? 'Modifica scuola' : 'Aggiungi nuova scuola'"
        @closeModal="closeModal"
      >
        <template #content-center>
          <v-row>
            <v-col cols="12">
              <v-card-text class="modal-text">
                <p>
                  Inserisci <strong>tutti</strong> i dati prima di proseguire:
                  <u>la scuola non sarà eliminabile.</u>
                </p>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="newSchool.name"
                label="Nome"
                hint="Il nome della scuola"
                outlined
                rounded
                tabindex="1"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" align="center">
              <v-autocomplete
                v-model="newSchool.region"
                label="Regione"
                hint="La regione dove si trova la scuola"
                :items="regions"
                outlined
                rounded
                tabindex="2"
              />
            </v-col>
            <v-col cols="4" align="center">
              <v-autocomplete
                :disabled="!newSchool.region"
                v-model="newSchool.province"
                label="Provincia"
                hint="La provincia dove si trova la scuola"
                :items="filteredProvinces"
                item-text="label"
                item-value="value"
                outlined
                rounded
                tabindex="3"
              />
            </v-col>
            <v-col cols="4" align="center">
              <v-autocomplete
                :disabled="!newSchool.region || !newSchool.province"
                v-model="newSchool.city"
                label="Città"
                hint="La città dove si trova la scuola"
                :items="filteredCities"
                outlined
                rounded
                tabindex="4"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" align="center">
              <v-text-field
                v-model="newSchool.address"
                label="Indirizzo"
                hint="L'indirizzo della scuola (via e numero civico)"
                outlined
                rounded
                tabindex="5"
              />
            </v-col>
            <v-col cols="4" align="center">
              <v-text-field
                v-model="newSchool.zip_code"
                label="CAP"
                hint="Il Codice di Avviamento Postale della scuola"
                outlined
                rounded
                tabindex="6"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">
              <v-autocomplete
                v-model="newSchool.category"
                label="Categoria"
                hint="La categoria della scuola (ateneo, scuola, altro)"
                :items="schoolCategories"
                outlined
                rounded
                tabindex="7"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">
              <v-autocomplete
                v-model="newSchool.type"
                label="Tipologia"
                hint="La tipologia della scuola"
                :items="schoolTypes"
                outlined
                rounded
                tabindex="8"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="newSchool.code"
                :disabled="isExistingSchool"
                label="Codice"
                hint="Il codice meccanografico della scuola"
                outlined
                rounded
                tabindex="9"
              />
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-row justify="end">
              <v-btn large plain class="white--text" @click="closeModal">
                <v-icon>mdi-close</v-icon>
                ANNULLA
              </v-btn>
              <v-btn
                color="green darken-2"
                class="white--text ml-3"
                large
                rounded
                :disabled="!isDataComplete"
                @click="
                  isExistingSchool ? updateExistingSchool() : saveSchool()
                "
              >
                <v-icon class="mr-1"> mdi-check </v-icon>
                <span v-if="isExistingSchool">MODIFICA</span>
                <span v-else>AGGIUNGI</span>
              </v-btn>
            </v-row>
          </v-card-actions>
        </template>
      </Modal>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "@store";
import { sendNotification } from "@/utils/notifications";
import Modal from "@cmp/utils/Modal";
import { regions } from "@data/regions.js";
import { provinces } from "@data/provinces.js";
import { cities } from "@data/cities.js";

export default {
  name: "SchoolsManagement",
  components: { Modal },
  data() {
    return {
      showNewSchoolModal: false,
      classroomToDelete: false,
      searchSchools: "",
      loadingSchools: false,
      confirmationString: "",
      isExistingSchool: false,
      newSchool: {
        address: "",
        city: "",
        code: "",
        name: "",
        province: "",
        region: "",
        type: "",
        zip_code: "",
        category: "",
      },
      schoolsHeaders: [
        {
          text: "Nome",
          align: "start",
          filterable: true,
          value: "name",
        },
        { text: "Indirizzo", filterable: true, value: "address" },
        { text: "Città", filterable: true, value: "city" },
        { text: "CAP", filterable: true, value: "zip_code" },
        { text: "Regione", filterable: true, value: "region" },
        {
          text: "Codice",
          filterable: true,
          value: "code",
        },
        {
          text: "Categoria",
          filterable: true,
          value: "category",
        },
        {
          text: "Tipo",
          filterable: true,
          value: "type",
        },
        {
          text: "Validata",
          filterable: true,
          value: "verified",
        },
        {
          text: "Modifica",
          filterable: false,
          value: "updateSchool",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("admin", ["allSchools"]),
    schoolTypes() {
      return [
        "Altro",
        "Università",
        ...new Set(
          this.allSchools.map((school) => school.type.trim().toUpperCase())
        ),
      ];
    },
    schoolCategories() {
      return ["ateneo", "scuola", "altro"];
    },
    regions() {
      return regions.map((region) => region.toUpperCase());
    },
    filteredProvinces() {
      return provinces
        .filter(
          (province) =>
            province.region.toUpperCase() == this.newSchool.region.toUpperCase()
        )
        .map((province) => province.name.toUpperCase());
    },
    filteredCities() {
      return cities
        .filter(
          (city) =>
            city.province.toUpperCase() == this.newSchool.province.toUpperCase()
        )
        .map((city) => city.name.toUpperCase());
    },
    isDataComplete() {
      return (
        this.newSchool.address &&
        this.newSchool.city &&
        this.newSchool.code &&
        this.newSchool.name &&
        this.newSchool.province &&
        this.newSchool.region &&
        this.newSchool.category &&
        this.newSchool.type &&
        this.newSchool.zip_code
      );
    },
  },
  async created() {
    this.loadingSchools = true;
    await this.getAllSchools();
    this.loadingSchools = false;
  },
  methods: {
    ...mapActions("admin", ["getAllSchools", "addSchool", "updateSchool"]),
    openModal(existingSchool) {
      if (existingSchool) {
        this.isExistingSchool = true;
        this.newSchool = existingSchool;
      }
      this.showNewSchoolModal = true;
    },
    closeModal() {
      this.isExistingSchool = false;
      this.showNewSchoolModal = false;
      this.resetNewSchool();
    },
    async saveSchool() {
      store.commit("loading/startLoading");
      try {
        await this.addSchool({
          address: this.newSchool.address.trim().toUpperCase(),
          city: this.newSchool.city,
          code: this.newSchool.code.trim(),
          name: this.newSchool.name.trim(),
          province: this.newSchool.province,
          region: this.newSchool.region,
          category: this.newSchool.category,
          type: this.newSchool.type,
          zip_code: this.newSchool.zip_code.trim(),
        });
        this.closeModal();
        sendNotification(`Scuola aggiunta con successo!`, "success");
      } catch (err) {
        /* managed by error handler */
      } finally {
        store.commit("loading/stopLoading");
      }
    },
    async updateExistingSchool() {
      store.commit("loading/startLoading");
      try {
        await this.updateSchool({
          address: this.newSchool.address.trim().toUpperCase(),
          city: this.newSchool.city,
          code: this.newSchool.code,
          name: this.newSchool.name.trim(),
          province: this.newSchool.province,
          region: this.newSchool.region,
          category: this.newSchool.category,
          type: this.newSchool.type,
          zip_code: this.newSchool.zip_code.trim(),
        });
        this.closeModal();
        sendNotification(`Scuola modificata con successo!`, "success");
      } catch (err) {
      } finally {
        store.commit("loading/stopLoading");
      }
    },
    getProvinceAbbr(name) {
      try {
        return provinces.find(
          (province) => province.name.toUpperCase() === name.toUpperCase()
        ).abbr;
      } catch {
        return name.toUpperCase();
      }
    },
    resetNewSchool() {
      this.newSchool = {
        address: "",
        city: "",
        code: "",
        name: "",
        province: "",
        region: "",
        category: "",
        type: "",
        zip_code: "",
      };
    },
  },
};
</script>

<style scoped>
#container {
  background-color: #1d1c4c !important;
  background-repeat: repeat;
}

.subtitle {
  font-size: 15px;
}

.btn-font {
  font-family: "Karasuma Gothic", sans-serif;
}

.modal-text {
  font-size: 1.4rem;
}

.data-table {
  background-color: transparent;
  font-weight: 500;
}
</style>
