<template>
  <v-main>
    <h2 class="wrapper-title px-5 px-md-0">Gestione Test</h2>
    <v-btn
      dark
      class="header-btn mt-4 pl-5 pl-md-0"
      color="transparent"
      @click="goBack"
    >
      <v-icon class="mr-5" left> mdi-arrow-left </v-icon>
      TORNA INDIETRO
    </v-btn>
    <v-card
      class="rounded-lg px-2 mt-6 mx-5 mx-md-0 mr-md-16"
      flat
      dark
      color="#1d1c4c"
    >
      <v-card-title class="card-title">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="currentStep" flat dark class="stepper mb-5">
          <QuizHeader :current-step="currentStep" :is-editing="isEditing" />
        </v-stepper>

        <QuizBasicInfo v-if="currentStep === 1" @onNextStep="nextStep" />
        <QuizQuestions v-if="currentStep === 2" />
        <QuizStructure v-if="currentStep === 3" />
        <QuizSessions v-if="currentStep === 4" />
      </v-card-text>
    </v-card>
  </v-main>
</template>

<script>
import QuizHeader from "@cmp-admin/quiz/QuizHeader.vue";
import QuizBasicInfo from "@cmp-admin/quiz/steps/QuizBasicInfo.vue";
import QuizQuestions from "@cmp-admin/quiz/steps/QuizQuestions.vue";

import store from "@store";
import { mapGetters } from "vuex";
import QuizSessions from "../../components/admin/quiz/steps/QuizSessions.vue";
import QuizStructure from "../../components/admin/quiz/steps/QuizStructure.vue";

export default {
  name: "QuizForm",
  components: {
    QuizHeader,
    QuizBasicInfo,
    QuizQuestions,
    QuizSessions,
    QuizStructure,
  },
  data() {
    return {
      currentStep: 1,
      quizTitle: "",
    };
  },
  computed: {
    ...mapGetters("quiz", ["selectedQuiz"]),
    isEditing() {
      return Boolean(this.$route.params.id);
    },
    title() {
      if (this.currentStep === 1) {
        return this.isEditing ? "Modifica Test" : "Crea Test";
      } else if (this.currentStep === 2) {
        return `Creazione domande del test: ${this.quizTitle}`;
      }
      return `Riepilogo: ${this.quizTitle}`;
    },
  },
  created() {
    this.quizTitle = { ...this.selectedQuiz }.title;
    if (this.$route.params.step > 1) {
      this.currentStep = Number(this.$route.params.step);
    }
  },
  methods: {
    async nextStep() {
      this.currentStep++;
      this.$logger("route:", this.$route);
      if (this.isEditing) {
        await store.dispatch("quiz/getQuizById", {
          quizId: this.$route.params.id,
        });
      }
    },
    previousStep() {
      this.currentStep--;
    },

    goBack() {
      this.$router.push({ name: "admin-quiz-management" });
    },
  },
  beforeRouteEnter: async (to, from, next) => {
    const quizId = to.params.id;
    await store.dispatch("quiz/getAllSessions");
    if (quizId) {
      await store.dispatch("quiz/getQuizById", {
        quizId,
      });
    } else {
      await store.commit("quiz/RESET_QUIZ");
    }
    next();
  },
};
</script>

<style scoped>
.container {
  max-width: 1185px;
}

.stepper {
  background-color: #1d1c4c;
}

.header-btn,
.card-title {
  font-family: "Karasuma Gothic";
  color: #ffffff;
}

.header-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  border-radius: 12px;
}

.card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 27.5px;
}
</style>

<style>
.rounded-btn {
  font-family: "Karasuma Gothic";
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  border-radius: 12px;
}
</style>
