<template>
  <v-card class="mt-3 pa-2 card-container rounded-lg" dark>
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="12">
          <h2 class="font-weight-light">REPORT DEI LOG DI DEBUG</h2>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <span class="text-subtitle-1"
            >Inserisci il contesto per cui desideri scaricare i log</span
          >
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-select v-model="selectedPackage" :items="packages"></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <span class="text-subtitle-1"
            >Inserisci la data per scaricare il report relativi al giorno
            selezionato</span
          >
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-menu
            ref="date-menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :return-value.sync="pickedDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="pickedDate"
                label="Data (aaaa-mm-gg)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                :rules="[rules.required]"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="pickedDate"
              no-title
              scrollable
              :max="today"
            >
              <v-spacer />
              <v-btn text color="primary" @click="dateMenu = false">
                Annulla
              </v-btn>
              <v-btn text color="primary" @click="downloadLogs"> OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LogsDownloadCard",
  data: () => ({
    dateMenu: false,
    pickedDate: "",
    packages: ["Main", "Lives", "Job", "Tracker"],
    selectedPackage: "Main",
    rules: {
      required: (v) => Boolean(v) || "Elemento obbligatorio",
    },
  }),
  computed: {
    today() {
      return new Date().toISOString().substring(0, 10);
    },
  },
  methods: {
    ...mapActions("admin", ["downloadDebugLogs"]),
    async downloadLogs() {
      try {
        await this.downloadDebugLogs({
          date: this.pickedDate,
          pack: this.selectedPackage.toLowerCase(),
        });
      } finally {
        this.dateMenu = false;
      }
    },
  },
};
</script>

<style scoped>
.card-container {
  background: #1d1c4c;
}
</style>
