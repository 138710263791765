const initialState = () => ({
  backendUrl: "",
  backendEventUrl: "",
  rocketchatWsUrl: "",
  rocketChatUrl: "",
  downloadUrl: "",
  keycloak: {
    realm: "",
    clientId: "",
    url: "",
  },
  is_download_innovation_season: false,
  rollbar_token: "",
  adminUrl: "",
  eventUrl: "",
  landingUrl: "",
  platformUrl: "",
  projectsUrl: "",
  authUrl: "",
  cookieDomain: "",
  publicUrl: "",
  livesUrl: "",
});

const state = initialState();

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  setData(state, data) {
    state.backendUrl = data.VUE_APP_BE;
    state.backendEventUrl = data.VUE_APP_BE_EVENT;
    state.rocketchatWsUrl = data.CHAT_WS_URL;
    state.rocketChatUrl = data.VUE_APP_CHAT_URL;
    state.downloadUrl = data.DW_LOGIN_URL;
    state.keycloak.realm = data.KEYCLOAK.REALM;
    state.keycloak.clientId = data.KEYCLOAK.CLIENT_ID;
    state.keycloak.url = data.KEYCLOAK.URL;
    state.is_download_innovation_season = data.IS_DOWNLOAD_INNOVATION_SEASON;
    state.rollbar_token = data.VUE_APP_ROLLBAR_TOKEN;
    state.adminUrl = data.ADMIN_URL;
    state.eventUrl = data.EVENT_URL;
    state.landingUrl = data.LANDING_URL;
    state.platformUrl = data.PLATFORM_URL;
    state.projectsUrl = data.PROJECTS_URL;
    state.authUrl = data.AUTH_URL;
    state.cookieDomain = data.COOKIE_DOMAIN;
    state.publicUrl = data.VUE_APP_BE_PUBLIC;
    state.livesUrl = data.VUE_APP_BE_LIVES;
  },
};

const actions = {
  async loadData({ commit }) {
    try {
      const fullUrl = window.location;
      const redirectUrl = `${fullUrl.protocol}//${fullUrl.host}/config.json`;
      const resp = await fetch(redirectUrl);
      const data = await resp.json();
      commit("setData", data);
    } catch (e) {
      console.log(e);
    }
  },
};

const getters = {
  backendUrl: (state) => state.backendUrl,
  backendEventUrl: (state) => state.backendEventUrl,
  rocketchatWsUrl: (state) => state.rocketchatWsUrl,
  rocketChatUrl: (state) => state.rocketChatUrl,
  downloadUrl: (state) => state.downloadUrl,
  keycloakRealm: (state) => state.keycloak.realm,
  keycloakClientID: (state) => state.keycloak.clientId,
  keycloakUrl: (state) => state.keycloak.url,
  isDownloadSeason: (state) => state.is_download_innovation_season,
  rollbarToken: (state) => state.rollbar_token,
  adminUrl: (state) => state.adminUrl,
  eventUrl: (state) => state.eventUrl,
  landingUrl: (state) => state.landingUrl,
  platformUrl: (state) => state.platformUrl,
  projectsUrl: (state) => state.projectsUrl,
  authUrl: (state) => state.authUrl,
  cookieDomain: (state) => state.cookieDomain,
  publicUrl: (state) => state.publicUrl,
  livesUrl: (state) => state.livesUrl,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
