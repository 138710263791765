<template>
  <v-main id="container">
    <v-container>
      <h2 class="wrapper-title pl-5">HackersGen Event</h2>
      <v-card
        id="link-mgmt-container"
        class="rounded-lg pa-5 mt-3"
        color="transparent"
        flat
      >
        <v-expansion-panels class="mt-5" dark flat>
          <v-expansion-panel
            v-for="(item, index) in items"
            :key="index"
            class="more-offers-panel mb-5"
          >
            <v-expansion-panel-header>
              <v-row>
                <v-col cols="12">
                  <h2 class="title-text">
                    {{ item.name }}
                  </h2>
                </v-col>
                <v-col cols="12">
                  <h3 class="subtitle-text">
                    {{ item.description }}
                  </h3>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <EventManagement v-if="item.name === 'Gestione Attività'" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import EventManagement from "../../components/admin/EventManagement.vue";
import store from "@store";

export default {
  name: "LinkManagement",
  components: {
    EventManagement,
  },
  data: () => ({
    items: [
      {
        name: "Gestione Attività",
        description: `Area dedicata alla gestione delle attività svolte durante l'HackersGen Event.`,
      },
    ],
  }),
};
</script>

<style scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.btn-size {
  min-width: 80%;
}

.title-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.subtitle-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff !important;
}

.more-offers-panel {
  background: #1d1c4c !important;
  backdrop-filter: blur(60px);
  border-radius: 12px !important;
  font-family: "Karasuma Gothic" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #f4f5f6 !important;
  margin-top: 0px !important;
  padding: 5px !important;
  letter-spacing: 0.75px !important;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
