<template>
  <v-container class="px-0">
    <v-col class="pa-0">
      <v-row
        v-if="menuRoutes.length"
        no-gutters
        class="mt-5 mb-5 px-3 desktop-menu"
      >
        <DesktopMenuTile
          v-for="menuRoute in menuRoutes"
          :key="menuRoute.text"
          :text="menuRoute.text"
          :route-name="'admin-' + menuRoute.routeName"
          :is-open="menuRoute.isOpen"
        />
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import userHandler from "@mixins/userHandler";
import DesktopMenuTile from "./DesktopMenuTile.vue";
export default {
  name: "DesktopMenu",
  components: { DesktopMenuTile },
  mixins: [userHandler],
  data() {
    return {
      menuRoutes: [],
    };
  },
  created() {
    const initialRoutes = [
      {
        text: "OnDemand",
        routeName: "ondemand-courses",
        isOpen: true
      },
      {
        text: "Live",
        routeName: "courses",
        isOpen: true
      },
    ];
    if (this.isStudent || this.isTeacher || this.isSorint) {
      initialRoutes.push({
        text: "Progetti",
        routeName: "projects",
        isOpen: true
      });
    }
    if (this.isTeacher) {
      initialRoutes.push({
        text: "Classi",
        routeName: "classes",
        isOpen: true
      });
    }
    initialRoutes.push({
      text: "Test",
      routeName: "test",
      isOpen: true
    });
    if (this.isStudent || this.isTeacher) {
      initialRoutes.push({
        text: "Report",
        routeName: "report",
        isOpen: true
      });
    }
    this.menuRoutes = initialRoutes;
  }
};
</script>

<style scoped>
.desktop-menu > :not(:last-child) {
  margin-right: 20px;
}
</style>
