<template>
  <div>Redirecting...</div>
</template>
<script>
import userHandler from "@mixins/userHandler";
import { pushPlatformUrl } from "@/utils/externalRouter";

export default {
  name: "KeycloakRedirect",
  mixins: [userHandler],
  mounted() {
    if (this.isAdmin) {
      this.$router.push({ name: "admin-link-management" });
      return;
    }
    if (this.isSorint) {
      pushPlatformUrl("/keycloak");
    }
    this.$router.push({ name: "401" });
  },
};
</script>
