class Comment {
  constructor(id, text, time, user_id, username, avatar) {
    this.id = id;
    this.text = text;
    this.time = time;
    this.user_id = user_id;
    this.username = username;
    this.avatar = avatar;
  }
}

export default Comment;
