<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mx-n6 mx-md-0">
        <QuestionsTable />
      </v-row>
      <DurationPicker
        :initial-duration="currentStructure.duration"
        @onUpdateDuration="updateDuration"
      />
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <NumbersAndCertificates
            :initial-structure="currentStructure"
            @onUpdateSelectedNumbers="updateStructure"
          />
        </v-col>
        <v-col cols="12" md="6">
          <PointsSelection
            :initial-structure="currentStructure"
            @onUpdateSelectedTestNumbers="updateTestNumbers"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        justify-md="end"
        class="mt-5"
      >
        <v-col cols="auto">
          <v-btn
            color="#00A1FF"
            class="mr-3 rounded-btn"
            :disabled="selectedQuiz.questions.length === 0"
            @click="openPreview"
          >
            <v-icon left>
              mdi-eye-outline
            </v-icon>
            VISUALIZZA ANTEPRIMA
          </v-btn>
        </v-col>
        <v-col
          cols="auto"
          class="mt-3 mt-md-0"
        >
          <v-btn
            dark
            color="#F39200"
            class="rounded-btn"
            @click="saveStructure"
          >
            <v-icon left>
              mdi-content-save-outline
            </v-icon>
            Salva struttura
          </v-btn>
        </v-col>
      </v-row>
      <PreviewDialog
        v-if="previewQuestions"
        :questions="selectedQuiz.questions"
        @onCloseModal="closePreview"
      />
    </v-col>
  </v-row>
</template>

<script>
import QuestionsTable from "../QuestionsTable.vue";
import NumbersAndCertificates from "../NumbersAndCertificates.vue";
import PointsSelection from "../PointsSelection.vue";
import cloneDeep from "lodash/cloneDeep";
import { mapActions, mapGetters } from "vuex";
import DurationPicker from "../DurationPicker.vue";
import PreviewDialog from "../dialogs/PreviewDialog.vue";
export default {
  name: "QuizStructure",
  components: {
    QuestionsTable,
    NumbersAndCertificates,
    PointsSelection,
    DurationPicker,
    PreviewDialog
  },
  data() {
    return {
      currentStructure: {},
      previewQuestions: false,
    };
  },
  computed: {
    ...mapGetters("quiz", ["selectedQuiz"]),
  },
  created() {
    const clonedSelectedQuiz = cloneDeep(this.selectedQuiz);
    const {
      duration,
      min_points,
      min_points_excellence,
      id,
      structure,
    } = clonedSelectedQuiz;
    const rawStructure = {
      duration,
      min_points,
      min_points_excellence,
      quiz_id: id,
      structure,
    };
    // this line removes the undefined values
    this.currentStructure = JSON.parse(
      JSON.stringify(rawStructure)
    );
  },
  methods: {
    ...mapActions("quiz", ["createUpdateStructure"]),
    updateDuration(duration) {
      this.currentStructure.duration = duration;
    },
    updateStructure(rawStructure) {
      const newStructure = [];
      const keys = Object.keys(rawStructure);
      keys.forEach((key) =>
        newStructure.push({
          number: rawStructure[key],
          points: Number.parseInt(key, 10),
        })
      );
      this.currentStructure.structure = newStructure;
    },
    updateTestNumbers(rawTestNumbers) {
      const { min, excellence } = rawTestNumbers;
      const rawJsonToAdd = {
        min_points: min,
        min_points_excellence: excellence,
      };
      const definedRealJsonObject = JSON.parse(
        JSON.stringify(rawJsonToAdd)
      );
      this.currentStructure = {
        ...this.currentStructure,
        ...definedRealJsonObject,
      };
    },
    async saveStructure() {
      await this.createUpdateStructure(
        this.currentStructure
      );
    },
    openPreview(){
      this.previewQuestions = true
    },
    closePreview(){
      this.previewQuestions = false
    }
  },
};
</script>

<style>
.input-number {
  border: 1px solid #F4F5F6;
  width: 70px;
  height: 44px;
  border-radius: 12px;
  line-height: 28px;
  text-align: left;
  color: #F4F5F6;
}
.section-title, .section-text {
  font-family: "Karasuma Gothic";
  font-size: 16px;
  line-height: 28px;
  color: "red";
  text-align: left;
}

.section-title {
  font-weight: 700;
}

.section-text, .input-number {
  font-weight: 400;
}

@media (max-width: 767.98px) {
.input-number {
    height: 30px;
    width: 50px;
    line-height: 20px;
  }
}

</style>