<template>
  <v-main>
    <v-card color="transparent" class="pa-2" flat>
      <v-card-title align="left">
        <v-col cols="12" class="d-flex justify-end px-0">
          <v-btn
            color="green darken-2"
            dark
            large
            class="btn-font"
            @click="downloadEventSubscriptions"
          >
            <v-icon class="mr-1"> mdi-download </v-icon>
            iscrizioni evento
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            large
            class="btn-font ml-5"
            @click="downloadActivities"
          >
            <v-icon class="mr-1"> mdi-download </v-icon>
            iscrizioni attività
          </v-btn>
          <v-btn
            color="green darken-2"
            dark
            large
            class="btn-font ml-5"
            @click="downloadNewsletters"
          >
            <v-icon class="mr-1"> mdi-download </v-icon>
            iscrizioni newsletters
          </v-btn>
        </v-col>
        <v-col cols="12" class="px-0">
          <v-btn
            color="blue darken-2"
            dark
            large
            class="btn-font"
            @click="openModal"
          >
            <v-icon class="mr-1"> mdi-plus </v-icon>
            Aggiungi Attività
          </v-btn>
        </v-col>
      </v-card-title>
      <v-data-table
        class="data-table"
        disable-sort
        dark
        disable-filtering
        :items-per-page="10"
        :headers="headers"
        :items="activitiesList"
      >
        <template v-slot:item.title="{ item }">
          {{ item.title }}
        </template>
        <template v-slot:item.subtitle="{ item }">
          {{ item.subtitle }}
        </template>
        <template v-slot:item.max_participants="{ item }">
          {{ item.max_participants }}
        </template>
        <template v-slot:item.current_participants="{ item }">
          {{ item.current_participants }}
        </template>
        <template v-slot:item.available_slots="{ item }">
          {{ item.max_participants - item.current_participants }}
        </template>
        <template v-slot:item.ratio="{ item }">
          {{ getRatio(item.current_participants) + "%" }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            :disabled="!item.participants"
            class="mr-2"
            color="blue"
            @click="showParticipants(item)"
            ><v-icon class="mr-3">mdi-account-group</v-icon>Iscritti</v-btn
          >
          <v-btn class="mr-2" color="orange" @click="editActivity(item)"
            ><v-icon class="mr-3">mdi-pencil</v-icon>Modifica</v-btn
          >
          <v-btn color="red" @click="openDeleteActivityModal(item)"
            ><v-icon class="mr-3">mdi-trash-can-outline</v-icon>Elimina</v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <Modal
      v-if="showNewActivityModal"
      :title="isEditing ? 'Modifica attività' : 'Nuova attività'"
      @closeModal="resetActivity"
    >
      <template #content-center>
        <v-row>
          <v-col cols="12">
            <v-card-text class="modal-text">
              <p>
                Stai
                {{ isEditing ? "modificando una delle" : "creando una nuova" }}
                attività per l'HackersGen Event
              </p>
            </v-card-text>
          </v-col>
          <v-col cols="12" align="center" class="px-10">
            <v-text-field v-model="newTitle" label="Titolo" outlined center />
          </v-col>
          <v-col cols="12" align="center" class="px-10">
            <v-text-field
              v-model="newSubtitle"
              label="Sottotitolo"
              outlined
              center
            />
          </v-col>
          <v-col cols="12" align="center" class="px-10">
            <v-textarea v-model="newDesc" label="Descrizione" outlined center />
          </v-col>
          <v-col cols="12" align="center" class="px-10">
            <v-text-field
              v-model="newParticipants"
              label="Max Partecipanti"
              outlined
              center
            />
          </v-col>
          <v-col v-if="isEditing" cols="12" align="center" class="px-10">
            <v-text-field
              :readonly="true"
              :disabled="true"
              v-model="newId"
              label="id"
              hint="Attenzione! Modificare questo valore potrebbe comportare la sovrascrittura di un'attività esistente"
              outlined
              center
            />
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-row justify="center">
            <v-btn large plain class="white--text mx-3" @click="resetActivity">
              Annulla
            </v-btn>
            <v-btn
              color="blue darken-2"
              class="white--text"
              large
              @click="isEditing ? updateActivity() : createActivity()"
            >
              {{ isEditing ? "Modifica" : "Aggiungi" }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </template>
    </Modal>
    <Modal
      v-if="showParticipantsListModal"
      title="Lista Partecipanti"
      @closeModal="showParticipantsListModal = false"
      :width="'1500px'"
    >
      <template #content>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="px-6 mb-2"
        />
      </template>
      <template #content-center>
        <v-data-table
          class="data-table"
          dark
          :search="search"
          :items="displayParticipantsList"
          :headers="peopleHeaders"
          :items-per-page="10"
        >
          <template v-slot:item.email="{ item }">
            <a :href="`mailto:${item.email}`">{{ item.email }}</a>
          </template>
          <template v-slot:item.role="{ item }">
            {{ item.role ? item.role : "" }}
          </template>
          <template v-slot:item.birth_date="{ item }">
            {{ item.birth_date ? item.birth_date : "" }}
          </template>
          <template v-slot:item.age="{ item }">
            <v-chip
              :color="
                getAge(item.birth_date) < 16
                  ? 'orange darken-2'
                  : 'green darken-2'
              "
            >
              {{ getAge(item.birth_date) }}
            </v-chip>
          </template>
          <template v-slot:item.birth_place="{ item }">
            {{ item.birth_place }}
          </template>
          <template v-slot:item.consent_date="{ item }">
            {{ item.consent_date ? item.consent_date : "" }}
          </template>
          <template v-slot:item.approval="{ item }">
            <v-chip
              v-if="item.approved || item.role === 'professor' || !item.minor"
              color="green darken-2"
              class="ml-3"
            >
              <v-icon style="margin-left: -0.5rem; margin-right: -0.5rem"
                >mdi-check-decagram</v-icon
              >
            </v-chip>
            <v-chip v-else color="orange darken-2" class="ml-3">
              <v-icon style="margin-left: -0.5rem; margin-right: -0.5rem"
                >mdi-alert-circle-outline</v-icon
              ></v-chip
            >
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="openApprovalModal(item, true)"
              rounded
              :disabled="
                item.approved || item.role === 'professor' || !item.minor
              "
              color="blue"
            >
              <v-icon style="margin-left: -0.5rem; margin-right: 0.25rem"
                >mdi-check-decagram</v-icon
              >
              Approva
            </v-btn>
            <v-btn
              @click="openApprovalModal(item, false)"
              rounded
              :disabled="
                item.approved || item.role === 'professor' || !item.minor
              "
              color="red"
              class="ml-2"
            >
              <v-icon style="margin-left: -0.5rem; margin-right: 0.25rem"
                >mdi-close-box</v-icon
              >
              Rifiuta
            </v-btn>
          </template>
        </v-data-table>
      </template>
    </Modal>
    <Modal
      v-if="showApprovalModal"
      :title="isApproving ? 'Approvazione Studente' : 'Rigetto Studente'"
      @closeModal="showApprovalModal = false"
      width="500px"
    >
      <template #content>
        <v-card-text class="approval-text">
          Sei sicuro di voler {{ isApproving ? "approvare" : "rifiutare" }} la
          richiesta di iscrizione di
          <strong>{{ currentStudent.name }} {{ currentStudent.surname }}</strong
          >?
        </v-card-text>
        <v-card-actions class="mt-3">
          <v-row justify="center">
            <v-btn
              large
              text
              class="white--text"
              @click="showApprovalModal = false"
            >
              <v-icon>mdi-close</v-icon>
              Annulla
            </v-btn>
            <v-btn
              v-if="isApproving"
              @click="approveParticipation(currentStudent.id)"
              rounded
              large
              color="blue"
              class="mx-3"
            >
              <v-icon style="margin-left: -0.5rem; margin-right: 0.25rem"
                >mdi-check-decagram</v-icon
              >
              Approva
            </v-btn>
            <v-btn
              v-else
              @click="rejectParticipation(currentStudent.id)"
              rounded
              large
              color="red"
            >
              <v-icon style="margin-left: -0.5rem; margin-right: 0.25rem"
                >mdi-close-box</v-icon
              >
              Rifiuta
            </v-btn>
          </v-row>
        </v-card-actions>
      </template>
    </Modal>
    <Modal
      v-if="showDeleteActivityModal"
      :title="'Eliminazione attività'"
      @closeModal="showDeleteActivityModal = false"
      width="500px"
    >
      <template #content>
        <v-card-title class="approval-text">
          Sei sicuro di voler eliminare questa attività?
        </v-card-title>
        <v-card-subtitle class="approval-text">
          <strong
            >Tutte le iscrizioni degli utenti a questa attività saranno
            eliminate per sempre!</strong
          >
        </v-card-subtitle>
        <v-card-actions class="mt-3">
          <v-row justify="center">
            <v-btn
              large
              text
              class="white--text"
              @click="showDeleteActivityModal = false"
            >
              <v-icon>mdi-close</v-icon>
              Annulla
            </v-btn>
            <v-btn
              v-if="isApproving"
              @click="deleteActivity(currentActivityId)"
              rounded
              large
              color="red"
              class="mx-3"
            >
              <v-icon style="margin-left: -0.5rem; margin-right: 0.25rem"
                >mdi-trash-can-outline</v-icon
              >
              Elimina
            </v-btn>
          </v-row>
        </v-card-actions>
      </template>
    </Modal>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";
import trigger from "@mixins/trigger";
import Modal from "@cmp/utils/Modal";
import { sendNotification } from "@/utils/notifications";

export default {
  name: "EventManagement",
  components: { Modal },
  mixins: [trigger],
  data: () => ({
    activitiesList: [],
    displayParticipantsList: [],
    currentActivityId: 0,
    currentStudent: {
      id: null,
      name: null,
      surname: null,
    },
    showNewActivityModal: false,
    showParticipantsListModal: false,
    showApprovalModal: false,
    showDeleteActivityModal: false,
    isApproving: true,
    isEditing: false,
    newTitle: "",
    newSubtitle: "",
    newDesc: "",
    newId: 0,
    newParticipants: 0,
    search: "",
    headers: [
      {
        text: "id",
        value: "id",
      },
      {
        text: "Titolo",
        value: "title",
      },
      {
        text: "Sottotitolo",
        value: "subtitle",
      },
      {
        text: "Posti Totali",
        value: "max_participants",
      },
      {
        text: "Iscrizioni",
        value: "current_participants",
      },
      {
        text: "Posti Rimasti",
        value: "available_slots",
      },
      {
        text: "% sugli iscritti attuali",
        value: "ratio",
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },
    ],
    peopleHeaders: [
      {
        text: "Nome",
        value: "name",
      },
      {
        text: "Cognome",
        value: "surname",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Ruolo",
        value: "role",
      },
      {
        text: "Data di Nascita",
        value: "birth_date",
      },
      {
        text: "Età",
        value: "age",
        sortable: false,
      },
      {
        text: "Luogo di Nascita",
        value: "birth_place",
      },
      {
        text: "Data Consenso",
        value: "consent_date",
      },
      {
        text: "Stato Appr.",
        value: "approval",
        sortable: false,
      },
      {
        text: "Azioni",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  async created() {
    this.activitiesList = await this.getActivities();
  },
  methods: {
    ...mapActions("eventActivities", [
      "getActivities",
      "downloadEventSubscriptions",
      "downloadActivities",
      "downloadNewsletters",
      "updateActivities",
      "createActivities",
      "deleteActivities",
      "approveStudentParticipation",
      "rejectStudentParticipation",
    ]),
    editActivity(activity) {
      this.newId = activity.id;
      this.newDesc = activity.description;
      this.newTitle = activity.title;
      this.newSubtitle = activity.subtitle;
      this.newParticipants = activity.max_participants;
      this.isEditing = true;
      this.openModal();
    },
    showParticipants(activity) {
      this.displayParticipantsList = activity.participants;
      this.currentActivityId = activity.id;
      this.showParticipantsListModal = true;
    },
    openApprovalModal(student, isApproving) {
      this.currentStudent.id = student.id;
      this.currentStudent.name = student.name;
      this.currentStudent.surname = student.surname;
      this.isApproving = isApproving;
      this.showApprovalModal = true;
    },
    openDeleteActivityModal(activity) {
      this.currentActivityId = activity.id;
      this.showDeleteActivityModal = true;
    },
    async createActivity() {
      try {
        const newActivity = {
          description: this.newDesc,
          max_participants: Number(this.newParticipants),
          title: this.newTitle,
          subtitle: this.newSubtitle,
        };
        await this.createActivities({ newActivity: newActivity });
        sendNotification(`Attività creata con successo!`, "success");

        this.activitiesList = await this.getActivities();
        this.resetActivity();
      } catch (err) {
        console.error(err);
      }
    },
    async updateActivity() {
      try {
        const newActivity = {
          description: this.newDesc,
          id: this.newId,
          max_participants: Number(this.newParticipants),
          title: this.newTitle,
          subtitle: this.newSubtitle,
        };

        await this.updateActivities({ newActivity: newActivity });
        sendNotification(`Attività modificata con successo!`, "success");

        this.activitiesList = await this.getActivities();
        this.resetActivity();
      } catch (err) {
        console.error(err);
      }
    },
    async deleteActivity(activityId) {
      try {
        await this.deleteActivities(activityId);
        sendNotification(`Attività eliminata con successo!`, "success");

        this.showDeleteActivityModal = false;
        this.activitiesList = await this.getActivities();
        this.resetActivity();
      } catch (err) {
        console.error(err);
      }
    },
    async approveParticipation(studentId) {
      try {
        await this.approveStudentParticipation({
          activityId: this.currentActivityId,
          studentId,
        });
        sendNotification(`Iscrizione approvata con successo!`, "success");
        this.displayParticipantsList = this.displayParticipantsList.map(
          (participant) => {
            if (participant.id !== studentId) {
              return participant;
            }

            let newParticipant = participant;
            newParticipant.approved = true;
            return newParticipant;
          }
        );

        this.showApprovalModal = false;
      } catch (err) {
        console.error(err);
      }
    },
    async rejectParticipation(studentId) {
      try {
        await this.rejectStudentParticipation({
          activityId: this.currentActivityId,
          studentId,
        });
        sendNotification(`Iscrizione rifiutata con successo!`, "success");
        this.displayParticipantsList = this.displayParticipantsList.filter(
          (participant) => participant.id !== studentId
        );
        this.showApprovalModal = false;
      } catch (err) {
        console.error(err);
      }
    },
    findValidId() {
      const usedIds = this.activitiesList.map((activity) => activity.id);
      let newId = 1;

      while (usedIds.indexOf(newId) >= 0) {
        newId++;
      }
      return newId;
    },
    openModal() {
      if (!this.isEditing) {
        this.newId = this.findValidId();
      }
      this.showNewActivityModal = true;
    },
    resetActivity() {
      this.newId = 0;
      this.newDesc = "";
      this.newTitle = "";
      this.newSubtitle = "";
      this.newParticipants = 0;
      this.showNewActivityModal = false;
      this.isEditing = false;
    },
    getTotalParticipants() {
      let totalParticipants = 0;
      this.activitiesList.forEach((activity) => {
        totalParticipants += activity.current_participants;
      });

      return totalParticipants;
    },
    getRatio(participants) {
      if (participants <= 0) {
        return 0;
      }
      const totalParticipants = this.getTotalParticipants();

      if (totalParticipants <= 0) {
        return 0;
      }

      return ((participants / totalParticipants) * 100).toFixed(2);
    },
    getAge(birthday) {
      if (!birthday) {
        return null;
      }

      const today = new Date();
      const birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    },
  },
};
</script>

<style scoped>
.data-table {
  background-color: transparent;
  font-weight: 500;
}

a,
a:visited {
  color: #f39200;
  font-weight: 500;
  text-decoration: none;
}

.approval-text {
  font-size: 18px;
}
</style>
