<template>
  <Modal
    :title="'Conferma pubblicazione'"
    :background="false"
    :glassify="false"
    @closeModal="() => $emit('onClosePublishDialog')"
  >
    <template #content>
      <v-col
        class="pa-0"
        align="center"
      >
        <h3 class="error--text my-5">
          ATTENZIONE! Questa azione non potrà essere
          modificata.
        </h3>
        <p class="mt-15">
          Sei davvero sicuro di pubblicare questo test?
        </p>
        <p>
          Conferma digitando
          <strong>{{ selectedQuiz.title }}</strong> all'interno
          dell'input qui sotto e clicca poi su
          <span class="primary white--text">CONFERMA</span>
        </p>
        <v-col
          class="pa-0 my-15"
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="insertedValue"
            label="Input di conferma"
            :placeholder="selectedQuiz.title"
            :rules="rules"
            hide-details="auto"
          />
        </v-col>
        <v-row
          no-gutters
          justify="end"
        >
          <v-btn
            :dark="canPublishQuiz"
            color="primary"
            large
            :disabled="!canPublishQuiz"
            @click="() => $emit('onConfirmPublish')"
          >
            CONFERMA
          </v-btn>
        </v-row>
      </v-col>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "../../../utils/Modal.vue";
export default {
  name: "PublishQuizDialog",
  components: {
    Modal,
  },
  data() {
    return {
      rules: [
        (value) => Boolean(value) || "Required.",
        (value) =>
          value === this.selectedQuiz.title ||
          "Il nome non coincide con quello qua sopra",
      ],
      insertedValue: "",
    };
  },
  computed: {
    ...mapGetters("quiz", ["selectedQuiz"]),
    canPublishQuiz() {
      return this.insertedValue === this.selectedQuiz.title;
    },
  },
};
</script>
