<template>
  <v-form
    ref="form"
    v-model="isValid"
  >
    <v-row class="mt-3">
      <v-col
        v-for="(blog, blogIndex) in blogLinks.links"
        :key="blog.id"
        cols="12"
        lg="4"
      >
        <v-row class="mb-2">
          <h3 class="label ml-3">
            Link thumbnail
          </h3>
        </v-row>
        <input
          v-model="blog.image_url"
          class="input-field mb-6"
          placeholder="Inserisci il link dell'immagine"
          :rules="[rules.url]"
        >
        <v-row class="mb-2">
          <h3 class="label ml-3">
            Title
          </h3>
        </v-row>
        <input
          v-model="blog.title"
          class="input-field mb-6"
          placeholder="Scrivi il titolo dell'articolo"
        >
        <v-row class="mb-2">
          <h3 class="label ml-3">
            Abstract
          </h3>
        </v-row>
        <textarea
          v-model="blog.abstract"
          rows="4"
          maxlength="200"
          class="input-field"
          placeholder="Scrivi la descrizione"
        />
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-end"
          >
            <span class="counter mr-3">{{ blog.abstract.length }}/200</span>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <h3 class="label ml-3">
            Link articolo
          </h3>
        </v-row>
        <input
          v-model="blog.post_url"
          class="input-field mb-4"
          placeholder="Inserisci il link del floating point"
          :rules="[rules.url]"
        >
        <v-row class="mt-3">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="blogIndex === 0"
                v-bind="attrs"
                icon
                dark
                color="transparent"
                v-on="on"
                @click="switchPosition(blogIndex - 1)"
              >
                <v-icon
                  v-if="$vuetify.breakpoint.mdAndUp"
                  color="#5A6972"
                >
                  mdi-arrow-left
                </v-icon>
                <v-icon
                  v-else
                  color="#5A6972"
                >
                  mdi-arrow-up
                </v-icon>
              </v-btn>
            </template>
            <span>Sposta a sinistra</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="blogIndex === blogLinks.links.length - 1"
                v-bind="attrs"
                dark
                color="transparent"
                icon
                v-on="on"
                @click="switchPosition(blogIndex)"
              >
                <v-icon
                  v-if="$vuetify.breakpoint.mdAndUp"
                  color="#5A6972"
                >
                  mdi-arrow-right
                </v-icon>
                <v-icon
                  v-else
                  color="#5A6972"
                >
                  mdi-arrow-down
                </v-icon>
              </v-btn>
            </template>
            <span>Sposta a destra</span>
          </v-tooltip>
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                color="#F39200"
                v-on="on"
                @click="deleteBlogLink(blogIndex)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Cancella il link</span>
          </v-tooltip>
        </v-row>
        <v-divider class="mt-6" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        class="d-flex justify-start"
      >
        <v-btn
          :disabled="blogLinks.links.length > 9"
          dark
          strong
          color="#F39200"
          class="white--text font-weight-bold btn-size rounded-lg"
          :width="$vuetify.breakpoint.xsOnly ? '100%' : ''"
          @click="addBlogLink"
        >
          aggiungi link
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="d-flex justify-end"
      >
        <v-btn
          dark
          strong
          color="#F39200"
          class="white--text font-weight-bold btn-size rounded-lg"
          :disabled="!blogLinksModified || !isValid"
          :class="{ 'disable-icon': !blogLinksModified || !isValid }"
          :width="$vuetify.breakpoint.xsOnly ? '100%' : ''"
          @click="saveBlogLinks"
        >
          <v-icon left>
            mdi-arrow-top-right
          </v-icon>
          pubblica
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-footer class="footer-text jutify-start">
          Gli articoli verranno visualizzati seguendo l'ordine in cui sono disposti qui.
          Per spostare un articolo, clicca sulle freccette in basso a sinistra di ogni
          form.
        </v-footer>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import settingsServices from "@services/settings";
import store from "@store";
import { sendNotification, handleAxiosError } from "@utils/notifications";
import isEqual from "lodash/isEqual";

export default {
  name: "NewsManagement",
  data: () => ({
    retrievedBlogLinks: { links: [] },
    blogLinks: { links: [] },
    rules: {
      required: (v) => Boolean(v) || "Valore richiesto",
      url: (v) =>
        !v ||
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
          v
        ) ||
        `URL non valido`,
    },
    isValid: false,
  }),
  computed: {
    blogLinksModified() {
      return !isEqual(this.retrievedBlogLinks, this.formattedBlogLinks);
    },
    formattedBlogLinks() {
      return this.blogLinks.links.map((link) => ({
        abstract: link.abstract,
        image_url: link.image_url,
        post_url: link.post_url,
        title: link.title,
      }));
    },
  },
  async created () {
    await this.retrieveBlogLinks();
  },
  methods: {
    addBlogLink() {
      this.blogLinks.links.push({ title:"", abstract: "", post_url: null, image_url: null, id: Math.random() });
    },
    deleteBlogLink(blogIndex) {
      this.blogLinks.links.splice(blogIndex, 1);
    },
    switchPosition(blogIndex) {
      const items = [
        this.blogLinks.links[blogIndex],
        this.blogLinks.links[blogIndex + 1],
      ];
      this.blogLinks.links.splice(blogIndex, 2, items[1], items[0]);
    },
    async saveBlogLinks() {
      store.commit("loading/startLoading");
      try {
        await settingsServices.setBlogArticles({ blog_links: this.formattedBlogLinks });
        this.retrieveBlogLinks();
        sendNotification(`Articoli salvati con successo`, "success");
      } catch (err) {
        handleAxiosError(`Errore durante il salvataggio degli articoli`, err);
      }
      store.commit("loading/stopLoading");
    },
    async retrieveBlogLinks() {
      store.commit("loading/startLoading");
      try{
        const { data } = await settingsServices.getBlogArticles();
        this.retrievedBlogLinks = data;
        this.blogLinks.links = this.retrievedBlogLinks.map((link) => ({
          ...link,
          id: Math.random(),
        }));
      
      } catch (err) {
        handleAxiosError("Errore nel recupero degli articoli", err);
      }
      store.commit("loading/stopLoading");
    },
  }
}
</script>

<style scoped>
textarea{
  resize: none
}

.counter{
  font-family: 'Karasuma Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.75px;
  color: #5a6972;
}

.label {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #C6CED2;
}

.input-field{
  padding: 12px 16px;
  border: 1px solid #E8EBED;
  border-radius: 12px;
  font-family: 'Karasuma Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #5a6972;
  width: 100%;
}

.input-field:focus{
  border: 1px solid #f39200;
}

.input-field::placeholder{
  color: #5a6972;
}

.footer-text {
  background-color: transparent !important;
  font-family: 'Karasuma Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #98A6AE;
  padding-left: 0;
}
</style>