<template>
  <Modal
    :title="'Seleziona le date della sessione'"
    :background="false"
    :glassify="false"
    @closeModal="() => $emit('onRemoveSelectedDates')"
  >
    <template #content>
      <v-col class="pa-0">
        <h4>Data inizio sessione (YYYY-MM-DD):</h4>
        <DatePicker
          :initial-date="selectedDates.startingDate"
          @onPickedDate="
            (date) => $emit('onUpdateStartingDate',date)
          "
        />
        <h4 class="mt-10">
          Data fine sessione (YYYY-MM-DD):
        </h4>
        <DatePicker
          :initial-date="selectedDates.endingDate"
          @onPickedDate="(date) => $emit('onUpdateEndingDate',date)"
        />
        <v-row
          v-if="selectedDates.id"
          no-gutters
          justify="center"
          justify-md="space-between"
        >
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            dark
            color="error"
            @click="() => $emit('onRemoveSession')"
          >
            ELIMINA SESSIONE
          </v-btn>
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            dark
            color="accent"
            @click="() => $emit('onModifySession')"
          >
            MODIFICA SESSIONE
          </v-btn>
        </v-row>
        <v-row
          v-else
          no-gutters
          justify="center"
        >
          <v-btn
            large
            dark
            color="primary"
            @click="() => $emit('onAddSession')"
          >
            AGGIUNGI SESSIONE
          </v-btn>
        </v-row>
      </v-col>
    </template>
  </Modal>
</template>

<script>
import Modal from "../../../utils/Modal.vue";
import DatePicker from "../DatePicker.vue";
export default {
  name: "AddChangeSessionDialog",
  components: { Modal, DatePicker },
  props: {
    selectedDates: {
      type: Object,
      required: true,
    },
  },
};
</script>
