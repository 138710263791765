<template>
  <v-main id="container">
    <v-container>
      <v-card class="pa-5 mt-3" color="#1d1c4c" dark>
        <v-row>
          <v-col>
            <v-card-title class="pa-0">
              <h2 class="font-weight-light">
                GESTIONE DOMANDE: {{ course.title }}
              </h2>
            </v-card-title>
          </v-col>
          <v-col cols="3">
            <v-btn
              color="admin_primary"
              class="white--text ma-5"
              large
              :to="{ name: 'admin-users' }"
            >
              <v-icon left> mdi-keyboard-backspace </v-icon>INDIETRO
            </v-btn>
          </v-col>
        </v-row>
        <v-expansion-panels v-model="panel" inset>
          <v-expansion-panel
            v-for="(episode, episodeIndex) of course.episodes"
            :key="episodeIndex"
            class="episodes-panels mb-5"
          >
            <v-expansion-panel-header>
              <h3>
                Episodio #{{ episodeIndex + 1 }}:
                <span class="font-weight-light">{{ episode.title }}</span>
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <QuestionDataTable
                    :course-id="course.id"
                    :episode-id="episode.id"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    :to="courseLinks[episodeIndex]"
                    color="#F39200"
                    class="white--text font-weight-light btn-size rounded-lg"
                  >
                    <strong>Apri pagina episodio</strong>
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import QuestionDataTable from "@cmp/admin/QuestionDataTable";
import { sendNotification } from "@utils/notifications";

export default {
  name: "QuestionsManagementDetails",
  components: {
    QuestionDataTable,
  },
  data() {
    return {
      panel: 0,
      id: this.$route.params.id,
      course: {
        title: "",
        authors: [{ name: "", role: "" }],
        resource: [""],
        scope: "",
        description: "",
        onDemand: false,
        episodes: [],
        id: "",
        thumbnailPath: "",
      },
    };
  },
  computed: {
    ...mapState("courses", ["courses"]),

    courseLinks() {
      const links = [];
      for (const episode of this.course.episodes) {
        links.push(`/course/${this.course.id}/video/${episode.videoId}`);
      }
      return links;
    },
  },

  async created() {
    if (this.courses.length === 0) {
      await this.getAllCourses();
    }
    if (this.id) {
      const index = this.courses.findIndex((c) => c.id === this.id);
      this.course = Object.assign({}, this.courses[index]);
    }
    if (this.course.id !== this.id) {
      sendNotification(
        `Nessun corso trovato con questo ID. Torna indietro.`,
        `error`
      );
    }
  },

  methods: {
    ...mapActions("courses", ["getAllCourses"]),
  },
};
</script>

<style scoped>
#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.episodes-panels {
  background: #01002e !important;
  backdrop-filter: blur(60px);
  border-radius: 12px !important;
  font-family: "Karasuma Gothic" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #f4f5f6 !important;
  margin-top: 0px !important;
  padding: 5px !important;
  letter-spacing: 0.75px !important;
}
</style>
