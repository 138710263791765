import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("trigger", ["needsUpdate"]),
  },
  methods: {
    ...mapActions("trigger", [
      "resetTrigger",
      "triggerUpdate",
    ]),
  },
};
