import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";

import store from "@/store";

import routes from "./routes";
import NProgress from "nprogress/nprogress";
import "nprogress/nprogress.css";
import { pushAuthUrl } from "@/utils/externalRouter";

NProgress.configure({
  easing: "ease",
  speed: 500,
  showSpinner: false,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

Vue.use(VueRouter);

Vue.use(Meta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: "metaInfo",
  refreshOnceOnNavigation: true,
});

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

/* eslint-disable consistent-return */
/* eslint-disable complexity */
router.beforeEach((to, from, next) => {
  if (from.name !== null) {
    NProgress.start();
  }
  if (to.name === "401") {
    next();
    return;
  }
  if (to.meta.accessible) {
    next();
    return;
  } else if (store.getters["auth/isAuthenticated"]) {
    /* Un giorno in ms */
    const one_day = 1000 * 3600 * 24;

    /* Se il token è scaduto anche per il refresh, faccio direttamente il logout */
    const now = new Date();
    const exp_refresh = store.getters["auth/user"].login_time + one_day;

    if (now > exp_refresh) {
      store.dispatch("auth/logout");
      return pushAuthUrl();
    }
  }
  if (!store.getters["auth/isAdmin"]) {
    return pushAuthUrl();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  store.commit("loading/removeLoading");
  NProgress.done();
});

router.onError(() => {
  store.commit("loading/removeLoading");
});

export default router;
