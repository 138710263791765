<template>
  <v-main id="container">
    <v-container>
      <h2 class="wrapper-title pl-5">Gestione Corsi</h2>
      <h3 />
      <v-card class="pa-5 mt-3" color="transparent" flat>
        <v-expansion-panels v-model="panel" class="mt-5" dark flat>
          <v-expansion-panel
            v-for="(typeOfCourse, courseIndex) in typeOfCourses"
            :key="courseIndex"
            class="type-of-courses-panel mb-5"
          >
            <v-expansion-panel-header>
              <v-row>
                <v-col cols="12">
                  <h2 class="title-text">
                    {{ typeOfCourse.title }}
                  </h2>
                </v-col>
                <v-col cols="12">
                  <h3 class="subtitle-text">
                    {{ typeOfCourse.subtitle }}
                  </h3>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="baseline">
                <v-col>
                  <v-row>
                    <v-col>
                      <v-btn
                        v-if="
                          typeOfCourse.title !== 'Archived' &&
                          typeOfCourse.title !== 'Tech Journey'
                        "
                        color="admin_primary"
                        class="white--text ma-5 rounded-lg"
                        large
                        :to="
                          typeOfCourse.title === 'Live'
                            ? `/course/edit`
                            : `/ondemandcourse/edit`
                        "
                      >
                        AGGIUNGI NUOVO CORSO
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="search"
                        label="Cerca un corso"
                        prepend-icon="mdi-magnify"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <OndemandCourseTable
                    v-if="typeOfCourse.title === 'On Demand'"
                    :search="search"
                  />
                  <LiveCoursesTable
                    v-else-if="typeOfCourse.title === 'Live'"
                    :search="search"
                  />
                  <TechJourneyTable
                    v-else-if="typeOfCourse.title === 'Tech Journey'"
                    :search="search"
                  />
                  <OndemandCoursesArchived
                    v-else-if="typeOfCourse.title === 'Archived'"
                    :search="search"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import store from "@store";
import OndemandCourseTable from "@cmp-admin/OndemandCourseTable.vue";
import LiveCoursesTable from "@cmp-admin/LiveCoursesTable.vue";
import TechJourneyTable from "@cmp-admin/TechJourneyTable.vue";
import OndemandCoursesArchived from "@cmp-admin/OndemandCoursesArchived.vue";

export default {
  name: "LiveCourses",
  components: {
    OndemandCourseTable,
    LiveCoursesTable,
    TechJourneyTable,
    OndemandCoursesArchived,
  },
  data() {
    return {
      panel: 4,
      search: "",
      typeOfCourses: [
        {
          id: Math.random(),
          title: "On Demand",
          subtitle: `Area per la creazione e modifica dei corsi on demand,
                disponibili a tutte le tipologie di utenti, senza necessità di
                iscrizione.`,
        },
        {
          id: Math.random(),
          title: "Live",
          subtitle: "Area per la creazione e modifica di eventi sincroni live.",
        },
        {
          id: Math.random(),
          title: "Tech Journey",
          subtitle: "Area relativa ai moduli della Tech Journey.",
        },
        {
          id: Math.random(),
          title: "Archived",
          subtitle:
            "In questa area sono raccolti tutti i corsi on demand e gli eventi live che hai archiviato come admin. È possibile renderli nuovamente disponibili agli utenti.",
        },
      ],
    };
  },
  beforeRouteEnter: async (to, from, next) => {
    try {
      store.commit("loading/startLoading");
      await store.dispatch("courses/getAllCourses");
      if (from.name === "admin-ondemandcourse-edit") {
        next((vm) => {
          vm.panel = 0;
        });
      } else if (from.name === "admin-course-edit") {
        next((vm) => {
          vm.panel = 1;
        });
      } else {
        next();
      }
    } catch (err) {
      next(err);
    }
    store.commit("loading/stopLoading");
  },
};
</script>

<style scoped>
.modal-text {
  font-size: 1.2rem;
}

#container {
  background-color: #01002e;
  background-repeat: repeat;
}

.title-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff;
}

.subtitle-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #ffffff;
}

.type-of-courses-panel {
  background: #1d1c4c !important;
  backdrop-filter: blur(60px);
  border-radius: 12px !important;
  font-family: "Karasuma Gothic" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #f4f5f6 !important;
  margin-top: 0px !important;
  padding: 5px !important;
  letter-spacing: 0.75px !important;
}

.data-table {
  background-color: transparent;
  font-weight: 500;
}
</style>
