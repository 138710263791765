class Student {
  constructor(email, name, surname, id) {
    this.email = email;
    this.name = name;
    this.surname = surname;
    this.id = id;
    this.stato_iscrizione = id ? "ISCRITTO" : "IN ATTESA DI CONFERMA";
  }
}

export default Student;
