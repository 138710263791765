import Author from "@/classes/author";

class ondemandCourse {
  constructor(
    id,
    title,
    description,
    authors,
    thumbnail_url,
    published,
    archived,
    points,
    internship_minutes,
    order_num,
    sections,
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.authors = [];
    this.thumbnailUrl = thumbnail_url;
    this.published = published;
    this.archived = archived;
    this.points = points;
    this.internshipMinutes = internship_minutes;
    this.orderNum = order_num;
    for (const section of sections) {
      this.sections.push(section);
    }
    for (const author of authors) {
      this.authors.push(new Author(author));
    }
  }
}

export default ondemandCourse