<template>
  <v-col
    class="pa-0"
    cols="12"
  >
    <v-card
      class="pa-5 mt-3"
      color="#1d1c4c"
      dark
    >
      <v-card-title class="pa-0">
        <v-col
          cols="12"
        >
          <v-row>
            <h2 class="font-weight-light">
              REPORT TEST
            </h2>
          </v-row>
        </v-col>

        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            sm="8"
            md="6"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cerca un test"
              single-line
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            xl="6"
            class="d-flex flex-wrap justify-end mt-5"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  color="secondary white--text"
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                  v-on="on"
                  @click="showConfirmModal = true"
                >
                  <v-icon left>
                    mdi-download
                  </v-icon>
                  <span v-if="$vuetify.breakpoint.xs">
                    Report quiz
                  </span>
                  <span v-else>
                    Scarica il report di tutti quiz
                  </span>
                </v-btn>
              </template>
              Scarica il report di tutti quiz in un file zip
            </v-tooltip>


            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  color="secondary white--text"
                  :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'ml-5'"
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                  v-on="on"
                  @click="downloadAllSubscriptions"
                >
                  <v-icon left>
                    mdi-download
                  </v-icon>
                  <span v-if="$vuetify.breakpoint.xs">
                    Classi iscritte
                  </span>
                  <span v-else>
                    Scarica tutte le classi iscritte
                  </span>
                </v-btn>
              </template>
            
              Scarica il report di tutte le classi iscritte in un file excel
            </v-tooltip>
          </v-col>
        </v-row>

        <Modal
          v-if="showConfirmModal"
          title="Download report di tutti i quiz"
          width="600px"
          :show-close-button="false"
          :glassify="true"
          @closeModal="showConfirmModal = false"
        >
          <template #content-center>
            <v-card-text
              class="mb-3"
              justify="center"
            >
              Confermando l'azione scaricherai un file zip molto pesante del
              report di <strong>TUTTI</strong> gli studenti. Si consiglia di eseguire
              questa azione solo a fine sessione. Procedere?
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-progress-circular
                v-if="isLoadingDownload"
                indeterminate
                class="mr-4"
              />
              <v-btn
                v-else
                color="success"
                class="rounded-lg"
                @click="downloadExportSummary"
              >
                Conferma
              </v-btn>
              <v-btn
                color="#f3900e"
                class="rounded-lg"
                @click="showConfirmModal = false"
              >
                Chiudi
              </v-btn>
            </v-card-actions>
          </template>
        </Modal>
      </v-card-title>

      <v-row class="mt-5">
        <v-col>
          <v-data-table
            :headers="quizHeaders"
            :items="filteredQuizzes"
            class="data-table"
          >
            <template #[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    class="white--text"
                    @click="downloadZip(item.id, item.title)"
                    v-on="on"
                  >
                    mdi-archive-arrow-down
                  </v-icon>
                </template>
                Scarica il report degli iscritti al test
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    v-if="item.someone_passed"
                    class="white--text ml-5"
                    v-on="on"
                    @click="downloadStudentResults(item.id, item.title)"
                  >
                    mdi-archive-arrow-down
                  </v-icon>
                </template>
                Scarica il report degli studenti passati
              </v-tooltip>
            </template>

            <template #[`item.openClasses`]="{ item }">
              <v-btn 
                color="#F39200"
                width="40px"
                height="40px"
                min-width="0"
                class="rounded-lg open-button"
                @click="selectQuiz(item.id)"
              >
                <v-icon>mdi-login</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@cmp-shared/utils/Modal.vue";

export default {
  name: "ReportTests",
  components: {
    Modal,
  },
  data() {
    return {
      showConfirmModal: false,
      isLoadingDownload: false,
      search: "",
      quizHeaders: [
        {
          text: "Test",
          value: "title",
          sortable: true,
        },
        {
          text: "Download",
          value: "actions",
          sortable: false,
        },
        {
          text: "Apri elenco classi",
          value: "openClasses",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("quiz", ["quizzes"]),
    filteredQuizzes() {
      return this.quizzes.filter((quiz) => {
        if (!this.search || quiz.title.includes(this.search)) {
          return true;
        }
        return false;
      });
    },
  },
  async created() {
    await this.getAllQuizzes();
  },
  methods: {
    ...mapActions("quiz", ["getAllQuizzes", "getAllQuizzesSubscriptions", "getQuizSubscriptions"]),
    ...mapActions("reports", ["downloadPassedQuizzes"]),
    ...mapActions("exam", ["exportQuizSummary"]),
    async downloadStudentResults(id, title) {
      try {
        await this.downloadPassedQuizzes({
          quiz_id: id,
          fileName: `Elenco_Passati_${title}`,
        });
      } catch (e) {
        this.$logger(e);
      }
    },
    async downloadExportSummary() {
      try {
        this.isLoadingDownload = true;
        await this.exportQuizSummary({
          filename: `Export_2022`,
        });
      } catch (e) {
        this.$logger(e);
      } finally {
        this.isLoadingDownload = null;
      }
    },
    async downloadAllSubscriptions() {
      await this.getAllQuizzesSubscriptions({
        fileName: `report_quizzes_subscriptions_${new Date()
          .toISOString()
          .substring(0, 10)}`,
      });
    },
    async downloadZip(quizId, quizTitle) {
      await this.getQuizSubscriptions({
        quizId,
        fileName: `subscriptions_quiz_${quizTitle}_${new Date()
          .toISOString()
          .substring(0, 10)}`,
      });
    },
    selectQuiz(quizId) {
      this.$router.push({
        name: `admin-report-test-details`,
        params: { id: quizId },
      });
    },
  },
};
</script>

<style>
.data-table{
  background-color: transparent !important;
}

.open-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #f4f5f6;
  text-transform: none;
}
</style>
