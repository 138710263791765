import settingsServices from "@services/settings";

const initialState = () => ({
  selectedTab: 0,
  notificationShown: true,
  notifications: {},
  notificationsLoaded: false,
  drawer: false,
});

const state = initialState();

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  /**
   * Mutation that sets the new selectedTab
   *
   * @returns {null}
   */
  changeTab: (state, tab) => {
    state.selectedTab = tab;
  },
  /**
   * Mutation that toggles the notifications
   *
   * @returns {null}
   */
  toggleNotificationShown: (state) => {
    state.notificationShown = !state.notificationShown;
  },
  /**
   * Mutation that hides the notifications
   *
   * @returns {null}
   */
  notificationsHide: (state) => {
    state.notificationShown = false;
  },

  setNotifications(state, notifications) {
    state.notifications = notifications;
    state.notificationsLoaded = true;
  },

  setDrawer(state, value) {
    state.drawer = value;
  },
};

const actions = {
  async getNotifications({ commit }) {
    try {
      const { data } = await settingsServices.getMessages();
      const errors = data.error.map((error) => ({text: error, id: Math.random(), type: "error"}));
      const warnings = data.warning.map((warning) => ({text: warning, id: Math.random(), type: "warning"}));
      const infos = data.info.map((info) => ({text: info.text, link: info.link, id: Math.random(), type: "info"}));
      commit("setNotifications", [...errors, ...warnings, ...infos]);
    } catch (err) {
      // do nothing
    }
  },
};

const getters = {
  tabs: (state, getters, rootState, rootGetters) => {
    if (rootGetters["auth/isTeacher"]) {
      return {
        courses: 0,
        projects: 1,
        classes: 2,
        report: 3,
        quiz: 4,
        support: 5,
      };
    }
    if (rootGetters["auth/isStudent"]) {
      return {
        courses: 0,
        projects: 1,
        report: 2,
        quiz: 3,
        support: 4,
      };
    }
    return {
      courses: 0,
      quiz: 1,
      support: 2
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
