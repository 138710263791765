import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Settings/get_settings_messages
 */
function getMessages() {
  return api.get("/settings/messages");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Settings/post_settings_messages
 */
function setMessages(messages) {
  return api.post("/settings/messages", messages);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Settings/get_settings_landing_links
 */
function getLandingLinks() {
  return api.get("/settings/landing-links");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Settings/post_settings_landing_links
 */
function setLandingLinks(links) {
  return api.post("/settings/landing-links", links);
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Settings/get_settings_blog_articles
 */
function getBlogArticles() {
  return api.get("/settings/blog-articles");
}

/**
 * API DOC: https://s4s-api.sorintdev.it/main/docs/rest/index.html#/Settings/post_settings_blog_articles
 */
function setBlogArticles(blogLinks) {
  return api.post("/settings/blog-articles", blogLinks);
}

export default {
  getMessages,
  setMessages,
  getLandingLinks,
  setLandingLinks,
  getBlogArticles,
  setBlogArticles,
};
