<template>
  <v-row v-if="tot_students && pending_students && subscribed_students">
    <v-col>
      <v-row>
        <v-btn
          color="admin_primary"
          class="white--text ma-5"
          large
          :to="{ name: 'admin-courses' }"
        >
          <v-icon left> mdi-keyboard-backspace </v-icon>INDIETRO
        </v-btn>
      </v-row>
      <v-row>
        <v-col>
          <CardProgressBar
            icon="mdi-playlist-check"
            color="green"
            title="Iscritti"
            :tot="tot_students"
            :value="subscribed_students"
          />
        </v-col>
        <v-col>
          <CardProgressBar
            icon="mdi-account-clock"
            color="red"
            title="Pendings"
            :tot="tot_students"
            :value="pending_students"
          />
        </v-col>
        <v-col cols="3">
          <Card
            icon="mdi-account-multiple"
            color="orange"
            title="Totale"
            :value="tot_students"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import courseService from "@/services/courses";
import CardProgressBar from "../admin/CardProgressBar.vue";
import Card from "../admin/Card.vue";
import courseHandler from "@mixins/courseHandler";
export default {
  name: "AdminInfoSection",
  components: {
    CardProgressBar,
    Card,
  },
  mixins: [courseHandler],
  data: () => ({
    tot_students: "",
    pending_students: "",
    subscribed_students: "",
  }),
  async created() {
    const response = await courseService.getSubscribedNumber(this.course.id);
    this.subscribed_students = response.data.students.toString();
    this.pending_students = response.data.pending_students.toString();
    this.tot_students = (
      parseInt(this.subscribed_students, 10) +
      parseInt(this.pending_students, 10)
    ).toString();
  },
};
</script>
