<template>
  <v-col
    class="pa-0"
    cols="12"
  >
    <h4 class="section-title">
      DEFINIZIONE DEI PUNTI
    </h4>
    <v-col class="pa-0">
      <v-row
        class="mb-2"
        align="center"
        no-gutters
      >
        <span class="grey--text">
          N° punti per passare il test:
        </span>
        <input
          type="number"
          class="input-number pl-3 ml-2 ml-md-0 mx-md-5 rounded-textbox"
          :value="initialStructure.min_points || 0"
          @input="($event) => setTestNumber($event, 'min')"
        >
      </v-row>
      <v-row
        class="mb-2"
        align="center"
        no-gutters
      >
        <span class="grey--text">
          N° punti per certifica eccellente:
        </span>
        <input
          type="number"
          :value="
            initialStructure.min_points_excellence || 0
          "
          class="input-number pl-3 ml-2 ml-md-0 mx-md-5 rounded-textbox"
          @input="
            ($event) => setTestNumber($event, 'excellence')
          "
        >
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
import { sendNotification } from "../../../utils/notifications";
export default {
  name: "QuizSelection",
  emits: ["onUpdateSelectedTestNumbers"],
  props: {
    initialStructure: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedTestNumbers: {},
    };
  },
  watch: {
    selectedTestNumbers() {
      this.updateSelectedTestNumbers();
    },
  },
  methods: {
    setTestNumber(event, key) {
      const { srcElement } = event;
      const { value } = srcElement;
      const integerValue = Number.parseInt(value, 10);
      if (isNaN(integerValue)) {
        sendNotification("Valore non valido", "error");
        return;
      }
      const objectToAdd = {};
      objectToAdd[key] = integerValue;
      this.selectedTestNumbers = {
        ...this.selectedTestNumbers,
        ...objectToAdd,
      };
    },
    updateSelectedTestNumbers() {
      this.$emit(
        "onUpdateSelectedTestNumbers",
        this.selectedTestNumbers
      );
    },
  },
};
</script>
