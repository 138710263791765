import eventActivitiesService from "@services/eventActivities";

import { handleAxiosError } from "@utils/notifications";

const actions = {
  async getActivities({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await eventActivitiesService.getActivities();
      return data;
    } catch (err) {
      await handleAxiosError("Errore nel recupero delle attività.", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async downloadEventSubscriptions({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await eventActivitiesService.downloadEventSubscriptions();
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = "elenco_iscritti_evento.xlsx";
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        "Errore nel download elenco iscrizioni evento.",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async downloadActivities({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await eventActivitiesService.downloadActivities();
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = "elenco_iscritti_attivita.xlsx";
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        "Errore nel download elenco iscrizioni attività.",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async downloadNewsletters({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await eventActivitiesService.downloadNewsletters();
      const blob = new Blob([data]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement("a");
        a.download = "elenco_iscritti_newsletters.xlsx";
        a.href = e.target.result;
        a.click();
      };
    } catch (err) {
      await handleAxiosError(
        "Errore nel download elenco iscrizioni newsletters.",
        err
      );
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async createActivities({ commit }, { newActivity }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await eventActivitiesService.createActivities(newActivity);
    } catch (err) {
      await handleAxiosError("Errore nella creazione dell'attività.", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async updateActivities({ commit }, { newActivity }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await eventActivitiesService.updateActivities(newActivity);
    } catch (err) {
      await handleAxiosError("Errore nella modifica dell'attività.", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteActivities({ commit }, activityId) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await eventActivitiesService.deleteActivities(activityId);
    } catch (err) {
      await handleAxiosError("Errore nell'eliminazione' dell'attività.", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async approveStudentParticipation({ commit }, { activityId, studentId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await eventActivitiesService.approveStudentParticipation(
        activityId,
        studentId
      );
    } catch (err) {
      await handleAxiosError("Errore nell'approvazione dello studente.", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async rejectStudentParticipation({ commit }, { activityId, studentId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await eventActivitiesService.rejectStudentParticipation(
        activityId,
        studentId
      );
    } catch (err) {
      await handleAxiosError("Errore nel rifiuto dell'iscrizione.", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
};

export default {
  namespaced: true,
  actions,
};
