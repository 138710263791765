<template>
<div>
  <h2 class="resource-title mb-2">Esercizio</h2>
  <v-card class="pa-2 rounded-lg" color="#1d1c4c" dark>
    <v-form v-model="isFormValid">
      <v-text-field
        v-model="ondemandExercise.title"
        :rules="[rules.required, rules.maxLengthTitle]"
        label="Titolo dell'esercizio"
        required
      />
      <v-text-field
        v-model="ondemandExercise.hint"
        :rules="[rules.required]"
        label="Suggerimento esercizio"
        required
      />
      <v-textarea
        v-model="ondemandExercise.text"
        :rules="[rules.required]"
        outlined
        label="Testo dell'esercizio"
      />
      <v-combobox
        v-if="!changeSection"
        v-model="ondemandExercise.sectionName"
        :items="sections"
        :rules="[rules.required]"
        label="Seleziona sezione"
      />
      <v-autocomplete
        v-if="changeSection"
        v-model="ondemandExercise.sectionName"
        :items="sections"
        :rules="[rules.required]"
        label="Cambia sezione"
      />
    </v-form>
    <v-card-actions>
      <v-row v-if="isEditable" no-gutters justify="start"><v-switch v-model="changeSection" label="Cambia sezione" /></v-row>
      <v-row no-gutters justify="end">
        <v-btn color="#F39200" class="white--text mr-2 rounded-lg" :disabled="!isFormValid" @click="saveExercise">Salva</v-btn>
        <v-btn v-if="isEditable" color="red" class="white--text mr-2 rounded-lg" @click="deleteOndemandExercise">Elimina esercizio</v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import sectionManager from "@mixins/sectionManager.js";
export default {
  name: "ProgramExercisesResourceManagement",
  mixins: [sectionManager],
  props: {
    resourceId: {
      type: String,
      required: true,
    },
    requestToTrue: {
      type: Function,
      required: true,
    },
    cleanResources: {
      type: Function,
      required: true,
    }
  },
  data: () => ({
    isFormValid: false,
    ondemandExercise: {
      hint: "",
      learningPathId: "",
      sectionName: "",
      title: "",
      text: "",
    },
    sections: [],
    rules: {
      required: (v) => Boolean(v) || "Elemento obbligatorio",
      maxLengthTitle: (v) => v?.length < 255 || "Lunghezza massima superata",
    },
  }),
  watch: {
    async resourceId(value) {
      if(value !== ""){
        await this.getData();
      } else {
        this.ondemandExercise = {};
      }
    }
  },
  computed: {
    isEditable() {
      return this.resourceId !== "";
    },
  },
  async created() {
    const data = await this.getOndemandById({
      id: this.$route.params.id,
    });
    const sections = data.sections?.map((value) => value.name);
    this.sections = [...new Set(sections)];
    if (!this.isEditable) {
      this.ondemandExercise.learningPathId = this.$route.params.id;
    } else {
      await this.getData();
    }
  },
  methods: {
    ...mapActions("learningPaths", ["getOndemandById"]),
    ...mapActions("learningResources", ["createOndemandExercise", "getOndemandExerciseDetails", "deleteOndemandResource", "getOndemandExerciseHint", "updateOndemandExercise", "changeResourceOrder"]),
    async saveExercise() {
      if(this.changeSection) {
        const requiredPosition = await this.getRequiredOrder(this.ondemandExercise.sectionName);
        await this.changeResourceOrder({ resourceId: this.ondemandExercise.learningPathId, newPosition: requiredPosition });
      }
      if(!this.isEditable) {
        await this.createOndemandExercise({ ondemandExercise: this.ondemandExercise });
      } else{
        await this.updateOndemandExercise({ ondemandExercise: this.ondemandExercise });
      }
      this.requestToTrue();
    },
    async getData() {
      const hint = await this.getOndemandExerciseHint({ id: this.resourceId });
      const dataOndemandExercise = await this.getOndemandExerciseDetails({ id: this.resourceId });
      this.ondemandExercise.title = dataOndemandExercise.title;
      this.ondemandExercise.sectionName = dataOndemandExercise.section_name;
      this.ondemandExercise.text = dataOndemandExercise.text;
      this.ondemandExercise.hint = hint.hint;
      this.ondemandExercise.learningPathId = this.resourceId;
    },
    async deleteOndemandExercise() {
      await this.deleteOndemandResource({ id: this.ondemandExercise.learningPathId });
      this.requestToTrue();
      this.cleanResources();
    }
  },
};
</script>

<style scoped>
.resource-title{
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 1px;
  color: #FFFFFF;
}
</style>