<template>
  <Modal
    :title="'Modifica domanda'"
    :background="false"
    :glassify="true"
    :bit-text="false"
    :width="'1280px'"
    @closeModal="() => $emit('onCloseEditQuestion')"
  >
    <template #content>
      <QuestionForm
        :current-question="selectedQuestion"
        :original-question="originalSelectedQuestion"
        :quiz-info="selectedQuiz"
        :hide-preview="true"
        @onResetIndex="() => $emit('onCloseEditQuestion')"
        @onAddEmptyAnswer="addEmptyAnswer"
      />
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "../../../utils/Modal.vue";
import QuestionForm from "../QuestionForm.vue";
export default {
  name: "EditQuestionModal",
  components: { Modal, QuestionForm },
  props: {
    selectedQuestion: {
      type: Object,
      required: true,
    },
    originalSelectedQuestion: {
      type: Object,
      required: true,
    },
  },
  computed:{
    ...mapGetters("quiz",["selectedQuiz"])
  },
  methods:{
    addEmptyAnswer(answer){
      this.selectedQuestion.answers.push(
        answer
      );
    },
  }
};
</script>
