export default {
  props: {
    course: {
      required: true,
      type: Object,
    },
    episode: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    lastLiveDay() {
      return new Date(
        Math.max(
          ...this.course.episodes.map((episode) => Number(episode.end))
        ) * 1000
      ).setHours(23, 59, 59, 0);
    },
    liveEpisode() {
      const now = Date.now() / 1000;
      const currentEpisodes = this.course.episodes.filter(
        (episode) => now <= episode.end && now >= episode.start
      );
      return currentEpisodes.length > 0 ? currentEpisodes[0] : null;
    },
    todayLiveEpisode() {
      const today = new Date().setHours(0, 0, 0, 0);
      const currentEpisodes = this.course.episodes.filter((episode) => {
        const episodeStartDate = new Date(episode.start * 1000);
        const episodeDay = new Date(
          episodeStartDate.getFullYear(),
          episodeStartDate.getMonth(),
          episodeStartDate.getDate()
        ).getTime();
        return today === episodeDay;
      });
      return currentEpisodes.length > 0 ? currentEpisodes[0] : null;
    },
    lastLiveEpisodeDone() {
      const today = new Date().setHours(0, 0, 0, 0);
      const lastEpisodesDone = this.course.episodes.filter((episode) => {
        const episodeStartDate = new Date(episode.start * 1000);
        const episodeDay = new Date(
          episodeStartDate.getFullYear(),
          episodeStartDate.getMonth(),
          episodeStartDate.getDate()
        ).getTime();
        return today > episodeDay && today < this.lastLiveDay;
      });
      return lastEpisodesDone.length > 0
        ? lastEpisodesDone[lastEpisodesDone.length - 1]
        : null;
    },
    isCorrectEpisode() {
      return !(
        this.liveEpisode !== null && this.liveEpisode.id !== this.episode.id
      );
    },
  },
  methods: {
    getEpisodeLink(episode) {
      return `/course/${this.course.id}/video/${episode.videoId}`;
    },
  },
};
