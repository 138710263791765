<template>
  <v-container
    class="rounded-t-lg mt-5 admin_background elevation-12 px-8 py-8"
  >
    <v-row justify="space-between" align="center">
      <v-col cols="12">
        <h2>Invia Comunicazione</h2>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-6">
      <v-col cols="12" md="6" class="pr-md-4">
        <v-select
          hide-selected
          label="DESTINATARI"
          :items="categories"
          item-text="label"
          item-value="name"
          v-model="activeCategory"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" class="pl-md-4">
        <v-text-field
          v-model="activeCategory"
          color="purple darken-2"
          label="CATEGORIA SELEZIONATA"
          class="input-content"
          readonly
          required
        />
      </v-col>
    </v-row>
    <!-- <v-row v-if="activeCategory === 'test_emails'" no-gutters class="mt-3">
      <v-col cols="12">
        <v-text-field
          color="purple darken-2"
          label="INDIRIZZI EMAIL"
          class="input-content"
          required
        />
      </v-col>
    </v-row> -->
    <v-row no-gutters class="mt-3">
      <v-col cols="12">
        <v-text-field
          v-model="subject"
          color="purple darken-2"
          label="OGGETTO"
          class="input-content"
          required
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-3">
      <v-col cols="12">
        <v-card
          color="#01002e"
          class="rounded-lg border mail-component-wrapper pb-3"
        >
          <TiptapVuetify
            v-model="content"
            :extensions="extensions"
            :toolbar-attributes="{
              color: 'rgba(0, 0, 0, 0)',
              short: true,
              class: 'ml-5 ml-md-0',
            }"
            :card-props="{
              flat: true,
              color: 'rgba(0, 0, 0, 0)',
              rounded: '50',
            }"
            class="input-content"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters justify="space-between" class="mt-6">
      <v-col align="start" cols="9">
        <v-chip
          v-for="(attachment, index) in attachments"
          :key="index"
          draggable
          close
          @click:close="removeAttachment(index)"
          :href="`${publicUrl}/email/attachment/${attachment.filename}`"
          color="#1D1C4C"
          class="mr-2 mb-2"
        >
          <v-icon class="mr-2">mdi-file</v-icon>
          {{ attachment.label }}
        </v-chip>
      </v-col>
      <v-col align="end" cols="12" md="3">
        <v-btn icon class="mr-2" @click="newAttachment">
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
        <v-btn
          class="rounded-lg"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          medium
          tile
          dark
          depressed
          color="primary"
          type="button"
          @click="sendEmail"
        >
          <v-icon small class="mr-2">mdi-send</v-icon>
          Invia Email
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import { mapActions, mapGetters } from "vuex";
import { sendNotification } from "@/utils/notifications";
export default {
  name: "ComposeEmailContainer",
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      subject: "Comunicazione di Servizio",
      activeCategory: "all",
      categories: [
        { label: "All Users", name: "all" },
        { label: "All Professors", name: "profs" },
        { label: "Active Professors", name: "profs_with_class" },
        { label: "All Students", name: "students" },
        { label: "Active Students", name: "students_with_class" },
        { label: "All Secondary Emails", name: "all_with_secondary_email" },
        { label: "TEST Emails", name: "test_emails" },
      ],
      attachments: [],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      content: `<h1>Ciao a tutti!</h1>
      <p>Come state?</p>`,
    };
  },
  computed: {
    ...mapGetters("config", ["backendUrl, publicUrl"]),
  },
  methods: {
    ...mapActions("mailingList", [
      "sendEmailToCategory",
      "addAttachment",
      "deleteAttachment",
    ]),
    async sendEmail() {
      try {
        let emailBody = this.content;
        emailBody = emailBody.concat(`<h3>Allegati:</h3><ul>`);
        this.attachments.forEach((attachment) => {
          emailBody = emailBody.concat(
            `<li><a href="${this.publicUrl}/email/attachment/${attachment.filename}" download="${attachment.label}">${attachment.label}</a></li>`
          );
        });
        emailBody = emailBody.concat(`</ul><br>`);

        await this.sendEmailToCategory({
          subject: this.subject,
          category: this.activeCategory,
          body: emailBody,
        });
        sendNotification(`Comunicazione inviata con successo!`, "success");
        this.resetMailForm();
      } catch (error) {
        sendNotification(
          `Errore durante l'invio della comunicazione.`,
          "error"
        );
        console.log(error);
      }
    },
    async removeAttachment(index) {
      try {
        this.deleteAttachment({
          filename: this.attachments.at(index).filename,
        });
        this.attachments.splice(index, 1);
      } catch (err) {
        console.error(err);
      }
    },
    async newAttachment() {
      try {
        let fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.multiple = true;
        fileInput.accept =
          ".xls, .xlsx, .ods, .doc, .docx, .odt, .png, .pdf, .jpeg, .jpg, .jpe, .jfif";

        fileInput.onchange = async (_) => {
          let selectedFiles = Array.from(fileInput.files);
          for (const attachment of selectedFiles) {
            let data = new FormData();
            data.append("file", attachment, attachment.name);
            let filename = await this.addAttachment({ file: data });
            this.attachments.push({ label: attachment.name, filename });
          }

          this.attachments.sort((a, b) => a.label.localeCompare(b.label));
        };

        fileInput.click();
      } catch (err) {
        console.error(err);
      }
    },
    resetMailForm() {
      this.content = `<h1>Ciao a tutti!</h1>
      <p>Come state?</p>`;
      this.subject = "Comunicazione di Servizio";
      this.attachments = [];
      this.activeCategory = "all";
    },
  },
};
</script>

<style>
.input-content {
  font-weight: 500;
}

.mail-component-wrapper {
  border: thin solid #f4f5f6 !important;
}
</style>
