<template>
  <div class="chat-container">
    <div class="chat-general">
      <iframe
        ref="iframe"
        class="iframe-player"
        title="Chat"
        :src="`${rocketChatUrl}/${path}?${embedded ? 'layout=embedded' : ''}`"
        @load="login"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Chat",

  props: {
    channel: {
      type: String,
      default: "general",
    },
    embedded: {
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters("config", ["rocketChatUrl"]),
    ...mapState("chat", ["authToken"]),
    path() {
      if (this.channel !== "") {
        return `${this.channel}`;
      }
      return "home";
    },
  },
  methods: {
    ...mapActions("chat", ["chatLogin"]),
    login() {
      const receiverWindow = this.$refs.iframe.contentWindow;
      this.$logger("iframe logout")
      receiverWindow.postMessage(
        {
          externalCommand: "logout",
        },
        this.rocketChatUrl
      );
      this.$logger(`iframe logout done from ${this.rocketChatUrl}`)
      if (this.authToken !== "") {
        this.iFrameLogin(this.authToken);
      } else {
        this.$logger("retrieve token from BE...")
        this.chatLogin().then((token) => {
          this.iFrameLogin(token);
        });
      }
    },
    iFrameLogin(token) {
      this.$logger("iframe login-with-token: ", token)
      const receiverWindow = this.$refs.iframe.contentWindow;
      this.$logger("receiverWindow: ", receiverWindow)
      receiverWindow.postMessage(
        {
          externalCommand: "login-with-token",
          token,
        },
        this.rocketChatUrl
      );
      this.$logger("postMessage login-with-token done")
    },
  },
};
</script>

<style scoped>
.chat-container,
.chat-general,
.iframe-player {
  height: 100%;
  width: 100%;
}
.iframe-player {
  border: none;
}
</style>
