<template>
  <div class="mt-2">
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
        xl="3"
      >
        <CardProgressBar
          icon="mdi-library"
          color="#1cc88a"
          title="Profs"
          :tot="tot"
          :value="professorsNum"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        xl="3"
      >
        <CardProgressBar
          icon="mdi-domain"
          color="#E76D21"
          title="Sorintians"
          :tot="tot"
          :value="sorintiansNum"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        xl="3"
      >
        <CardProgressBar
          icon="mdi-book"
          color="#4e73df"
          title="Students"
          :tot="tot"
          :value="studentsNum"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        xl="3"
      >
        <CardProgressBar
          icon="mdi-account-multiple"
          color="#36b9cc"
          title="Guests"
          :tot="tot"
          :value="guestsNum"
        />
        <span class="d-flex justify-end amount-number">
          <strong>TOTAL USERS:</strong>{{ tot }}
        </span>
      </v-col>
    </v-row>
    <v-container class="rounded-lg elevation-12 admin_background">
      <v-row class="py-3 ">
        <v-col
          class="d-flex justify-start"
          cols="12"
          md="3"
        >
          <v-btn
            v-if="user === 'Students'"
            class="rounded-lg"
            medium
            tile
            dark
            depressed
            color="#636490"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            type="button"
            @click="downloadPendingStudents"
          >
            download pending students
          </v-btn>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndDown && user === 'Profs'"
          cols="12"
        >
          <v-btn
            class="rounded-lg"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            medium
            tile
            dark
            depressed
            color="#1cc88a"
            type="button"
            @click="downloadTeachersInfo(false)"
          >
            download all profs
          </v-btn>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndDown && user === 'Profs'"
          cols="12"
        >
          <v-btn
            class="rounded-lg"
            medium
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            tile
            dark
            depressed
            color="#AF61EB"
            type="button"
            @click="downloadTeachersInfo(true)"
          >
            download active profs
          </v-btn>
        </v-col>
        <v-col
          v-if="user === 'Profs' && $vuetify.breakpoint.mdAndUp"
          cols="12"
          md="9"
          class="d-flex justify-end"
        >
          <v-btn
            class="rounded-l-lg"
            medium
            tile
            dark
            depressed
            color="#1cc88a"
            type="button"
            @click="downloadTeachersInfo(false)"
          >
            download all profs
          </v-btn>
          <v-btn
            class="rounded-r-lg"
            medium
            tile
            dark
            depressed
            color="#AF61EB"
            type="button"
            @click="downloadTeachersInfo(true)"
          >
            download active profs
          </v-btn>
        </v-col>
        <v-col
          v-if="user === 'Students'"
          cols="12"
          md="9"
          class="d-flex justify-end"
        >
          <v-btn
            class="rounded-lg"
            medium
            tile
            dark
            depressed
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            color="#4e73df"
            type="button"
            @click="downloadStudentsInfo"
          >
            download students
          </v-btn>
        </v-col>
        <v-col
          v-if="user === 'Guests'"
          cols="12"
          md="9"
          class="d-flex justify-end"
        >
          <v-btn
            class="rounded-lg"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            medium
            tile
            dark
            depressed
            color="#36b9cc"
            type="button"
            @click="downloadGuestsInfo"
          >
            download guests
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 rounded-t-0">
          <DataTable :update-user="updateUser" />
        </v-col>
      </v-row>
    </v-container>
    <ComposeEmailContainer />
  </div>
</template>

<script>
import DataTable from "./DataTable";
import CardProgressBar from "./CardProgressBar.vue";
import services from "@services/admin";
import { mapActions } from "vuex";
import ComposeEmailContainer from "./ComposeEmailContainer.vue";

export default {
  name: "MailingList",
  components: {
    DataTable,
    CardProgressBar,
    ComposeEmailContainer,
  },
  data() {
    return {
      tot: "",
      sorintiansNum: "0",
      studentsNum: "0",
      professorsNum: "0",
      guestsNum: "0",
      user: "Students",
      maxlength: 1900,
    };
  },
  async created() {
    await this.getStatistics();
  },
  methods: {
    ...mapActions("admin", ["downloadPendingStudents", "downloadStudentsInfo", "downloadTeachersInfo", "downloadGuestsInfo"]),
    async getStatistics() {
      const { data } = await services.getUserStatistics();
      this.guestsNum = String(data.num_visitors);
      this.sorintiansNum = String(data.num_sorintians);
      this.studentsNum = String(data.num_students);
      this.professorsNum = String(data.num_professors);
      this.tot = String(data.num_students + data.num_visitors + data.num_sorintians + data.num_professors)
    },
    updateUser(_user) {
      this.user = _user;
    },
  },
};
</script>

<style>
.amount-number{
  font-weight: 400;
}
</style>
