<template>
  <v-container
    class="px-0 pt-2 pb-0 chat-container"
    fill-height
  >
    <v-overlay
      v-if="disabled"
      absolute
      class="fill-height"
      opacity="0.5"
    >
      <v-tooltip top>
        <template #activator="{ on }">
          <v-row v-on="on">
            <v-col>
              <v-icon
                color="white"
                x-large
                class="mb-7"
              >
                mdi-lock
              </v-icon>
            </v-col>
          </v-row>
        </template>

        <span> Chat chiusa </span>
      </v-tooltip>
    </v-overlay>
    <v-chip
      v-if="wantsToReadMessages"
      class="ma-2 scroller"
      color="grey"
      label
      text-color="white"
      @click="() => updateWantsToReadMessages(false)"
    >
      Scorri in automatico chat
      <v-icon right>
        mdi-arrow-down
      </v-icon>
    </v-chip>
    <MessageList
      :messages="messages"
      :channel-id="channelId"
      :user-id="userId"
      :wants-to-read-messages="wantsToReadMessages"
      @onWantsToReadMessages="updateWantsToReadMessages"
    />
    <MessageInput
      :disabled="disabled"
      :channel-id="channelId"
      @messageSended="sendMessage"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import chatService from "@services/chat";
import MessageList from "./MessageList.vue";
import MessageInput from "./MessageInput.vue";

export default {
  name: "Chat",
  components: { MessageList, MessageInput },
  props: {
    channelId: {
      type: String,
      required: true,
    },
    publicChannel: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      messages: [],
      show: false,
      wantsToReadMessages: false,
    };
  },
  computed: {
    ...mapState("chat", ["userId", "authToken"]),
    ...mapGetters("config", ["rocketchatWsUrl"]),
  },
  async created() {
    if (this.channelId) {
      await this.chatLogin();
      this.startWs();
      this.getMessages();
    }
  },
  methods: {
    ...mapActions("chat", ["chatLogin"]),
    async getMessages() {
      const { data } = await chatService.loadRoomMessages(
        this.channelId,
        this.publicChannel
      );
      // messages with "t" property are of the kind "user X entered the channel", we don't want to show them
      this.messages = data.messages
        .filter((m) => !m.t)
        .map((m) => ({
          msg: m.msg,
          u: m.u,
          ts: new Date(m.ts),
          show_time: false,
        }))
        .reverse();

      await chatService.setMessagesRead(this.channelId);
    },

    async startWs() {
      this.connection = new WebSocket(this.rocketchatWsUrl);

      this.connection.onmessage = this.onMessageReceived;

      this.connection.onclose = this.startWs;

      this.connection.onopen = await this.onConnectionOpen;
    },
    onMessageReceived(event) {
      const data = JSON.parse(event.data);
      if (
        data.msg === "changed" &&
        data.collection === "stream-room-messages" &&
        data.fields.args[0].u._id !== this.userId
      ) {
        const [msg] = data.fields.args;
        if (!msg.t) {
          this.messages = [
            ...this.messages,
            {
              msg: msg.msg,
              u: { _id: msg.u._id, name: msg.u.name },
              ts: new Date(msg.ts.$date),
            },
          ];
        }
      }
    },
    onConnectionOpen() {
      let data = {
        msg: "connect",
        version: "1",
        support: ["1"],
      };
      this.connection.send(JSON.stringify(data));

      data = {
        msg: "method",
        method: "login",
        id: `id${window.performance.now()}`,
        params: [{ resume: this.authToken }],
      };

      this.connection.send(JSON.stringify(data));

      data = {
        msg: "method",
        method: "joinRoom",
        id: `id${window.performance.now()}`,
        params: [this.channelId, "joinCode"],
      };
      this.connection.send(JSON.stringify(data));

      data = {
        msg: "sub",
        id: `id${window.performance.now()}`,
        name: "stream-room-messages",
        params: [this.channelId, false],
      };

      this.connection.send(JSON.stringify(data));
    },
    sendMessage(data) {
      this.connection.send(JSON.stringify(data));

      this.messages = [
        ...this.messages,
        {
          msg: data.params[0].msg,
          u: { _id: this.userId },
          ts: new Date(),
          show_time: false,
        },
      ];
    },
    updateWantsToReadMessages(value) {
      this.wantsToReadMessages = value;
    },
  },
};
</script>

<style scoped>
.scroller {
  position: absolute;
  z-index: 10;
  bottom: 75px;
  left: 50%;
  transform: translate(-50%, 0);
}

.chat-container {
  max-height: 587px;
}
</style>
