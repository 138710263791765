class Teacher {
  constructor(id, name, surname, email, school, subject, headmaster, verified) {
    this.id = id;
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.school = school;
    this.subject = subject;
    this.headmaster = headmaster;
    this.verified = verified;
  }
}

export default Teacher;
